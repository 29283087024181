import { useState, useContext, useEffect } from 'react'
import IOPage from '../Components/IOPage';
import LanguageSelector from './ComponentsStylish/LanguageSelector';
import DownloadButton from './ComponentsStylish/Buttons/DownloadButton';
import UploadButton from './ComponentsStylish/Buttons/UploadButton';
import SaveSettingsButton from './ComponentsStylish/Buttons/SaveSettingsButton';
import FoundryConnectorButton from './ComponentsStylish/Buttons/FoundryConnectorButton';
import {InputField} from './ComponentsStylish/InputField';
import CharacterSheetHeader from './SubPagesCharacterSheet/CharacterSheetHeader'
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext,ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext,TextDataContextProvider} from '../API/TextDataContext'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import label_style from '../StylesStylish/label.module.css'
import field_style from '../StylesStylish/field.module.css'
import table_style from '../StylesStylish/table.module.css'
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const SettingsPage = () => {
    const {
        API_switch_language,
        API_get_language_list,
        API_init_character,
        API_update_base_stat,
        API_update_campaign_name,
        API_set_tool_settings_foundry_url
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels
    } = useContext(TextDataContext);

    const {
        API_get_tool_data,
        API_set_tool_data,
        API_get_tool_settings,
        API_set_tool_settings,
        API_get_dice_log,
        API_get_dice_log_visibility,
        API_set_dice_log_visibility
    } = useContext(ToolDataContext);

    const {
        API_get_remote_connect_message,
        API_set_remote_connect_message,
        //API_get_legacy_login, 
        //API_set_legacy_login,
        API_get_user
    } = useContext(UserDataContext);

    const switchLanguage = (_language) => {
        API_switch_language(_language);
    }

    return ( 
        <div>
            <div>
                <table className={table_style.std_table}>
                    <tr>
                        <th colspan={2}>
                            {API_get_tab_view_labels().settings}
                        </th>
                    </tr>
                    <tr style={{height: 12}}>
                        
                    </tr>
                    <tr>
                        <td style={{width: 120, minWidth: 120}}>
                            {API_get_std_labels().version}
                        </td>
                        <td style={{width: 380, minWidth: 380}}>
                            v{API_get_tool_data().version.main}.{API_get_tool_data().version.sub}.{API_get_tool_data().version.rev}
                        </td>
                        <td style={{width: 80, minWidth: 80}}>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {API_get_std_labels().language}
                        </td>
                        <td>
                            <LanguageSelector />
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {API_get_std_labels().import_local}
                        </td>
                        <td>
                            <UploadButton />
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {API_get_std_labels().export_local}
                        </td>
                        <td>
                            <DownloadButton />
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {API_get_std_labels().foundry_url}
                        </td>
                        <td style={{minHeight:25, height:25}}>
                            <InputField type='text' widthValue={360} labelVisibility={false} content={API_get_tool_settings().foundry_url} setter={API_set_tool_settings_foundry_url}/>
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <FoundryConnectorButton />
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{marginLeft: 5, marginTop: 12}}>
                <div style={{display: 'inline-block'}}>
                    <SaveSettingsButton height={30}/>
                </div>
                <div style={{display: 'inline-block', height: '100%', marginLeft: 10}}>
                    <label style={{verticalAlign: 'middle', paddingBottom: 12}}>{API_get_remote_connect_message()}</label>
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;