import { useContext } from 'react'
import {APIContext} from '../../../API/API'
import {AttributeContext} from '../../../API/AttributeContext'
import {ScreenDataContext} from '../../../API/ScreenDataContext'
import {TextDataContext} from '../../../API/TextDataContext'
import CharacterSkillSheetTableHeader from '../SubPagesCharacterSkillSheet/CharacterSkillSheetTableHeader';
import CharacterSkillSheetTable from '../SubPagesCharacterSkillSheet/CharacterSkillSheetTable';
import CharacterSkillSheetHeader from '../SubPagesCharacterSkillSheet/CharacterSkillSheetHeader';
import circleImg from '../../../Rss/Images/Zirkel.png';


const MagicSkillSheet = () => {

    const {
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        API_get_attribute_group_by_id,
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_skill_group_screen,
        API_set_skill_group_screen,
        API_get_magic_screen,
        API_set_magic_screen,
        API_get_skill_group_tab_name
    } = useContext(ScreenDataContext);

    const {
        API_get_tab_view_labels
    } = useContext(TextDataContext);

    const getTalentTabBar = () => {
        let _groups = API_get_attributes();
        let _mainTabBar = [
            {content: API_get_tab_view_labels().magic_skills, screen: 'magic_skills'},
            {content: API_get_tab_view_labels().magic_spells, screen: 'magic_spells'}
        ];

        return _mainTabBar;
    }

    const getVisibility = () => {
        return API_get_attribute_group_by_id(API_get_attribute_group_ref().magic).focus >= 0 ;
    }

    return ( 
        <div>
            <div style={{ marginTop: 15 }}>
                <CharacterSkillSheetHeader group_id={API_get_attribute_group_ref().magic} />
            </div>
            <div style={{ display: 'inline-block', width: 777, minWidth: 777, marginRight: 16, marginTop: 15, verticalAlign: 'top' }}>
                <div>
                    <div>
                        <CharacterSkillSheetTableHeader attribute_id={API_get_attribute_ref().magic_resistance} />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <CharacterSkillSheetTable attribute_id={API_get_attribute_ref().magic_resistance} />
                    </div>
                </div>
                <div style={{marginTop: 45, textAlign: 'center'}}>
                    {getVisibility() &&
                        <img src={circleImg} style={{width: 450}} alt="Logo" />
                    }
                </div>
            </div>
            {getVisibility() &&
                <div style={{ display: 'inline-block', width: 777, minWidth: 777, marginTop: 15, verticalAlign: 'top' }}>
                    <div>
                        <CharacterSkillSheetTableHeader attribute_id={API_get_attribute_ref().arcana} />
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <CharacterSkillSheetTable attribute_id={API_get_attribute_ref().arcana} />
                    </div>
                </div>
            }
        </div>
     );
}
 
export default MagicSkillSheet