import { useState, useContext } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {InputField} from '../../ComponentsStylish/InputField';
import {
    ParseToInteger,
    TryParse
} from '../../../API/Functions/GeneralFunctions'
import table_style from '../../../StylesStylish/table.module.css'

const MoneyTable = () => {
    const {
        API_modify_money_list
    } = useContext(APIContext);

    const {
        API_get_std_header_labels,
        API_get_money_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_money_list
    } = useContext(BaseStatContext);

    //const [editMoneyList, setEditMoneyList] = useState(false);
    const [moneyListInput, setMoneyListInput] = useState([0,0,0,0]);

    /*const toggleEditMoneyList = () => {
        setMoneyListInput(getMoneyList());
        setEditMoneyList(!editMoneyList);
    }*/

    const modifyMoneyList = (_index, _value) => {
        API_modify_money_list(_index, _value);
    }

    const modifyEternium = (_newValue) => {
        tryModifyMoneyListInput(0, _newValue);
    }

    const incrementEternium = (_increment) => {
        let _moneyListInput = {...moneyListInput};
        let _value = _moneyListInput[0];
        tryModifyMoneyListInput(0, _value+_increment);
    }

    const modifyGold = (_newValue) => {
        tryModifyMoneyListInput(1, _newValue);
    }

    const incrementGold = (_increment) => {
        let _moneyListInput = {...moneyListInput};
        let _value = _moneyListInput[1];
        tryModifyMoneyListInput(1, _value+_increment);
    }

    const modifySilver = (_newValue) => {
        tryModifyMoneyListInput(2, _newValue);
    }

    const incrementSilver = (_increment) => {
        let _moneyListInput = {...moneyListInput};
        let _value = _moneyListInput[2];
        tryModifyMoneyListInput(2, _value+_increment);
    }

    const modifyCopper = (_newValue) => {
        tryModifyMoneyListInput(3, _newValue);
    }

    const incrementCopper = (_increment) => {
        let _moneyListInput = {...moneyListInput};
        let _value = _moneyListInput[3];
        tryModifyMoneyListInput(3, _value+_increment);
    }

    const tryModifyMoneyListInput = (_index, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyMoneyList(_index, _newValue);


        modifyMoneyListInput(_index, _newValue);
    }

    const modifyMoneyListInput = (_index, _value) => {
        let _moneyListInput = {...moneyListInput};
        _moneyListInput[_index] = ParseToInteger(_value);
        setMoneyListInput(_moneyListInput);
    }
    
    /*const getMoneyList = () => {
        return [API_get_money_list()[0].amount, API_get_money_list()[1].amount, API_get_money_list()[2].amount, API_get_money_list()[3].amount];
    }*/

    return ( 
        <div>
            <table className = {table_style.std_table}>
                <tr>
                    <th colspan={5}>
                        {API_get_money_header_labels().money}
                    </th>
                </tr>
                <tr>
                    <td style={{fontWeight: 'bold', textAlign: 'left', paddingLeft: 5}} colspan={2}>{API_get_money_header_labels().coin}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 80}}>{API_get_money_header_labels().worth}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 80}}>{API_get_std_header_labels().weight}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 80}}>{API_get_std_header_labels().amount}</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left', width: 140}}>{API_get_money_list()[0].display_name}</td>
                    <td style={{width: 32, textAlign: 'center'}}>[{API_get_money_list()[0].abbreviation}]</td>
                    <td style={{textAlign: 'center'}}>{API_get_money_list()[0].worth}G</td>
                    <td style={{textAlign: 'center'}}>{1000*API_get_money_list()[0].weight}g</td>
                    <td style={{textAlign: 'center'}}>
                        <InputField type='integer' 
                                    widthValue={28} 
                                    labelVisibility={false} 
                                    content={API_get_money_list()[0].amount} 
                                    setter={modifyEternium} 
                                    buttonOnly={false} 
                                    buttonField={true} 
                                    buttonCallback={incrementEternium}
                                    />
                        {/*!editMoneyList && 
                            API_get_money_list()[0].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[0]} onChange={(e) => tryModifyMoneyListInput(0, e.target.value)} onBlur={(e) => modifyMoneyList(0, e.target.value)} />
                        */}
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left', borderRight: '0px solid black'}}>{API_get_money_list()[1].display_name}</td>
                    <td style={{textAlign: 'center'}}>[{API_get_money_list()[1].abbreviation}]</td>
                    <td style={{textAlign: 'center'}}>{API_get_money_list()[1].worth}G</td>
                    <td style={{textAlign: 'center'}}>{1000*API_get_money_list()[1].weight}g</td>
                    <td style={{textAlign: 'center'}}>
                        <InputField type='integer' 
                                    widthValue={28} 
                                    labelVisibility={false} 
                                    content={API_get_money_list()[1].amount} 
                                    setter={modifyGold} 
                                    buttonOnly={false} 
                                    buttonField={true} 
                                    buttonCallback={incrementGold}
                                    />
                        {/*!editMoneyList && 
                            API_get_money_list()[1].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[1]} onChange={(e) => tryModifyMoneyListInput(1, e.target.value)} onBlur={(e) => modifyMoneyList(1, e.target.value)} />
                        */}
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left', borderRight: '0px solid black'}}>{API_get_money_list()[2].display_name}</td>
                    <td style={{textAlign: 'center'}}>[{API_get_money_list()[2].abbreviation}]</td>
                    <td style={{textAlign: 'center'}}>{API_get_money_list()[2].worth}G</td>
                    <td style={{textAlign: 'center'}}>{1000*API_get_money_list()[2].weight}g</td>
                    <td style={{textAlign: 'center'}}>
                        <InputField type='integer' 
                                    widthValue={28} 
                                    labelVisibility={false} 
                                    content={API_get_money_list()[2].amount} 
                                    setter={modifySilver} 
                                    buttonOnly={false} 
                                    buttonField={true} 
                                    buttonCallback={incrementSilver}
                                    />
                        {/*!editMoneyList && 
                            API_get_money_list()[2].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[2]} onChange={(e) => tryModifyMoneyListInput(2, e.target.value)} onBlur={(e) => modifyMoneyList(2, e.target.value)} />
                        */}
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left', borderRight: '0px solid black'}}>{API_get_money_list()[3].display_name}</td>
                    <td style={{textAlign: 'center'}}>[{API_get_money_list()[3].abbreviation}]</td>
                    <td style={{textAlign: 'center'}}>{API_get_money_list()[3].worth}G</td>
                    <td style={{textAlign: 'center'}}>{1000*API_get_money_list()[3].weight}g</td>
                    <td style={{textAlign: 'center'}}>
                        <InputField type='integer' 
                                    widthValue={28} 
                                    labelVisibility={false} 
                                    content={API_get_money_list()[3].amount} 
                                    setter={modifyCopper} 
                                    buttonOnly={false} 
                                    buttonField={true} 
                                    buttonCallback={incrementCopper}
                                    />
                        {/*!editMoneyList && 
                            API_get_money_list()[3].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[3]} onChange={(e) => tryModifyMoneyListInput(3, e.target.value)} onBlur={(e) => modifyMoneyList(3, e.target.value)} />
                        */}
                    </td>
                </tr>
            </table>
            {/*<div style={{marginTop: 5}}>
                <button className={btn.button_std} style={{width: 80, minWidth: 80}} onClick={toggleEditMoneyList}>{API_get_std_labels().edit}</button>
            </div>*/}
        </div>
    );
}
export default MoneyTable