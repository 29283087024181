import { createRef, useContext, useState } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {CampaignContext} from '../../../API/CampaignContext'
import {RestUserContext} from '../../../ServerConnection/RestUserContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import refreshIcon from '../../../Rss/Images/icons/refresh.png';
import { Tooltip } from 'react-tooltip'
import OverlayMessageField from '../OverlayMessageField'
import { IsNullOrEmpty } from '../../../API/Functions/GeneralFunctions'

const ReloadButton = (props) => {
    const { width = 48, height = 24 } = props;

    const [reloadMessage, setReloadMessage] = useState('')
    const [reloadMessageSuccess, setReloadMessageSuccess] = useState(false)

    const {
        API_get_character_file_name,
        API_get_character_file,
        API_get_campaign_file_name,
        API_get_campaign_file
    } = useContext(APIContext);

    const {
        API_get_user,
        API_get_character_owner,
        API_get_character_id
    } = useContext(UserDataContext);

    const {
        API_get_campaign_owner,
        API_get_campaign_id
    } = useContext(CampaignContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);

    
    const {
        REST_save_character,
        REST_save_campaign,
        REST_load_campaign,
        REST_load_character
    } = useContext(RestUserContext);

    const reloadFromRemote = () => {
        console.log('reloadFromRemote')
        let _user_role = API_get_user().role;
        console.log(_user_role)
        if(_user_role == 'player')
        {
            loadCampaign();
        }
        else if(_user_role == 'game_master')
        {
            loadCharacter();
        }
    }

    const loadCharacter = () => {
        let _character_owner = API_get_character_owner();
        let _character_id = API_get_character_id();

        REST_load_character(_character_owner, _character_id, null, setReloadMessage, setReloadMessageSuccess);
    }

    const loadCampaign = () => {
        let _campaign_owner = API_get_campaign_owner();
        let _campaign_id = API_get_campaign_id();

        REST_load_campaign(_campaign_owner, _campaign_id, null, setReloadMessage, setReloadMessageSuccess);
    }

    const getOverlayVisibility = () => {
        if(IsNullOrEmpty(reloadMessage))
            return false;

        return true;
    }

    return ( 
        <div>
            <div style={{ display: 'inline-block', position: 'relative' }}>
                <button className={btn_style.std_button} onClick={reloadFromRemote} style={{ height: 30, widht: 44, minWidth: 44 }} data-tooltip-id="btn-refresh-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().refresh}>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle', alignItems: 'center' }}>
                        <img src={refreshIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
                    </div>
                </button>
                <Tooltip id="btn-refresh-tooltip" />
                {getOverlayVisibility() &&
                    <OverlayMessageField position={'left'}
                        variableWidth={true}
                        parentWidth={44}
                        parentHeight={30}
                        content={reloadMessage}
                        success={reloadMessageSuccess} />
                }
            </div>
        </div>
     );
}
 
export default ReloadButton;