import { useState } from 'react'
import {APIContext} from '../../API/API'
import {TextDataContext} from '../../API/TextDataContext'
import { Tooltip } from 'react-tooltip'

const TabElement = (props) => {
    const { content = 'default', screen = 'default', screen_setter = null, screen_getter = null} = props;

    const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => {
        if(screen_getter == null)
            return;

        screen_setter(screen);
    }

    const handleMouseOver = () => {
        setIsHovered(true);
    }

    const handleMouseOut = () => {
        setIsHovered(false);
    }

    const isActive = () => {
        if(isHovered)
            return true;

        if(screen_getter == null)
            return false;

        if(screen_getter() != screen)
            return false;

        return true;

    }

    const getHighlightColor = () => {
        if(isHovered)
            return '#ffffff'

        return '#eeeeee'
    }

    const labelStyle = {
        cursor: 'pointer',
        color: isActive() ? getHighlightColor() : 'black'
    };

    return ( 
        <div>
            <label
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={ labelStyle}
                >
                    {content}
            </label>
        </div>
     );
}
 
export default TabElement;