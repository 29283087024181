import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../Functions/CharacterFunctions";
import { CloneObj } from "../../API/Functions/GeneralFunctions";
import { GetDiceList } from "../DiceRoller";

var init_free_skill_points = {
    used: 0,
    bonus: 0,
    total: 0
}

var init_negative_skill_restriction = {
    single: -5,
    total: -5
}

var init_skills  = [
    {
        skill_group_id: 0,
        display_name: 'Constitution',
        //free_skill_points: {used: 0, total: 0},
        skills:[
            {
                id: 0, 
                display_name: 'Toughness', 
                attr_mod_1_id: 0, 
                attr_mod_2_id: 0, 
                cost: 3, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Endurance', 
                attr_mod_1_id: 0, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Poison resistance', 
                attr_mod_1_id: 0, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Disease resistance', 
                attr_mod_1_id: 0, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Hold breath', 
                attr_mod_1_id: 0, 
                attr_mod_2_id: 0, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Booze', 
                attr_mod_1_id: 0, 
                attr_mod_2_id: 0, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 1,
        display_name: 'Strength',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Force', 
                attr_mod_1_id: 1, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            /*{
                id: 1, 
                display_name: 'Heavy Lifting', 
                attr_mod_1_id: 1, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Impact', 
                attr_mod_1_id: 1, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            {
                id: 1, 
                display_name: 'Throw', 
                attr_mod_1_id: 1, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Load Capacity', 
                attr_mod_1_id: 1, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Climb', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 3, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 2,
        display_name: 'Dexterity',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Swim', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Run', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Jump', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            /*{
                id: 3, 
                display_name: 'Landing', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            /*{
                id: 4, 
                display_name: 'Pass obstacles', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Dodge', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 10, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            {
                id: 3, 
                display_name: 'Reflexes', 
                attr_mod_1_id: 2, 
                attr_mod_2_id: 10, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 3,
        display_name: 'Coordination',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Ride', 
                attr_mod_1_id: 3, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Balance', 
                attr_mod_1_id: 3, 
                attr_mod_2_id: 2, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Tie up/Unleash', 
                attr_mod_1_id: 3, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Steering vehicles', 
                attr_mod_1_id: 3, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 4,
        display_name: 'Stealth',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Sneak', 
                attr_mod_1_id: 4, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Hide', 
                attr_mod_1_id: 4, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Hide object', 
                attr_mod_1_id: 4, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Appear unsuspicious', 
                attr_mod_1_id: 4, 
                attr_mod_2_id: 8, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 5,
        display_name: 'Thievery',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Pick pocket', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Pick lock', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Foul game', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Poison knowledge', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Set/Disarm Traps', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Assassination', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 4, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
            /*{
                id: 6, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }*/
        ]
    },
    {
        skill_group_id: 6,
        display_name: 'Society',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Estimate value', 
                attr_mod_1_id: 6, 
                attr_mod_2_id: 6, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Etiquette', 
                attr_mod_1_id: 6, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Alley knowledge', 
                attr_mod_1_id: 6, 
                attr_mod_2_id: 6, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Dice-, Card-, Boardgames', 
                attr_mod_1_id: 6, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Assess characters', 
                attr_mod_1_id: 6, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 7,
        display_name: 'Eloquence',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Convince/Negotiate', 
                attr_mod_1_id: 7, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Calm', 
                attr_mod_1_id: 7, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Inspire', 
                attr_mod_1_id: 7, 
                attr_mod_2_id: 9, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Leadership', 
                attr_mod_1_id: 7, 
                attr_mod_2_id: 6, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Interrogate', 
                attr_mod_1_id: 7, 
                attr_mod_2_id: 8, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 8,
        display_name: 'Deceive',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Threaten', 
                attr_mod_1_id: 8, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Lie/Deceive', 
                attr_mod_1_id: 8, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Fraud', 
                attr_mod_1_id: 8, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Manipulation', 
                attr_mod_1_id: 8, 
                attr_mod_2_id: 7, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Feign', 
                attr_mod_1_id: 8, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 7, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
            /*{
                id: 8, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }*/
        ]
    },
    {
        skill_group_id: 9,
        display_name: 'Bardic art',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Sing', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 9, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Play instruments', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 9, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Acting', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 9, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Dancing', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 2, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            /*{
                id: 4, 
                display_name: 'Story telling', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 9, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            {
                id: 4, 
                display_name: 'Stories and Legends', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Draw/Paint', 
                attr_mod_1_id: 9, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 10,
        display_name: 'Perception',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Senses', 
                attr_mod_1_id: 10, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Orientation', 
                attr_mod_1_id: 10, 
                attr_mod_2_id: 11, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Sense for danger', 
                attr_mod_1_id: 10, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            /*{
                id: 3, 
                display_name: 'Attention', 
                attr_mod_1_id: 10, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            {
                id: 3, 
                display_name: 'Estimate distance/speed', 
                attr_mod_1_id: 10, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Tracking', 
                attr_mod_1_id: 10, 
                attr_mod_2_id: 11, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 11,
        display_name: 'Nature',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Weather forecast', 
                attr_mod_1_id: 11, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Bushcraft', 
                attr_mod_1_id: 11, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Prepare food', 
                attr_mod_1_id: 11, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Flora&Fauna', 
                attr_mod_1_id: 11, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Animal traps', 
                attr_mod_1_id: 11, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Woodworking', 
                attr_mod_1_id: 11, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 12,
        display_name: 'Healing',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Heal wounds', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 3, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Heal disease', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Anatomy', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Heal soul', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Heal poison', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Heal curse', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 13,
        display_name: 'Common',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Read and Write', 
                attr_mod_1_id: 13, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Geaology/Geaography', 
                attr_mod_1_id: 13, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Cultures and Nations', 
                attr_mod_1_id: 13, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Monsters and Creatures', 
                attr_mod_1_id: 13, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Languages', 
                attr_mod_1_id: 13, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Mathematics', 
                attr_mod_1_id: 13, 
                attr_mod_2_id: 13, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 14,
        display_name: 'Mystic',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Divinity', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Magic knowledge', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Astronomy', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Alchemy', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Ghosts and Demons', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 15,
        display_name: 'Military',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Weapons knowledge', 
                attr_mod_1_id: 15, 
                attr_mod_2_id: 16, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Military knowledge', 
                attr_mod_1_id: 15, 
                attr_mod_2_id: 15, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Tactics', 
                attr_mod_1_id: 15, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Siege', 
                attr_mod_1_id: 15, 
                attr_mod_2_id: 15, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Army logistics', 
                attr_mod_1_id: 15, 
                attr_mod_2_id: 15, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 16,
        display_name: 'Melee Combat',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Unarmed', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            /*{
                id: 1, 
                display_name: 'Martial arts', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Light weapons', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 3, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Medium weapons', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Heavy weapons', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Polearms', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            {
                id: 1, 
                display_name: 'Duelist', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Two weapons', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 2, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Two handed', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Shield fight', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Armor habituation', 
                attr_mod_1_id: 16, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 17,
        display_name: 'Ranged Combat',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Shooting weapons', 
                attr_mod_1_id: 17, 
                attr_mod_2_id: 10, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Throwing weapons', 
                attr_mod_1_id: 17, 
                attr_mod_2_id: 3, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            /*{
                id: 2, 
                display_name: 'Heavy Throwing weapons', 
                attr_mod_1_id: 17, 
                attr_mod_2_id: 1, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Slings', 
                attr_mod_1_id: 17, 
                attr_mod_2_id: 3, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Blowpipes', 
                attr_mod_1_id: 17, 
                attr_mod_2_id: 10, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },*/
            {
                id: 5, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 6, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 7, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 8, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
            /*{
                id: 9, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 10, 
                display_name: 'empty', 
                attr_mod_1_id: 5, 
                attr_mod_2_id: 17, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }*/
        ]
    },
    {
        skill_group_id: 18,
        display_name: 'Arcana',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Light', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Heat', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Fire', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Earth/ore/rock', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 4, 
                display_name: 'Nature', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 11, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 5, 
                display_name: 'Life', 
                attr_mod_1_id: 12, 
                attr_mod_2_id: 12, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 6, 
                display_name: 'Body', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 12, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 7, 
                display_name: 'Darkness', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 8, 
                display_name: 'Cold', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 9, 
                display_name: 'Water', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 10, 
                display_name: 'Air', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 11, 
                display_name: 'Construct', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 12, 
                display_name: 'Death', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 13, 
                display_name: 'Spirit', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 10, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 14, 
                display_name: 'Kho\u0027Tal', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 15, 
                display_name: 'Arcana', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 16, 
                display_name: 'Daemonica', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 14, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 17, 
                display_name: 'Holiness', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 12, 
                cost: 1, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    },
    {
        skill_group_id: 19,
        display_name: 'Magic Resistance',
        //free_skill_points: {used: 0, total: 0},
        skills: [
            {
                id: 0, 
                display_name: 'Resistance to magic damage', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 0, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 1, 
                display_name: 'Resistance to magic manipulation', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 10, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 2, 
                display_name: 'Resistance to magic detection', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 4, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            },
            {
                id: 3, 
                display_name: 'Magic senses', 
                attr_mod_1_id: 14, 
                attr_mod_2_id: 10, 
                cost: 2, 
                //max: 0, 
                ancestry_mod: 0,
                other_bonus: 0, 
                temporary_mod: 0, 
                increase: 0, 
                value: 0, 
                dice: 0
            }
        ]
    }
     
 ]

 const init_formula_skill_ids = {
    toughness: {group_id: 0, skill_id: 0},
    endurance: {group_id: 0, skill_id: 1},
    load_capacity: {group_id: 1, skill_id: 4},
    /*unarmed: {group_id: 16, skill_id: 0},
    duelist: {group_id: 16, skill_id: 1},
    two_weapons: {group_id: 16, skill_id: 2},
    two_handed: {group_id: 16, skill_id: 3},
    shield_fight: {group_id: 16, skill_id: 4},
    armor_habituation: {group_id: 16, skill_id: 5},
    shooting_weapon: {group_id: 17, skill_id: 1},
    throwing_weapon: {group_id: 17, skill_id: 2},*/
    //fist_fight: {group_id: 16, skill_id: 0},
    //martial_arts: {group_id: 16, skill_id: 1},
    //light_weapons: {group_id: 16, skill_id: 2},
    //medium_weapons: {group_id: 16, skill_id: 3},
    //heavy_weapons: {group_id: 16, skill_id: 4},
    //polearms: {group_id: 16, skill_id: 5},
    //cross_bow: {group_id: 17, skill_id: 0},
    //light_throwing_weapons: {group_id: 17, skill_id: 1},
    //heavy_throwing_weapons: {group_id: 17, skill_id: 2},
    //slings: {group_id: 17, skill_id: 3},
    //blowpipes: {group_id: 17, skill_id: 4}
 }

 const INIT_get_formula_skill_ids = () => 
 {
     return CloneObj(init_formula_skill_ids, true);
 }

 const INIT_get_free_skill_points = () => 
 {
     return CloneObj(init_free_skill_points, true);
 }

const INIT_get_skills = () => 
{
    return CloneObj(init_skills, true);
}

const INIT_get_negative_skill_restriction = () => 
{
    return CloneObj(init_negative_skill_restriction, true);
}

export 
{
    INIT_get_formula_skill_ids,
    INIT_get_free_skill_points,
    INIT_get_skills,
    INIT_get_negative_skill_restriction
};