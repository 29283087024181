import {BACKEND_get_std_labels} from './Lang/TextData'
import{
    GetElementById,
    SetTimedMessage,
    IsNullOrEmpty
} from '../API/Functions/GeneralFunctions'
//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
var dice_list = [];

const GetDiceList = (_value_double) => 
{
    let _value = Math.floor(_value_double/2);
    let _dice_list = { dice: [_value, _value+2], offset: _value-4}
    if(_value <= 0){_dice_list = { dice: [4], offset: _value-2}}
    else if(_value == 1){_dice_list = { dice: [4], offset: -1}}
    else if(_value == 2){_dice_list = { dice: [4], offset: 0}}
    else if(_value == 3){_dice_list = { dice: [6], offset: 0}}
    else if(_value == 4){_dice_list = { dice: [8], offset: 0}}
    else if(_value == 5){_dice_list = { dice: [10], offset: 0}}
    else if(_value == 6){_dice_list = { dice: [12], offset: 0}}
    else if(_value == 7){_dice_list = { dice: [10,4], offset: 0}}
    else if(_value == 8){_dice_list = { dice: [12,4], offset: 0}}
    else if(_value == 9){_dice_list = { dice: [12,6], offset: 0}}
    else if(_value == 10){_dice_list = { dice: [12,8], offset: 0}}
    else if(_value == 11){_dice_list = { dice: [12,10], offset: 0}}
    else if(_value == 12){_dice_list = { dice: [12,12], offset: 0}}
    else if(_value == 13){_dice_list = { dice: [12,10,4], offset: 0}}
    else if(_value == 14){_dice_list = { dice: [12,12,4], offset: 0}}
    else if(_value == 15){_dice_list = { dice: [12,12,6], offset: 0}}
    else if(_value == 16){_dice_list = { dice: [12,12,8], offset: 0}}
    else if(_value == 17){_dice_list = { dice: [12,12,10], offset: 0}}
    else if(_value == 18){_dice_list = { dice: [12,12,12], offset: 0}}
    else if(_value == 19){_dice_list = { dice: [12,12,10,4], offset: 0}}
    else if(_value == 20){_dice_list = { dice: [12,12,12,4], offset: 0}}
    else if(_value == 21){_dice_list = { dice: [12,12,12,6], offset: 0}}
    else if(_value == 22){_dice_list = { dice: [12,12,12,8], offset: 0}}
    else if(_value == 23){_dice_list = { dice: [12,12,12,10], offset: 0}}
    else if(_value == 24){_dice_list = { dice: [12,12,12,12], offset: 0}}
    else if(_value == 25){_dice_list = { dice: [20,12,12,6], offset: 0}}
    else if(_value == 26){_dice_list = { dice: [20,12,12,8], offset: 0}}
    else if(_value == 27){_dice_list = { dice: [20,12,12,10], offset: 0}}
    else if(_value == 28){_dice_list = { dice: [20,12,12,12], offset: 0}}
    else if(_value == 29){_dice_list = { dice: [20,20,12,6], offset: 0}}
    else if(_value == 30){_dice_list = { dice: [20,20,12,8], offset: 0}}
    else if(_value == 31){_dice_list = { dice: [20,20,12,10], offset: 0}}
    else if(_value == 32){_dice_list = { dice: [20,20,12,12], offset: 0}}
    else {_dice_list = { dice: [20,20,20], offset: _value-30}}
    return _dice_list;
}

const ConsolidateList = (_dice_list) => {
    let _consolidated_list = [];
    let _new_dice = true;
    let _new_key = 0;
    for(let key1 in _dice_list){
        _new_dice = true;
        for(let key2 in _consolidated_list){
            if(_dice_list[key1] == _consolidated_list[key2].dice){
                _consolidated_list[key2].amount += 1;
                _new_dice = false;
            }
        }
        if(_new_dice){
            _consolidated_list.push({amount: 1, dice: _dice_list[key1]});
            _new_key += 1;
        }
    }
    return _consolidated_list;
}

const GetDiceListString = (_dice_list, _default_dice_label = 'd', _good_roll = false) => 
{
    if(_dice_list == null)
        return 'N/A';

    let _consolidated_list = ConsolidateList(_dice_list.dice);

    let _return_string = '';
    let _first_element_written = false;
    //let _dice_label = BACKEND_get_std_labels().dice_label;
    let _amount = 0;
    let _dice_value = 0;
    let _dice_label = _default_dice_label;
    if(_dice_label == 'default'){
        _dice_label = BACKEND_get_std_labels().dice_label;
    }
    for(var key in _consolidated_list)
    {
        if(_first_element_written)
        {
            _return_string += '+';
        }
        else
        {
            _first_element_written = true;
        }
        _amount = _consolidated_list[key].amount;
        _dice_value = _consolidated_list[key].dice;
        if(_amount == 1){
            _return_string += _dice_label + _dice_value;
            if(_good_roll)
                _return_string += 'xo' + _dice_value;
        }
        else if(_amount > 1){
            _return_string += '' + _amount + _dice_label + _dice_value;
            if(_good_roll)
                _return_string += 'xo' + _dice_value;
        }

    }
    if(_dice_list.offset > 0)
    {
        _return_string += '+' + _dice_list.offset;
    }
    else if(_dice_list.offset < 0)
    {
        _return_string += _dice_list.offset;
    }
    return _return_string;
}

const GetFoundryDiceListString = (_dice_list, _good_roll = true) => {
    let _return_message = GetDiceListString(_dice_list, 'd', _good_roll);
    return _return_message;
}

export {
    GetDiceList,
    GetDiceListString,
    GetFoundryDiceListString
}