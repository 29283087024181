import { createRef, useContext, useState } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {ToolDataContext} from '../../../API/ToolDataContext'
import {RemoteConnectContext} from '../../../ServerConnection/RemoteConnectContext'
import {
    BACKEND_get_dice_connector_window,
    BACKEND_set_dice_connector_window,
    BACKEND_get_connector_url
} from '../../../ServerConnection/FoundryConnectorParameters'
import btn_style from '../../../StylesStylish/btn.module.css'
import remoteConnectorIcon from '../../../Rss/Images/icons/remote_connector.png';
import { Tooltip } from 'react-tooltip'


const DiceButton = (props) => {
    const { width = '72px', height = '32px', roll = 'd6', roll_label = 'd6'} = props;

    const {
        BACKEND_send_dice_roll,
        BACKEND_open_foundry_connector_window
    } = useContext(RemoteConnectContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);
  
    function openConnector() {
        BACKEND_open_foundry_connector_window();
    }

    return ( 
        <div>
            <button className={btn_style.std_button} onClick={openConnector} style={{width: width, minWidth: width, height: height}} data-tooltip-id="btn-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().open_foundry_connector}>
                <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                    <img src={remoteConnectorIcon} alt="Bild" style={{ float: 'left', width: '18px', marginRight: 5 }} />
                </div>
            </button>
            <Tooltip id="btn-tooltip" />
        </div>
     );
}
 
export default DiceButton;