import { useState, useContext, useEffect } from 'react'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {CampaignContext, CampaignContextProvider} from '../API/CampaignContext'
import CampaignSelectionTable from './SubPagesCampaignSelectionSheet/CampaignSelectionTable'
import table_style from '../StylesStylish/table.module.css'

const CampaignSelectionSheet = () => {

    const {
        API_get_base_stats,
        API_set_base_stats,
        API_get_free_attribute_points,
        API_set_free_attribute_points,
        API_get_free_focus_points,
        API_set_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility,
        API_get_stat_overview,
        API_set_stat_overview,
        API_get_money_list,
        API_set_money_list,
        API_get_ancestry_saldo,
        API_set_ancestry_saldo
    } = useContext(BaseStatContext);

    const {
        API_get_campaign_id,
        API_set_campaign_id,
        API_get_campaign_name,
        API_set_campaign_name,
        API_get_campaign_description,
        API_set_campaign_description,
        API_get_subscribed_user,
        API_set_subscribed_user,
        API_get_monster_list,
        API_set_monster_list,
        API_get_monster_by_id,
        API_get_remote_campaign_message,
        API_set_remote_campaign_message,
        API_get_remote_subscribed_user_message,
        API_set_remote_subscribed_user_message
    } = useContext(CampaignContext);

    const {
        API_get_std_labels,
        API_get_message_box_content_labels,
        API_get_std_header_labels,
        API_get_user_labels,
        API_get_tab_view_labels
    } = useContext(TextDataContext)

    return ( 
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={{display: 'inline-block', marginRight: 45, marginBottom: 25}}>
                <CampaignSelectionTable />
            </div>
            <div style={{display: 'inline-block'}}>
                <table className={table_style.std_table}>
                    <tr>
                        <th colspan={2}>
                            {API_get_std_header_labels().current_state}
                        </th>
                    </tr>
                    <tr style={{borderBottom: '1px solid black'}}>
                        <td style={{width: 200, minWidth: 200, fontWeight: 'bold'}}>
                            {API_get_std_labels().loaded_campaigns}
                        </td>
                        <td style={{width: 400, minWidth: 400, fontWeight: 'bold'}}>
                            {API_get_campaign_name()}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default CampaignSelectionSheet;