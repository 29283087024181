import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {InputField} from '../../ComponentsStylish/InputField';
import FreePointsTable from './FreePointsTable';
import RemoteUploadButton from '../../ComponentsStylish/Buttons/RemoteUploadButton';
import NewCharacterButton from '../../ComponentsStylish/Buttons/NewCharacterButton';
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const FreePointsHeader = () => {

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_init_character,
        API_set_base_stats_name,
        API_set_base_stats_heritage,
        API_set_base_stats_ancestry,
        API_set_base_stats_gender,
        API_set_base_stats_hair_color,
        API_set_base_stats_eye_color,
        API_set_base_stats_skin_color,
        API_set_base_stats_famous,
        API_set_base_stats_notorious,
        API_set_base_stats_height,
        API_set_base_stats_weight,
        API_set_base_stats_age,
        API_set_base_stats_experience,
        API_set_base_stats_level
    } = useContext(APIContext);

    const {
        API_get_user
    } = useContext(UserDataContext);
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels
    } = useContext(TextDataContext);

    const marginBottom = 4;
    const _user_role = API_get_user().role;
    return ( 
        <div>
            <div>
                <div style={{display: 'inline-block'}}>
                    <FreePointsTable id={'focus'}/>
                </div>
                <div style={{display: 'inline-block'}}>
                    <FreePointsTable id={'attribute'}/>
                </div>
                <div style={{display: 'inline-block'}}>
                    <FreePointsTable id={'skill'}/>
                </div>
            </div> 
        </div>
    );
}

export default FreePointsHeader;