import { useState, createContext} from 'react'
import {
    BACKEND_set_melee_fighting_types,
    BACKEND_get_melee_fighting_types,
    BACKEND_set_ranged_fighting_types,
    BACKEND_get_ranged_fighting_types,
    //BACKEND_get_hand_types,
    BACKEND_set_unarmed_weapons,
    BACKEND_get_unarmed_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_ranged_weapons,
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
} from '../Backend/Parameters/CharacterParameters'

import{
    GetElementById
} from './Functions/GeneralFunctions'

const EquipmentContext = createContext();

const EquipmentContextProvider = ({children}) => {

    const [meleeFightingTypes, setMeleeFightingTypes] = useState({...BACKEND_get_melee_fighting_types()});
    const [rangedFightingTypes, setRangedFightingTypes] = useState({...BACKEND_get_ranged_fighting_types()});
    //const [handTypes, setHandTypes] = useState({...BACKEND_get_hand_types()});
    const [unarmedWeapons, setUnarmedWeapons] = useState({...BACKEND_get_unarmed_weapons()});
    const [meleeWeapons, setMeleeWeapons] = useState({...BACKEND_get_melee_weapons()});
    const [rangedWeapons, setRangedWeapons] = useState({...BACKEND_get_ranged_weapons()});
    const [armor, setArmor] = useState({...BACKEND_get_armor()});
    const [gear, setGear] = useState({...BACKEND_get_gear()});

    const API_get_melee_fighting_types = () => {return meleeFightingTypes;}
    const API_set_melee_fighting_types = () => {setMeleeFightingTypes({...BACKEND_get_melee_fighting_types()});}

    const API_get_ranged_fighting_types = () => {return rangedFightingTypes;}
    const API_set_ranged_fighting_types = () => {setRangedFightingTypes({...BACKEND_get_ranged_fighting_types()});}

    /*const API_get_hand_types = () => {return handTypes;}
    const API_set_hand_types = () => {setHandTypes({...BACKEND_get_hand_types()});}*/

    const API_get_unarmed_weapons = () => {return unarmedWeapons;}
    const API_set_unarmed_weapons = () => {setUnarmedWeapons({...BACKEND_get_unarmed_weapons()});}

    const API_get_melee_weapons = () => {return meleeWeapons;}
    const API_set_melee_weapons = () => {setMeleeWeapons({...BACKEND_get_melee_weapons()});}

    const API_get_ranged_weapons = () => {return rangedWeapons;}
    const API_set_ranged_weapons = () => {setRangedWeapons({...BACKEND_get_ranged_weapons()});}

    const API_get_armor = () => {return armor;}
    const API_set_armor = () => {setArmor({...BACKEND_get_armor()});}

    const API_get_gear = () => {return gear;}
    const API_set_gear = () => {setGear({...BACKEND_get_gear()});}


    const API_get_melee_fighting_type_by_id = (_id) => {
        return GetElementById(_id, meleeFightingTypes);
    }

    const API_get_ranged_fighting_type_by_id = (_id) => {
        return GetElementById(_id, rangedFightingTypes);
    }

    /*const API_get_hand_type_by_id = (_id) => {
        return GetElementById(_id, handTypes);
    }*/

    const API_get_armor_by_id = (_id) => {
        return GetElementById(_id, armor);
    }

    const API_get_gear_by_id = (_id) => {
        return GetElementById(_id, gear);
    }

    const API_get_unarmed_weapon_by_id = (_id) => {
        return GetElementById(_id, unarmedWeapons);
    }

    const API_get_melee_weapon_by_id = (_id) => {
        console.log()
        return GetElementById(_id, meleeWeapons);
    }

    const API_get_ranged_weapon_by_id = (_id) => {
        return GetElementById(_id, rangedWeapons);
    }

    return(
        <EquipmentContext.Provider value={{
            API_get_melee_fighting_types,
            API_set_melee_fighting_types,
            API_get_ranged_fighting_types,
            API_set_ranged_fighting_types,
            //API_get_hand_types,
            //API_set_hand_types,
            API_get_unarmed_weapons,
            API_set_unarmed_weapons,
            API_get_melee_weapons,
            API_set_melee_weapons,
            API_get_ranged_weapons,
            API_set_ranged_weapons,
            API_get_armor,
            API_set_armor,
            API_get_gear,
            API_set_gear,
            API_get_melee_fighting_type_by_id,
            API_get_ranged_fighting_type_by_id,
            //API_get_hand_type_by_id,
            API_get_armor_by_id,
            API_get_gear_by_id,
            API_get_unarmed_weapon_by_id,
            API_get_melee_weapon_by_id,
            API_get_ranged_weapon_by_id
        }}>
            {children}
        </EquipmentContext.Provider>
    )
}

export {EquipmentContext, EquipmentContextProvider}