import { useState, useRef } from 'react';
import {DecodeImage} from '../../../API/Functions/GeneralFunctions'
import btn_style from '../../../StylesStylish/btn.module.css'
import imageUploadIcon from '../../../Rss/Images/icons/image_upload.png';

const UploadImageButton = (props) => {
    const { image_setter = null } = props;
    const fileInputRef = useRef(null);


    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            image_setter(reader.result);
        };

        reader.readAsDataURL(selectedFile);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <button className={btn_style.std_button} style={{width: 40}} onClick={handleButtonClick}>
                <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                    <img src={imageUploadIcon} alt="Bild" style={{ float: 'left', width: '20px' }} />
                </div>
            </button>
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleImageChange} />
        </div>
    );
}

export default UploadImageButton;