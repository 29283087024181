import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../Functions/CharacterFunctions";
import { CloneObj } from "../../API/Functions/GeneralFunctions";


const init_campaign_name = '';
const init_campaign_description = '';
const init_subscribed_user = [];
const init_monster_list  = [];

const init_monster_template = 
{
    id: 0,
    display_name: '',
    initiative: 0,
    damage: 0,
    hitpoints: 0,
    reflexes: 0,
    attack: 0,
    defense: 0,
    initiative_dice: null,
    attack_dice: null,
    defense_dice: null,
    comment: ''
}

var INIT_get_campaign_characters = () => {
    return [];
}

var INIT_get_campaign_owner = () => {
    return '';
}

var INIT_get_campaign_id = () => {
    return -1;
}

const INIT_get_campaign_name = () => {
    return init_campaign_name
}

const INIT_get_campaign_description = () => {
    return init_campaign_description
}

const INIT_get_subscribed_user = () => {
    return CloneObj(init_subscribed_user)
}

const INIT_get_monster_list = () => 
{
    return CloneObj(init_monster_list, true);
}

const INIT_get_monster_template = () => 
{
    return CloneObj(init_monster_template, true);
}

export 
{
    INIT_get_campaign_characters,
    INIT_get_campaign_owner,
    INIT_get_campaign_id,
    INIT_get_subscribed_user,
    INIT_get_campaign_name,
    INIT_get_campaign_description,
    INIT_get_monster_list,
    INIT_get_monster_template
};