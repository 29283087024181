import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {MessageBoxContext, MessageBoxContextProvider} from '../../API/MessageBoxContext'
import {RestUserContext, RestUserContextProvider} from '../../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {IsObjectNullOrUndefined} from '../../API/Functions/GeneralFunctions'
import ImageContainer from '../ComponentsStylish/ImageContainer';
import campaignDefault from '../../Rss/Images/campaign_default.png';
import refreshIcon from '../../Rss/Images/icons/refresh.png';
import importIcon from '../../Rss/Images/icons/import_database.png';
import upIcon from '../../Rss/Images/icons/up.png';
import downIcon from '../../Rss/Images/icons/down.png';
import deleteIcon from '../../Rss/Images/icons/remove.png';
import subscribeIcon from '../../Rss/Images/icons/subscribe_character.png';
import unsubscribeIcon from '../../Rss/Images/icons/unsubscribe_character.png';
import checkIcon from '../../Rss/Images/icons/check.png';
import minusIcon from '../../Rss/Images/icons/minus.png';
import {InputField} from '../ComponentsStylish/InputField';
import RemoteDownloadButton from '../ComponentsStylish/Buttons/RemoteUploadButton';
import NewCharacterButton from '../ComponentsStylish/Buttons/NewCharacterButton';
import DiceButton from '../ComponentsStylish/Buttons/DiceButton';
import label_style from '../../StylesStylish/label.module.css'
import field_style from '../../StylesStylish/field.module.css'
import table_style from '../../StylesStylish/table.module.css'
import btn_style from '../../StylesStylish/btn.module.css'
import { Tooltip } from 'react-tooltip'
import OverlayMessageField from '../ComponentsStylish/OverlayMessageField'
import { IsNullOrEmpty } from '../../API/Functions/GeneralFunctions'
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const CampaignSelectionTable = () => {

    const {
        REST_login_user,
        REST_create_new_user,
        REST_load_characters,
        REST_save_character,
        REST_load_character,
        REST_delete_character,
        REST_apply_config,
        REST_load_campaign,
        REST_delete_campaign,
        REST_subscribe_character_to_campaign,
        REST_save_campaign,
        REST_load_assigned_campaigns,
        REST_load_campaigns,
        REST_subscribe_user_to_campaign
    } = useContext(RestUserContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message,
        API_get_character_id,
        API_set_character_id
    } = useContext(UserDataContext);

    const {
        API_move_campaign_element
    } = useContext(APIContext)

    const {
        API_get_std_labels,
        API_get_message_box_content_labels,
        API_get_std_header_labels,
        API_get_user_labels,
        API_get_tab_view_labels
    } = useContext(TextDataContext)

    const {
        API_get_campaign_id,
        API_get_remote_campaign_message,
        API_set_remote_campaign_message,
        API_get_remote_subscribed_user_message,
        API_set_remote_subscribed_user_message
    } = useContext(CampaignContext)

    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    const [refreshMessage, setRefreshMessage] = useState('')
    const [refreshMessageSuccess, setRefreshMessageSuccess] = useState(false)
    const [loadSubscribeMessage, setLoadSubscribeMessage] = useState([]);
    const [loadSubscribeMessageSuccess, setLoadSubscribeMessageSuccess] = useState(false);
    const [loadCampaignMessage, setLoadCampaignMessage] = useState([]);
    const [loadCampaignMessageSuccess, setLoadCampaignMessageSuccess] = useState(false);
    
    const updateLoadCampaignMessage = (_index, _message) => {
        if(getCampaigns() == null)
            return;

        if(getCampaigns().length == 0)
            return;

        if(_index < 0  || _index >= getCampaigns().length)
            return;

        let _copy = new Array(getCampaigns().length).fill('');

        _copy[_index] = _message;
        setLoadCampaignMessage(_copy);
    }

    const updateSubscribeMessage = (_index, _message) => {
        if(getCampaigns() == null)
            return;

        if(getCampaigns().length == 0)
            return;

        if(_index < 0  || _index >= getCampaigns().length)
            return;

        let _copy = new Array(getCampaigns().length).fill('');

        _copy[_index] = _message;
        setLoadSubscribeMessage(_copy);
    }

    const getCampaigns = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.campaigns;
    }

    const getUserRole = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.role;
    }
    
    const loadCampaignsFromRemote = () => {
        let _user = API_get_user();
        if(getUserRole() == 'game_master'){
            REST_load_campaigns(_user.name, setRefreshMessage, setRefreshMessageSuccess);
        }
        else if(getUserRole() == 'player' ){
            REST_load_assigned_campaigns(_user.name, setRefreshMessage, setRefreshMessageSuccess);
        }
    }
    
    /*const loadAssignedCampaignsFromRemote = () => {
        console.log("REST_load_assigned_campaigns")
        REST_load_assigned_campaigns();
    }

    const saveCampaignToRemote = () => {
        REST_save_campaign();
    }*/

    const loadCampaignFromRemote = (_campaignID, _campaignOwner, _index) => {
        let _actualCampaignOwner = _campaignOwner
        if(IsObjectNullOrUndefined(_campaignOwner) || getUserRole() == 'game_master')
            _actualCampaignOwner = API_get_user().name;

        let _loadCampaignMessage = (_message) => updateLoadCampaignMessage(_index, _message);
        REST_load_campaign(_actualCampaignOwner, _campaignID, null, _loadCampaignMessage, setLoadCampaignMessageSuccess);
    }

    const subscribeCharacter = (_campaignOwner, _campaignID, _index) => {
        let _characterOwner = API_get_user().name;
        let _characterID = API_get_character_id();
        let _loadSubscribeMessage = (_message) => updateSubscribeMessage(_index, _message);
        REST_subscribe_character_to_campaign(_characterOwner, _characterID, _campaignOwner, _campaignID, true, _loadSubscribeMessage, setLoadSubscribeMessageSuccess);
    }

    const removeHandler = (_campaignOwner, _campaignID) => {
        let _actualCampaignOwner = _campaignOwner;
        if(IsObjectNullOrUndefined(_campaignOwner))
            _actualCampaignOwner = API_get_user().name;

        if(getUserRole() == 'game_master'){
            return deleteCampaignAtRemote(_actualCampaignOwner, _campaignID);
        }
        else if(getUserRole() == 'player'){
            return unsubscribeUser(_actualCampaignOwner, _campaignID);
        }
    }
    const unsubscribeUser = (_campaignOwner, _campaignID) => {
        //REST_subscribe_user_to_campaign(_campaignOwner, _campaignID, false);
        let _content = API_get_message_box_content_labels().unsubscribe_character;
        API_open_yes_no_message_box(_content, () => REST_subscribe_user_to_campaign(_campaignOwner, _campaignID, false, setRefreshMessage, setRefreshMessageSuccess));
    }

    const deleteCampaignAtRemote = (_actualCampaignOwner, _campaignID) => {
        let _content = API_get_message_box_content_labels().delete_campaign;
        API_open_yes_no_message_box(_content, () => REST_delete_campaign(_actualCampaignOwner, _campaignID, setRefreshMessage, setRefreshMessageSuccess));
    }

    const moveElement = (_characterID, _direction) => {
        API_move_campaign_element(_characterID, _direction);
    }

    const get_action_colspan = () => {
        if(getUserRole() == 'game_master'){
            return 5;
        }
        else if(getUserRole() == 'player'){
            return 6;
        }
    }

    const getSubscribeTooltip = (_subscription_state) => {
        if(_subscription_state)
        {
            return API_get_std_labels().unsubscribe_character
        }
        else
        {
            return API_get_std_labels().subscribe_character
        }
    }

    const getRemoveTooltip = () => {
        if(getUserRole() == 'game_master'){
            return API_get_std_labels().delete
        }
        else if(getUserRole() == 'player'){
            return API_get_std_labels().remove_campaign
        }
    }

    const getSubscriptionIcon = (_subscription_state) => {
        if(_subscription_state)
        {
            return unsubscribeIcon
        }
        else
        {
            return subscribeIcon
        }
    }

    const getSubscriptionStateIcon = (_subscription_state) => {
        if(_subscription_state)
        {
            return checkIcon
        }
        else
        {
            return minusIcon
        }
    }

    const getColspanHeader = () => {
        if(getUserRole() == 'game_master'){
            return 8;
        }
        else if(getUserRole() == 'player'){
            return 10;
        }
    }

    const getRefreshOverlayVisibility = () => {
        if(IsNullOrEmpty(refreshMessage))
            return false;

        return true;
    }

    const getLoadOverlayVisibility = (_index) => {
        if(loadCampaignMessage == null)
            return false;

        if(loadCampaignMessage.length == 0)
            return false;

        if(_index < 0  || _index >= loadCampaignMessage.length)
            return false;

        if(IsNullOrEmpty(loadCampaignMessage[_index]))
            return false;

        return true;
    }

    const getSubscribeOverlayVisibility = (_index) => {
        if(loadSubscribeMessage == null)
            return false;

        if(loadSubscribeMessage.length == 0)
            return false;

        if(_index < 0  || _index >= loadSubscribeMessage.length)
            return false;

        if(IsNullOrEmpty(loadSubscribeMessage[_index]))
            return false;

        return true;
    }

    const getImage = (_image) => {
        if(_image == null)
            return campaignDefault;

        return _image
    }
    
    const isRowSelected = (_id) =>{
        let _loaded_campaign_id = API_get_campaign_id()
        if(_id == _loaded_campaign_id){
            return true;
        }
        else
        {
            return false;
        }
    }

    return ( 
        <div style={{marginLeft: 15}}>
            <table className={table_style.std_table}>
                <tr>
                    <th style={{textAlign: 'center'}} colspan={getColspanHeader()}>{API_get_tab_view_labels().campaigns}</th>
                </tr>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{API_get_std_header_labels().image}</td>
                    <td style={{width: 400, minWidth: 400, fontWeight: 'bold', textAlign: 'left', paddingLeft: 5}}>{API_get_tab_view_labels().campaign}</td>
                    { getUserRole() == 'player' &&
                        <td style={{width: 200, minWidth: 200, fontWeight: 'bold', textAlign: 'left', paddingLeft: 5}}>{API_get_user_labels().user}</td>
                    }
                    { getUserRole() == 'player' &&
                        <td style={{width: 80, minWidth: 80, fontWeight: 'bold', textAlign: 'center'}}>{API_get_std_header_labels().state}</td>
                    }
                    {/*<td className={table.cell_header} style={{width: 64, minWidth: 64}}>Load</td>*/}
                    <td style={{textAlign: 'center', fontWeight: 'bold'}} colspan={get_action_colspan()}>{API_get_std_header_labels().actions}</td>
                </tr>
                {Object.entries(getCampaigns()).map(([key, value], index) => (
                    <tr style={{ background: isRowSelected(value.id) ? 'rgba(120, 140, 140, 0.3)' : 'transparent' }}>
                        <td>
                            <ImageContainer image={value.image} default_image={campaignDefault} borderWidth={1} width={100} max_height={100} />
                            {/*<img src={getImage(value.image)} alt={campaignDefault} style={{ float: 'left', width: '100px', border: '1px solid black' }} />*/}
                        </td>
                        <td style={{textAlign: 'left'}}><div></div>{value.name} <br /> <label style={{fontSize: 12}}>ID: {value.id}</label></td>
                        { getUserRole() == 'player' &&
                            <td style={{paddingLeft: 5}}>{value.user}</td>
                        }
                        { getUserRole() == 'player' &&
                            <td style={{textAlign: 'center'}}>
                                <div style={{display: 'inline-block'}}>
                                    <img src={getSubscriptionStateIcon(value.subscription_status)} alt="Bild" style={{ float: 'left', width: '14px' }} />
                                </div>
                            </td>
                        }
                        { getUserRole() == 'player' &&
                            <td style={{width: 12, minWidth: 12, padding: 1}}>
                                <div style={{display:'inline-block', position: 'relative'}}>
                                    <button className={btn_style.icon_button} onClick={() => subscribeCharacter(value.user, value.id, index)} data-tooltip-id="btn-subscribe-tooltip" data-tooltip-place="bottom" data-tooltip-content={getSubscribeTooltip(value.subscription_status)}>  
                                        <img src={getSubscriptionIcon(value.subscription_status)} alt="Bild" style={{ float: 'left', width: '14px' }} />
                                    </button>
                                    <Tooltip id="btn-subscribe-tooltip" />
                                    { getSubscribeOverlayVisibility(index) &&
                                        <OverlayMessageField position={'right'}
                                                            variableWidth={true}
                                                            parentWidth={24}
                                                            parentHeight={24}
                                                            content={loadSubscribeMessage[index]}
                                                            success={loadSubscribeMessageSuccess}/>
                                    }
                                </div>
                            </td>
                        }   
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <div style={{display:'inline-block', position: 'relative'}}>
                                <button className={btn_style.icon_button} onClick={() => loadCampaignFromRemote(value.id, value.user, index)} data-tooltip-id="btn-load-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().load_campaign}>
                                    <img src={importIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                                </button>
                                <Tooltip id="btn-load-tooltip" />
                                { getLoadOverlayVisibility(index) &&
                                    <OverlayMessageField position={'left'}
                                                        variableWidth={true}
                                                        parentWidth={24}
                                                        parentHeight={24}
                                                        content={loadCampaignMessage[index]}
                                                        success={loadCampaignMessageSuccess}/>
                                }
                            </div>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value.id, 'up')}>
                                <img src={upIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value.id, 'down')}>
                                <img src={downIcon} alt="Bild" style={{ float: 'left', width: '14px', marginTop: 2 }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <button className={btn_style.icon_button} onClick={() => removeHandler(value.user, value.id)} data-tooltip-id="btn-remove-tooltip" data-tooltip-place="bottom" data-tooltip-content={getRemoveTooltip()}>
                                <img src={deleteIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                            <Tooltip id="btn-remove-tooltip" />
                        </td>
                    </tr>
                    
                ))}
            </table>
            <div style={{marginLeft: 4, marginTop: 15}} >
                <div style={{display:'inline-block', position: 'relative'}}>
                    <button className={btn_style.std_button} onClick={loadCampaignsFromRemote} style={{height: 30, widht: 44, minWidth: 44}} data-tooltip-id="btn-refresh-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().refresh}>
                        <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                            <img src={refreshIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
                        </div>
                    </button>
                    <Tooltip id="btn-refresh-tooltip" />
                    { getRefreshOverlayVisibility() &&
                        <OverlayMessageField position={'right'}
                                            variableWidth={true}
                                            parentWidth={44}
                                            parentHeight={30}
                                            content={refreshMessage}
                                            success={refreshMessageSuccess}/>
                    }
                </div>
            </div>
            {/*<p style={{color: 'red', height: 25}}>{API_get_remote_campaign_message()}</p>*/}
        </div>
    );
}

export default CampaignSelectionTable;