import { useState } from 'react'
import {APIContext} from '../../API/API'
import {TextDataContext} from '../../API/TextDataContext'
import { Tooltip } from 'react-tooltip'

//parent needs to have display: 'relative'
const OverlayMessageField = (props) => {
    const { 
        content = null,
        variableWidth = false,
        position = 'bottom',
        parentWidth = 0,
        parentHeight = 0,
        width = 80, 
        height = 25, 
        background = 'rgba(0, 0, 0, 0.7)',
        color = 'rgba(250, 250, 250, 0.9)',
        success = true} = props;

    const [marginLeft, setMarginLeft] = useState(0);
    const [marginTop, setMarginTop] = useState(0);

    const baseMargin = 6;

    const calcOverlayCenterMargin = () => {
        let _margin = (parentHeight - height )/2;
        return Math.floor(_margin);
    }

    const getMarginTop = (_position) => {
        if (_position == 'left') {
            return calcOverlayCenterMargin();
        }
        else if (_position == 'right') {
            return calcOverlayCenterMargin();
        }
        else {
            //'none' also exists
            return 0;
        }
    }

    const getMarginLeft = (_position) => {
        if (_position == 'left') {
            return -getWidth() - baseMargin;
        }
        else if (_position == 'right') {
            return parentWidth + baseMargin;
        }
        else {
            //'none' also exists
            return 0;
        }
    }

    const getWidth = () => {
        if(content == null || !variableWidth)
            return width;

        let _characterWidth = 8.5;
        let _messageLength = content.length;
        return _messageLength*_characterWidth;

    }

    const getColor = () => {
        if(success)
        {
            return color;
        }
        else
        {
            return 'red';
        }
    }

    const getBorder = () => {
        if(success)
        {
            return '1px solid rgba(240, 240, 240, 0.7)';
        }
        else
        {
            return '1px solid red';
        }
    }
    
    const overlayStyle = () => {
        let _style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0',
            left: '0',
            marginTop: getMarginTop(position), 
            marginRight: 0, 
            marginBottom: 0, 
            marginLeft: getMarginLeft(position), 
            width: getWidth(),
            height: height,
            minHeight: height,
            background: background,
            color: getColor(),
            border: getBorder(),
            zIndex: '9999'
            //display: isHovering ? 'block' : 'none',
        }
        return _style;
    }

    const getContent = () => {
        if (content == null)
            return 'default'

        return content;
    }

    return ( 
        <div style={overlayStyle()}>
            <label style={{color: getColor(), marginBottom: 1}}>{getContent()}</label>
        </div>
     );
}
 
export default OverlayMessageField;