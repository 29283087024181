import { useState, createContext} from 'react'

const ScreenDataContext = createContext();

const ScreenDataContextProvider = ({children}) => {

    const [activeMainScreen, setActiveMainScreen] = useState('character');
    const [activeSheetScreen, setActiveSheetScreen] = useState('overview');
    const [skillGroupScreen, setSkillGroupScreen] = useState('screen_tab_0');
    const [magicScreen, setMagicScreen] = useState('magic_skills');
    const [activeScreen, setActiveScreen] = useState('skills');

    const API_get_active_main_screen = () => {return activeMainScreen;}
    const API_set_active_main_screen = (_value) => {setActiveMainScreen(_value)}

    const API_get_active_sheet_screen = () => {return activeSheetScreen;}
    const API_set_active_sheet_screen = (_value) => {setActiveSheetScreen(_value);}

    const API_get_skill_group_screen = () => {return skillGroupScreen;}
    const API_set_skill_group_screen = (_value) => {setSkillGroupScreen(_value);}

    const API_get_magic_screen = () => {return magicScreen;}
    const API_set_magic_screen = (_value) => {setMagicScreen(_value);}

    const API_get_active_screen = () => {return activeScreen;}
    const API_set_active_screen = (_value) => {setActiveScreen(_value);}
    
    
    const API_get_skill_group_tab_name = (_index) => {
        return 'screen_tab_' + _index;
    }

    return(
        <ScreenDataContext.Provider value={{
            API_get_active_main_screen,
            API_set_active_main_screen,
            API_get_active_sheet_screen,
            API_set_active_sheet_screen,
            API_get_skill_group_screen,
            API_set_skill_group_screen,
            API_get_magic_screen,
            API_set_magic_screen,
            API_get_active_screen,
            API_set_active_screen,
            API_get_skill_group_tab_name
        }}>
            {children}
        </ScreenDataContext.Provider>
    )
}

export {ScreenDataContext, ScreenDataContextProvider}