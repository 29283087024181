import { useContext } from 'react'
import {APIContext} from '../../API/API'
import {AttributeContext} from '../../API/AttributeContext'
import {ScreenDataContext} from '../../API/ScreenDataContext'
import TabBar from '../ComponentsStylish/TabBar';
import CharacterSkillSheetHeader from './SubPagesCharacterSkillSheet/CharacterSkillSheetHeader';
import CharacterSkillSheetBody from './SubPagesCharacterSkillSheet/CharacterSkillSheetBody';

const CharacterSkillSheet = () => {

    const {
        API_get_attribute_group_ref
    } = useContext(APIContext);

    const {
        API_get_attribute_group_by_id,
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_skill_group_screen,
        API_set_skill_group_screen,
        API_get_skill_group_tab_name
    } = useContext(ScreenDataContext);

    const getTalentTabBar = () => {
        let _groups = API_get_attributes();
        let _mainTabBar = [];

        console.log('getTalentTabBar');
        console.log(_groups);
        for(let key in _groups)
        {
            console.log(_groups[key]);
            if(_groups[key].group_id == API_get_attribute_group_ref().magic)
                continue;
            
            let _screen_tab = API_get_skill_group_tab_name(_groups[key].group_id);
            _mainTabBar.push({content: _groups[key].group, screen: _screen_tab});
        }

        return _mainTabBar;
    }
    
    const idMapper = (_id) => {
        let _outID = _id;
    

        return _outID;
    }

    return ( 
        <div>
            <div style={{marginTop: 20}}>
                <TabBar tab_elements = {getTalentTabBar()} width = {1568} screen_setter={API_set_skill_group_screen} screen_getter={API_get_skill_group_screen}  />
            </div>
            <div>
                {Object.entries(API_get_attributes()).map(([key, value], index) => (
                    <div>
                        {API_get_skill_group_screen() == API_get_skill_group_tab_name(value.group_id) &&
                            <div>
                                <div style={{marginTop: 15}}>
                                    <CharacterSkillSheetHeader group_id={idMapper(value.group_id)} />
                                </div>
                                <div>
                                    <CharacterSkillSheetBody group_id={idMapper(value.group_id)} />
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CharacterSkillSheet;