import { useState, useContext } from 'react'
import FeatTable from './SubPagesFeatSheet/FeatTable'

const CharacterFeatSheet = (props) => {

    return ( 
        <div>
            <div style={{marginTop: 15}}>
                <FeatTable level={2}/>
            </div>
            <div style={{marginTop: 15}}>
                <FeatTable level={3}/>
            </div>
        </div>
    );
}

export default CharacterFeatSheet