import { useState, useRef, useContext } from 'react'
import {APIContext} from '../../../API/API'
import {MagicContext} from '../../../API/MagicContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {SkillContext} from '../../../API/SkillContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {MessageBoxContext} from '../../../API/MessageBoxContext'
import DiceButton from '../../ComponentsStylish/Buttons/DiceButton';
import upIcon from '../../../Rss/Images/icons/up.png';
import downIcon from '../../../Rss/Images/icons/down.png';
import deleteIcon from '../../../Rss/Images/icons/remove.png';
import plusIcon from '../../../Rss/Images/icons/plus_icon.png';
import {InputField} from '../../ComponentsStylish/InputField';
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'
import field_style from '../../../StylesStylish/field.module.css'
import { Tooltip } from 'react-tooltip'

const MagicSpellSheet = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_modify_magic_spell_name,
        API_modify_magic_spell_might,
        API_modify_magic_spell_description,
        API_switch_magic_element_type,
        API_switch_magic_circle_type,
        API_add_new_magic_spell,
        API_remove_magic_spell,
        API_move_magic_spell
    } = useContext(APIContext);

    const {
        API_get_skill_by_id
    } = useContext(SkillContext);

    const {
        API_get_magic_spells,
        API_set_magic_spells,
        API_get_magic_element_types,
        API_set_magic_element_types,
        API_get_magic_circle_types,
        API_set_magic_circle_types,
        API_get_magic_spell_by_id,
        API_get_magic_element_type_by_id,
        API_get_magic_circle_type_by_id
    } = useContext(MagicContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels,
        API_get_message_box_content_labels
    } = useContext(TextDataContext);

    const {
        API_open_yes_no_message_box
    } = useContext(MessageBoxContext);

    const {
        API_get_user,
        API_get_character_read_only
    } = useContext(UserDataContext);

    
    const selectElementRef = useRef(null);

    const [editList, setEditList] = useState(null);
    //const [editName, setEditName] = useState(null);
    const [editMight, setEditMight] = useState(null);
    //const [editDescription, setEditDescription] = useState(null);

    /*const clickEditElementHandler = (_value) => {
        let _list = API_get_magic_spells();
        let _local_edit_list = [];
        let _local_edit_name = [];
        let _local_edit_might = [];
        let _local_edit_description = [];

        for(let key in _list){
            _local_edit_list.push({id: _list[key].id, value: false});
            _local_edit_name.push({id: _list[key].id, value: _list[key].display_name});
            _local_edit_might.push({id: _list[key].id, value: _list[key].might});
            _local_edit_description.push({id: _list[key].id, value: _list[key].description});
        }

        if(editList == null)
        {
            for(let key in _local_edit_list){
                if(_local_edit_list[key].id == _value.id)
                    _local_edit_list[key].value = true;
            }

            setEditName(_local_edit_name);
            setEditMight(_local_edit_might);
            setEditDescription(_local_edit_description);
            setEditList(_local_edit_list);

            return;
        }


        for(let key1 in _local_edit_list){
            for(let key2 in editList){
                if(_local_edit_list[key1].id == editList[key2].id){
                    _local_edit_list[key1].value = editList[key2].value;
                }
            }
        }

        for(let key in _local_edit_list){
            if(_local_edit_list[key].id == _value.id)
            _local_edit_list[key].value = !_local_edit_list[key].value;
        }

        setEditName(_local_edit_name);
        setEditMight(_local_edit_might);
        setEditDescription(_local_edit_description);
        setEditList(_local_edit_list);
    }*/

    /*const getValueByID = (_id, _list, _type) => {
        return GetListParameterByID(_id, _list, _type);
    }

    const setValueByID = (_id, _list, _setter, _newValue, _type) => {
        SetListParameterByID(_id, _list, _setter, _newValue, _type);
    }*/

    const modifyName = (_id, _newValue) => {
        API_modify_magic_spell_name(_id, _newValue);
    }

    /*const tryModifyMight = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyMight(_id, _newValue);

        SetListParameterByID(_id, editMight, setEditMight, _newValue, 'integer');
    }*/

    const modifyMight = (_id, _newValue) => {
        API_modify_magic_spell_might(_id, _newValue);
    }

    const incrementMight = (_id, _increment) => {
        let _spell = getMagicSpell(_id);
        if(_spell == null)
            return;

        let _might = _spell.might;
        modifyMight(_id, _might+_increment);
    }

    const modifyDescription = (_id, _newValue) => {
        API_modify_magic_spell_description(_id, _newValue);
    }

    const getElementTypes = () => {
        return API_get_magic_element_types();
    }

    const getCircleTypes = () => {
        return API_get_magic_circle_types();
    }
    
    const getElementTypeByID = (_id) => {
        return API_get_magic_element_type_by_id(_id);
    }
    
    const getCircleTypeByID = (_id) => {
        return API_get_magic_circle_type_by_id(_id);
    }

    const handleMagicElementTypeSelected = (event, _list_element_id) => {
        let _new_type = JSON.parse(event.target.value);
        API_switch_magic_element_type(_new_type.id, _list_element_id);
    }

    const handleMagicCircleTypeSelected = (event, _list_element_id) => {
        let _new_type = JSON.parse(event.target.value);
        API_switch_magic_circle_type(_new_type.id, _list_element_id);
    }
    
    const addNewElement = () => {
        API_add_new_magic_spell();
    }

    const removeElement = (_element) => {
        let _content = API_get_message_box_content_labels().delete_magic_spell;
        API_open_yes_no_message_box(_content, () => API_remove_magic_spell(_element));
    }

    const moveElement = (_element, _direction) => {
        API_move_magic_spell(_element, _direction);
    }

    const getMagicSkill = (_spell_id) => {
        let _element = getMagicSkillElement(_spell_id);
        let _skill = API_get_skill_by_id(_element.group_id, _element.skill_id);
        return _skill;
    }

    const getMagicSkillElement = (_spell_id) => {
        let _element_id = getMagicSkillElementID(_spell_id);
        let _elementType = API_get_magic_element_type_by_id(_element_id);
        return _elementType
    }

    const getMagicSkillElementID = (_spell_id) => {
        let _spell = getMagicSpell(_spell_id);
        if(_spell == null)
            return -1;

        return _spell.id;
    }

    const getMagicSpell = (_spell_id) => {
        let _spells = API_get_magic_spells()
        for (let key in _spells){
            if(_spells[key].id == _spell_id)
                return _spells[key]
        }
        return null;
    }
                        
    return ( 
        <div>
            <table className={table_style.std_table} style={{background: 'transparent'}}>
                <tr>
                    <th colspan={11}>
                        {API_get_tab_view_labels().magic_spells}
                    </th>
                </tr>
                <tr style={{height: 12}}></tr>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td style={{fontWeight: 'bold', width: 300, minWidth: 300, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().magic_spell}</td>
                    <td style={{fontWeight: 'bold', width: 135, minWidth: 135, textAlign: 'center'}}>{API_get_std_header_labels().element}</td>
                    <td style={{fontWeight: 'bold', width: 60, minWidth: 60, textAlign: 'center'}}>{API_get_std_header_labels().circle}</td>
                    <td style={{fontWeight: 'bold', width: 100, minWidth: 100, textAlign: 'center'}}>{API_get_std_header_labels().might}</td>
                    <td style={{fontWeight: 'bold', width: 638, minWidth: 638, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().description}</td>
                    <td style={{fontWeight: 'bold', width: 60, minWidth: 60, textAlign: 'center'}}>{API_get_std_header_labels().value}</td>
                    <td style={{fontWeight: 'bold', width: 110, minWidth: 110, textAlign: 'center'}}>{API_get_std_header_labels().dice}</td>
                    <td style={{fontWeight: 'bold', width: 106, minWidth: 106}} colspan={4}></td>
                </tr>
                <tr style={{height: 8}}></tr>
                {Object.entries(API_get_magic_spells()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left'}}>
                            <InputField type='text' 
                                        labelVisibility={false}
                                        widthValue={292} 
                                        content={value.display_name} 
                                        placeholder = {API_get_std_labels().default_name}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        setter={modifyName}
                                        setter_parameter={[value.id]} />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.display_name}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 295}} value={getValueByID(value.id, editName, 'text')} onChange={(e) => setValueByID(value.id, editName, setEditName, e.target.value, 'text')} onBlur={(e) => modifyName(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <select className={field_style.drop_down_field} ref={selectElementRef} value={JSON.stringify(getElementTypeByID(value.element_id))} style={{textAlign: 'center', height: 26}} onChange={(e) => handleMagicElementTypeSelected(e, value.id)} disabled={API_get_character_read_only()}>
                                {Object.entries(getElementTypes()).map(([key, value]) => (
                                    <option key={value.id} value={JSON.stringify(value)}>
                                        {value.display_name}
                                    </option>
                                ))}
                             </select>
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <select className={field_style.drop_down_field} value={JSON.stringify(getCircleTypeByID(value.circle_id))} style={{width: 40, textAlign: 'center', height: 26}} onChange={(e) => handleMagicCircleTypeSelected(e, value.id)} disabled={API_get_character_read_only()}>
                                {Object.entries(getCircleTypes()).map(([key, value]) => (
                                    <option key={value.id} value={JSON.stringify(value)}>
                                        {value.display_name}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <InputField type='integer' 
                                        widthValue={39} 
                                        labelVisibility={false}
                                        content={value.might} 
                                        setter={modifyMight} 
                                        setter_parameter={[value.id]}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        buttonField={true} 
                                        minusButtonCallback={() => incrementMight(value.id, -1)}
                                        plusButtonCallback={() => incrementMight(value.id, 1)}
                         />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                value.might
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 55}} value={getValueByID(value.id, editMight, 'integer')} onChange={(e) => tryModifyMight(value.id, e.target.value)} onBlur={(e) => modifyMight(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{textAlign: 'left'}}>
                            <InputField type='text' 
                                        labelVisibility={false}
                                        widthValue={630} 
                                        content={value.description} 
                                        placeholder = {API_get_std_labels().default_description}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        setter={modifyDescription}
                                        setter_parameter={[value.id]} />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.description}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 725}} value={getValueByID(value.id, editDescription, 'text')} onChange={(e) => setValueByID(value.id, editDescription, setEditDescription, e.target.value, 'text')} onBlur={(e) => modifyDescription(value.id, e.target.value)} />
                            */}
                        </td>
                        {/*<td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                            <button className={btn_style.button_icon} onClick={() => clickEditElementHandler(value)}>E</button>
                        </td>*/}
                        <td style={{textAlign: 'center'}}>
                            {getMagicSkill(value.id).value}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <DiceButton width={'100px'} height={'28px'} roll={getMagicSkill(value.id).dice}/>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value, 'up')}>
                                <img src={upIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value, 'down')}>
                                <img src={downIcon} alt="Bild" style={{ float: 'left', width: '14px', marginTop: 2 }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => removeElement(value)}>
                                <img src={deleteIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td style={{textAlign: 'right', paddingLeft: 5}}>
                        <button className={btn_style.icon_button} onClick={() => addNewElement()} data-tooltip-id="btn-add-tooltip"  data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().add_spell}>
                            <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 0}} src={plusIcon} alt="Bild" />
                        </button>
                        <Tooltip id="btn-add-tooltip" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan={4}></td>
                </tr>
            </table>
        </div>
    );
}
 
export default MagicSpellSheet;