import { useState, useContext, useEffect } from 'react';
import characterDefault from '../Rss/Images/character_default.png';
import {UserDataContext} from '../API/UserDataContext';
import {ScreenDataContext} from '../API/ScreenDataContext';
import {TextDataContext} from '../API/TextDataContext';
import {BaseStatContext} from '../API/BaseStatContext';
import CharacterSheetHeader from './SubPagesCharacterSheet/CharacterSheetHeader';
import FreePointsTable from './SubPagesCharacterSheet/SubPagesHeader/FreePointsTable';
import TalentTable from './SubPagesCharacterSheet/SubPagesHeader/TalentTable';
import DiceButton from './ComponentsStylish/Buttons/DiceButton';
import UploadImageButton from './ComponentsStylish/Buttons/UploadImageButton';
import ImageContainer from './ComponentsStylish/ImageContainer';
import TabBar from './ComponentsStylish/TabBar';
import CharacterOverviewSheet from './SubPagesCharacterSheet/CharacterOverviewSheet';
import CharacterSkillSheet from './SubPagesCharacterSheet/CharacterSkillSheet';
import CharacterEquipmentSheet from './SubPagesCharacterSheet/CharacterEquipmentSheet';
import CharacterFeatSheet from './SubPagesCharacterSheet/CharacterFeatSheet';
import CharacterMagicSheet from './SubPagesCharacterSheet/CharacterMagicSheet';

const CharacterSheetStylish = () => {
    
    const {
        API_get_user, 
        API_set_user,
        API_get_character_id,
        API_set_character_id,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const {
        API_get_character_image,
        API_set_character_image
    } = useContext(BaseStatContext);

    const {
        API_set_tab_view_labels, 
        API_get_tab_view_labels
    } = useContext(TextDataContext);

    const {
        API_set_active_sheet_screen, 
        API_get_active_sheet_screen
    } = useContext(ScreenDataContext);

    const getMainTabBar = () => {
        let _mainTabBar = [
            {content: API_get_tab_view_labels().overview, screen: 'overview'},
            {content: API_get_tab_view_labels().skills, screen: 'skills'},
            {content: API_get_tab_view_labels().combat + '\u0026' + API_get_tab_view_labels().equipment, screen: 'equipment'},
            {content: API_get_tab_view_labels().feat, screen: 'feat'},
            {content: API_get_tab_view_labels().magic, screen: 'magic'}
        ]

        return _mainTabBar;
    }

    const getUserRole = () => {
        let role = API_get_user().role;
        return role;
    }

    return ( 
        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{marginRight: 10, marginTop: 5}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <ImageContainer image={API_get_character_image()} default_image={characterDefault} width={400} max_height={400} />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{order: 1}}>
                                <label style={{marginTop: 2, fontSize: 11}}>ID: {API_get_character_id()}</label>
                            </div>
                            <div style={{order: 2}}>
                                {getUserRole() == 'player' &&
                                    <UploadImageButton image_setter={API_set_character_image}/>
                                }
                            </div>
                        </div>
                        {/*<img src={campaignDefault} alt="Super Check" style={{ float: 'left', width: '400px', border: '2px solid black' }} />*/}
                    </div>
                    {/*<div style={{display: 'flex', flexDirection: 'column'}}>
                        <img src={characterDefault} alt="Super Check" style={{ float: 'left', width: '400px', border: '2px solid black' }} />
                        <label style={{marginTop: 2, fontSize: 11}}>ID: {API_get_character_id()}</label>
                    </div>*/}
                </div>
                <div>
                    <CharacterSheetHeader />
                </div>
            </div>
            <div>
                <div style={{marginTop: 20}}>
                    <TabBar tab_elements = {getMainTabBar()} width = {1568} screen_setter={API_set_active_sheet_screen} screen_getter={API_get_active_sheet_screen}  />
                </div>
                {API_get_active_sheet_screen() == 'overview' &&
                    <CharacterOverviewSheet />
                }
                {API_get_active_sheet_screen() == 'skills' &&
                    <CharacterSkillSheet />
                }
                {API_get_active_sheet_screen() == 'equipment' &&
                    <CharacterEquipmentSheet />
                }
                {API_get_active_sheet_screen() == 'feat' &&
                    <CharacterFeatSheet />
                }
                {API_get_active_sheet_screen() == 'magic' &&
                    <CharacterMagicSheet />
                }
            </div>
        </div>
    );
}

export default CharacterSheetStylish;