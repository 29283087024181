
import { useContext } from 'react'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {ParseToFloat} from '../../../API/Functions/GeneralFunctions'
import table_style from '../../../StylesStylish/table.module.css'

const RestrictionTable = () => {

    const {
        API_get_stat_overview_labels
    } = useContext(TextDataContext);


    const {
        API_get_stat_overview,
    } = useContext(BaseStatContext);

    return ( 
        <div>
            <table className = {table_style.std_table}>
                <tr>
                    <th colspan={2}>{API_get_stat_overview_labels().stat_overview}</th>
                </tr>
                <tr>
                    <td style={{textAlign: 'left', minWidth: 170, width: 170}}>{API_get_stat_overview_labels().carried_weight}</td>
                    <td style={{textAlign: 'center', minWidth: 80, width: 80}}>{ParseToFloat(API_get_stat_overview().carried_weight)}kg</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left'}}>{API_get_stat_overview_labels().damage_restriction}</td>
                    <td style={{textAlign: 'center'}}>{API_get_stat_overview().damage_restriction}</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left'}}>{API_get_stat_overview_labels().armor_restriction}</td>
                    <td style={{textAlign: 'center'}}>{API_get_stat_overview().armor_restriction}</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left'}}>{API_get_stat_overview_labels().other_restriction}</td>
                    <td style={{textAlign: 'center'}}>{API_get_stat_overview().other_restriction}</td>
                </tr>
            </table>
        </div>
    );
}
 
export default RestrictionTable;
                