import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {EquipmentContext, EquipmentContextProvider} from '../../../API/EquipmentContext'
import {TextDataContext, TextDataContextProvider} from '../../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../../API/BaseStatContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {MessageBoxContext} from '../../../API/MessageBoxContext'
import { 
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID,
    TryParse
} from '../../../API/Functions/GeneralFunctions'
import DiceButton from '../../ComponentsStylish/Buttons/DiceButton';
import Checkbox from '../../ComponentsStylish/Checkbox'
import upIcon from '../../../Rss/Images/icons/up.png';
import downIcon from '../../../Rss/Images/icons/down.png';
import deleteIcon from '../../../Rss/Images/icons/remove.png';
import plusIcon from '../../../Rss/Images/icons/plus_icon.png';
import {InputField} from '../../ComponentsStylish/InputField';
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'
import field_style from '../../../StylesStylish/field.module.css'
import { Tooltip } from 'react-tooltip'

const ArmorTable = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_modify_armor_name,
        API_toggle_wear_armor,
        API_modify_armor_weight,
        API_modify_armor_class,
        API_modify_armor_restriction,
        API_add_new_armor_element,
        API_remove_armor_element,
        API_move_armor_element
    } = useContext(APIContext);

    const {
        API_get_armor,
        API_set_armor,
        API_get_gear,
        API_set_gear,
        API_get_melee_weapon_type_by_id,
        API_get_ranged_weapon_type_by_id,
        API_get_hand_type_by_id,
        API_get_armor_by_id,
        API_get_gear_by_id
    } = useContext(EquipmentContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels,
        API_get_message_box_content_labels
    } = useContext(TextDataContext);

    const {
        API_get_stat_overview,
        API_set_stat_overview
    } = useContext(BaseStatContext);

    const {
        API_open_yes_no_message_box
    } = useContext(MessageBoxContext);

    const {
        API_get_user,
        API_get_character_read_only
    } = useContext(UserDataContext);
    
    const [editList, setEditList] = useState(null);
    const [editName, setEditName] = useState(null);
    const [editPutOn, setEditPutOn] = useState(null);
    const [editWeight, setEditWeight] = useState(null);
    const [editArmorClass, setEditArmorClass] = useState(null);
    const [editArmorRestriction, setEditArmorRestriction] = useState(null);

    const clickEditElementHandler = (_value) => {
        let _list = API_get_armor();
        let _local_edit_list = [];
        let _local_edit_name = [];
        let _local_edit_put_on = [];
        let _local_edit_weight = [];
        let _local_edit_armor_class = [];
        let _local_edit_armor_restriction = [];

        for(let key in _list){
            _local_edit_list.push({id: _list[key].id, value: false});
            _local_edit_name.push({id: _list[key].id, value: _list[key].display_name});
            _local_edit_put_on.push({id: _list[key].id, value: _list[key].weight});
            _local_edit_weight.push({id: _list[key].id, value: _list[key].weight});
            _local_edit_armor_class.push({id: _list[key].id, value: _list[key].armor_class});
            _local_edit_armor_restriction.push({id: _list[key].id, value: _list[key].armor_restriction});
        }

        if(editList == null)
        {
            for(let key in _local_edit_list){
                if(_local_edit_list[key].id == _value.id)
                    _local_edit_list[key].value = true;
            }

            setEditName(_local_edit_name);
            setEditPutOn(_local_edit_put_on);
            setEditWeight(_local_edit_weight);
            setEditArmorClass(_local_edit_armor_class);
            setEditArmorRestriction(_local_edit_armor_restriction);
            setEditList(_local_edit_list);

            return;
        }


        for(let key1 in _local_edit_list){
            for(let key2 in editList){
                if(_local_edit_list[key1].id == editList[key2].id){
                    _local_edit_list[key1].value = editList[key2].value;
                }
            }
        }

        for(let key in _local_edit_list){
            if(_local_edit_list[key].id == _value.id)
            _local_edit_list[key].value = !_local_edit_list[key].value;
        }
            
        setEditName(_local_edit_name);
        setEditPutOn(_local_edit_put_on);
        setEditWeight(_local_edit_weight);
        setEditArmorClass(_local_edit_armor_class);
        setEditArmorRestriction(_local_edit_armor_restriction);
        setEditList(_local_edit_list);
    }

    const getValueByID = (_id, _list, _type) => {
        return GetListParameterByID(_id, _list, _type);
    }

    const setValueByID = (_id, _list, _setter, _newValue, _type) => {
        SetListParameterByID(_id, _list, _setter, _newValue, _type);
    }

    const modifyName = (_id, _newValue) => {
        API_modify_armor_name(_id, _newValue);
    }

    const toggleWearArmor = (_id) => {
        API_toggle_wear_armor(_id);
    }

    /*const tryModifyWeight = (_id, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyWeight(_id, _newValue);

        SetListParameterByID(_id, editWeight, setEditWeight, _newValue, 'float');
    }*/

    const modifyWeight = (_id, _newValue) => {
        API_modify_armor_weight(_id, _newValue);
    }

    /*const tryModifyArmorClass = (_id, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyArmorClass(_id, _newValue);

        SetListParameterByID(_id, editArmorClass, setEditArmorClass, _newValue, 'float');
    }*/

    const modifyArmorClass = (_id, _newValue) => {
        API_modify_armor_class(_id, _newValue);
    }

    const incrementArmorClass = (_armor_id, _increment) => {
        let _armor = API_get_armor_by_id(_armor_id);
        let _armor_class = _armor.armor_class;
        API_modify_armor_class(_armor_id, _armor_class + _increment);
    }

    /*const tryModifyArmorRestriction = (_id, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyArmorRestriction(_id, _newValue);

        SetListParameterByID(_id, editArmorRestriction, setEditArmorRestriction, _newValue, 'float');
    }*/

    const modifyArmorRestriction = (_id, _newValue) => {
        API_modify_armor_restriction(_id, _newValue);
    }

    const incrementArmorRestriction = (_armor_id, _increment) => {
        let _armor = API_get_armor_by_id(_armor_id);
        let _armor_restriction = _armor.armor_restriction;
        API_modify_armor_restriction(_armor_id, _armor_restriction + _increment);
    }
    
    const addNewElement = () => {
        API_add_new_armor_element();
    }

    const removeElement = (_element) => {
        //API_remove_armor_element(_element);
        let _content = API_get_message_box_content_labels().delete_armor;
        API_open_yes_no_message_box(_content, () => API_remove_armor_element(_element));
    }

    const moveElement = (_element, _direction) => {
        API_move_armor_element(_element, _direction);
    }
    

    return ( 
        <div>
            <table className={table_style.std_table}>
                <tr>
                    <th colspan={5}>
                        {API_get_equipment_header_labels().armor}
                    </th>
                </tr>
                <tr style={{height: 12}}></tr>
                <tr>
                    <td style={{width: 330, minWidth: 330, fontWeight: 'bold', textAlign: 'left', paddingLeft: 5, borderBottom: '1px solid black'}}>{API_get_equipment_header_labels().armor}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 72, minWidth: 72, borderBottom: '1px solid black'}}>{API_get_equipment_header_labels().put_on}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 84, minWidth: 84, borderBottom: '1px solid black'}}>{API_get_std_header_labels().weight}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 86, minWidth: 86, borderBottom: '1px solid black'}}>{API_get_equipment_header_labels().armor_class_abbreviation}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 86, minWidth: 86, borderBottom: '1px solid black'}}>{API_get_equipment_header_labels().armor_restriction_abbreviation}</td>
                    <td style={{width: 106, minWidth: 106}} colspan={4}></td>
                </tr>
                <tr style={{height: 8}}></tr>
                {Object.entries(API_get_armor()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left'}}>
                            <InputField type='text' 
                                        labelVisibility={false}
                                        widthValue={322} 
                                        content={value.display_name} 
                                        placeholder = {API_get_std_labels().default_name}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        setter={modifyName}
                                        setter_parameter={[value.id]} />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.display_name}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input style={{width: 295}} value={getValueByID(value.id, editName, 'text')} onChange={(e) => setValueByID(value.id, editName, setEditName, e.target.value, 'text')} onBlur={(e) => modifyName(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <Checkbox checked={value.is_put_on} onChange={() => toggleWearArmor(value.id)}/>
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <InputField 
                                type='float' 
                                labelVisibility={false} 
                                widthValue={80} 
                                content={value.weight} 
                                setter={modifyWeight} 
                                setter_parameter={[value.id]}
                                paddingTop={1} 
                                paddingBottom={1}
                                readOnly={API_get_character_read_only()}  />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                value.weight + ' kg'
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' style={{width: 65}} value={getValueByID(value.id, editWeight, 'float')} onChange={(e) => tryModifyWeight(value.id, e.target.value)} onBlur={(e) => modifyWeight(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <InputField type='integer' 
                                        widthValue={28} 
                                        labelVisibility={false}
                                        content={value.armor_class} 
                                        setter={modifyArmorClass} 
                                        setter_parameter={[value.id]}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        buttonField={true} 
                                        minusButtonCallback={() => incrementArmorClass(value.id, -1)}
                                        plusButtonCallback={() => incrementArmorClass(value.id, 1)}/>
                            
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                value.armor_class
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' style={{width: 60}} value={getValueByID(value.id, editArmorClass, 'float')} onChange={(e) => tryModifyArmorClass(value.id, e.target.value)} onBlur={(e) => modifyArmorClass(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <InputField type='integer' 
                                        widthValue={28} 
                                        labelVisibility={false}
                                        content={value.armor_restriction} 
                                        setter={modifyArmorRestriction} 
                                        setter_parameter={[value.id]}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        buttonField={true} 
                                        minusButtonCallback={() => incrementArmorRestriction(value.id, -1)}
                                        plusButtonCallback={() => incrementArmorRestriction(value.id, 1)}/>
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                value.armor_restriction
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' style={{width: 60}} value={getValueByID(value.id, editArmorRestriction, 'float')} onChange={(e) => tryModifyArmorRestriction(value.id, e.target.value)} onBlur={(e) => modifyArmorRestriction(value.id, e.target.value)} />
                            */}
                        </td>
                        {/*<td style={{width: 12, minWidth: 12, borderRight: '0px solid black'}}>
                            <button className={btn_style.icon_button} onClick={() => clickEditElementHandler(value)}>E</button>
                        </td>*/}
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value, 'up')}>
                                <img src={upIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value, 'down')}>
                                <img src={downIcon} alt="Bild" style={{ float: 'left', width: '14px', marginTop: 2 }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => removeElement(value)}>
                                <img src={deleteIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                    </tr>
                ))}
                <tr style={{height: 6}}></tr>
                <tr>
                    <td style={{textAlign: 'right', paddingLeft: 5}}>
                        <button className={btn_style.icon_button} onClick={() => addNewElement()} data-tooltip-id="btn-add-tooltip"  data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().add_armor}>
                            <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 0}} src={plusIcon} alt="Bild" />
                        </button>
                        <Tooltip id="btn-add-tooltip" />
                    </td>
                    <td></td>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{API_get_std_header_labels().total}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{API_get_stat_overview().armor_class}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{API_get_stat_overview().armor_restriction}</td>
                    <td colspan={4}></td>
                </tr>
            </table>
        </div>
    );
}
 
export default ArmorTable;