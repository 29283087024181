import { useState, useContext, createContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import{
    SetTimedMessage
} from '../API/Functions/GeneralFunctions'
import {UserDataContext} from '../API/UserDataContext'
import {CampaignContext} from '../API/CampaignContext'
import {ToolDataContext} from '../API/ToolDataContext'
import {BaseStatContext} from '../API/BaseStatContext'

import {
    REST_get_server_url
} from './ComParamters'
import{
    BACKEND_set_user,
    BACKEND_get_user,
    BACKEND_set_character_owner, 
    BACKEND_get_character_owner,
    BACKEND_set_character_id,
    BACKEND_get_character_id
} from '../Backend/Parameters/UserData'
import{
    BACKEND_get_tool_data,
    BACKEND_set_tool_data,
    BACKEND_get_tool_settings,
    BACKEND_set_tool_settings
} from '../Backend/Parameters/ToolData'
import{
    BACKEND_set_campaign_owner,
    BACKEND_get_campaign_owner,
    BACKEND_set_campaign_id,
    BACKEND_get_campaign_id,
    BACKEND_set_subscribed_user,
    BACKEND_get_subscribed_user,
    BACKEND_set_campaign_characters
} from '../Backend/Parameters/CampaignParameters'
import{
    INIT_get_campaign_id
} from '../Backend/ParametersInit/CampaignInit'
import{
    INIT_get_character_id
} from '../Backend/ParametersInit/UserDataInit'
import{
    PrepareCharacterForDownload,
    LoadCharacter,
    PrepareCampaignForDownload,
    LoadCampaign
} from '../Backend/Functions/IOFunctions'
import { INIT_get_guest_user } from '../Backend/ParametersInit/UserDataInit'
import {IsNullOrEmpty} from '../API/Functions/GeneralFunctions'

const RestUserContext = createContext();

const RestUserContextProvider = ({children}) => {
    
    const {
        API_user_login,
        API_full_GUI_update,
        API_switch_language
    } = useContext(APIContext);

    const {
        API_get_user,
        API_set_user,
        API_set_character_owner,
        API_set_character_id,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const {
        API_get_campaign_characters,
        API_set_campaign_characters,
        API_set_campaign_owner,
        API_set_campaign_id,
        API_set_remote_campaign_message,
        API_get_remote_campaign_message,
        API_set_remote_subscribed_user_message,
        API_get_remote_subscribed_user_message,
        API_set_subscribed_user,
        API_get_subscribed_user,
        API_get_campaign_image,
        API_set_campaign_image
    } = useContext(CampaignContext);

    const {
        API_set_tool_data,
        API_set_tool_settings
    } = useContext(ToolDataContext);

    const {
        API_get_character_image,
        API_set_character_image
    } = useContext(BaseStatContext);
    
    
    

    const request_post = 'POST';
    const content_json = 'application/json';
    const timeout = 4000;

    const triggerTimeOutMessage = (_message_setter) => {
        SetTimedMessage('[timeout]: No connection to backend', _message_setter, timeout)
    }

    const try_parse_json = (_jsonString, _message_setter = null, _message_setter_success = null) => {
        var _data = null;
        try {
            _data = JSON.parse(_jsonString);
        } catch (error) {
            SetTimedMessage('Response bad JSON format', _message_setter, timeout)
            return {success: false, data: {}}
        }
        return {success: true, data: _data}
    }

    const set_xhr = (route, content_type, _message_setter, _message_setter_success) => {
        const xhr = new XMLHttpRequest();

        xhr.open(request_post, REST_get_server_url() + route);
        xhr.setRequestHeader('Content-Type', content_type);
        xhr.timeout = timeout;
        xhr.ontimeout = function() {triggerTimeOutMessage(_message_setter);};
        return xhr;
    }

    const std_response_handler = (_status, _resp, _message_setter, _message_success_setter, _success_callback = null) => {
        let _success = false;

        if(_status == 200)
        {
            if(_resp.success)
            {
                _success = true;
                if(_success_callback != null)
                {
                    _success_callback();
                }
            }

            if(_message_setter != null)
                SetTimedMessage(_resp.message, _message_setter, timeout)

        }
        else{
            if(_message_setter != null)
            {
                SetTimedMessage('Bad Status: ' + _status, _message_setter, timeout)
                //API_set_remote_connect_message('Bad Status: ' + _status);
            }
        }
        
        if(_message_success_setter != null)
            _message_success_setter(_success);
    }

    const user_login = (_resp, _role, _response_status, _message_setter, _message_success_setter) => {
        let _user = {name: '', role: _role}
        if(!_resp.hasOwnProperty('validation')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_resp.hasOwnProperty('user')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_resp.user.hasOwnProperty('name')){bad_response_message(_message_setter, _message_success_setter); return;}

        if(_resp.validation)
            _user = { name: _resp.user.name, role: _role, characters: [], campaigns: []}

        API_user_login(_user, _resp.validation, _response_status);

        if(_resp.validation)
        {
            if(_role == 'player')
            {
                //let _user = BACKEND_get_user();
                REST_apply_config(_message_setter, _message_success_setter);
                //REST_load_characters(_user.name, _message_setter, _message_success_setter);
            }
            else if(_role == 'game_master')
            {
                //REST_apply_config(_message_setter);
                REST_apply_config(_message_setter, _message_success_setter);
                //let _user = BACKEND_get_user();
                //REST_load_campaigns(_user.name, _message_setter, _message_success_setter)
            }
        }
    }

    const load_characters = (_characters) => {
        BACKEND_set_user({...BACKEND_get_user(), characters: {..._characters}});
        API_set_user();
    }

    const load_campaigns_characters = (_characters) => {
        BACKEND_set_campaign_characters(_characters);
        API_set_campaign_characters();
    }

    const load_campaigns = (_campaigns) => {
        BACKEND_set_user({...BACKEND_get_user(), campaigns: {..._campaigns}});
        API_set_user();
    }

    const load_subscribed_user = (_subscribed_user) => {
        BACKEND_set_subscribed_user(_subscribed_user);
        API_set_subscribed_user();
    }

    const load_character = (_character, _portrait) => {
        LoadCharacter(_character);
        API_set_character_image(_portrait);
        API_full_GUI_update();
    }

    const load_campaign = (_campaign, _portrait, _message_setter = null, _message_success_setter = null) => {
        console.log("load campaign");
        console.log(_campaign);
        LoadCampaign(_campaign);
        API_set_campaign_image(_portrait);
        API_full_GUI_update();

        let _user_name = BACKEND_get_user().name;
        let _user_role = BACKEND_get_user().role;
        if(_user_role == 'game_master')
        {
            REST_load_subscribed_users(_user_name, _campaign.id, _message_setter, _message_success_setter);
            REST_load_campaign_characters(_user_name, _campaign.id, _message_setter, _message_success_setter);
        }
        else if(_user_role == 'player'){
            console.log(BACKEND_get_user());
            REST_load_campaign_characters(_campaign.owner, _campaign.id, _message_setter, _message_success_setter);
        }
    }

    const apply_config = (_config, _role, _message_setter, _message_success_setter) => {
        //if(!_config.hasOwnProperty('last_character_id')){bad_response_message(_message_setter); return;}
        //if(!_config.hasOwnProperty('last_campaign_id')){bad_response_message(_message_setter); return;}
        /*if(!_config.hasOwnProperty('last_character')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.last_character.hasOwnProperty('id')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.last_character.hasOwnProperty('owner')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.hasOwnProperty('last_campaign')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.last_campaign.hasOwnProperty('id')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.last_campaign.hasOwnProperty('owner')){bad_response_message(_message_setter, _message_success_setter); return;}*/
        
        let _load_last_character = true;
        let _load_last_campaign = true;
        if(!_config.hasOwnProperty('last_character')){bad_response_message(_message_setter, _message_success_setter); _load_last_character = false;}
        if(_load_last_character){
            if(!_config.last_character.hasOwnProperty('id')){bad_response_message(_message_setter, _message_success_setter); _load_last_character = false;}
            if(!_config.last_character.hasOwnProperty('owner')){bad_response_message(_message_setter, _message_success_setter); _load_last_character = false;}
        }
        if(!_config.hasOwnProperty('last_campaign')){bad_response_message(_message_setter, _message_success_setter); _load_last_campaign = false;}
        if(_load_last_campaign){
            if(!_config.last_campaign.hasOwnProperty('id')){bad_response_message(_message_setter, _message_success_setter); _load_last_campaign = false;}
            if(!_config.last_campaign.hasOwnProperty('owner')){bad_response_message(_message_setter, _message_success_setter); _load_last_campaign = false;}
        }

        let _user = BACKEND_get_user();
        let _user_name = _user.name;

        if(_role == 'player')
        {
            if(_load_last_character)
            {
                BACKEND_set_character_owner(_config.last_character.owner);
                BACKEND_set_character_id(_config.last_character.id);
                API_set_character_owner();
                API_set_character_id();
                REST_load_character(_user_name, _config.last_character.id, null, _message_setter, _message_success_setter)
            }
            if(_load_last_campaign)
            {
                BACKEND_set_campaign_owner(_config.last_campaign.owner);
                BACKEND_set_campaign_id(_config.last_campaign.id);
                API_set_campaign_owner();
                API_set_campaign_id();
                REST_load_campaign(_config.last_campaign.owner, _config.last_campaign.id, null, _message_setter, _message_success_setter)
            }
            REST_load_assigned_campaigns(_user_name, _message_setter, _message_success_setter);
            REST_load_characters(_user.name, _message_setter, _message_success_setter);
        }
        else if(_role == 'game_master')
        {
            console.log('game_master')
            console.log(_load_last_character)
            console.log(_load_last_campaign)
            console.log(_config)
            if(_load_last_character)
            {
                BACKEND_set_character_owner(_config.last_character.owner);
                BACKEND_set_character_id(_config.last_character.id);
                API_set_character_owner();
                API_set_character_id();
                REST_load_character(_config.last_character.owner, _config.last_character.id, null, _message_setter, _message_success_setter)
            }
            if(_load_last_campaign)
            {
                BACKEND_set_campaign_owner(_config.last_campaign.owner);
                BACKEND_set_campaign_id(_config.last_campaign.id);
                API_set_campaign_owner();
                API_set_campaign_id();
                REST_load_campaign(_user_name, _config.last_campaign.id, null, _message_setter, _message_success_setter)
                REST_load_subscribed_users(_user_name, _config.last_campaign.id, _message_setter, _message_success_setter);
                REST_load_campaign_characters(_user_name, _config.last_campaign.id, _message_setter, _message_success_setter);
            }
            REST_load_campaigns(_user.name, _message_setter, _message_success_setter)
        }
        if(!_config.hasOwnProperty('tool_settings')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.tool_settings.hasOwnProperty('foundry_url')){bad_response_message(_message_setter, _message_success_setter); return;}
        BACKEND_set_tool_settings({...BACKEND_get_tool_settings(), foundry_url: _config.tool_settings.foundry_url});
        API_set_tool_settings();
        
        if(!_config.hasOwnProperty('tool_data')){bad_response_message(_message_setter, _message_success_setter); return;}
        if(!_config.tool_data.hasOwnProperty('language')){bad_response_message(_message_setter, _message_success_setter); return;}
        API_switch_language(_config.tool_data.language);
    }

    /*const load_config = (_config, _role, _message_setter, _message_success_setter) => {
        //if(!_config.hasOwnProperty('last_character_id')){bad_response_message(_message_setter); return;}
        //if(!_config.hasOwnProperty('last_campaign_id')){bad_response_message(_message_setter); return;}
        let _user_name = BACKEND_get_user().name;
        let _load_last_character = true;
        let _load_last_campaign = true;
        if(!_config.hasOwnProperty('last_character')){bad_response_message(_message_setter, _message_success_setter); _load_last_character = false;}
        if(_load_last_character){
            if(!_config.last_character.hasOwnProperty('id')){bad_response_message(_message_setter, _message_success_setter); _load_last_character = false;}
            if(!_config.last_character.hasOwnProperty('owner')){bad_response_message(_message_setter, _message_success_setter); _load_last_character = false;}
        }
        if(!_config.hasOwnProperty('last_campaign')){bad_response_message(_message_setter, _message_success_setter); _load_last_campaign = false;}
        if(_load_last_campaign){
            if(!_config.last_campaign.hasOwnProperty('id')){bad_response_message(_message_setter, _message_success_setter); _load_last_campaign = false;}
            if(!_config.last_campaign.hasOwnProperty('owner')){bad_response_message(_message_setter, _message_success_setter); _load_last_campaign = false;}
        }
        

        if(_role == 'player')
        {
            if(_load_last_character)
                REST_load_character(_user_name, _config.last_character.id, () => apply_config(_config, _role, _message_setter, _message_success_setter), _message_setter, _message_success_setter)
            
            if(_load_last_campaign)
                REST_load_campaign(_config.last_campaign.owner, _config.last_campaign.id, () => apply_config(_config, _role, _message_setter, _message_success_setter), _message_setter, _message_success_setter)
        }
        else if(_role == 'game_master')
        {
            //apply_config(_config, _role, _message_setter) //For test purposes
            if(_load_last_campaign)
                REST_load_campaign(_user_name, _config.last_campaign.id, () => apply_config(_config, _role, _message_setter, _message_success_setter), _message_setter, _message_success_setter)
            
            if(_load_last_character)
                REST_load_character(_config.last_character.owner, _config.last_character, () => apply_config(_config, _role, _message_setter, _message_success_setter), _message_setter, _message_success_setter)
        }
        
    }*/

    const save_character = (_character_id, _message_setter = null, _message_success_setter = null) => {
        BACKEND_set_character_id(_character_id)
        API_set_character_id()
        let _user = BACKEND_get_user();
        REST_load_characters(_user.name, null, null);
    }

    const save_campaign = (_campaign_id, _message_setter = null, _message_success_setter = null) => {
        BACKEND_set_campaign_id(_campaign_id);
        API_set_campaign_id();
        let _user = BACKEND_get_user();
        REST_load_campaigns(_user.name, _message_setter, _message_success_setter);
    }

    const bad_response_message = (_message_setter, _message_success_setter) => {
        if(_message_success_setter != null)
            _message_success_setter(false);

        SetTimedMessage("Bad response from backend", _message_setter, timeout)
        //API_set_remote_connect_message("Bad response from backend");
    }

    const block_guest_user = (_message_setter, _message_success_setter) => {
        let _guest_user_check = BACKEND_get_user()
        if(_guest_user_check != null)
        {
            if(_guest_user_check.name == INIT_get_guest_user().name){
                if(_message_success_setter != null)
                    _message_success_setter(false);

                SetTimedMessage('No remote functionality as guest', _message_setter, timeout)
                return false;
            }
        }
        if(_message_success_setter != null)
            _message_success_setter(true);

        return true;
    }

    const empty_character_name_message = (_message_setter = null, _message_success_setter = null) => {
        if(_message_setter == null)
        {
            API_set_remote_connect_message("Character name is empty");
        }
        else
        {
            SetTimedMessage("Character name is empty", _message_setter, timeout)
        }
    }

    const empty_campaign_name_message = (_message_setter = null, _message_success_setter = null) => {
        if(_message_setter == null)
        {
            API_set_remote_campaign_message("Campaign name is empty");
        }
        else
        {
            SetTimedMessage("Campaign name is empty", _message_setter, timeout)
        }
    }

    const send_post_request = (xhr, data, _message_setter, _message_success_setter) => {
        if(!block_guest_user(_message_setter, _message_success_setter)){return};
        xhr.send(data);
    }

    const REST_login_user = (_user, _password, _role, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message;
        if(_in_message_setter != null)
            _message_setter = _in_message_setter

        const route = '/login';
        const data = JSON.stringify({name: _user, password: _password});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message;
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => user_login(_resp, _role, xhr.status, _message_setter, _message_success_setter))
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_create_new_user = (_user, _password, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        const route = '/create_user';
        const data = JSON.stringify({name: _user, password: _password});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter)
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_load_characters = (_user_name, _in_message_setter = null, _message_success_setter = null) => {
        console.log('REST_load_characters');
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user = BACKEND_get_user()
        const route = '/get_characters';
        const data = JSON.stringify({user: _user_name});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter, _message_success_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_characters(_resp.list));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_save_character = (_in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter

        let _user_name = BACKEND_get_user().name
        let _character_id = BACKEND_get_character_id()
        let _character = PrepareCharacterForDownload()
        let _image = API_get_character_image();
        
        const route = '/save_character';
        const data = JSON.stringify({user_name: _user_name, character_id: _character_id, character: _character, portrait: _image});
        const base_stats = _character.base_stats;
        const character_name = base_stats.name;
        if(IsNullOrEmpty(character_name)){empty_character_name_message(_message_setter); return;}

        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter

            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('created_id')){bad_response_message(_message_setter, _message_success_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => save_character(_resp.created_id, _message_setter, _message_success_setter));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_load_character = (_character_owner, _character_id, _success_callback = null, _in_message_setter = null, _message_success_setter = null) => {
        console.log('REST_load_character');
        console.log('_character_owner');
        console.log(_character_owner);
        console.log('_character_id');
        console.log(_character_id);
        let _message_setter = API_set_remote_connect_message;
        if(_in_message_setter != null)
            _message_setter = _in_message_setter;
            
        if(_character_id == INIT_get_character_id())
        {
            if(_message_success_setter != null)
                _message_success_setter(false);
    
            SetTimedMessage("No character loaded", _message_setter, timeout)
            return;
        }
        let _user_name = BACKEND_get_user().name
        const route = '/get_character_by_id'

        const data = JSON.stringify({user: _user_name, character_owner: _character_owner, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        console.log('data');
        console.log(data);
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter;

            console.log('xhr.responseText')
            console.log(xhr.responseText)
            let _json_parse_result = try_parse_json(xhr.responseText);
            console.log('_json_parse_result')
            console.log(_json_parse_result)
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            console.log('_resp')
            console.log(_resp)

            if(!_resp.hasOwnProperty('element')){bad_response_message(_message_setter, _message_success_setter); return;}
            if(!_resp['element'].hasOwnProperty('character')){bad_response_message(_message_setter, _message_success_setter); return;}
            if(!_resp['element'].hasOwnProperty('portrait')){bad_response_message(_message_setter, _message_success_setter); return;}

            //let _json_parse_character_result = try_parse_json(_resp.character);
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_character(_resp.element.character, _resp.element.portrait));

            if(/*_resp.success &&*/ _success_callback != null)
                _success_callback();
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_delete_character = (_character_owner, _character_id, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user_name = BACKEND_get_user().name
        const route = '/delete_character_by_id';
        const data = JSON.stringify({character_owner: _character_owner, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;

            let _user = BACKEND_get_user();
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => REST_load_characters(_user.name));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_apply_config = (_in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        let _user_name = BACKEND_get_user().name
        let _user_role = BACKEND_get_user().role
        const route = '/get_config';
        const data = JSON.stringify({user_name: _user_name});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)

        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('config')){bad_response_message(_message_setter, _message_success_setter); return;}
            
            //std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_config(_resp.config, _user_role, _message_setter, _message_success_setter));
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => apply_config(_resp.config, _user_role, null, null));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
    }

    const REST_set_config = (_user_name, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        let _tool_data = BACKEND_get_tool_data();
        let _tool_settings = BACKEND_get_tool_settings();
        const route = '/set_config';
        const data = JSON.stringify({user_name: _user_name, tool_data: _tool_data, tool_settings: _tool_settings});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)

        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter);
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
    }

    const REST_load_campaign_characters = (_campaign_owner, _campaignID, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_connect_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user_name = BACKEND_get_user().name
        const route = '/load_campaign_characters';
        const data = JSON.stringify({campaign_owner: _campaign_owner, campaign_id: _campaignID});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter, _message_success_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_campaigns_characters(_resp.list))
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_load_campaigns = (_user_name, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_campaign_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user = BACKEND_get_user()
        const route = '/get_campaigns';
        const data = JSON.stringify({user: _user_name});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter, _message_success_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_campaigns(_resp.list));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_save_campaign = (_in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_campaign_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        let _user_name = BACKEND_get_user().name
        let _campaign_id = BACKEND_get_campaign_id()
        let _campaign = PrepareCampaignForDownload()
        let _image = API_get_campaign_image();
        const route = '/save_campaign';

        const data = JSON.stringify({user_name: _user_name, campaign_id: _campaign_id, campaign: _campaign, portrait: _image});
        const campaign_name = _campaign.name;
        if(IsNullOrEmpty(campaign_name)){empty_campaign_name_message(_message_setter, _message_success_setter); return;}

        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('created_id')){bad_response_message(_message_setter, _message_success_setter); return;}

            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => save_campaign(_resp.created_id));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_load_campaign = (_campaign_owner, _campaign_id, _success_callback = null, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_campaign_message
        if (_in_message_setter != null)
            _message_setter = _in_message_setter

        if (_campaign_id == INIT_get_campaign_id()) {
            if (_message_success_setter != null)
                _message_success_setter(false);

            SetTimedMessage("No campaign loaded", _message_setter, timeout)
            return;
        }

        let _user_name = BACKEND_get_user().name
        const route = '/get_campaign_by_id'
        const data = JSON.stringify({user: _user_name, campaign_owner: _campaign_owner, campaign_id: _campaign_id});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;

            if(!_resp.hasOwnProperty('element')){bad_response_message(_message_setter, _message_success_setter); return;}
            if(!_resp['element'].hasOwnProperty('campaign')){bad_response_message(_message_setter, _message_success_setter); return;}
            if(!_resp['element'].hasOwnProperty('portrait')){bad_response_message(_message_setter, _message_success_setter); return;}

            //let _json_parse_character_result = try_parse_json(_resp.campaign);
            
            if(_resp.success && _success_callback != null)
                _success_callback();
            
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_campaign(_resp.element.campaign, _resp.element.portrait));
        };

        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_delete_campaign = (_campaign_owner, _campaign_id, _in_message_setter = null, _message_success_setter = null) => {

        let _message_setter = API_set_remote_campaign_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user_name = BACKEND_get_user().name
        const route = '/delete_campaign_by_id';
        const data = JSON.stringify({campaign_owner: _campaign_owner, campaign_id: _campaign_id});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {

            let _message_setter = API_set_remote_campaign_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);

            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter, _message_success_setter); return;}
            let _resp = _json_parse_result.data;

            let _user = BACKEND_get_user();
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => REST_load_campaigns(_user.name));
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_subscribe_character_to_campaign = (_character_owner, _character_id, _campaign_owner, _campaign_id, _subscribe_state, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_campaign_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user_name = BACKEND_get_user().name
        //let _characterID = BACKEND_get_character_id()
        if(_character_id == INIT_get_character_id())
        {
            SetTimedMessage("Save character first", _message_setter, timeout)
            return;
        }
        if(IsNullOrEmpty(_character_owner) || IsNullOrEmpty(_campaign_owner))
        {
            SetTimedMessage("User name empty", _message_setter, timeout)
            return;
        }
        const route = '/subscribe_character_to_campaign';
        const data = JSON.stringify({character_owner: _character_owner, character_id: _character_id, campaign_owner: _campaign_owner, campaign_id: _campaign_id, subscription_state: _subscribe_state});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            console.log("response")
            console.log(_json_parse_result)
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            let _user_role = BACKEND_get_user().role;
            let _user_name = BACKEND_get_user().name;
            if(_user_role == 'player')
            {
                std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => REST_load_assigned_campaigns(_user_name))
            }
            else if(_user_role == 'game_master')
            {
                std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => REST_load_campaign_characters(_user_name, _campaign_id))
            }
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_load_assigned_campaigns = (_user_name, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_campaign_message;
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user_name = BACKEND_get_user().name;
        let _character_id = BACKEND_get_character_id();
        /*if(_character_id == INIT_get_character_id())
        {
            _message_setter("Save character first")
            return;
        }*/
        const route = '/load_assigned_campaigns';
        const data = JSON.stringify({user_name: _user_name, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter, _message_success_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_campaigns(_resp.list))
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }

    const REST_subscribe_user_to_campaign = (_subscribe_user_name, _campaign_id, _subscription_state, _in_message_setter = null, _message_success_setter = null) => {
        let _message_setter = API_set_remote_subscribed_user_message;
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        if(_campaign_id == INIT_get_campaign_id())
        {
            if(_message_success_setter != null)
                _message_success_setter(false);

            SetTimedMessage("Save campaign to remote before subscribing characters", _message_setter, timeout)
            return;
        }
        console.log("ASFDFDDFSDF");
        const route = '/subscribe_user_to_campaign';
        let _user_name = ''
        let _campaign_owner = ''
        let _user_role = BACKEND_get_user().role;
        if(_user_role == 'player')
        {
            _user_name = BACKEND_get_user().name
            _campaign_owner = _subscribe_user_name
        }
        else if(_user_role == 'game_master')
        {
            _user_name = _subscribe_user_name
            _campaign_owner = BACKEND_get_user().name
        }

        const data = JSON.stringify({user_name: _user_name, campaign_owner: _campaign_owner, campaign_id: _campaign_id, subscription_state: _subscription_state});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        xhr.onload = function() {

            let _message_setter = API_set_remote_subscribed_user_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            let _user_role = BACKEND_get_user().role
            let _user_name = BACKEND_get_user().name
            if(_user_role == 'player')
            {
                std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => REST_load_assigned_campaigns(_user_name))
            }
            else if(_user_role == 'game_master')
            {
                std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => REST_load_subscribed_users(_user_name, _campaign_id))
            }
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
    }
    
    const REST_load_subscribed_users = (_user_name, _campaign_id, _in_message_setter = null, _message_success_setter = null) => {
        console.log('REST_load_subscribed_users');
        console.log(_user_name);
        console.log(_campaign_id);
        let _message_setter = API_set_remote_subscribed_user_message
        if(_in_message_setter != null)
            _message_setter = _in_message_setter
        //let _user_name = BACKEND_get_user().name
        const route = '/load_subscribed_users';
        const data = JSON.stringify({campaign_owner: _user_name, campaign_id: _campaign_id});
        const xhr = set_xhr(route, content_json, _message_setter, _message_success_setter)
        console.log(_user_name)
        console.log(_campaign_id)
        xhr.onload = function() {

            let _message_setter = API_set_remote_subscribed_user_message
            if(_in_message_setter != null)
                _message_setter = _in_message_setter
            let _json_parse_result = try_parse_json(xhr.responseText);

            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter, _message_success_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, _message_success_setter, () => load_subscribed_user(_resp.list))
        };
        send_post_request(xhr, data, _message_setter, _message_success_setter);
        //xhr.send(data);
    }
        
    return(
        <RestUserContext.Provider value={{
            REST_login_user,
            REST_create_new_user,
            REST_load_characters,
            REST_save_character,
            REST_load_character,
            //REST_load_campaign_character,
            REST_delete_character,
            REST_apply_config,
            REST_set_config,
            REST_load_campaign_characters,
            //REST_unsubscribe_character_from_campaign,
            REST_load_campaigns,
            REST_save_campaign,
            REST_load_campaign,
            REST_delete_campaign,
            REST_subscribe_character_to_campaign,
            REST_load_assigned_campaigns,
            REST_subscribe_user_to_campaign,
            REST_load_subscribed_users
        }}>
            {children}
        </RestUserContext.Provider>
    )
}

export {RestUserContext, RestUserContextProvider}