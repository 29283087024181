import {
    BACKEND_get_attribute_by_id,
    BACKEND_get_attribute_group_by_id
} from '../BackendRouter'

import {
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_free_skill_points,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_set_unarmed_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_money_list,
    BACKEND_set_money_list,
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_feat_lv_2_template,
    BACKEND_get_feat_lv_3_template,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_formula_skill_ids,
    BACKEND_get_attribute_group_ref,
    BACKEND_get_attribute_ref,
    BACKEND_set_ancestry_saldo,
    BACKEND_get_ancestry_saldo
} from '../Parameters/CharacterParameters'

import {
    //INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_ancestry_saldo,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    //INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restrictionX
} from '../ParametersInit/AttributesInit'
import {
    INIT_get_base_stats, 
    INIT_get_character_stats,
    INIT_get_money_list,
} from '../ParametersInit/CharacterStatInit'
import { 
    INIT_get_skills,
    INIT_get_free_skill_points
 } from "../ParametersInit/SkillParametersInit";
 import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from '../Parameters/ToolData'
import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeapons,
    RecalcWeight,
    RecalcArmor
} from './RecalcFunctions'
import {
    Translate
} from '../Lang/Translator'
import {
    GetDiceList,
} 
from '../DiceRoller'
import { 
    INIT_get_unarmed_weapons,
    INIT_get_melee_weapons,
    INIT_get_ranged_weapons,
    INIT_get_armor,
    INIT_get_gear
} 
from '../ParametersInit/EquipmentListInit';
import { 
    ParseToFloat, 
    ParseToInteger,
    GetListParameterByID,
    SetListParameterByID,
    AddElementToList,
    RemoveElementFromList,
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'
import { 
    INIT_get_feat_lvl_2,
    INIT_get_feat_lvl_3
} 
from '../ParametersInit/FeatListInit';
import { 
    INIT_get_magic_spells
} 
from '../ParametersInit/MagicSpellsInit';
import {
    INIT_get_guest_user,
    INIT_get_character_id
} from '../ParametersInit/UserDataInit'
import {
    BACKEND_set_character_id
} from '../Parameters/UserData'


const InitCharacter = (_language) => {
    //console.log('InitCharacter');
    //BACKEND_set_free_attribute_points({used: 0, total: GetFreeAttributes(0)});
    var _init_free_attribute_points = INIT_get_free_attribute_points();
    var _init_free_focus_points = INIT_get_free_focus_points();
    var _init_free_skill_points = INIT_get_free_skill_points();
    var _init_base_stats = INIT_get_base_stats();

    BACKEND_set_character_id(INIT_get_character_id());
    BACKEND_set_tool_data(BACKEND_get_tool_data());
    BACKEND_set_base_stats(_init_base_stats);
    BACKEND_set_ancestry_saldo(INIT_get_ancestry_saldo());
    BACKEND_set_comments('');
    BACKEND_set_attribute_groups(INIT_get_attribute_groups());
    BACKEND_set_attributes(INIT_get_attributes());
    BACKEND_set_character_stats(INIT_get_character_stats());
    BACKEND_set_money_list(INIT_get_money_list());
    //BACKEND_set_secondary_attributes(INIT_get_secondary_attributes());
    BACKEND_set_skills(INIT_get_skills());
    BACKEND_set_unarmed_weapons(INIT_get_unarmed_weapons());
    BACKEND_set_melee_weapons(INIT_get_melee_weapons());
    BACKEND_set_ranged_weapons(INIT_get_ranged_weapons());
    BACKEND_set_armor(INIT_get_armor());
    BACKEND_set_gear(INIT_get_gear());
    BACKEND_set_feat_lv_2(INIT_get_feat_lvl_2());
    BACKEND_set_feat_lv_3(INIT_get_feat_lvl_3());
    BACKEND_set_magic_spells(INIT_get_magic_spells());
    BACKEND_set_dice_log({...BACKEND_get_dice_log(), dice: GetDiceList(4)});
    
    //_init_free_attribute_points.total = GetFreeAttributePoints(_init_base_stats.level);
    //_init_free_focus_points.total = GetFreeFocusPoints(_init_base_stats.level);
    //_init_free_skill_points.total = GetFreeSkillPoints(_init_base_stats.level);
    BACKEND_set_free_attribute_points({..._init_free_attribute_points, total: GetFreeAttributePoints(_init_base_stats.level)});
    BACKEND_set_free_focus_points({..._init_free_focus_points, total: GetFreeFocusPoints(_init_base_stats.level)});
    BACKEND_set_free_skill_points({..._init_free_skill_points, total: GetFreeSkillPoints(_init_base_stats.level)});

    RecalcFreeAttributePoints();
    RecalcFreeFocusPoints();
    RecalcFreeSkillPoints();
    RecalcAttributes();
    RecalcSkills();
    RecalcWeapons();
    RecalcArmor();
    Translate(_language);

    //BACKEND_set_attribute_points({used: 0, total: GetFreeAttributes(0)});
    //var _init_free_attribute_points = INIT_get_free_attribute_points();
    //var _init_free_focus_points = INIT_get_free_focus_points();
    //var _init_free_skill_points = INIT_get_free_skill_points();
    //var _init_base_stats = INIT_get_base_stats();

    RecalcWeight();
}

function CalculateSkillAttibuteMod(_skill_group_id, _skill) {
    let _lead_attribute_1 = BACKEND_get_attribute_by_id(_skill.attr_mod_1_id);
    let _lead_attribute_2 = BACKEND_get_attribute_by_id(_skill.attr_mod_2_id);
    let _val_1 = _lead_attribute_1.value;
    let _val_2 = _lead_attribute_2.value;
    let _val = (_val_1 + _val_2)/4;
    return Math.round(_val);
}

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
function CalculateAttribute (_attribute){
    let _attribute_group = BACKEND_get_attribute_group_by_id(_attribute.group);
    /*let _free_focus_points_bonus = _attribute_group.focus;
    if(_attribute_group.focus == -1)
    {
        _free_focus_points_bonus = -2;
    }
    else if(_attribute_group.focus == 0)
    {
        _free_focus_points_bonus = 0;
    }
    else if(_attribute_group.focus == 1)
    {
        _free_focus_points_bonus = 0;
    }
    else if(_attribute_group.focus == 2)
    {
        _free_focus_points_bonus = 1;
    }
    else if(_attribute_group.focus == 3)
    {
        _free_focus_points_bonus = 2;
    }*/

    return /*_free_focus_points_bonus +*/ _attribute.base +  _attribute.increase + _attribute.ancestry_bonus + _attribute.temporary_mod + _attribute.other_bonus;
}

function CalculateSkill (_skill_group_id, _skill){
    //let _lead_attribute_1 = BACKEND_get_attribute_by_id(_skill.attr_mod_1_id);
    //let _lead_attribute_2 = BACKEND_get_attribute_by_id(_skill.attr_mod_2_id);
    
    if(_skill_group_id != BACKEND_get_attribute_ref().arcana)
    {
        return CalculateStandardSkill(_skill_group_id, _skill);
    } 
    else if(_skill_group_id == BACKEND_get_attribute_ref().arcana){
        return CalculateMagicSkill (_skill_group_id, _skill);
    }
}

function CalculateStandardSkill (_skill_group_id, _skill) {
    let _group_attribute = BACKEND_get_attribute_by_id(_skill_group_id);
    let _mod = CalculateSkillAttibuteMod(_skill_group_id, _skill);
    return _skill.increase + _skill.temporary_mod + _skill.other_bonus + _skill.ancestry_mod + _group_attribute.value + Math.round(_mod);
}

function CalculateMagicSkill (_skill_group_id, _skill) {
    let _group_attribute = BACKEND_get_attribute_by_id(_skill_group_id);
    let _mod = CalculateSkillAttibuteMod(_skill_group_id, _skill);
    let _increase = _skill.increase*(_skill.increase+1);
    _increase = ParseToInteger(_increase/2);
    
    return _increase + _skill.temporary_mod + _skill.other_bonus + _skill.ancestry_mod + _group_attribute.value + Math.round(_mod);
}

function GetMaxSkillIncrease(_group_id/*, _skill*/) {
    //console.log('GetMaxSkillIncrease');
    //console.log(_group_id);
    let _base_stats = BACKEND_get_base_stats();
    let _attribute = BACKEND_get_attribute_by_id(_group_id);
    let _attribute_group = BACKEND_get_attribute_group_by_id(_attribute.group);

    let _level = _base_stats.level;
    let _focus = _attribute_group.focus;

    //console.log(_attribute_group.display_name);
    //console.log(_attribute_group.max_specialization);
    
    if(_group_id != BACKEND_get_attribute_ref().arcana)
    {
        return GetStandardSkillIncrease(_focus, _level/*, _attribute.value*/);
    }
    else if(_group_id == BACKEND_get_attribute_ref().arcana){
        return GetMagicSkillIncrease (/*_focus, _level, _attribute.value*/);
    }

    return -1;
    
}

function GetStandardSkillIncrease(_focus, _level/*, _value*/)
{
    var _r0 = 0;
    var _r20 = 0;

    if(_focus <= -1)
    {
        _r0 = 1;
        _r20 = 6;
    }
    else if(_focus == 0)
    {
        _r0 = 4;
        _r20 = 10;
    }
    else if(_focus == 1)
    {
        _r0 = 5;
        _r20 = 12;
    }
    else if(_focus == 2)
    {
        _r0 = 6;
        _r20 = 16;
    }
    else if(_focus >= 3)
    {
        _r0 = 7;
        _r20 = 20;
    }

    let _m = (_r20 - _r0)/20.0;
    let _val = Math.round(_m*_level +_r0 /*+ _value/5*/);

    return _val;
}

function GetMagicSkillIncrease(/*_focus, _level, _value*/)
{
    let _base_stats = BACKEND_get_base_stats();
    let _group_id = BACKEND_get_attribute_ref().arcana
    let _attribute = BACKEND_get_attribute_by_id(_group_id);
    let _attribute_group = BACKEND_get_attribute_group_by_id(_attribute.group);

    let _level = _base_stats.level;
    let _focus = _attribute_group.focus;
    /*let _val = Math.round(_value/3);
    if(_val <= 0)
    {
        _val = 1;
    }
    else if(_val > 7)
    {
        _val = 7;
    }*/


    var _r0 = 0;
    var _r20 = 0;

    if(_focus <= -1)
    {
        _r0 = 0;
        _r20 = 0;
    }
    else if(_focus == 0)
    {
        _r0 = 1;
        _r20 = 3;
    }
    else if(_focus == 1)
    {
        _r0 = 1;
        _r20 = 5;
    }
    else if(_focus == 2)
    {
        _r0 = 2;
        _r20 = 6;
    }
    else if(_focus >= 3)
    {
        _r0 = 3;
        _r20 = 7;
    }

    let _m = (_r20 - _r0)/20.0;
    let _val = Math.round(_m*_level +_r0 /*+ _value/5*/);

    return _val;
}

function GetFreeAttributePoints (_level) 
{
    let _free_attribute_points = BACKEND_get_free_attribute_points();
    return 6*_level+50+_free_attribute_points.bonus;
}

function GetFreeFocusPoints (_level) 
{
    var _free_focus_points = BACKEND_get_free_focus_points();
    return 7+_free_focus_points.bonus;
}

function GetFreeSkillPoints (_level) 
{
    let _free_skill_points = BACKEND_get_free_skill_points();
    return 24*_level+_free_skill_points.bonus+96;
}

/*function GetSkillPointsAttribute (_value) 
{
    return 10*_value;
}*/

function GetSkillByFormulaID (_formula_id) {
    let _skills = BACKEND_get_skills();
    for(let key1 in _skills)
    {
        if(_skills[key1].skill_group_id == _formula_id.group_id){
            for(let key2 in _skills[key1].skills)
            {
                if(_skills[key1].skills[key2].id == _formula_id.skill_id){
                    return _skills[key1].skills[key2];
                }
            }
        }
    }
    return null;
}

function GetHitpoints ()
{
    let formular_skill_ids = BACKEND_get_formula_skill_ids();
    let _toughness_skill = GetSkillByFormulaID(formular_skill_ids.toughness);

    var _hitpoints = BACKEND_get_character_stats().hitpoints;
    //var _constitution = BACKEND_get_attribute_by_id(0);
    return 3*_toughness_skill.value+20+_hitpoints.temporary_mod+_hitpoints.bonus;
}

function GetLoadCapacity ()
{
    let formular_skill_ids = BACKEND_get_formula_skill_ids();
    let _load_capacity_skill = GetSkillByFormulaID(formular_skill_ids.load_capacity);

    var _load_capacity = BACKEND_get_character_stats().load_capacity;
    //var _constitution = BACKEND_get_attribute_by_id(0);
    return 3*_load_capacity_skill.value+28+_load_capacity.temporary_mod+_load_capacity.bonus;
}

function GetEndurance ()
{
    let formular_skill_ids = BACKEND_get_formula_skill_ids();
    let _endurance_skill = GetSkillByFormulaID(formular_skill_ids.endurance);

    var _endurance = BACKEND_get_character_stats().endurance;
    //var _constitution = BACKEND_get_attribute_by_id(0);
    return 4*_endurance_skill.value+16+_endurance.temporary_mod+_endurance.bonus;
}

function GetKhoTal ()
{
    var _kho_tal = BACKEND_get_character_stats().kho_tal;
    var _level = BACKEND_get_base_stats().level;
    return Math.round(_level/4)+1+_kho_tal.bonus;
}

/*function GetInitiative ()
{
    //var _initiative = BACKEND_get_secondary_attributes().initiative;
    var _dexterity = BACKEND_get_attribute_by_id(2);
    var _perception= BACKEND_get_attribute_by_id(10);
    var _result = Math.round((2*_perception.value+_dexterity.value)/3)
    return _result+_initiative.temporary_mod+_initiative.bonus;
}

function GetDodgeValue ()
{
    var _dodge = BACKEND_get_secondary_attributes().dodge;
    var _dexterity = BACKEND_get_attribute_by_id(2);
    var _perception= BACKEND_get_attribute_by_id(10);
    var _result = Math.round((_perception.value+2*_dexterity.value)/3)
    return _result+_dodge.temporary_mod+_dodge.bonus;
}*/

function GetFocusCost(_focus)
{
    if(_focus <= 1)
    {
        return 1;
    }
    else
    {
        return 2;
    }
}

function GetMaxAttributeIncrease(_focus, _level)
{
    var _r0 = 0;
    var _r20 = 0;

    if(_focus <= -1)
    {
        _r0 = 0;
        _r20 = 5;
    }
    else if(_focus == 0)
    {
        _r0 = 4;
        _r20 = 11;
    }
    else if(_focus == 1)
    {
        _r0 = 5;
        _r20 = 13;
    }
    else if(_focus == 2)
    {
        _r0 = 6;
        _r20 = 17;
    }
    else if(_focus >= 3)
    {
        _r0 = 7;
        _r20 = 21;
    }

    var _m = (_r20 - _r0)/20.0;
    var _val = Math.round(_m*_level +_r0);

    return _val;
}

//Convert attributes from json structure to program structure
const ConvertAttributes = (_LoadedAttributeGroups, _LoadedAttributes) =>
{
    var data =[];

    var i = 0;
    var j = 0;

    for (const key1 in _LoadedAttributeGroups) {
        if (typeof _LoadedAttributeGroups[key1] !== 'undefined') {
            data.push({group_id: _LoadedAttributeGroups[key1].id, group: _LoadedAttributeGroups[key1].display_name, focus: _LoadedAttributeGroups[key1].focus, max_training: _LoadedAttributeGroups[key1].max_training, max_specialization: _LoadedAttributeGroups[key1].max_specialization, attributes: []});
            for (const key2 in _LoadedAttributes) {
                if (typeof _LoadedAttributes[key2] !== 'undefined') {
                    if(_LoadedAttributeGroups[key1].id == _LoadedAttributes[key2].group)
                    {
                        data[i].attributes.push(_LoadedAttributes[key2]);
                    }
                }
            }
            i += 1;
        }
    }

    return data;
}

//Convert attributes from program structure to json structure
const ConvertAttributesBack = (m_Attributes) =>
{
    var dataAttributeGroups =[];
    var dataAttributes =[];

    for(var index1 in m_Attributes)
    {
        if (typeof m_Attributes[index1] !== 'undefined') {
            dataAttributeGroups.push(m_Attributes[index1].group);
            for(var index2 in m_Attributes[index1].attributes)
            {
                if (typeof m_Attributes[index1].attributes[index2] !== 'undefined') {
                    dataAttributes.push(m_Attributes[index1].attributes[index2]);
                }
            }
        }
    }
    return {groups: dataAttributeGroups, attributes: dataAttributes};
}

const ModifyMoneyList = (_index, _value) => {
    let _money_list = BACKEND_get_money_list();

    for(var key in _money_list){
        if(_money_list[key].id == _index){
            _money_list[key].amount = ParseToInteger(_value);
        }
    }

    BACKEND_set_money_list(_money_list);
    RecalcWeight();
}

const getFeatList = (_feat_level) => {
    if(_feat_level == 2)
    {
        return BACKEND_get_feat_lv_2();
    }
    else if(_feat_level == 3)
    {
        return BACKEND_get_feat_lv_3();
    }
}

const getFeatSetter = (_feat_level) => {
    if(_feat_level == 2)
    {
        return BACKEND_set_feat_lv_2;
    }
    else if(_feat_level == 3)
    {
        return BACKEND_set_feat_lv_3;
    }
}

const getFeatTemplate = (_feat_level) => {
    if(_feat_level == 2)
    {
        return BACKEND_get_feat_lv_2_template();
    }
    else if(_feat_level == 3)
    {
        return BACKEND_get_feat_lv_3_template();
    }
}

const ModifyFeatName = (_id, _new_value, _feat_level) => {
    SetListParameterByID(_id, getFeatList(_feat_level), getFeatSetter(_feat_level), _new_value, 'text', 'id', 'display_name');
}

const ModifyFeatDescription = (_id, _new_value, _feat_level) => {
    SetListParameterByID(_id, getFeatList(_feat_level), getFeatSetter(_feat_level), _new_value, 'text', 'id', 'description');
}

const AddNewFeatElement = (_feat_level) => {
    AddElementToList(getFeatList(_feat_level), getFeatTemplate(_feat_level), getFeatSetter(_feat_level));
}

const RemoveFeatElement = (_element, _feat_level) => {
    RemoveElementFromList(_element.id, getFeatList(_feat_level), getFeatSetter(_feat_level));
}

const MoveFeatElement = (_element, _direction, _feat_level) => {
    MoveElementInList(_element.id, getFeatList(_feat_level), getFeatSetter(_feat_level), _direction);
}

export {
    InitCharacter,
    CalculateAttribute, 
    CalculateSkill, 
    GetHitpoints,
    GetLoadCapacity,
    GetEndurance,
    //GetInitiative,
    GetKhoTal,
    //GetDodgeValue,
    GetFreeAttributePoints, 
    GetFreeFocusPoints, 
    GetFreeSkillPoints,
    //GetSkillPointsAttribute,
    GetFocusCost, 
    GetMaxAttributeIncrease,
    GetMaxSkillIncrease,
    GetMagicSkillIncrease, 
    ConvertAttributes, 
    ConvertAttributesBack,
    CalculateSkillAttibuteMod,
    ModifyMoneyList,
    ModifyFeatName,
    ModifyFeatDescription,
    AddNewFeatElement,
    RemoveFeatElement,
    MoveFeatElement
}