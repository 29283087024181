import { useState, useContext, useRef } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {MessageBoxContext, MessageBoxContextProvider} from '../../API/MessageBoxContext'
import {RestUserContext, RestUserContextProvider} from '../../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {
    ParseToInteger,
    ParseToFloat,
    IsNullOrEmpty} 
from '../../API/Functions/GeneralFunctions'
import characterPortrait from '../../Rss/Images/character_default.png';
import refreshIcon from '../../Rss/Images/icons/refresh.png';
import importIcon from '../../Rss/Images/icons/import_database.png';
import upIcon from '../../Rss/Images/icons/up.png';
import downIcon from '../../Rss/Images/icons/down.png';
import deleteIcon from '../../Rss/Images/icons/remove.png';
import subscribeIcon from '../../Rss/Images/icons/subscribe_character.png';
import unsubscribeIcon from '../../Rss/Images/icons/unsubscribe_character.png';
import checkIcon from '../../Rss/Images/icons/check.png';
import minusIcon from '../../Rss/Images/icons/minus.png';
import RemoteDownloadButton from '../ComponentsStylish/Buttons/RemoteUploadButton';
import NewCharacterButton from '../ComponentsStylish/Buttons/NewCharacterButton';
import DiceButton from '../ComponentsStylish/Buttons/DiceButton';
import label_style from '../../StylesStylish/label.module.css'
import field_style from '../../StylesStylish/field.module.css'
import table_style from '../../StylesStylish/table.module.css'
import btn_style from '../../StylesStylish/btn.module.css'
import {InputField} from '../ComponentsStylish/InputField';
import { Tooltip } from 'react-tooltip'
import OverlayMessageField from '../ComponentsStylish/OverlayMessageField'

const CharacterSubscriptionField = () => {
    const {
        REST_login_user,
        REST_create_new_user,
        REST_load_characters,
        REST_save_character,
        REST_load_character,
        REST_delete_character,
        REST_apply_config,
        REST_subscribe_user_to_campaign,
        REST_load_subscribed_users
    } = useContext(RestUserContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    
    const {
        API_get_subscribed_user,
        API_get_campaign_name,
        API_get_campaign_id,
        API_get_remote_campaign_message,
        API_set_remote_campaign_message,
        API_get_remote_subscribed_user_message,
        API_set_remote_subscribed_user_message
    } = useContext(CampaignContext);

    const {
        API_move_subscribed_user_element
    } = useContext(APIContext)

    const {
        API_get_std_labels,
        API_get_user_labels,
        API_get_std_header_labels,
        API_get_message_box_content_labels
    } = useContext(TextDataContext)
    
    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    //const inputRef = useRef();
    const [subscribedUser, setSubscribedUser] = useState('');
    //const [subscribe, setSubscribe] = useState(false); // muss noch auf Multi Select umgestellt werden
    const [refreshMessage, setRefreshMessage] = useState('')
    const [refreshMessageSuccess, setRefreshMessageSuccess] = useState(false)

    const getCampaigns = () => {
        //console.log('getCharacter');
        let _subscribedUser = API_get_subscribed_user();
        //console.log(user);

        return _subscribedUser;
    }

    const getUserRole = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.role;
    }
    
    /*const loadFromRemote = () => {
        //console.log('loadFromRemote')
        //console.log(API_get_user());
        //API_set_remote_connect_message('Try');
        REST_load_characters();
    }

    const saveCharacterToRemote = () => {
        //API_set_remote_connect_message('Save');
        REST_save_character();
    }*/

    const loadSubscribedUsersFromRemote = () => {
        let _campaignID = API_get_campaign_id();
        let _user_name = API_get_user().name;
        //API_set_remote_connect_message('Load');
        REST_load_subscribed_users(_user_name, _campaignID, setRefreshMessage, setRefreshMessageSuccess);
    }

    /*const deleteCharacterAtRemote = (_characterID) => {
        //API_set_remote_connect_message('Delete');
        REST_delete_character(_characterID);
    }

    const getConfigFromRemote = () => {
        //console.log("getConfigFromRemote");
        REST_apply_config();
    }*/

    const handleSubscribedUserInputChange = (_user_name) => {
        setSubscribedUser(_user_name);
    };


    const unsubscribeUser = (_userName) => {
        let _campaignID = API_get_campaign_id();
        //REST_subscribe_user_to_campaign(_userName, _campaignID, false);
        let _content = API_get_message_box_content_labels().delete_character;
        API_open_yes_no_message_box(_content, () => REST_subscribe_user_to_campaign(_userName, _campaignID, false, setRefreshMessage, setRefreshMessageSuccess));
    }
    
    const subscribeUser = () => {
        let _campaignID = API_get_campaign_id();
        //let _inputValue = inputRef.current.value;
        REST_subscribe_user_to_campaign(subscribedUser, _campaignID, true, setRefreshMessage, setRefreshMessageSuccess);
    }

    /*const removeElement = (_element) => {
        //API_remove_gear_element(_element);
    }*/

    const moveElement = (_userName, _direction) => {
        API_move_subscribed_user_element(_userName, _direction);
        //API_move_gear_element(_element, _direction);
    }

    const getOverlayVisibility = () => {
        if(IsNullOrEmpty(refreshMessage))
            return false;

        return true;
    }

    return ( 
        <div style={{marginLeft: 15}}>
            <table className={table_style.std_table} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                <tr style={{borderBottom: '1px solid black'}}>
                    <th style={{width: 400, minWidth: 400, textAlign: 'center'}} colspan={5}>{API_get_user_labels().user}</th>
                </tr>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td style={{width: 400, minWidth: 400, textAlign: 'left', fontWeight: 'bold', paddingLeft: 5}}>{API_get_user_labels().user}</td>
                    {/*<td className={table.cell_header} style={{width: 100, minWidth: 100}}>Unsubscribe</td>*/}
                    <td style={{width: 86, minWidth: 86, fontWeight: 'bold'}} colspan={4}>{API_get_std_header_labels().actions}</td>
                </tr>
                {Object.entries(getCampaigns()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: 5}}>{value.name}</td>
                        {/*<td>
                            <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => deleteCharacterAtRemote(value.id)}>Unsubscribe</button>
                        </td>*/}
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value.name, 'up')}>
                                <img src={upIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value.name, 'down')}>
                                <img src={downIcon} alt="Bild" style={{ float: 'left', width: '14px', marginTop: 2 }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                        </td>
                        <td style={{width: 12, minWidth: 12, padding: 1}}>
                            <button className={btn_style.icon_button} onClick={() => unsubscribeUser(value.name)} data-tooltip-id="btn-unsubscribe-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().unsubscribe_user}>
                                <img src={unsubscribeIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
                            </button>
                        <Tooltip id="btn-unsubscribe-tooltip" />
                        </td>
                    </tr>
                    
                ))}
            </table>
            { getUserRole() == 'game_master' &&
                <div style={{marginTop: 15}}>
                    <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                        <InputField type='text' widthLabel={140} widthValue={260} label={API_get_std_labels().subscribe_user} content={subscribedUser} setter={handleSubscribedUserInputChange} />
                    </div>
                    <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                        {/*<button onClick={subscribeUser} style={{margin: '0px 0px 0px 0px', width: 120, minWidth: 120}}>Subscribe</button>*/}
                        
                        <button className={btn_style.std_button} onClick={subscribeUser} style={{marginLeft: 4, height: 32, widht: 44, minWidth: 44}} data-tooltip-id="btn-subscribe-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().subscribe_user}>
                            <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                                <img src={subscribeIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
                            </div>
                        </button>
                        <Tooltip id="btn-subscribe-tooltip" />
                    </div>
                    <div style={{marginLeft: 4, marginTop: 15}}>
                        <div style={{display:'inline-block', position: 'relative'}}>
                            <button className={btn_style.std_button} onClick={loadSubscribedUsersFromRemote} style={{height: 30, widht: 44, minWidth: 44}} data-tooltip-id="btn-refresh-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().refresh}>
                                <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                                    <img src={refreshIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
                                </div>
                            </button>
                            <Tooltip id="btn-refresh-tooltip" />
                            { getOverlayVisibility() &&
                                <OverlayMessageField position={'right'}
                                                    variableWidth={true}
                                                    parentWidth={44}
                                                    parentHeight={30}
                                                    success={refreshMessageSuccess}
                                                    content={refreshMessage}/>
                            }
                        </div>
                        {/*<button onClick={loadSubscribedUsersFromRemote} style={{margin: 0, width:120, minWidth: 120}}>Show Remote</button>*/}
                    </div>
                    {/*<table>
                        <tr style={{height: 30}}>
                            <td style={{width: 110, minWidth: 110, verticalAlign: 'middle'}}>
                                Subscribe User:
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                                <input type="text" value={subscribedUser} onChange={(e) => handleSubscribedUserInputChange(e.target.value)} ref={inputRef}/>
                            </td>
                            <td style={{width: 12, minWidht: 12}}></td>
                            <td style={{width: 124, minWidth: 124, verticalAlign: 'middle', textAlign: 'center'}}>
                                <button onClick={subscribeUser} style={{margin: '0px 0px 0px 0px', width: 120, minWidth: 120}}>Subscribe</button>
                            </td>
                            <td style={{width: 124, minWidth: 124, verticalAlign: 'middle', textAlign: 'center'}}>
                                <button onClick={loadSubscribedUsersFromRemote} style={{margin: 0, width:120, minWidth: 120}}>Show Remote</button>
                            </td>
                        </tr>
                    </table>*/}
                </div>
            }
            {/*getUserRole() == 'player' &&
                <div style={{marginTop: 5}}>
                    <button onClick={loadFromRemote} style={{margin: 0, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            */}
            {/*<p style={{color: 'red', height: 25,}}>{API_get_remote_subscribed_user_message()}</p>*/}
        </div>
    );
}
 
export default CharacterSubscriptionField