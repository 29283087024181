import { useState, useContext, useEffect } from 'react';
import CharacterStatsTable from './SubPagesOverviewSheet/CharacterStatsTable';
import CommentField from './SubPagesOverviewSheet/CommentField';
import MoneyTable from './SubPagesOverviewSheet/MoneyTable';
import RestrictionTable from './SubPagesOverviewSheet/RestrictionTable';

const CharacterOverviewSheet = () => {

    return ( 
        <div>
            <div style={{display: 'inline-block', marginTop: 15}}>
                <div style={{display: 'inline-block', verticalAlign: 'top', marginRight: 362}}>
                    <div style={{display: 'inline-block', verticalAlign: 'top', marginBottom: 15}}>
                        <CharacterStatsTable />
                    </div>
                    <div>
                        <MoneyTable />
                    </div>
                </div>
                <div style={{display: 'inline-block', verticalAlign: 'top', marginRight: 15}}>
                    <div>
                        <RestrictionTable />
                    </div>
                </div>
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                    <CommentField />
                </div>
            </div>
        </div>
    );
}

export default CharacterOverviewSheet;