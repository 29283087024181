import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'
import {ScreenDataContext, ScreenDataContextProvider} from '../API/ScreenDataContext'
import {RestUserContext, RestUserContextProvider} from '../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import {
    REST_get_server_url
} from '../ServerConnection/ComParamters'

import btn from '../Styles/btn.module.css'
import container from '../Styles/container.module.css'
import { 
    INIT_get_guest_user,
    INIT_get_admin_user 
} from '../Backend/ParametersInit/UserDataInit'


const LoginPage = () => {

    const {
        API_switch_language,
        API_get_language_list,
        API_init_character,
        API_init_campaign,
        API_full_GUI_update,
        API_user_login
    } = useContext(APIContext);

    const {
        REST_login_user,
        REST_create_new_user
    } = useContext(RestUserContext);

    const {
        API_get_tool_data,
        API_get_user_mode,
        API_set_user_mode
    } = useContext(ToolDataContext);

    const {
        API_get_active_screen,
        API_set_active_screen
    } = useContext(ScreenDataContext);

    const {
        API_get_remote_connect_message,
        API_set_remote_connect_message,
        //API_get_legacy_login, 
        //API_set_legacy_login,
    } = useContext(UserDataContext);

    // Zustände für Benutzername und Passwort
    const [activeScreen, setActiveScreen] = useState('login');
    const [switchScreenLabel, setSwitchScreenLabel] = useState('Create new account');
    const [deleteAccount, setDeleteAccount] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Log in');
    const [isHovered, setIsHovered] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [guestLogin, setGuestLogin] = useState(false);
    const [gmLogin, setGmLogin] = useState(false);
    const [adminLogin, setAdminLogin] = useState(false);
    
    // Funktion zum Verarbeiten des Formulars
    const handleSubmit = (e) => {
        e.preventDefault();

        if(activeScreen == 'login'){
            logIn(username, password);
        }
        else if(activeScreen == 'new_account'){
            createNewAccount(username, password, repeatPassword);
        }
    }

    const switchScreen = () => {
        if(activeScreen == 'new_account'){
            setSwitchScreenLabel('Create new account');
            setButtonLabel('Log in')
            setActiveScreen('login');
        }
        else if(activeScreen == 'delete_account'){
            setSwitchScreenLabel('Delete account');
            setButtonLabel('Log in')
            setActiveScreen('login');
        }
        else if(activeScreen == 'login'){
            setSwitchScreenLabel('Go to login');
            setButtonLabel('Create new');
            setActiveScreen('new_account');
        }
    }

    const labelStyle = {
        color: isHovered ? 'blue' : 'grey', // Ändere die Farbe hier
        // Weitere Stile hier hinzufügen
    };

    const startHover = () => {
        setIsHovered(true);
    }

    const stopHover = () => {
        setIsHovered(false);
    }

    const toggleDeleteAccount = () => {
        if(deleteAccount)
        {
            setButtonLabel('Create new');
        }
        else
        {
            setButtonLabel('Delete account');
        }
        setDeleteAccount(!deleteAccount)
    }

    const logIn = (_user, _password) => {

        if(guestLogin && !gmLogin && !adminLogin)
        {
            API_user_login(null, true, 200, 'player');
        }
        else if(guestLogin && gmLogin && !adminLogin)
        {
            API_user_login(null, true, 200, 'game_master');
        }
        else if(_user == '' || _password == '')
        {
            const changeValue = () => {
                setTimeout(() => {
                    API_set_remote_connect_message('');
                }, 3000);
            };
            
            API_set_remote_connect_message('User or password empty');
            changeValue();
        }
        else if(!guestLogin && !gmLogin && !adminLogin)
        {
            REST_login_user(_user, _password, 'player');
        }
        else if(!guestLogin && gmLogin && !adminLogin)
        {
            REST_login_user(_user, _password, 'game_master');
        }
    }

    const createNewAccount = (_user_name, _password, _repeatPassword) => {
        if(_user_name == '' || _user_name == null)
        {
            API_set_remote_connect_message('User empty');
            return;
        }
        else if(_user_name.toLowerCase() == INIT_get_guest_user().name.toLowerCase())
        {
            API_set_remote_connect_message(INIT_get_guest_user().name + ' is an invalid user');
            return;
        }
        else if(_user_name.toLowerCase() == INIT_get_admin_user().name.toLowerCase())
        {
            API_set_remote_connect_message(INIT_get_admin_user().name + ' is an invalid user');
            return;
        }
        if(_password != _repeatPassword)
        {
            API_set_remote_connect_message('Second password does not match');
            return;
        }
        if(_password == '' || _password == null)
        {
            API_set_remote_connect_message('Password empty');
            return;
        }
        REST_create_new_user(_user_name, _password);
    }

    return ( 
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div>
                <p style={{fontSize: 30, fontWeight: 'bold', marginBottom: 30}}>Welcome to the Council</p>
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <table>
                            <tr>
                                <td style={{width: 80, minWidth: 80}}>
                                    <label htmlFor="username">User name</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        //required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="password">Password</label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        //required
                                    />
                                </td>
                            </tr>
                            { activeScreen != 'login' &&
                                <tr>
                                    <td>
                                        <label htmlFor="password">Repeat PW</label>
                                    </td>
                                    <td>
                                        <input
                                            type="password"
                                            id="repeatPassword"
                                            value={repeatPassword}
                                            onChange={(e) => setRepeatPassword(e.target.value)}
                                            //required
                                        />
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                    <div style={{marginTop: 5, height: 15}}>
                        <p style={{color: 'red'}}>{API_get_remote_connect_message()}</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: 'transparent', marginTop: 10}}>
                        <button className={btn.std_button} style={{marginTop: 5, width: 238, minWidth: 238, height: 30, minHeight: 30}} type="submit">{buttonLabel}</button>
                    </div>
                </form>
                
                <div style={{display: 'inline-block', marginTop: 15}}>
                    <div style={{display: 'inline-block'}}>
                        <label style={labelStyle} onClick={switchScreen} onMouseEnter={startHover} onMouseLeave={stopHover}>{switchScreenLabel}</label>
                    </div>
                    { activeScreen != 'login' &&
                        <div style={{display: 'inline-block', marginLeft: 75}}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={deleteAccount}
                                    onChange={() => toggleDeleteAccount()}
                                />
                                    Delete Account
                            </label>
                        </div>
                    }
                </div>
                <div style={{marginTop: 15}}>
                    <a href={REST_get_server_url()} target="_blank">Verify site</a>
                </div>
                
                {/* activeScreen == 'login' &&
                    <div style={{marginTop: 15}}>
                        <label>
                            <input
                                type="checkbox"
                                checked={API_get_legacy_login()} // Den Zustand der Checkbox setzen
                                onChange={() => API_set_legacy_login(!API_get_legacy_login())} // Event-Handler für Klicks
                            />
                                Legacy Login
                        </label>
                    </div>
                */}
                { activeScreen == 'login' &&
                    <div style={{marginTop: 15}} >
                        <label>
                            <input
                                type="checkbox"
                                checked={guestLogin} // Den Zustand der Checkbox setzen
                                onChange={() => setGuestLogin(!guestLogin)} // Event-Handler für Klicks
                            />
                                Login as Guest
                        </label>
                    </div>
                }
                { activeScreen == 'login' &&
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={gmLogin} // Den Zustand der Checkbox setzen
                                onChange={() => setGmLogin(!gmLogin)} // Event-Handler für Klicks
                            />
                                Login as GM
                        </label>
                    </div>
                }
                { activeScreen == 'login' &&
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={adminLogin} // Den Zustand der Checkbox setzen
                                onChange={() => setAdminLogin(!adminLogin)} // Event-Handler für Klicks
                            />
                                Login as Admin
                        </label>
                    </div>
                }
            </div>
        </div>
    );
}

export {LoginPage};