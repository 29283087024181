import { useState, useContext, useEffect } from 'react'
import background from "../Rss/Images/paper-1914901_1920.jpg";
//import background from "../Rss/Images/paper-1914901_1920_REWORKED.jpg";
import {ScreenDataContext} from '../API/ScreenDataContext'
import {MessageBoxContext} from '../API/MessageBoxContext'
import {ToolDataContext} from '../API/ToolDataContext'
import CharacterSheetStylish from './CharacterSheetStylish';
import CampaignSheetStylish from './CampaignSheetStylish';
import RulesPage from './RulesPage';
import CampaignSelectionSheet from './CampaignSelectionSheet';
import CharacterSelectionSheet from './CharacterSelectionSheet';
import SideBarStylish from './SideBarStylish';
import SettingsPage from './SettingsPage';
import YesNoMessageBox from './ComponentsStylish/YesNoMessageBox';

const MainPageStylish = () => {
    const {
        API_get_active_main_screen,
        API_set_active_main_screen,
        API_get_active_screen,
        API_set_active_screen

    } = useContext(ScreenDataContext);

    const {
        API_get_tool_data
    } = useContext(ToolDataContext);


    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    const getSideBarMargin = () => {
        if(API_get_tool_data().language == 'de')
        {
            return 0;
        }
        else if(API_get_tool_data().language == 'en')
        {
            return 18;
        }
        return 0;
    }

    return ( 
        <div style={{
            display: 'flex',
            minHeight: '100vh'
          }}>
            { API_get_yes_no_message_box_visibility() &&
                <YesNoMessageBox />
            }
            <div style={{
                flex: 1, 
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                minHeight: '100%'
                }}>
                <div style={{float: 'right', marginRight: getSideBarMargin()}}>
                    <SideBarStylish />
                </div>
            </div>
            <div style={{
              minWidth: '1600px', 
              width: '1600px',
              boxSizing: 'border-box',
              padding: '15px',
              backgroundRepeat: 'repeat',
              backgroundImage: `url(${background})`,
              minHeight: '100%'
            }}>
                { API_get_active_main_screen() == 'campaign' &&
                    <CampaignSheetStylish/>
                }
                { API_get_active_main_screen() == 'character' &&
                    <CharacterSheetStylish/>
                }
                { API_get_active_main_screen() == 'remote_campaigns' &&
                    <CampaignSelectionSheet/>
                }
                { API_get_active_main_screen() == 'remote_characters' &&
                    <CharacterSelectionSheet/>
                }
                { API_get_active_main_screen() == 'rules' &&
                    <RulesPage />
                }
                { API_get_active_main_screen() == 'settings' &&
                    <SettingsPage />
                }
            </div>
            <div style={{flex: 1, 
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                minHeight: '100%'}}></div>
        </div>
    );
}

export default MainPageStylish;