import { CloneObj } from "../../API/Functions/GeneralFunctions";

var guest_user_init = {
    id: -1,
    name: 'Guest',
    role: 'player',
    characters: [{"id": 0, "name": "Beasty"}, {"id": 1, "name": "Backer"} ],
    campaigns: [{"id": 0, "name": "The Turbe"}, {"id": 1, "name": "Wooby"}]
}

var admin_user_init = {
    id: -1,
    name: 'Admin',
    role: 'admin',
    characters: [],
    campaigns: []
}

var INIT_get_character_owner = () => {
    return '';
}

var INIT_get_character_id = () => {
    return -1;
}

const INIT_get_admin_user = () => 
{
    return CloneObj(admin_user_init, true);
}

const INIT_get_guest_user = () => 
{
    return CloneObj(guest_user_init, true);
}

export{
    INIT_get_guest_user,
    INIT_get_admin_user,
    INIT_get_character_owner,
    INIT_get_character_id
}