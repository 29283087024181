import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {AttributeContext} from '../../../API/AttributeContext'
import {InputField} from '../../ComponentsStylish/InputField';
import RemoteUploadButton from '../../ComponentsStylish/Buttons/RemoteUploadButton';
import NewCharacterButton from '../../ComponentsStylish/Buttons/NewCharacterButton';
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const KhoTalTable = () => {

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_init_character,
        API_set_base_stats_name,
        API_set_base_stats_heritage,
        API_set_base_stats_ancestry,
        API_set_base_stats_gender,
        API_set_base_stats_hair_color,
        API_set_base_stats_eye_color,
        API_set_base_stats_skin_color,
        API_set_base_stats_famous,
        API_set_base_stats_notorious,
        API_set_base_stats_height,
        API_set_base_stats_weight,
        API_set_base_stats_age,
        API_set_base_stats_experience,
        API_set_base_stats_level,
        API_increment_kho_tal
    } = useContext(APIContext);

    const {
        API_get_user,
        API_get_character_read_only
    } = useContext(UserDataContext);
    
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels,
        API_get_std_header_labels
    } = useContext(TextDataContext);

    const {
        API_set_character_stats,
        API_get_character_stats
    } = useContext(AttributeContext);

    const marginBottom = 4;
    const _user_role = API_get_user().role;

    const incrementKhoTal = (_increment) => {
        API_increment_kho_tal(_increment);
    }
    const getKhoTalLabel = () => {
        let _message = API_get_std_labels().kho_tal + ' (' + API_get_std_header_labels().max + ': ' + API_get_character_stats().kho_tal.value + ')';
        return _message
    }
    return ( 
        <div>
            <InputField type='integer' 
                        widthLabel={124} 
                        widthValue={39} 
                        label={getKhoTalLabel()} 
                        content={API_get_character_stats().kho_tal.value - API_get_character_stats().kho_tal.lost} 
                        setter={null} 
                        readOnly={API_get_character_read_only()}
                        buttonOnly={true} 
                        buttonField={true} 
                        minusButtonCallback={() => incrementKhoTal(1)}
                        plusButtonCallback={() => incrementKhoTal(-1)}
                         />
        </div>
    );
}


        /*<button className={btn.button_icon} onClick={() => incrementKhoTal(-1)} style={{width: 22, height: 22, margin: '0px 2px 0px 0px'}}>+</button>
        <button className={btn.button_icon} onClick={() => incrementKhoTal(1)} style={{width: 22, height: 22, margin: '0px 5px 0px 0px'}}>-</button>
    </div>
</div>
</td>
<td>{API_get_character_stats().kho_tal.formula}</td>
<td>
{!API_get_edit_character_visibility() && 
    <p className={field.paragraph_std}>{API_get_character_stats().kho_tal.bonus}</p>
}
{API_get_edit_character_visibility() && 
    <input type='number' className={field.input_field_number} style={{width: 60}} onChange={(e) => tryModifyKhoTal(e.target.value)} value={editKhoTal} onBlur={(e) => modifyCharacterStat(API_get_character_stats().kho_tal.id, e.target.value)} />
}
</td>
<td>{API_get_character_stats().kho_tal.temporary_mod}</td>
<td>{API_get_character_stats().kho_tal.value - API_get_character_stats().kho_tal.lost}</td>
<td>{API_get_character_stats().kho_tal.value}</td>*/
export default KhoTalTable;