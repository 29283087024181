import { useState, useEffect, createContext, useContext} from 'react'
//import {LoadedAttributesContext, LoadedAttributesContextProvider} from './LoadedAttributesContext'
import {BaseStatContext, BaseStatContextProvider} from './BaseStatContext'
import {AttributeContext, AttributeContextProvider} from './AttributeContext'
import {SkillContext, SkillContextProvider} from './SkillContext'
import {ToolDataContext, ToolDataContextProvider} from './ToolDataContext'
import {TextDataContext, TextDataContextProvider} from './TextDataContext'
import {EquipmentContext, EquipmentContextProvider} from './EquipmentContext'
import {ScreenDataContext, ScreenDataContextProvider} from './ScreenDataContext'
import {CampaignContext, CampaignContextProvider} from './CampaignContext'
import {MagicContext, MagicContextProvider} from './MagicContext'
import {UserDataContext, UserDataContextProvider} from './UserDataContext'
import {RemoteConnectContext, RemoteConnectContextProvider} from '../ServerConnection/RemoteConnectContext'
import{CheckIfKeyIsContained} from './Functions/GeneralFunctions'

import {
    BACKEND_increment_level, 
    BACKEND_update_base_stat, 
    BACKEND_update_campaign_name,
    BACKEND_increment_attribute,
    BACKEND_increment_skill,
    BACKEND_increment_focus,
    BACKEND_increment_kho_tal,
    BACKEND_increment_free_attribute_points_bonus,
    BACKEND_increment_free_skill_points_bonus,
    BACKEND_increment_free_focus_points_bonus,
    BACKEND_switch_language,
    BACKEND_get_character_file,
    BACKEND_get_campaign_file,
    BACKEND_upload_character,
    BACKEND_upload_campaign,
    BACKEND_init_character,
    BACKEND_init_campaign,
    BACKEND_get_attribute_group_by_id,
    BACKEND_get_attribute_by_id,
    BACKEND_roll_dice,
    BACKEND_recalc_attributes,
    BACKEND_recalc_free_attribute_points,
    BACKEND_recalc_free_focus_points,
    BACKEND_recalc_free_skill_points,
    BACKEND_modify_character_stat_bonus,
    //BACKEND_modify_secondary_attribute_bonus,
    BACKEND_change_hitpoints,
    BACKEND_switch_weapon_type,
    //BACKEND_switch_ranged_weapon_type,
    //BACKEND_switch_hand_type,
    BACKEND_modify_weapon_name,
    BACKEND_modify_weapon_weight,
    BACKEND_modify_melee_weapon_attack_mod,
    BACKEND_modify_melee_weapon_defense_mod,
    BACKEND_modify_ranged_weapon_precision_mod,
    BACKEND_switch_lead_attribute,
    BACKEND_add_new_weapon,
    BACKEND_remove_weapon,
    BACKEND_move_weapon,
    BACKEND_modify_ammo_name,
    BACKEND_modify_ammo,
    BACKEND_modify_ammo_weight,
    BACKEND_modify_damage,
    BACKEND_modify_money_list,
    BACKEND_modify_armor_name,
    BACKEND_toggle_wear_armor,
    BACKEND_modify_armor_weight,
    BACKEND_modify_armor_class,
    BACKEND_modify_armor_restriction,
    BACKEND_add_new_armor_element,
    BACKEND_remove_armor_element,
    BACKEND_move_armor_element,
    BACKEND_modify_feat_name,
    BACKEND_modify_feat_description,
    BACKEND_add_new_feat_element,
    BACKEND_remove_feat_element,
    BACKEND_move_feat_element,
    BACKEND_modify_gear_name,
    BACKEND_modify_gear_amount,
    BACKEND_modify_gear_weight,
    BACKEND_modify_gear_description,
    BACKEND_add_new_gear_element,
    BACKEND_remove_gear_element,
    BACKEND_move_gear_element,
    BACKEND_get_max_magic_circle,
    BACKEND_modify_magic_spell_name,
    BACKEND_modify_magic_spell_might,
    BACKEND_modify_magic_spell_description,
    BACKEND_switch_magic_element_type,
    BACKEND_switch_magic_circle_type,
    BACKEND_add_new_magic_spell,
    BACKEND_remove_magic_spell,
    BACKEND_move_magic_spell,
    BACKEND_modify_monster_name,
    BACKEND_modify_monster_initiative,
    BACKEND_modify_monster_damage,
    BACKEND_modify_monster_hitpoints,
    BACKEND_modify_monster_reflexes,
    BACKEND_modify_monster_attack,
    BACKEND_modify_monster_defense,
    BACKEND_modify_monster_comment,
    BACKEND_add_monster_element,
    BACKEND_remove_monster_element,
    BACKEND_move_monster_element,
    BACKEND_move_character_element,
    BACKEND_move_campaign_element,
    BACKEND_move_subscribed_user_element,
    BACKEND_user_login
} from '../Backend/BackendRouter'

import {
    CalculateAttributes, 
    GetFreeAttributes, 
    ConvertAttributes, 
    ConvertAttributesBack,
    CalculateSkillAttibuteMod
} from '../Backend/Functions/CharacterFunctions'
import 
{
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_attribute_group_ref,
    BACKEND_get_attribute_ref
} from '../Backend/Parameters/CharacterParameters'
import 
{
    BACKEND_set_campaign_description
} from '../Backend/Parameters/CampaignParameters'
import 
{
    BACKEND_get_character_file_name,
    BACKEND_get_campaign_file_name,
    BACKEND_set_upload_state,
    BACKEND_get_upload_state
} from '../Backend/Parameters/GameData'
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_tool_settings, 
    BACKEND_set_tool_settings,
    BACKEND_get_language_list,
} from '../Backend/Parameters/ToolData'
import {
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_get_guest_user,
    BACKEND_set_guest_user
} from '../Backend/Parameters/UserData'
/*import {
    BACKEND_get_base_stat_labels, 
    BACKEND_set_base_stat_labels,
    BACKEND_get_std_labels, 
    BACKEND_set_std_labels
} from '../Backend/Lang/TextData'*/
import{
    GetDiceListString,
    GetFoundryDiceListString
} from '../Backend/DiceRoller'

const APIContext = createContext();

const APIContextProvider = ({children}) => {

    const {
        API_get_base_stats,
        API_set_base_stats,
        API_get_free_attribute_points,
        API_set_free_attribute_points,
        API_get_free_focus_points,
        API_set_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility,
        API_get_stat_overview,
        API_set_stat_overview,
        API_get_money_list,
        API_set_money_list,
        API_get_ancestry_saldo,
        API_set_ancestry_saldo,
        API_set_character_image
    } = useContext(BaseStatContext);

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_set_secondary_attributes,
        //API_get_secondary_attributes,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id,
        API_get_lead_attribute_tags,
        API_set_lead_attribute_tags
    } = useContext(AttributeContext);

    const {
        API_set_skills,
        API_get_skills,
        API_get_edit_skill_visibility,
        API_set_edit_skill_visibility,
        API_get_increase_selector,
        API_set_increase_selector,
        API_get_free_skill_points,
        API_set_free_skill_points,
        API_get_feat_lv_2,
        API_set_feat_lv_2,
        API_get_feat_lv_3,
        API_set_feat_lv_3,
        API_get_skill_by_id,
        API_get_feat_by_id
    } = useContext(SkillContext);

    const {
        API_get_tool_data,
        API_set_tool_data,
        API_get_tool_settings,
        API_set_tool_settings,
        API_get_dice_log,
        API_set_dice_log,
        API_get_dice_log_visibility,
        API_set_dice_log_visibility
        //API_get_user_mode,
        //API_set_user_mode
    } = useContext(ToolDataContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels,
        API_get_message_box_content_labels,
        API_set_message_box_content_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels,
        API_get_user_labels,
        API_set_user_labels,
    } = useContext(TextDataContext);

    const {
        API_set_campaign_id,
        API_set_campaign_name,
        API_set_campaign_description,
        API_get_monster_list,
        API_set_monster_list,
        API_get_monster_by_id,
        API_set_subscribed_user,
        API_set_campaign_image
    } = useContext(CampaignContext);

    const {
        API_get_active_main_screen,
        API_set_active_main_screen,
        API_get_active_sheet_screen,
        API_set_active_sheet_screen,
        API_get_active_screen,
        API_set_active_screen
    } = useContext(ScreenDataContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_character_id,
        API_set_character_id,
        API_get_character_read_only,
        API_set_character_read_only,
        API_get_campaign_read_only,
        API_set_campaign_read_only,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const {
        API_get_magic_spells,
        API_set_magic_spells,
        API_get_max_magic_circle,
        API_set_max_magic_circle,
        API_get_magic_element_types,
        API_set_magic_element_types,
        API_get_magic_circle_types,
        API_set_magic_circle_types,
        API_get_magic_spell_by_id,
        API_get_magic_element_type_by_id,
        API_get_magic_circle_type_by_id
    } = useContext(MagicContext);
    
    const {
        API_get_melee_fighting_types,
        API_set_melee_fighting_types,
        API_get_ranged_fighting_types,
        API_set_ranged_fighting_types,
        //API_get_hand_types,
        //API_set_hand_types,
        API_get_unarmed_weapons,
        API_set_unarmed_weapons,
        API_get_melee_weapons,
        API_set_melee_weapons,
        API_get_ranged_weapons,
        API_set_ranged_weapons,
        API_get_armor,
        API_set_armor,
        API_get_gear,
        API_set_gear,
        API_get_melee_weapon_type_by_id,
        API_get_ranged_weapon_type_by_id,
        API_get_hand_type_by_id,
        API_get_armor_by_id,
        API_get_gear_by_id
    } = useContext(EquipmentContext);
    
    const {
        BACKEND_send_dice_roll,
        BACKEND_open_foundry_connector_window
    } = useContext(RemoteConnectContext);

    const API_init_character = (_language) =>
    {
        BACKEND_init_character(_language);
        API_set_character_image(null);
        API_set_dice_log_visibility(false);
        API_full_GUI_update();
    }

    const API_init_campaign = (_language) => {
        BACKEND_init_campaign(_language);
        API_set_campaign_image(null);
        API_full_GUI_update();
    }

    const API_full_GUI_update = () => {
        API_set_dice_log();
        //API_set_std_labels();
        API_set_base_stats();
        API_set_free_attribute_points(); 
        API_set_free_focus_points();
        API_set_free_skill_points();
        API_set_ancestry_saldo();
        API_set_attributes();
        API_set_skills();
        API_set_character_stats();
        API_set_money_list();
        API_set_stat_overview();
        //API_set_secondary_attributes();
        API_set_melee_fighting_types();
        API_set_ranged_fighting_types();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
        //API_set_hand_types();
        API_set_comments();
        API_set_armor();
        API_set_gear();
        API_set_magic_spells();
        API_set_max_magic_circle();
        API_set_magic_element_types();
        API_set_magic_circle_types();
        API_set_monster_list();

        API_set_base_stat_labels();
        API_set_std_labels();
        API_set_std_header_labels();
        API_set_stat_overview_labels();
        API_set_money_header_labels();
        API_set_message_box_content_labels();
        API_set_tab_view_labels();
        API_set_character_stats_labels();
        API_set_table_header_labels();
        API_set_equipment_header_labels();
        API_set_user_labels();

        API_set_feat_lv_2();
        API_set_feat_lv_3();

        //API_set_user_mode();
        API_set_tool_data();
        API_set_tool_settings();
        API_set_user();
        API_set_character_id();
        //API_set_dice_log();
        //API_set_dice_log_visibility();
        //API_set_edit_character_visibility();
        //API_set_edit_skill_visibility();
        //API_set_increase_selector();
        //API_set_user_mode();

        //campaign
        API_set_campaign_id();
        API_set_campaign_name();
        API_set_campaign_description();
        API_set_subscribed_user();
        API_set_monster_list();
    }

    const API_increment_level = (_increment) => {
        BACKEND_increment_level(_increment);

        API_set_base_stats();
        API_set_free_attribute_points();
        API_set_free_skill_points();
        API_set_free_focus_points();
        API_set_attributes();
        API_set_skills();
        API_set_max_magic_circle();
    }

    const API_update_base_stat = (_baseStat) => {
        BACKEND_update_base_stat(_baseStat);

        API_set_base_stats();
    }

    const API_update_campaign_name = (_name) => {
        BACKEND_update_campaign_name(_name);
        API_set_campaign_name();
    }

    const API_update_campaign_description = (_description) => {
        BACKEND_set_campaign_description(_description);
        API_set_campaign_description();
    }

    

    const API_increment_attribute = (_attributeID, _type, _increment) => {
        BACKEND_increment_attribute(_attributeID, _increment, _type, true);
        //BACKEND_increment_attribute(_attributeID, _increment, true);

        API_set_attributes();
        API_set_skills();
        API_set_free_attribute_points();
        API_set_character_stats();
        API_set_ancestry_saldo();
        //API_set_secondary_attributes();
    }

    /*const API_increment_ancestry_bonus = (_attributeID, _increment) => {
        BACKEND_increment_attribute(_attributeID, _increment, 'ancestry', true);
        //BACKEND_increment_ancestry_bonus(_attributeID, _increment, true);

        API_set_attributes();
        API_set_skills();
        API_set_character_stats();
        API_set_secondary_attributes();
    }

    const API_increment_other_attribute_bonus = (_attributeID, _increment) => {
        BACKEND_increment_attribute(_attributeID, _increment, 'other', true);
        //BACKEND_increment_other_attribute_bonus(_attributeID, _increment, true);

        API_set_attributes();
        API_set_skills();
        API_set_character_stats();
        API_set_secondary_attributes();
    }*/

    const API_increment_skill = (_attributeID, _skillID, _type, _increment) => {
        
        BACKEND_increment_skill(_attributeID, _skillID, _increment, _type, true);

        API_set_skills();
        API_set_free_skill_points();
        API_set_character_stats();
    }

    /*const API_increment_other_skill_bonus = (_groupID, _attributeID, _increment) => {
        BACKEND_increment_other_skill_bonus(_groupID, _attributeID, _increment, true);

        API_set_skills();
        API_set_free_skill_points();
        API_set_character_stats();
    }*/
    
    const API_increment_focus = (_attributeGroupID, _increment) => {
        BACKEND_increment_focus(_attributeGroupID, _increment);

        API_set_attributes();
        API_set_free_focus_points();
        API_set_max_magic_circle();
    }

    const API_increment_kho_tal = (_increment) => {
        BACKEND_increment_kho_tal(_increment);

        API_set_character_stats();
    }

    const API_increment_free_attribute_points_bonus = (_increment) => {
        BACKEND_increment_free_attribute_points_bonus(_increment);

        API_set_free_attribute_points();
    }
    
    const API_increment_free_skill_points_bonus = (_increment) => {
        BACKEND_increment_free_skill_points_bonus(_increment);

        API_set_free_skill_points();
    }

    const API_increment_free_focus_points_bonus = (_increment) => {
        BACKEND_increment_free_focus_points_bonus(_increment);

        API_set_free_focus_points();
    }

    const API_switch_language = (_language) => {
        BACKEND_switch_language(_language);
        API_full_GUI_update();
    }

    const API_get_language_list = () => {
        return BACKEND_get_language_list();
    }

    const API_get_character_file_name = () => {
        return BACKEND_get_character_file_name();
    }

    const API_get_character_file = () => {
        return BACKEND_get_character_file();
    }
    
    const API_get_campaign_file_name = () => {
        return BACKEND_get_campaign_file_name();
    }

    const API_get_campaign_file = () => {
        return BACKEND_get_campaign_file();
    }  
    
    const callback_full_GUI_update = () => {
        API_full_GUI_update();
    }

    const API_upload_character = (e) => {
        BACKEND_upload_character(e, callback_full_GUI_update);
    }

    const API_upload_campaign = (e) => {
        BACKEND_upload_campaign(e, callback_full_GUI_update);
    }

    const API_update_comments = (_text) => {
        BACKEND_set_comments(_text);

        API_set_comments();
    }

    const API_get_dice_list_string = (_dice_list, _good_roll = false) =>
    {
        return GetDiceListString(_dice_list, 'd', _good_roll);
    }

    const API_get_foundry_dice_list_string = (_dice_list, _good_roll = true) =>
    {
        let _roll_string = GetFoundryDiceListString(_dice_list, _good_roll);
        return _roll_string;
    }

    /*const API_get_attribute_group_by_id = (_id) => {
        return BACKEND_get_attribute_group_by_id(_id);
    }

    const API_get_attribute_by_id = (_id) => {
        return BACKEND_get_attribute_by_id(_id);
    }*/

    const API_switch_weapon_type = (_weapon_type_id, _weapon_id, _type) => {
        BACKEND_switch_weapon_type(_weapon_type_id, _weapon_id, _type);
        API_set_weapons(_type);
    }

    /*const API_switch_ranged_weapon_type = (_weapon_type_id, _weapon_id) => {
        BACKEND_switch_ranged_weapon_type(_weapon_type_id, _weapon_id);
        
        API_set_ranged_weapons();
    }*/

    /*const API_switch_hand_type = (_hand_type_id, _weapon_id, _type) => {
        BACKEND_switch_hand_type(_hand_type_id, _weapon_id, _type);
        API_set_weapons(_type);
    }*/

    const API_add_new_weapon = (_type) => {
        BACKEND_add_new_weapon(_type);
        API_set_weapons(_type);
        API_set_stat_overview();
    }

    const API_remove_weapon = (_weapon, _type) => {
        BACKEND_remove_weapon(_weapon, _type);
        API_set_weapons(_type);
        API_set_stat_overview();
    }

    const API_move_weapon = (_weapon, _direction, _type) => {
        BACKEND_move_weapon(_weapon, _direction, _type);
        API_set_weapons(_type);
    }

    const API_set_weapons = (_type) => {
        if(_type=='unarmed')
        {
            API_set_unarmed_weapons();
        }
        else if(_type=='melee')
        {
            API_set_melee_weapons();
        }
        else if(_type=='ranged')
        {
            API_set_ranged_weapons();
        }
    }

    //can be removed in stylish mode
    const API_roll_dice = (_display_name, _dice, _good_roll, _roll_type = 'default', _paramter = '') => {
        BACKEND_roll_dice(_display_name, _dice, _good_roll, BACKEND_send_dice_roll, _roll_type, _paramter);
        API_set_dice_log_visibility(true);
        API_set_dice_log();
        
        setTimeout(() => {
            API_set_dice_log_visibility(false);
            API_set_dice_log();
        }, 4000);
    }

    const API_modify_character_stat_bonus = (_id, _value) => {
        BACKEND_modify_character_stat_bonus(_id, _value);
        API_set_character_stats();
    }

    /*const API_modify_secondary_attribute_bonus = (_id, _value) => {
        BACKEND_modify_secondary_attribute_bonus(_id, _value);
        API_set_secondary_attributes();
    }*/

    const API_change_hitpoints = (_val) => {
        BACKEND_change_hitpoints(_val);
        API_set_character_stats();
    }

    const API_get_skill_attribute_mod = (_skill_group_id, _skill) => {;
        return CalculateSkillAttibuteMod(_skill_group_id, _skill);
    }

    const API_modify_weapon_name = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_weapon_name(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
    }

    const API_modify_weapon_weight = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_weapon_weight(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
        API_set_stat_overview();
    }

    const API_modify_melee_weapon_attack_mod = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_melee_weapon_attack_mod(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
    }

    const API_modify_melee_weapon_defense_mod = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_melee_weapon_defense_mod(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
    }

    const API_modify_ranged_weapon_precision_mod = (_weapon_id, _new_value) => {
        BACKEND_modify_ranged_weapon_precision_mod(_weapon_id, _new_value);
        API_set_ranged_weapons();
    }

    const API_switch_lead_attribute = (_weapon_id, _new_value, _type) => {
        BACKEND_switch_lead_attribute(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
    }
    
    const API_modify_ammo_name = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_ammo_name(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
    }

    const API_modify_ammo = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_ammo(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
        API_set_stat_overview();
    }

    const API_modify_ammo_weight = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_ammo_weight(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
        API_set_stat_overview();
    }

    const API_modify_damage = (_weapon_id, _new_value, _type) => {
        BACKEND_modify_damage(_weapon_id, _new_value, _type);
        API_set_weapons(_type);
    }

    const API_modify_money_list = (_index, _value) => {
        BACKEND_modify_money_list(_index, _value);
        API_set_money_list();
        API_set_stat_overview();
    }

    const API_modify_armor_name = (_id, _new_value) => {
        BACKEND_modify_armor_name(_id, _new_value);
        API_set_armor();
    }

    const API_toggle_wear_armor = (_id) => {
        BACKEND_toggle_wear_armor(_id);
        API_set_armor();
        API_set_stat_overview();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
    }
    
    const API_modify_armor_weight = (_id, _new_value) => {
        BACKEND_modify_armor_weight(_id, _new_value);
        API_set_armor();
        API_set_stat_overview();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
    }
    
    const API_modify_armor_class = (_id, _new_value) => {
        BACKEND_modify_armor_class(_id, _new_value);
        API_set_armor();
        API_set_stat_overview();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
    }
    
    const API_modify_armor_restriction = (_id, _new_value) => {
        BACKEND_modify_armor_restriction(_id, _new_value);
        API_set_armor();
        API_set_stat_overview();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
    }
    
    const API_add_new_armor_element = () => {
        BACKEND_add_new_armor_element();
        API_set_armor();
        API_set_stat_overview();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
    }
    
    const API_remove_armor_element = (_element) => {
        BACKEND_remove_armor_element(_element);
        API_set_armor();
        API_set_stat_overview();
        API_set_unarmed_weapons();
        API_set_melee_weapons();
        API_set_ranged_weapons();
    }
    
    const API_move_armor_element = (_element, _direction) => {
        BACKEND_move_armor_element(_element, _direction);
        API_set_armor();
    }

    const API_set_feat_list = (_feat_level) => {
        if(_feat_level == 2)
        {
            API_set_feat_lv_2();
        }
        else if(_feat_level == 3)
        {
            API_set_feat_lv_3();
        }
    }
    
    const API_modify_feat_name = (_id, _new_value, _feat_level) => {
        BACKEND_modify_feat_name(_id, _new_value, _feat_level);
        API_set_feat_list(_feat_level);
    }
    
    const API_modify_feat_description = (_id, _new_value, _feat_level) => {
        BACKEND_modify_feat_description(_id, _new_value, _feat_level);
        API_set_feat_list(_feat_level);
    }
    
    const API_add_new_feat_element = (_feat_level) => {
        BACKEND_add_new_feat_element(_feat_level);
        API_set_feat_list(_feat_level);
    }
    
    const API_remove_feat_element = (_element, _feat_level) => {
        BACKEND_remove_feat_element(_element, _feat_level);
        API_set_feat_list(_feat_level);
    }
    
    const API_move_feat_element = (_element, _direction, _feat_level) => {
        BACKEND_move_feat_element(_element, _direction, _feat_level);
        API_set_feat_list(_feat_level);
    }
    

    const API_modify_gear_name = (_id, _new_value) => {
        BACKEND_modify_gear_name(_id, _new_value);
        API_set_gear();
    }
    
    const API_modify_gear_amount = (_id, _new_value) => {
        BACKEND_modify_gear_amount(_id, _new_value);
        API_set_gear();
        API_set_stat_overview();
    }
    
    const API_modify_gear_weight = (_id, _new_value) => {
        BACKEND_modify_gear_weight(_id, _new_value);
        API_set_gear();
        API_set_stat_overview();
    }
    
    const API_modify_gear_description = (_id, _new_value) => {
        BACKEND_modify_gear_description(_id, _new_value);
        API_set_gear();
    }
    
    const API_add_new_gear_element = () => {
        BACKEND_add_new_gear_element();
        API_set_gear();
        API_set_stat_overview();
    }
    
    const API_remove_gear_element = (_element) => {
        BACKEND_remove_gear_element(_element);
        API_set_gear();
        API_set_stat_overview();
    }
    
    const API_move_gear_element = (_element, _direction) => {
        BACKEND_move_gear_element(_element, _direction);
        API_set_gear();
    }
    
    /*const API_get_max_magic_circle = () => {
        return BACKEND_get_max_magic_circle();
    }*/

    const API_modify_magic_spell_name = (_id, _new_value) => {
        BACKEND_modify_magic_spell_name(_id, _new_value);
        API_set_magic_spells();
    }
    
    const API_modify_magic_spell_might = (_id, _new_value) => {
        BACKEND_modify_magic_spell_might(_id, _new_value);
        API_set_magic_spells();
    }
    
    const API_modify_magic_spell_description = (_id, _new_value) => {
        BACKEND_modify_magic_spell_description(_id, _new_value);
        API_set_magic_spells();
    }
    
    const API_switch_magic_element_type = (_type_id, _list_element_id) => {
        BACKEND_switch_magic_element_type(_type_id, _list_element_id);
        API_set_magic_spells();
    }
    
    const API_switch_magic_circle_type = (_type_id, _list_element_id) => {
        BACKEND_switch_magic_circle_type(_type_id, _list_element_id);
        API_set_magic_spells();
    }
    
    const API_add_new_magic_spell = () => {
        BACKEND_add_new_magic_spell();
        API_set_magic_spells();
    }
    
    const API_remove_magic_spell = (_element) => {
        BACKEND_remove_magic_spell(_element);
        API_set_magic_spells();
    }
    
    const API_move_magic_spell = (_element, _direction) => {
        BACKEND_move_magic_spell(_element, _direction);
        API_set_magic_spells();
    }
    

    const API_modify_monster_name = (_id, _new_value) => {
        BACKEND_modify_monster_name(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_initiative = (_id, _new_value) => {
        BACKEND_modify_monster_initiative(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_damage = (_id, _new_value) => {
        BACKEND_modify_monster_damage(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_hitpoints = (_id, _new_value) => {
        BACKEND_modify_monster_hitpoints(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_reflexes = (_id, _new_value) => {
        BACKEND_modify_monster_reflexes(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_attack = (_id, _new_value) => {
        BACKEND_modify_monster_attack(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_defense = (_id, _new_value) => {
        BACKEND_modify_monster_defense(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_modify_monster_comment = (_id, _new_value) => {
        BACKEND_modify_monster_comment(_id, _new_value);
        API_set_monster_list();
    }
    
    const API_add_monster_element = () => {
        BACKEND_add_monster_element();
        API_set_monster_list();
    }
    
    const API_remove_monster_element = (_element) => {
        BACKEND_remove_monster_element(_element);
        API_set_monster_list();
    }
    
    const API_move_monster_element = (_element, _direction) => {
        BACKEND_move_monster_element(_element, _direction);
        API_set_monster_list();
    }

    const API_get_attribute_group_ref = () => {
        return BACKEND_get_attribute_group_ref();
    }
    
    const API_get_attribute_ref = () => {
        return BACKEND_get_attribute_ref();
    }

    const API_move_character_element = (_character_id, _direction) => {
        BACKEND_move_character_element(_character_id, _direction);
        API_set_user();
    }

    const API_move_campaign_element = (_campaign_id, _direction) => {
        BACKEND_move_campaign_element(_campaign_id, _direction);
        API_set_user();
    }

    const API_move_subscribed_user_element = (_user_name, _direction) => {
        BACKEND_move_subscribed_user_element(_user_name, _direction);
        API_set_subscribed_user();
    }

    const API_user_login = (_user, _validation, _backend_status, _guest_login_role = '') => {
        if(_validation){
            if(_user != null)
            {
                BACKEND_user_login(_user);
                if(_user.role == 'player'){
                    API_set_character_read_only(false);
                    API_set_campaign_read_only(true);
                    API_set_active_main_screen('character');
                    API_set_active_sheet_screen('overview');
                    API_set_active_screen('general');
                }
                else if(_user.role == 'game_master'){
                    API_set_character_read_only(true);
                    API_set_campaign_read_only(false);
                    API_set_active_main_screen('campaign');
                    API_set_active_sheet_screen('overview');
                    API_set_active_screen('combat');
                }
            }
            else
            {
                if(_guest_login_role == 'player'){
                    API_set_character_read_only(false);
                    API_set_campaign_read_only(true);
                    API_set_active_main_screen('character');
                    API_set_active_sheet_screen('overview');
                    API_set_active_screen('general');
                }
                else if(_guest_login_role == 'game_master'){
                    API_set_character_read_only(true);
                    API_set_campaign_read_only(false);
                    API_set_active_main_screen('campaign');
                    API_set_active_sheet_screen('overview');
                    API_set_active_screen('combat');
                }
                BACKEND_user_login({...BACKEND_get_guest_user(), role: _guest_login_role});
                API_set_active_screen('general');
            }
            API_full_GUI_update();
        }
        else
        {
            /*const changeValue = () => {
                setTimeout(() => {
                    API_set_remote_connect_message('');
                }, 3000);
            };*/
            
            if(_backend_status == 200)
            {
                API_set_remote_connect_message('User or password incorrect');
            }
            else if(_backend_status != 'no_connection')
            {
                API_set_remote_connect_message('Wrong response from backend (Status: ' + _backend_status + ')');
            }
            else if(_backend_status == 'no_connection')
            {
                API_set_remote_connect_message('No backend connection');
            }
            //changeValue();
        }
    }

    const API_set_base_stats_param = (_param, _value) => {
        let _base_stats = {...BACKEND_get_base_stats()}
        //if(CheckIfKeyIsContained(_param, _base_stats))
        //    _base_stats[_param] = _value;

        //if(!_character.hasOwnProperty(_param))
        _base_stats[_param] = _value;

        API_update_base_stat({..._base_stats});
    }

    const API_set_base_stats_name = (_value) => {
        API_set_base_stats_param('name', _value)
    }
    
    const API_set_base_stats_heritage = (_value) => {
        API_set_base_stats_param('heritage', _value)
    }

    const API_set_base_stats_ancestry = (_value) => {
        API_set_base_stats_param('ancestry', _value)
    }
    
    const API_set_base_stats_hair_color = (_value) => {
        API_set_base_stats_param('hair_color', _value)
    }
    
    const API_set_base_stats_eye_color = (_value) => {
        API_set_base_stats_param('eye_color', _value)
    }

    const API_set_base_stats_gender = (_value) => {
        API_set_base_stats_param('gender', _value)
    }
    
    const API_set_base_stats_skin_color = (_value) => {
        API_set_base_stats_param('skin_color', _value)
    }
    
    const API_set_base_stats_famous = (_value) => {
        API_set_base_stats_param('famous', _value)
    }
    
    const API_set_base_stats_notorious = (_value) => {
        API_set_base_stats_param('notorious', _value)
    }
    
    const API_set_base_stats_height = (_value) => {
        API_set_base_stats_param('height', _value)
    }
    
    const API_set_base_stats_weight = (_value) => {
        API_set_base_stats_param('weight', _value)
    }
    
    const API_set_base_stats_age = (_value) => {
        API_set_base_stats_param('age', _value)
    }
    
    const API_set_base_stats_experience = (_value) => {
        API_set_base_stats_param('experience', _value)
    }
    
    const API_set_base_stats_level = (_value) => {
        API_set_base_stats_param('level', _value)
    }
    
    const API_set_tool_settings_foundry_url = (_value) => {
        BACKEND_set_tool_settings({...BACKEND_get_tool_settings(), foundry_url: _value});
        API_set_tool_settings();
    }

    return(
        <APIContext.Provider value={{
            API_get_attribute_group_ref,
            API_get_attribute_ref,
            API_full_GUI_update,
            API_increment_level,
            API_update_base_stat,
            API_update_campaign_name,
            API_update_campaign_description,
            API_increment_attribute,
            API_increment_skill,
            //API_increment_ancestry_bonus,
            //API_increment_other_attribute_bonus,
            //API_increment_other_skill_bonus,
            API_increment_focus,
            API_increment_kho_tal,
            API_increment_free_attribute_points_bonus,
            API_increment_free_skill_points_bonus,
            API_increment_free_focus_points_bonus,
            API_update_comments,
            API_switch_language,
            API_get_language_list,
            API_get_character_file_name,
            API_get_character_file,
            API_get_campaign_file_name,
            API_get_campaign_file,
            API_upload_character,
            API_upload_campaign,
            API_init_character,
            API_init_campaign,
            API_get_dice_list_string,
            API_get_foundry_dice_list_string,
            //API_get_attribute_group_by_id,
            //API_get_attribute_by_id,
            API_roll_dice,
            API_modify_character_stat_bonus,
            //API_modify_secondary_attribute_bonus,
            API_change_hitpoints,
            API_get_skill_attribute_mod,
            API_switch_weapon_type,
            //API_switch_ranged_weapon_type,
            //API_switch_hand_type,
            API_modify_weapon_name,
            API_modify_weapon_weight,
            API_modify_melee_weapon_attack_mod,
            API_modify_melee_weapon_defense_mod,
            API_modify_ranged_weapon_precision_mod,
            API_switch_lead_attribute,
            API_add_new_weapon,
            API_remove_weapon,
            API_move_weapon,
            API_modify_ammo_name,
            API_modify_ammo,
            API_modify_ammo_weight,
            API_modify_damage,
            API_modify_money_list,
            API_modify_armor_name,
            API_toggle_wear_armor,
            API_modify_armor_weight,
            API_modify_armor_class,
            API_modify_armor_restriction,
            API_add_new_armor_element,
            API_remove_armor_element,
            API_move_armor_element,
            API_modify_feat_name,
            API_modify_feat_description,
            API_add_new_feat_element,
            API_remove_feat_element,
            API_move_feat_element,
            API_modify_gear_name,
            API_modify_gear_amount,
            API_modify_gear_weight,
            API_modify_gear_description,
            API_add_new_gear_element,
            API_remove_gear_element,
            API_move_gear_element,
            //API_get_max_magic_circle,
            API_modify_magic_spell_name,
            API_modify_magic_spell_might,
            API_modify_magic_spell_description,
            API_switch_magic_element_type,
            API_switch_magic_circle_type,
            API_add_new_magic_spell,
            API_remove_magic_spell,
            API_move_magic_spell,
            API_modify_monster_name,
            API_modify_monster_initiative,
            API_modify_monster_damage,
            API_modify_monster_hitpoints,
            API_modify_monster_reflexes,
            API_modify_monster_attack,
            API_modify_monster_defense,
            API_modify_monster_comment,
            API_add_monster_element,
            API_remove_monster_element,
            API_move_monster_element,
            API_move_character_element,
            API_move_campaign_element,
            API_move_subscribed_user_element,
            API_user_login,
            API_set_base_stats_param,
            API_set_base_stats_name,
            API_set_base_stats_heritage,
            API_set_base_stats_ancestry,
            API_set_base_stats_gender,
            API_set_base_stats_hair_color,
            API_set_base_stats_eye_color,
            API_set_base_stats_skin_color,
            API_set_base_stats_famous,
            API_set_base_stats_notorious,
            API_set_base_stats_height,
            API_set_base_stats_weight,
            API_set_base_stats_age,
            API_set_base_stats_experience,
            API_set_base_stats_level,
            API_set_tool_settings_foundry_url
        }}>
            {children}
        </APIContext.Provider>
    )
}

export {APIContext, APIContextProvider}