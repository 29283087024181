import { useState, useContext } from 'react'
import {APIContext} from '../../../API/API'
import {AttributeContext} from '../../../API/AttributeContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {MultiLineInput} from '../../ComponentsStylish/MultiLineInput';
import table_style from '../../../StylesStylish/table.module.css'

const CommentField = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod
    } = useContext(APIContext);

    const {
        API_get_attributes,
        API_get_comments,
        API_get_character_stats,
        //API_get_secondary_attributes,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const updateComments = (_text) => {
        API_update_comments(_text);
    }

    return ( 
        <div>
            <div style={{alignItems: 'left', position: 'relative'}}>
                <div style={{display: 'inline-block'}}>
                    <table className = {table_style.std_table}>
                        <tr>
                            <th style={{width: 448, minWidth: 448}}>
                                {API_get_character_stats_labels().comments}
                            </th>
                        </tr>
                    </table>
                    <MultiLineInput rows={25} cols={60} content={API_get_comments()} default_content={API_get_character_stats_labels().add_comments} setter={updateComments} />
                </div>
            </div>
        </div>
    );
}
 
export default CommentField;