import { useState, useContext } from 'react'
import {CheckIfKeyIsContained, IsWhitespace, ParseToInteger, ParseToFloat, TryParse} from '../../API/Functions/GeneralFunctions'
import OverlayMessageField from './OverlayMessageField'
import field_style from '../../StylesStylish/field.module.css'
import label_style from '../../StylesStylish/label.module.css'
import btn_style from '../../StylesStylish/btn.module.css'
import diceIcon from '../../Rss/Images/icons/dice.png';
import plusIcon from '../../Rss/Images/icons/plus_icon.png';
import minusIcon from '../../Rss/Images/icons/minus_icon.png';

const InputField = (props) => {
    const { 
        content = '',
        placeholder = '',
        widthLabel = 120, 
        widthValue = 120, 
        heightHeader = 48, 
        heightStd = 24, 
        fontSize= 'default', 
        fontSizeLabel = 'default', 
        fontSizeValue = 'default', 
        textAlign='default', 
        paddingTop='default', 
        paddingRight='default', 
        paddingBottom='default', 
        paddingLeft='default', 
        label = '', 
        labelVisibility = true, 
        setter = null,
        setter_parameter = null,
        buttonOnly = false, 
        readOnly = false, 
        buttonField = false, 
        buttonWidth = 24, 
        buttonCallback = null, 
        plusButtonCallback = null, 
        minusButtonCallback = null, 
        overlayMessage = null } = props;

    const [currentInput, setCurrentInput] = useState(props.content);
    const [buttonVisibility, setButtonVisibility] = useState(false);
    const [isOverlayVisibile, setIsOverlayVisibile] = useState(false);
    const [overlayPosition, setOverlayPosition] = useState('left');

    const buttonMargin = 4;

    const updateInput = (_input, _use_setter = true) => {
        if(_use_setter)
        {
            if(setter_parameter == null)
            {
                props.setter(_input);
            }
            else if(setter_parameter.length == 0)
            {
                props.setter(_input);
            }
            else if(setter_parameter.length == 1)
            {
                props.setter(setter_parameter[0], _input);
            }
            else if(setter_parameter.length == 2)
            {
                props.setter(setter_parameter[0], setter_parameter[1], _input);
            }
        }
        setCurrentInput(_input);
    }

    const getFieldPadding = () => {
        return getPaddingTop() + getPaddingBottom();
    }

    const getFieldHeight = () => {
        return heightStd + getFieldPadding() + 1 ;
    }

    const getButtonHeight = () => {
        return getFieldHeight();
    }

    const getButtonFieldWidth = (_side) => {
        if(buttonField)
            return buttonWidth + buttonMargin;

        if(_side == 'right')
            return getPaddingRight();

        if(_side == 'left')
                return getPaddingLeft();

        return 0
    }

    const getFieldWidth = () => {
        return widthValue + getButtonFieldWidth('left')+getButtonFieldWidth('right') + 1;
    }

    const validateInput = (_input) => {
        let _validateValue = _input;
        let _use_setter = false;
        
        if(props.type=='integer')
        {
            _validateValue = ParseToInteger(_validateValue);
            if(Number.isInteger(_validateValue))
                _use_setter = true;
        }
        else if(props.type=='float')
        {
            _validateValue = _validateValue.replace(',', '.')
            let _numberOfDots = _validateValue.split('.').length - 1;
            let _lastLetter = _input.charAt(_input.length - 1);
            if(_lastLetter == '.' && _numberOfDots == 1 )
            {
                let _slicedInput = _input.slice(0, _input.length - 1);
                if(Number.isInteger(_slicedInput))
                    _use_setter = false;
            }
            else
            {
                _validateValue = ParseToFloat(_validateValue);
                _use_setter = true;
            }
        }
        //props.setter(_validateValue);
        updateInput(_validateValue, _use_setter);
    }

    const getContent = () => {
        if(props.type=='integer')
        {
            if(Number.isInteger(currentInput))
            {
                return props.content
            }
            else
            {
                return currentInput
            }
        }
        else if(props.type=='float')
        {
            let _validateValue = currentInput;
            let _numberOfDots = 0;
            let _lastLetter = null;
            if(typeof _validateValue === 'string')
            {
                _validateValue = _validateValue.replace(',', '.');
                _numberOfDots = _validateValue.split('.').length - 1;
                _lastLetter = _validateValue.charAt(_validateValue.length - 1);
            }
            if(_lastLetter == '.' && _numberOfDots == 1 )
            {
                return currentInput;
            }
            else
            {
                if(TryParse(currentInput, 'float')){
                    return props.content;
                }
                else
                {
                    return currentInput;
                }
            }
        }
        else
        {
            return props.content;
        }
    }

    const handleClick = (_increment) => {
        if(_increment > 0 && plusButtonCallback != null)
        {
            plusButtonCallback();
        }
        if(_increment < 0 && minusButtonCallback != null)
        {
            minusButtonCallback();
        }
        if(buttonCallback != null)
        {
            buttonCallback(_increment);
        }
    }

    const hoverButtonField = (_visibility, _side) => {
        if(!buttonField)
            return;

        if(readOnly)
            return;

        if(_visibility)
        {
            setButtonVisibility(true);
            if(_side == 'left')
            {
                setOverlayVisibility(true);
                setOverlayPosition('left');
            }
            else if(_side == 'right')
            {
                setOverlayVisibility(true);
                setOverlayPosition('right');
            }
            else
            {
                setOverlayVisibility(false);
                setOverlayPosition('none');
            }
        }
        else
        {
            setButtonVisibility(false);
            setOverlayVisibility(false);
        }
    }

    const getOverlayVisibility = () => {
        return isOverlayVisibile;
    }

    const setOverlayVisibility = (_value) => {
        if(overlayMessage == null)
            return setIsOverlayVisibile(false);

        return setIsOverlayVisibile(_value);
    }

    const getLabelFontSize = () => {
        if (fontSizeLabel == 'default' && fontSize == 'default'){
            if(props.type == 'header'){
                return 40;
            }
            else if(props.type == 'text'){
                return 18;
            }
            else{
                return 18;
            }
        }
        else if (fontSizeLabel == 'default' && fontSize != 'default'){
            return fontSize;
        }
        else
        {
            return fontSizeLabel;
        }
    }

    const getValueFontSize = () => {
        if (fontSizeValue == 'default' && fontSize == 'default'){
            if(props.type == 'header'){
                return 40;
            }
            else if(props.type == 'text'){
                return 18;
            }
            else{
                return 18;
            }
        }
        else if (fontSizeValue == 'default' && fontSize != 'default'){
            return fontSize;
        }
        else
        {
            return fontSizeValue;
        }
    }

    const getTextAlign = () => {
        if (textAlign == 'default'){
            if(props.type == 'header'){
                return 'left';
            }
            else if(props.type == 'text'){
                return 'left';
            }
            else{
                return 'center';
            }
        }
        else
        {
            return textAlign;
        }
    }

    const getPaddingTop = () => {
        if (paddingTop == 'default'  && props.type != 'header')
            return 3;

        if (paddingTop == 'default'  && props.type == 'header')
            return 0;

        return paddingTop;
    }

    const getPaddingBottom = () => {
        if (paddingBottom == 'default'  && props.type != 'header')
            return 3;

        if (paddingBottom == 'default'  && props.type == 'header')
            return 0;

        return paddingBottom;
    }

    const getPaddingLeft = () => {
        if (paddingLeft == 'default' && getTextAlign() == 'left')
            return 3;

        if (paddingLeft == 'default' && getTextAlign() == 'right')
                return 3;

        if (paddingLeft == 'default' && getTextAlign() == 'center')
            return 0;

        return paddingLeft;
    }

    const getPaddingRight = () => {
        if (paddingRight == 'default' && getTextAlign() == 'left')
            return 3;

        if (paddingRight == 'default' && getTextAlign() == 'right')
                return 3;

        if (paddingRight == 'default' && getTextAlign() == 'center')
            return 0;

        return paddingRight;
    }

    const styleHeaderField = () => {
        let _style = {
            width: widthValue, 
            minWidth: widthValue, 
            height: heightHeader, 
            fontSize: getValueFontSize(),
            textAlign: getTextAlign(),
            paddingTop: getPaddingTop(),
            paddingRight: getPaddingRight(),
            paddingBottom: getPaddingBottom(),
            paddingLeft: getPaddingLeft()
        }
        return _style;
    }

    const styleTextField = () => {
        let _style = {
            width: widthValue, 
            minWidth: widthValue, 
            height: heightStd, 
            fontSize: getValueFontSize(),
            textAlign: getTextAlign(),
            paddingTop: getPaddingTop(),
            paddingRight: getPaddingRight(),
            paddingBottom: getPaddingBottom(),
            paddingLeft: getPaddingLeft()
        }
        return _style;
    }

    const styleIntegerField = () => {
        let _style = {
            display: 'block', 
            width: widthValue, 
            minWidth: widthValue, 
            height: heightStd, 
            fontSize: getValueFontSize(),
            textAlign: getTextAlign(),
            paddingTop: getPaddingTop(),
            paddingRight: getButtonFieldWidth('right'),
            paddingBottom: getPaddingBottom(),
            paddingLeft: getButtonFieldWidth('left')
        }
        return _style;
    }

    const styleFloatField = () => {
        let _style = {
            display: 'block', 
            width: widthValue,
            minWidth: widthValue,
            height: heightStd,
            fontSize: getValueFontSize(),
            textAlign: getTextAlign(),
            paddingTop: getPaddingTop(),
            paddingRight: getButtonFieldWidth('right'),
            paddingBottom: getPaddingBottom(),
            paddingLeft: getButtonFieldWidth('left')
        }
        return _style;
    }

    const labelStyle = () => {
        let _style = {
            width: widthLabel,
            minWidth: widthLabel,
            height: heightStd, 
            fontSize: fontSizeLabel, 
            display: 'inline-block'
        }
        return _style;
    }

    const leftButtonStyle = () => {
        let _style = {position: 'absolute', left: 0, width:  buttonWidth, minWidth:  buttonWidth, height:  getButtonHeight(), minHeight: getButtonHeight()}
        return _style;
    }

    const rightButtonStyle = () => {
        let _style = {position: 'absolute', right: 0, width:  buttonWidth, minWidth:  buttonWidth, height:  getButtonHeight(), minHeight: getButtonHeight(), justifyContent: 'center', alignItems: 'center', textAlign: 'center'}
        return _style;
    }

    const getReadOnly = () => {
        if(readOnly || buttonOnly)
            return true;
    }
    

    return ( 
        <div>
            {props.type != 'header' && labelVisibility &&
                <div style={{display:'inline-block'}}>
                    <div style={{display: 'inline-block'}}>
                        <label 
                            className={label_style.std_label} 
                            fontSize={getLabelFontSize()}
                            style={labelStyle()}>{props.label}</label>
                    </div>
                </div>
            }
            <div style={{display:'inline-block', position: 'relative'}}>
                {props.type == 'header' &&
                    <input 
                        type='text' 
                        placeholder={placeholder}
                        className={field_style.input_field_header} 
                        style={styleHeaderField()} 
                        value={getContent()} 
                        onChange={(e) => updateInput(e.target.value)} 
                        readOnly={getReadOnly()} />
                }
                {props.type == 'text' &&
                    <input 
                        type='text' 
                        placeholder={placeholder}
                        className={field_style.input_field} 
                        style={styleTextField()} 
                        value={getContent()} 
                        onChange={(e) => updateInput(e.target.value)} 
                        readOnly={getReadOnly()} />
                }
                {props.type == 'integer' &&
                    <div style={{display: 'inline-block'}}>
                        <div style={{position: 'relative', minWidth: getFieldWidth(), width: getFieldWidth()}}>
                            { buttonVisibility &&
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button 
                                        className={btn_style.input_field_button_left} 
                                        style={leftButtonStyle()} 
                                        onClick={() => handleClick(-1)} 
                                        onMouseEnter={() => hoverButtonField(true, 'left')} 
                                        onMouseLeave={() => hoverButtonField(false, 'left')}>
                                        <img src={minusIcon} alt="Bild" style={{ float: 'left', width: '9px' }} /> 
                                    </button>
                                </div>
                            }
                            { buttonVisibility &&
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button 
                                        className={btn_style.input_field_button_right} 
                                        style={rightButtonStyle()} 
                                        onClick={() => handleClick(1)} 
                                        onMouseEnter={() => hoverButtonField(true, 'right')} 
                                        onMouseLeave={() => hoverButtonField(false, 'right')}>
                                        <img src={plusIcon} alt="Bild" style={{ float: 'left', width: '9px' }} /> 
                                    </button>
                                </div>
                            }
                            <input 
                                type='text' 
                                placeholder={placeholder}
                                className={field_style.input_field} 
                                style={styleIntegerField()} 
                                value={getContent()} 
                                onChange={(e) => validateInput(e.target.value)} 
                                readOnly={getReadOnly()} 
                                onMouseEnter={() => hoverButtonField(true, 'none')} 
                                onMouseLeave={() => hoverButtonField(false, 'none')} />
                        </div>
                    </div>
                }
                {props.type == 'float' &&
                    <div style={{display: 'inline-block'}}>
                        <div style={{position: 'relative', minWidth: getFieldWidth(), width: getFieldWidth()}}>
                            { buttonVisibility &&
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button 
                                        className={btn_style.input_field_button_left} 
                                        style={leftButtonStyle()} 
                                        onClick={() => handleClick(-1)} 
                                        onMouseEnter={() => hoverButtonField(true, 'left')} 
                                        onMouseLeave={() => hoverButtonField(false, 'left')}>
                                        <img src={minusIcon} alt="Bild" style={{ float: 'left', width: '9px' }} /> 
                                    </button>
                                </div>
                            }
                            { buttonVisibility &&
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button 
                                        className={btn_style.input_field_button_right} 
                                        style={rightButtonStyle()} 
                                        onClick={() => handleClick(1)} 
                                        onMouseEnter={() => hoverButtonField(true, 'right')} 
                                        onMouseLeave={() => hoverButtonField(false, 'right')}>
                                        <img src={plusIcon} alt="Bild" style={{ float: 'left', width: '9px' }} /> 
                                    </button>
                                </div>
                            }
                            <input 
                                type='text' 
                                placeholder={placeholder}
                                className={field_style.input_field} 
                                style={styleFloatField()} 
                                value={getContent()} 
                                onChange={(e) => validateInput(e.target.value)} 
                                readOnly={getReadOnly()}
                                onMouseEnter={() => hoverButtonField(true, 'none')} 
                                onMouseLeave={() => hoverButtonField(false, 'none')} />
                        </div>
                    </div>
                }
                {getOverlayVisibility() &&
                    <OverlayMessageField position={overlayPosition}
                                        parentWidth={getFieldWidth()}
                                        parentHeight={getFieldHeight()}
                                        content={overlayMessage}/>
                }
            </div>
        </div>
    );
}
 
export {
    InputField
}