
import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {MessageBoxContext, MessageBoxContextProvider} from '../../API/MessageBoxContext'
import {RestUserContext, RestUserContextProvider} from '../../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {
    ParseToInteger,
    ParseToFloat,
    IsObjectNullOrUndefined,
    IsNullOrEmpty,
    ValidateImage
} from '../../API/Functions/GeneralFunctions'
import ImageContainer from '../ComponentsStylish/ImageContainer';
import defaultPortrait from '../../Rss/Images/character_default.png';
import refreshIcon from '../../Rss/Images/icons/refresh.png';
import importIcon from '../../Rss/Images/icons/import_database.png';
import upIcon from '../../Rss/Images/icons/up.png';
import downIcon from '../../Rss/Images/icons/down.png';
import deleteIcon from '../../Rss/Images/icons/remove.png';
import subscribeIcon from '../../Rss/Images/icons/subscribe_character.png';
import unsubscribeIcon from '../../Rss/Images/icons/unsubscribe_character.png';
import checkIcon from '../../Rss/Images/icons/check.png';
import minusIcon from '../../Rss/Images/icons/minus.png';
import RemoteDownloadButton from '../ComponentsStylish/Buttons/RemoteUploadButton';
import NewCharacterButton from '../ComponentsStylish/Buttons/NewCharacterButton';
import DiceButton from '../ComponentsStylish/Buttons/DiceButton';
import label_style from '../../StylesStylish/label.module.css'
import field_style from '../../StylesStylish/field.module.css'
import table_style from '../../StylesStylish/table.module.css'
import btn_style from '../../StylesStylish/btn.module.css'
import { Tooltip } from 'react-tooltip'
import OverlayMessageField from '../ComponentsStylish/OverlayMessageField'

const CharacterSelectionTable = (props) => {
    const { display_subscribed_characters = false } = props;

    const {
        REST_login_user,
        REST_create_new_user,
        REST_load_characters,
        REST_save_character,
        REST_load_character,
        //REST_load_campaign_character,
        REST_delete_character,
        REST_apply_config,
        REST_subscribe_character_to_campaign,
        REST_load_campaign_characters,
        REST_load_assigned_campaigns
    } = useContext(RestUserContext);

    const {
        API_get_character_id,
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const {
        API_move_character_element
    } = useContext(APIContext)

    const {
        API_get_campaign_characters,
        API_set_campaign_characters,
        API_get_campaign_id,
        API_get_campaign_owner
    } = useContext(CampaignContext)

    const {
        API_get_std_header_labels,
        API_get_tab_view_labels,
        API_get_std_labels,
        API_get_user_labels,
        API_get_message_box_content_labels
    } = useContext(TextDataContext)

    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);
    
    const [refreshMessage, setRefreshMessage] = useState('');
    const [refreshMessageSuccess, setRefreshMessageSuccess] = useState(false);
    const [loadCharacterMessage, setLoadCharacterMessage] = useState([]);
    const [loadCharacterMessageSuccess, setLoadCharacterMessageSuccess] = useState(false);

    const updateLoadCharacterMessage = (_index, _message) => {
        if(getCharacters() == null)
            return;

        if(getCharacters().length == 0)
            return;

        if(_index < 0  || _index >= getCharacters().length)
            return;

        let _copy = new Array(getCharacters().length).fill('');

        _copy[_index] = _message;
        setLoadCharacterMessage(_copy);
    }

    const getCharacters = () => {
        if(getUserRole() == 'game_master'){
            return API_get_campaign_characters();
        }
        else if(getUserRole() == 'player' && !display_subscribed_characters){
            let user = API_get_user();
            return user.characters;
        }
        else if(getUserRole() == 'player' && display_subscribed_characters){
            return API_get_campaign_characters();
        }

    }

    /*const getLoadCharacterMessage = (_index) => {
        console.log('getLoadCharacterMessage');
        if(getCharacters() == null)
            return '';

        if(getCharacters().length == 0)
            return '';

        if(_index < 0 || _index >= getCharacters().length)
            return '';

        //let _array = Array.from({ length: getCharacters().length }, () => '');
        return loadCharacterMessage[_index];

    }*/

    const getUserRole = () => {
        let user = API_get_user();

        return user.role;
    }
    
    
    const loadCharactersFromRemote = () => {
        let _user = API_get_user();
        if(getUserRole() == 'game_master'){
            let _campaign_id = API_get_campaign_id();
            REST_load_campaign_characters(_user.name, _campaign_id, setRefreshMessage, setRefreshMessageSuccess);
        }
        else if(getUserRole() == 'player' && !display_subscribed_characters){
            REST_load_characters(_user.name, setRefreshMessage, setRefreshMessageSuccess);
        }
        else if(getUserRole() == 'player' && display_subscribed_characters){
            let _campaign_owner = API_get_campaign_owner();
            let _campaign_id = API_get_campaign_id();
            REST_load_campaign_characters(_campaign_owner, _campaign_id, setRefreshMessage, setRefreshMessageSuccess);
        }
    }

    /*const loadCharactersFromRemote = () => {
        REST_load_characters();
    }
    
    const loadCampaignCharactersFromRemote = () => {
        let _campaignID = API_get_campaign_id();
        console.log(_campaignID)
        //console.log('loadFromRemote')
        //console.log(API_get_user());
        //API_set_remote_connect_message('Try');
        REST_load_campaign_characters(_campaignID);
    }*/

    const saveCharacterToRemote = () => {
        //API_set_remote_connect_message('Save');
        REST_save_character(setRefreshMessage, setRefreshMessageSuccess);
    }

    const loadCharacterFromRemote = (_characterOwner, _characterID, index) => {
        let _actualCharacterOwner = _characterOwner;
        if(IsObjectNullOrUndefined(_characterOwner) || getUserRole() == 'player')
            _actualCharacterOwner = API_get_user().name;
        
        /*if(getUserRole() == 'game_master'){
            REST_load_campaign_characters();
        }
        else if(getUserRole() == 'player'){
            REST_load_characters();
        }*/
        
        //API_set_remote_connect_message('Load');
        let _loadCharacterMessage = (_message) => updateLoadCharacterMessage(index, _message);
        REST_load_character(_actualCharacterOwner, _characterID, () => REST_load_assigned_campaigns(_actualCharacterOwner), _loadCharacterMessage, setLoadCharacterMessageSuccess);
    }

    /*const loadCampaignCharacterFromRemote = (_characterOwner, _characterID) => {
        let _actualCharacterOwner = _characterOwner;
        if(_characterOwner == null || getUserRole() == 'player')
        _actualCharacterOwner = API_get_user().name;

        //Kann vermutlich durch REST_load_character ersetzt werden nach stylish Umzug
        REST_load_campaign_character(_actualCharacterOwner, _characterID, () => REST_load_assigned_campaigns());
    }*/

    const moveElement = (_characterID, _direction) => {
        API_move_character_element(_characterID, _direction);
    }

    /*const getConfigFromRemote = () => {
        //console.log("getConfigFromRemote");
        REST_apply_config();
    }*/

    
    const getRemoveTooltip = () => {
        if(getUserRole() == 'game_master'){
            return API_get_std_labels().remove_character
        }
        else if(getUserRole() == 'player'){
            return API_get_std_labels().delete
        }
    }

    const reomveHandler = (_characterOwner, _characterID) => {
        let _actualCharacterOwner = _characterOwner;
        if(IsObjectNullOrUndefined(_characterOwner) || getUserRole() == 'player')
            _actualCharacterOwner = API_get_user().name;

        if(getUserRole() == 'game_master'){
            let _campaignID = API_get_campaign_id();
            let _campaignOwner = API_get_user().name
            //REST_subscribe_character_to_campaign(_characterOwner, _characterID, _campaignOwner, _campaignID, false);
            let _content = API_get_message_box_content_labels().unsubscribe_character;
            API_open_yes_no_message_box(_content, () => REST_subscribe_character_to_campaign(_actualCharacterOwner, _characterID, _campaignOwner, _campaignID, false, setRefreshMessage, setRefreshMessageSuccess));
        }
        else if(getUserRole() == 'player'){
            //REST_delete_character(_characterOwner, _characterID);
            let _content = API_get_message_box_content_labels().delete_character;
            API_open_yes_no_message_box(_content, () => REST_delete_character(_actualCharacterOwner, _characterID, setRefreshMessage, setRefreshMessageSuccess));
        }
    }

    const getRefreshOverlayVisibility = () => {
        if(IsNullOrEmpty(refreshMessage))
            return false;

        return true;
    }

    const getLoadOverlayVisibility = (_index) => {
        if(loadCharacterMessage == null)
            return false;

        if(loadCharacterMessage.length == 0)
            return false;

        if(_index < 0  || _index >= loadCharacterMessage.length)
            return false;

        if(IsNullOrEmpty(loadCharacterMessage[_index]))
            return false;

        return true;
    }

    const getImage = (_image) => {
        if(_image == null)
            return defaultPortrait;
        //if(ValidateImage(_image) == null)
          //  return defaultPortrait;

        //if(!ValidateImage(_image))
          //  return defaultPortrait;
            

        return _image
    }

    const getActionVisibility = () => {
        if(getUserRole() == 'game_master')
        {
            return true;
        }
        else if(getUserRole() == 'player' && !display_subscribed_characters)
        {
            return true;
        }
        return false;
    }
    
    const isRowSelected = (_id) =>{
        let _character_campaign_id = API_get_character_id()
        if(_id == _character_campaign_id){
            return true;
        }
        else
        {
            return false;
        }
    }

    return ( 
        <div style={{marginLeft: 15}}>
            <table className={table_style.std_table} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                <tr>
                    <th style={{textAlign: 'center'}} colspan={8}>{API_get_tab_view_labels().characters}</th>
                </tr>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{API_get_std_header_labels().image}</td>
                    <td style={{width: 400, minWidth: 400, textAlign: 'left', fontWeight: 'bold', paddingLeft: 5}}>{API_get_std_labels().character}</td>
                    {/*<td style={{width: 280, minWidth: 280}}>ID</td>*/}
                    { getUserRole() == 'game_master' &&
                        <td style={{width: 200, minWidth: 200, textAlign: 'left', fontWeight: 'bold', paddingLeft: 5}}>{API_get_user_labels().user}</td>
                    }
                    {/*<td className={table.cell_header}>Load</td>*/}
                    { getActionVisibility() &&
                        <td style={{fontWeight: 'bold', textAlign: 'align'}} colspan={5}>{API_get_std_header_labels().actions}</td>
                    }
                </tr>
                {Object.entries(getCharacters()).map(([key, value], index) => (
                    <tr style={{ background: isRowSelected(value.id) ? 'rgba(120, 140, 140, 0.3)' : 'transparent' }}>
                        <td>
                            <ImageContainer image={value.image} default_image={defaultPortrait} borderWidth={1} width={100} max_height={100} />
                        </td>
                        <td style={{textAlign: 'left', paddingLeft: 5}}>{value.name} <br /> <label style={{fontSize: 12}}>ID: {value.id}</label></td>
                        { getUserRole() == 'game_master' &&
                            <td style={{textAlign: 'left', paddingLeft: 5}}>{value.user}</td>
                        }
                        { getActionVisibility() && 
                            <td style={{width: 12, minWidth: 12, padding: 1}}>
                                <div style={{display:'inline-block', position: 'relative'}}>
                                    <button className={btn_style.icon_button} onClick={() => loadCharacterFromRemote(value.user, value.id, index)} data-tooltip-id="btn-load-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().load_character}>
                                        <img src={importIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                                    </button>
                                    <Tooltip id="btn-load-tooltip" />
                                    { getLoadOverlayVisibility(index) &&
                                        <OverlayMessageField position={'left'}
                                                            variableWidth={true}
                                                            parentWidth={24}
                                                            parentHeight={24}
                                                            success={loadCharacterMessageSuccess}
                                                            content={loadCharacterMessage[index]}/>
                                    }
                                </div>
                            </td>
                        }
                        { getActionVisibility() && 
                            <td style={{width: 12, minWidth: 12, padding: 1}}>
                                <button className={btn_style.icon_button} onClick={() => moveElement(value.id, 'up')}>
                                    <img src={upIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                                </button>
                            </td>
                        }
                        { getActionVisibility() && 
                            <td style={{width: 12, minWidth: 12, padding: 1}}>
                                <button className={btn_style.icon_button} onClick={() => moveElement(value.id, 'down')}>
                                        <img src={downIcon} alt="Bild" style={{ float: 'left', width: '14px', marginTop: 2 }} />
                                </button>
                            </td>
                        }
                        { getActionVisibility() && 
                            <td style={{width: 12, minWidth: 12, padding: 1}}>
                            </td>
                        }
                        { getActionVisibility() && 
                            <td style={{width: 12, minWidth: 12, padding: 1}}>
                                <button className={btn_style.icon_button} onClick={() => reomveHandler(value.user, value.id)} data-tooltip-id="btn-remove-tooltip" data-tooltip-place="bottom" data-tooltip-content={getRemoveTooltip()}>
                                    <img src={deleteIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                                </button>
                                <Tooltip id="btn-remove-tooltip" />
                            </td>
                        }
                    </tr>
                    
                ))}
            </table>
            <div style={{marginLeft: 4, marginTop: 15}}>
                <div style={{display:'inline-block', position: 'relative'}}>
                    <button className={btn_style.std_button} onClick={loadCharactersFromRemote} style={{height: 30, widht: 44, minWidth: 44}} data-tooltip-id="btn-refresh-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().refresh}>
                        <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                            <img src={refreshIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
                        </div>
                    </button>
                    <Tooltip id="btn-refresh-tooltip" />
                    { getRefreshOverlayVisibility() &&
                        <OverlayMessageField position={'right'}
                                            variableWidth={true}
                                            parentWidth={44}
                                            parentHeight={30}
                                            success={refreshMessageSuccess}
                                            content={refreshMessage}/>
                    }
                </div>
            </div>
            {/* getUserRole() == 'player' &&
                <div>
                    <button onClick={loadCharactersFromRemote} style={{margin: 5, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            }
            { getUserRole() == 'game_master' &&
                <div>
                    <button onClick={loadCampaignCharactersFromRemote} style={{marginTop: 5, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            */}
            {/*<button onClick={getConfigFromRemote}>Get Config</button>
            <p style={{color: 'red', height: 25, marginTop: 5}}>{API_get_remote_connect_message()}</p>*/}
        </div>
    );
}
 
export default CharacterSelectionTable