import { useState, useContext, useEffect } from 'react'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import CharacterSelectionTable from './SubPagesCharacterSelectionSheet/CharacterSelectionTable'
import CharacterSubscriptionField from './SubPagesCharacterSelectionSheet/CharacterSubscriptionField'

const CharacterSelectionSheet = () => {
    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const getUserRole = () => {
        let user = API_get_user();
    
        return user.role;
    }

    return ( 
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 65}}>
                <CharacterSelectionTable />
            </div>
            { getUserRole() == 'game_master' &&
                <div>
                    <CharacterSubscriptionField />
                </div>
            }
        </div>
    );
}

export default CharacterSelectionSheet;