import { CloneObj } from "../../API/Functions/GeneralFunctions";

var init_base_stats = {
    name: '',
    ancestry: '',
    heritage: '',    
    hair_color: '',  
    gender: '',   
    eye_color: '',    
    skin_color: '',   
    height: 0,    
    weight: 0, 
    famous: 0,     
    notorious: 0,  
    age: 0, 
    experience: 0, 
    level: 1
}

var init_character_stats = 
{
    hitpoints: {/*formula: '', base: 3, */id: 0, temporary_mod: 0, bonus: 0, lost: 0, value: 20},
    load_capacity: {/*formula: 'a', base: 3, */id: 1, temporary_mod: 0, bonus: 0, lost: 0, value: 20},
    endurance: {/*formula: 'a', base: 3, */id: 2, temporary_mod: 0, bonus: 0, lost: 0, value: 20},
    kho_tal: {/*formula: 'a', base: 3, */id: 3, temporary_mod: 0, bonus: 0, lost: 0, value: 20}
}

var init_stat_overview = 
{
    carried_weight: 0.0,
    damage_restriction: 0,
    armor_class: 0,
    armor_restriction: 0,
    other_restriction: 0
}

var init_money_list = [
    {id: 0, display_name: 'Eternium', abbreviation: 'E', worth: 10, weight: 0.02, amount: 0},
    {id: 1, display_name: 'Gold', abbreviation: 'G', worth: 1, weight: 0.1, amount: 0},
    {id: 2, display_name: 'Silver', abbreviation: 'S', worth: 0.1, weight: 0.1, amount: 0},
    {id: 3, display_name: 'Copper', abbreviation: 'C', worth: 0.01, weight: 0.1, amount: 0}
]

const INIT_get_base_stats = () => 
{
    return CloneObj(init_base_stats, true);
}

const INIT_get_character_stats = () => 
{
    return CloneObj(init_character_stats, true);
}

const INIT_get_stat_overview = () => 
{
    return CloneObj(init_stat_overview, true);
}

const INIT_get_money_list = () => 
{
    return CloneObj(init_money_list, true);
}

export 
{
    INIT_get_base_stats, 
    INIT_get_character_stats,
    INIT_get_stat_overview,
    INIT_get_money_list
};