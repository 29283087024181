import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {SkillCheckTable} from './SubPagesRules/SkillCheckTable'
import label_style from '../StylesStylish/label.module.css'
import field_style from '../StylesStylish/field.module.css'
import table_style from '../StylesStylish/table.module.css'

const RulesPage = () => {

    return ( 
        <div>
            <div style={{display: 'flex'}}>
                <div style={{display: 'inline-block'}}>
                    <SkillCheckTable type={'meaning'}></SkillCheckTable>
                </div>
                <div style={{display: 'inline-block', marginLeft: 145}}>
                    <SkillCheckTable type={'difficulty'}></SkillCheckTable>
                </div>
                <div style={{display: 'inline-block', marginLeft: 145}}>
                    <SkillCheckTable type={'quality'}></SkillCheckTable>
                </div>
            </div>
        </div>
     );
}
 
export default RulesPage;