import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../../API/API'
import MeleeWeaponTable from './SubPagesEquipmentSheet/MeleeWeaponTable'
import RangedWeaponTable from './SubPagesEquipmentSheet/RangedWeaponTable'
import ArmorTable from './SubPagesEquipmentSheet/ArmorTable'
import InventoryTable from './SubPagesEquipmentSheet/InventoryTable'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const CharacterEquipmentSheet = () => {
    

    return ( 
        <div>
            <div>
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                    <MeleeWeaponTable list_type={'unarmed'}></MeleeWeaponTable>
                </div>
                <div style={{ marginBottom: 15 }}>
                    <MeleeWeaponTable list_type={'melee'}></MeleeWeaponTable>
                </div>
                <div style={{ marginBottom: 15 }}>
                    <RangedWeaponTable list_type={'ranged'}></RangedWeaponTable>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'inline-block', marginBottom: 15 }}>
                    <ArmorTable></ArmorTable>
                </div>
                <div style={{ display: 'inline-block', marginLeft: 15, marginBottom: 15 }}>
                    <InventoryTable></InventoryTable>
                </div>
            </div>
        </div>
     );
}
 
export default CharacterEquipmentSheet;