var base_stat_labels = {
    name: '',
    ancestry: '',
    heritage: '',
    hair_color: '',
    gender: '',   
    eye_color: '',
    skin_color: '',  
    height: '',
    weight: '',
    famous: '',
    notorious: '',
    age: '',
    experience: '',
    level: ''
}

var base_stat_labels_translations = {
    name: {en: 'Name', de: 'Name'},
    ancestry: {en: 'Ancestry', de: 'Volk'},
    heritage: {en: 'Heritage', de: 'Herkunft'},
    hair_color: {en: 'Hair color', de: 'Haarfarbe'},
    gender: {en: 'Gender', de: 'Geschlecht'},  
    eye_color: {en: 'Eye color', de: 'Augenfarbe'},
    skin_color: {en: 'Skin-/fur color', de: 'Haut-/Fellfarbe'}, 
    height: {en: 'Height', de: 'Gr\u00F6\u00DFe'},
    weight: {en: 'Weight', de: 'Gewicht'},
    famous: {en: 'Famous', de: 'Ber\u00FChmt'},
    notorious: {en: 'Notorious', de: 'Ber\u00FCchtigt'},
    age: {en: 'Age', de: 'Alter'},
    experience: {en: 'Experience', de: 'Erfahrung'},
    level: {en: 'Level', de: 'Stufe'}
}

const BACKEND_get_base_stat_labels = () => 
{
    return base_stat_labels;
}

const BACKEND_set_base_stat_labels = (_base_stat_labels) => 
{
    base_stat_labels = _base_stat_labels;
}

const BACKEND_get_base_stat_labels_translations = () => 
{
    return base_stat_labels_translations;
}

var std_labels = {
    character: 'Character',
    new_character: 'New character',
    new_campaign: 'New campaign',
    load_character: 'Load character',
    load_campaign: 'Load campaign',
    loaded_character: 'Loaded character',
    loaded_campaign: 'Loaded campaign',
    game_master: 'Game Master',
    kho_tal: 'Kho\u0027Tal',
    save: 'Save',
    load: 'Load',
    import: 'Import',
    export: 'Export',
    edit: 'Edit',
    init: 'Init',
    new: 'New',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    cancel: 'Cancel',
    refresh: 'Refresh',
    delete: 'Delete',
    edit_character: 'Edit Character',
    dice_label: 'd',
    language: 'language',
    import_local: 'Local import',
    export_local: 'Local export',
    foundry_url: 'Foundry URL',
    open_foundry_connector: 'Open FoundryVTT Connector',
    version: 'Version',
    add_campaign_description: 'Add description...',
    remove_campaign: 'Remove campaign',
    remove_character: 'Remove character',
    subscribe_character: 'Add character',
    unsubscribe_character: 'Remove character',
    subscribe_user: 'Add user',
    unsubscribe_user: 'Remove user',
    add_feat: 'Add feat',
    add_weapon: 'Add weapon',
    add_armor: 'Add armor',
    add_gear: 'Add gear',
    add_spell: 'Add spell',
    default_name: 'Name...',
    default_description: 'Description...'
}

var std_labels_translations = {
    character: {en: 'Character', de: 'Charakter'},
    new_character: {en: 'New character', de: 'Neuer Charakter'},
    new_campaign: {en: 'New campaign', de: 'Neue Kampagne'},
    load_character: {en: 'Load character', de: 'Lade Charakter'},
    load_campaign: {en: 'Load campaign', de: 'Lade Kampagne'},
    loaded_character: {en: 'Loaded character', de: 'Geladener Charakter'},
    loaded_campaign: {en: 'Loaded campaign', de: 'Geladene Kampagne'},
    game_master: {en: 'Game Master', de: 'Meister'},
    kho_tal: {en: 'Kho\u0027Tal', de: 'Kho\u0027Tal'},
    save: {en: 'Save', de: 'Speichern'},
    load: {en: 'Load', de: 'Laden'},
    import: {en: 'Import', de: 'Import'},
    export: {en: 'Export', de: 'Export'},
    edit: {en: 'Edit', de: 'Bearb.'},
    init: {en: 'Init', de: 'Init'},
    new: {en: 'New', de: 'Neu'},
    yes: {en: 'Yes', de: 'Ja'},
    no: {en: 'No', de: 'Nein'},
    ok: {en: 'Ok', de: 'Ok'},
    cancel: {en: 'Cancel', de: 'Abbrechen'},
    refresh: {en: 'Refresh', de: 'Aktualisieren'},
    delete: {en: 'Delete', de: 'L\u00F6schen'},
    edit_character: {en: 'Edit Character', de: 'Charakter bearb.'},
    dice_label: {en: 'd', de: 'W'},
    language: {en: 'Language', de: 'Sprache'},
    import_local: {en: 'Local import', de: 'Lokaler Import'},
    export_local: {en: 'Local export', de: 'Lokaler Export'},
    foundry_url: {en: 'Foundry URL', de: 'Foundry URL'},
    open_foundry_connector: {en: 'Open FoundryVTT Connector', de: '\u00D6ffne FoundryVTT Connector'},
    version: {en: 'Version', de: 'Version'},
    add_campaign_description: {en: 'Add description...', de: 'Beschreibung...'},
    remove_campaign: {en: 'Remove campaign', de: 'Entferne Kampagne'},
    remove_character: {en: 'Remove character', de: 'Entferne Charakter'},
    subscribe_character: {en: 'Add character', de: 'F\u00FCge Charakter hinzu'},
    unsubscribe_character: {en: 'Remove character', de: 'Entferne Charakter'},
    subscribe_user: {en: 'Add user', de: 'F\u00FCge Nutzer hinzu'},
    unsubscribe_user: {en: 'Remove user', de: 'Entferne Nutzer'},
    add_feat: {en: 'Add feat', de: 'F\u00FCge Fertigkeit hinzu'},
    add_weapon: {en: 'Add weapon', de: 'F\u00FCge Waffe hinzu'},
    add_armor: {en: 'Add armor', de: 'F\u00FCge R\u00FCstung hinzu'},
    add_gear: {en: 'Add gear', de: 'F\u00FCge Gegenstand hinzu'},
    add_spell: {en: 'Add spell', de: 'F\u00FCge Spruch hinzu'},
    default_name: {en: 'Name...', de: 'Name...'},
    default_description: {en: 'Description...', de: 'Beschreibung...'}
}

const BACKEND_set_std_labels = (_std_labels) => 
{
    std_labels = _std_labels;
}

const BACKEND_get_std_labels = () => 
{
    return std_labels;
}

const BACKEND_get_std_labels_translations = () => 
{
    return std_labels_translations;
}

var tab_view_labels = {
    character: 'Character',
    characters: 'Characters',
    campaign: 'Campaign',
    campaigns: 'Campaigns',
    sheet: 'Sheet',
    overview: 'Overview',
    general: 'General',
    attributes: 'Attributes',
    skills: 'Skills',
    equipment: 'Equipment',
    combat: 'Combat',
    magic: 'Magic',
    magic_skills: 'Magic Skills',
    magic_spells: 'Magic Spells',
    feat: 'Feat',
    combat: 'Combat',
    rules: 'Rules',
    settings: 'Settings',
    remote_user: "Remote"
}

var tab_view_translations = {
    character: {en: 'Character', de: 'Charakter'},
    characters: {en: 'Characters', de: 'Charaktere'},
    campaign: {en: 'Campaign', de: 'Kampagne'},
    campaigns: {en: 'Campaigns', de: 'Kampagnen'},
    sheet: {en: 'Sheet', de: 'Pass'},
    overview: {en: 'Overview', de: '\u00DCbersicht'},
    general: {en: 'General', de: 'Allgemein'},
    attributes: {en: 'Attributes', de: 'Attribute'},
    skills: {en: 'Skills', de: 'Fertigkeiten'},
    equipment: {en: 'Equipment', de: 'Ausr\u00FCstung'},
    combat: {en: 'Combat', de: 'Kampf'},
    magic: {en: 'Magic', de: 'Magie'},
    feat: {en: 'Feats', de: 'Sonderfertigkeiten'},
    combat: {en: 'Combat', de: 'Kampf'},
    rules: {en: 'Rules', de: 'Regeln'},
    settings: {en: 'Settings', de: 'Einstellungen'},
    remote_user: {en: 'Remote', de: 'Remote'}
}

const BACKEND_set_tab_view_labels = (_tab_view_labels) => 
{
    tab_view_labels = _tab_view_labels;
}

const BACKEND_get_tab_view_labels = () => 
{
    return tab_view_labels;
}

const BACKEND_get_tab_view_translations = () => 
{
    return tab_view_translations;
}

var std_header_labels = {
    talent_in: 'Talent in',
    training_in: 'Training in',
    max_training: 'Maximum Training',
    max_specialization: 'Maximum Specialization',
    max_circle: 'Maximum Circle',
    formula: 'Formula',
    characteristic: 'Characteristic',
    characteristics: 'Characteristics',
    characteristic_abbr: 'CH',
    skill: 'Skill',
    skill_short: 'Skill',
    skills: 'Skills',
    type: 'Type',
    increase: 'Increase',
    abbreviation: 'Abr.',
    lead_attribute: 'Lead-CH',
    influence: 'Influence',
    weight: 'Weight',
    total: 'Total',
    amount: 'Amount',
    cost: 'Cost',
    cost_first_letter: 'C',
    max: 'Max',
    base: 'Base',
    temporary_mod: 'Temp',
    bonus: 'Bonus',
    remain: 'Remain',
    value: 'Value',
    dice: 'Dice',
    description: 'Description',
    comment: 'Comment',
    monster: 'Monster',
    level: 'Level',
    grade: 'Grade',
    feat: 'Feat',
    magic: 'Magic',
    magic_spell: 'Magic Spell',
    element: 'Element',
    circle: 'Circle',
    might: 'Might',
    range: 'Range',
    meaning: 'Meaning',
    difficulty: 'Difficulty',
    quality: 'Quality',
    actions: 'Actions',
    image: 'Image',
    state: 'State',
    current_state: 'Current State'
}

var std_header_translations = {
    talent_in: {en: 'Talent in', de: 'Talent in'},
    training_in: {en: 'Training in', de: '\u00DCbung in'},
    max_training: {en: 'Maximum Training', de: 'Maximale \u00DCbung'},
    max_specialization: {en: 'Maximum Specialization', de: 'Maximale Spezialisierung'},
    max_circle: {en: 'Maximum Circle', de: 'Maximaler Zirkel'},
    formula: {en: 'Formula', de: 'Formel'},
    characteristic: {en: 'Characteristic', de: 'Eigenschaft'},
    characteristics: {en: 'Characteristics', de: 'Eigenschaften'},
    characteristic_abbr: {en: 'CH', de: 'EG'},
    skill: {en: 'Skill', de: 'Fertigkeit'},
    skill_short: {en: 'Skill', de: 'Fert.'},
    skills: {en: 'Skills', de: 'Fertigkeiten'},
    type: {en: 'Type', de: 'Typ'},
    increase: {en: 'Increase', de: 'Steig.'},
    abbreviation: {en: 'Abr.', de: 'Abk.'},
    influence: {en: 'Influence', de: 'Einfluss'},
    lead_attribute: {en: 'Lead-CH', de: 'Leit-EG'},
    weight: {en: 'Weight', de: 'Gewicht'},
    total: {en: 'Total', de: 'Total'},
    amount: {en: 'Amount', de: 'Anzahl'},
    cost: {en: 'Cost', de: 'Kosten'},
    cost_first_letter: {en: 'C', de: 'K'},
    max: {en: 'Max', de: 'Max'},
    base: {en: 'Base', de: 'Basis'},
    temporary_mod: {en: 'Temp', de: 'Temp'},
    bonus: {en: 'Bonus', de: 'Bonus'},
    remain: {en: 'Remain', de: 'Verbl.'},
    value: {en: 'Value', de: 'Wert'},
    dice: {en: 'Dice', de: 'W\u00FCrfel'},
    description: {en: 'Description', de: 'Beschreibung'},
    comment: {en: 'Comment', de: 'Kommentar'},
    monster: {en: 'Monster', de: 'Monster'},
    level: {en: 'Level', de: 'Level'},
    grade: {en: 'Grade', de: 'Grad'},
    feat: {en: 'Feat', de: 'Fertigkeit'},
    magic: {en: 'Magic', de: 'Magie'},
    magic_spell: {en: 'Magic Spell', de: 'Zauberspruch'},
    element: {en: 'Element', de: 'Element'},
    specialization: {en: 'Spec.', de: 'Spez.'},
    circle: {en: 'Circle', de: 'Zirkel'},
    might: {en: 'Might', de: 'Macht'},
    range: {en: 'Range', de: 'Interval'},
    meaning: {en: 'Meaning', de: 'Bedeutung'},
    difficulty: {en: 'Difficulty', de: 'Schwierigkeit'},
    quality: {en: 'Quality', de: 'Qualit\u00E4t'},
    actions: {en: 'Actions', de: 'Aktionen'},
    image: {en: 'Image', de: 'Bild'},
    state: {en: 'State', de: 'Status'},
    current_state: {en: 'Current State', de: 'Aktueller Status'}
}

const BACKEND_set_std_header_labels = (_std_header_labels) => 
{
    std_header_labels = _std_header_labels;
}

const BACKEND_get_std_header_labels = () => 
{
    return std_header_labels;
}

const BACKEND_get_std_header_translations = () => 
{
    return std_header_translations;
}

var stat_overview_labels = {
    stat_overview: 'Stat Overview',
    carried_weight: 'Carried Weight',
    damage_restriction: 'Dmg Restriction',
    armor_restriction: 'Armor Restriction',
    other_restriction: 'Other Restriction'
}

var stat_overview_translations = {
    stat_overview: {en: 'Stat Overview', de: 'Status \u00DCbersicht'},
    carried_weight: {en: 'Carried Weight', de: 'Getragenes Gewicht'},
    damage_restriction: {en: 'Damage Restriction', de: 'Einschr\u00E4nkung Schaden'},
    armor_restriction: {en: 'Armor Restriction', de: 'Einschr\u00E4nkung R\u00FCstung'},
    other_restriction: {en: 'Other Restriction', de: 'Einschr\u00E4nkung Andere'}
}

const BACKEND_set_stat_overview_labels = (_stat_overview_labels) => 
{
    stat_overview_labels = _stat_overview_labels;
}

const BACKEND_get_stat_overview_labels = () => 
{
    return stat_overview_labels;
}

const BACKEND_get_stat_overview_translations = () => 
{
    return stat_overview_translations;
}

var money_header_labels = {
    money: 'Money',
    coin: 'Coin',
    worth: 'Worth'
}

var money_header_translations = {
    money: {en: 'Money', de: 'Geld'},
    coin: {en: 'Coin', de: 'M\u00FCnze'},
    worth: {en: 'Worth', de: 'Wert'}
}

const BACKEND_set_money_header_labels = (_money_header_labels) => 
{
    money_header_labels = _money_header_labels;
}

const BACKEND_get_money_header_labels = () => 
{
    return money_header_labels;
}

const BACKEND_get_money_header_translations = () => 
{
    return money_header_translations;
}
var money_list_translations = [
    {id: 0, en: {display_name: 'Eternium', abbreviation: 'E'}, de: {display_name: 'Eternium', abbreviation: 'E'}},
    {id: 1, en: {display_name: 'Gold', abbreviation: 'G'}, de: {display_name: 'Gold', abbreviation: 'G'}},
    {id: 2, en: {display_name: 'Silver', abbreviation: 'S'}, de: {display_name: 'Silber', abbreviation: 'S'}},
    {id: 3, en: {display_name: 'Copper', abbreviation: 'C'}, de: {display_name: 'Kupfer', abbreviation: 'K'}}
]

const BACKEND_get_money_list_translations = () => 
{
    return money_list_translations;
}

var message_box_content_labels = {
    new_character: 'Do you want to create a new character?',
    new_campaign: 'Do you want to create a new campaign?',
    delete_character: 'Do you want to delete the character?',
    delete_campaign: 'Do you want to delete the campaign?',
    delete_feat: 'Do you want to delete the feat?',
    delete_magic_spell: 'Do you want to delete the spell?',
    delete_weapon: 'Do you want to delete the weapon?',
    delete_armor: 'Do you want to delete the armor?',
    delete_gear: 'Do you want to delete the gear?',
    unsubscribe_character: 'Do you want to remove character from campaign?',
    open_foundry_connector: 'FoundryVTT Connector is not active. \n Do you want to open it?'
}

var message_box_content_translations = {
    new_character: {en: 'Do you want to create a new character?', de: 'Willst du einen neuen Charakter erstellen?'},
    new_campaign: {en: 'Do you want to create a new campaign?', de: 'Willst du eine neue Kampagne erstellen?'},
    delete_character: {en: 'Do you want to delete the character?', de: 'Willst du den Charakter l\u00F6schen?'},
    delete_campaign: {en: 'Do you want to delete the campaign?', de: 'Willst du die Kampagne l\u00F6schen?'},
    delete_feat: {en: 'Do you want to delete the feat?', de: 'Willst du die Fertigkeit l\u00F6schen?'},
    delete_magic_spell: {en: 'Do you want to delete the spell?', de: 'Willst du den Zauberspruch l\u00F6schen?'},
    delete_weapon: {en: 'Do you want to delete the weapon?', de: 'Willst du die Waffe l\u00F6schen?'},
    delete_armor: {en: 'Do you want to delete the armor?', de: 'Willst du die R\u00FCstung l\u00F6schen?'},
    delete_gear: {en: 'Do you want to delete the gear?', de: 'Willst du den Gegenstand l\u00F6schen?'},
    unsubscribe_character: {en: 'Do you want to remove character from campaign?', de: 'Willst du den Charakter von der Kampagne entfernen?'},
    open_foundry_connector: {en: 'FoundryVTT Connector is not active.<br>Do you want to open it?', de: 'FoundryVTT Connector ist nicht ge\u00F6ffnet.<br>Soll er g\u00F6ffnet werden?'}
}

const BACKEND_set_message_box_content_labels = (_message_box_content_labels) => 
{
    message_box_content_labels = _message_box_content_labels;
}

const BACKEND_get_message_box_content_labels = () => 
{
    return message_box_content_labels;
}

const BACKEND_get_message_box_content_translations = () => 
{
    return message_box_content_translations;
}

var character_stats_labels = {
    comments: 'Comments',
    character_stats: 'Character Stats',
    add_comments: 'Add comments...',
    hitpoints: 'Hitpoints',
    reflexes: 'Reflexes',
    load_capacity: 'Load capacity',
    //initiative: 'Initiative',
    //dodge: 'Dodge',
    deal_dmg: 'Deal Dmg',
    heal: 'Heal',
    formula_toughness: 'Though.',
    formula_load_capacity: 'Load Cap.',
    formula_endurance: 'End.'
}

var character_stats_translations = {
    comments: {en: 'Comments', de: 'Kommentare'},
    character_stats: {en: 'Character Stats', de: 'Charakter Status'},
    add_comments: {en: 'Add comments...', de: 'Kommentar hinzuf\u00FCgen...'},
    hitpoints: {en: 'Hitpoints', de: 'Lebensenergie'},
    reflexes: {en: 'Reflexes', de: 'Reflexe'},
    load_capacity: {en: 'Load capacity', de: 'Tragkraft'},
    endurance: {en: 'Endurance', de: 'Ausdauer'},
    //initiative: {en: 'Initiative', de: 'Initiative'},
    //dodge: {en: 'Dodge', de: 'Ausweichen'},
    deal_dmg: {en: 'Deal Dmg', de: 'Schaden'},
    heal: {en: 'Heal', de: 'Heilen'},
    formula_toughness: {en: 'Though.', de: 'Z\u00E4hig.'},
    formula_load_capacity: {en: 'Load Cap.', de: 'Tragkr.'},
    formula_endurance: {en: 'End.', de: 'Ausd.'},
}

const BACKEND_set_character_stats_labels = (_character_stats_labels) => 
{
    character_stats_labels = _character_stats_labels;
}

const BACKEND_get_character_stats_labels = () => 
{
    return character_stats_labels;
}

const BACKEND_get_character_stats_translations = () => 
{
    return character_stats_translations;
}

var attribute_header_labels = {
    free_attribute_points: 'Free Training',
    free_skill_points: 'Free Specialization',
    free_focus_points: 'Free Talent',
    focus: 'Talent',
    training: 'Training',
    specialization: 'Specialization',
    ancestry_mod: 'Ancest.',
    other_bonus: 'Other Bonus',
    ancestry_bonus: 'Other Bonus'
}

var attribute_header_translations = {
    free_attribute_points: {en: 'Free Training', de: 'Freie \u00DCbung'},
    free_skill_points: {en: 'Free Specialization', de: 'Freie Spezialisierung'},
    free_focus_points: {en: 'Free Talent', de: 'Freies Talent'},
    focus: {en: 'Talent', de: 'Talent'},
    training: {en: 'Training', de: '\u00DCbung'},
    specialization: {en: 'Specialization', de: 'Spezialisierung'},
    ancestry_mod: {en: 'Ancestry', de: 'Volk'},
    other_bonus: {en: 'Other Bonus', de: 'Weitere Boni'},
    ancestry_bonus: {en: 'Ancestry Bonus', de: 'V\u00F6lker Bonus'}
}

const BACKEND_set_attribute_header_labels = (_attribute_header_labels) => 
{
    attribute_header_labels = _attribute_header_labels;
}

const BACKEND_get_attribute_header_labels = () => 
{
    return attribute_header_labels;
}

const BACKEND_get_attribute_header_translations = () => 
{
    return attribute_header_translations;
}

var user_labels = {
    user: 'User',
    role: 'Role',
    player: 'Player',
    game_master: 'Game Master'
}

var user_label_translations = {
    user: {en: 'User', de: 'Spieler'},
    role: {en: 'Role', de: 'Rolle'},
    player: {en: 'Player', de: 'Spieler'},
    game_master: {en: 'Game Master', de: 'Meister'}
}

const BACKEND_set_user_labels = (_user_labels) => 
{
    user_labels = _user_labels;
}

const BACKEND_get_user_labels = () => 
{
    return user_labels;
}

const BACKEND_get_user_label_translations = () => 
{
    return user_label_translations;
}

var equipment_header_labels = {
    unarmed_weapon: 'Unarmed',
    melee_weapon: 'Melee weapon',
    ranged_weapon: 'Ranged weapon',
    attack_mod: 'At.-Mod.',
    defense_mod: 'Def.-Mod.',
    precision_mod: 'Prec.-Mod.',
    initiative: 'Initiative',
    initiative_dice: 'Initiative Dice',
    attack: 'Attack',
    defense: 'Defense',
    precision: 'Precision',
    attack_dice: 'Attack dice',
    defense_dice: 'Defense dice',
    precision_dice: 'Aim dice',
    ammo_name: 'Am. name',
    ammo: 'Ammo',
    ammo_weight: 'Am. weight',
    hand: 'Hand',
    damage: 'Damage',
    fist_fight: 'Fist fight',
    armor: 'Armor',
    put_on: 'Put on',
    armor_class_abbreviation: 'AC',
    armor_restriction_abbreviation: 'RT',
    gear: 'Gear'
}

var equipment_header_translations = {
    unarmed_weapon: {en: 'Unarmed', de: 'Unbewaffnet'},
    melee_weapon: {en: 'Melee weapon', de: 'Nahkampfwaffe'},
    ranged_weapon: {en: 'Ranged weapon', de: 'Fernkampfwaffe'},
    attack_mod: {en: 'At.-Mod.', de: 'Angr.-Mod.'},
    defense_mod: {en: 'Def.-Mod.', de: 'Vert.-Mod.'},
    precision_mod: {en: 'Prec.-Mod.', de: 'Pr\u00E4z.-Mod.'},
    initiative: {en: 'Initiative', de: 'Initiative'},
    initiative_dice: {en: 'Initiative Dice', de: 'Initiative W\u00FCrfel'},
    attack: {en: 'Attack', de: 'Angriff'},
    defense: {en: 'Defense', de: 'Vert.'},
    precision: {en: 'Precision', de: 'Pr\u00E4zision'},
    attack_dice: {en: 'Attack dice', de: 'Angriffs W\u00FCrfel'},
    defense_dice: {en: 'Defense dice', de: 'Vert. W\u00FCrfel'},
    precision_dice: {en: 'Aim dice', de: 'Zielen W\u00FCrfel'},
    ammo_name: {en: 'Ammo name', de: 'Munition Name'},
    ammo: {en: 'Ammo', de: 'Munition'},
    ammo_weight: {en: 'Am. weight', de: 'Mun. Gew.'},
    hand: {en: 'Hand', de: 'Hand'},
    damage: {en: 'Damage', de: 'Schaden'},
    fist_fight: {en: 'Fist fight', de: 'Faustkampf'},
    armor: {en: 'Armor', de: 'R\u00FCstung'},
    put_on: {en: 'Put on', de: 'Angelegt'},
    armor_class_abbreviation: {en: 'AC', de: 'RS'},
    armor_restriction_abbreviation: {en: 'RT', de: 'BH'},
    gear: {en: 'Gear', de: 'Gegenstand'}
}

const BACKEND_set_equipment_header_labels = (_equipment_header_labels) => 
{
    equipment_header_labels = _equipment_header_labels;
}

const BACKEND_get_equipment_header_labels= () => 
{
    return equipment_header_labels;
}

const BACKEND_get_equipment_header_translations = () => 
{
    return equipment_header_translations;
}

var melee_fighting_type_translations = [
    {id: 0, en: 'Unarmed', de: 'Unbewaffnet'},
    //{id: 1, en: 'One hand', de: 'Einh\u00E4ndig'},
    {id: 1, en: 'Main hand', de: 'Haupthand'},
    {id: 2, en: 'Offhand Weapon', de: 'Offhand Waffe'},
    {id: 3, en: 'Double handed', de: 'Zweih\u00E4ndig'},
    {id: 4, en: 'Offfhand Shield', de: 'Offhand Schild'},
    /*{id: 1, en: 'Light', de: 'Flink'},
    {id: 2, en: 'Medium', de: 'Medium'},
    {id: 3, en: 'Heavy', de: 'Schwer'},
    {id: 4, en: 'Pole', de: 'Stange'}*/
]

const BACKEND_get_melee_fighting_type_translations = () => 
{
    return melee_fighting_type_translations;
}

var ranged_weapon_fighting_translations = [
    {id: 0, en: 'None', de: 'Keine'},
    {id: 1, en: 'Shooting', de: 'Schu\u00DFwaffe'},
    {id: 2, en: 'Throwing', de: 'Wurfwaffe'},
    /*{id: 1, en: '(Cross-)bow', de: 'Armbr./Bogen'},
    {id: 2, en: 'Light throw', de: 'Leichte Wurfw.'},
    {id: 3, en: 'Heavy throw', de: 'Schwere Wurfw.'},
    {id: 4, en: 'Slings', de: 'Schleudern'},
    {id: 5, en: 'Blowpipes', de: 'Blasrohr'}*/
]

const BACKEND_get_ranged_fighting_type_translations = () => 
{
    return ranged_weapon_fighting_translations;
}

/*var hand_type_translations = [
    {id: 0, en: 'One hand', de: 'Einhand'},
    {id: 1, en: 'Main', de: 'Haupthand'},
    {id: 2, en: 'Offhand', de: 'Offhand'},
    {id: 2, en: 'Both', de: 'Beide'}
]

const BACKEND_get_hand_type_translations = () => 
{
    return hand_type_translations;
}*/

var attribute_group_translations = [
    {id: 0, en: 'Robustness', de: 'Robustheit'},
    {id: 1, en: 'Agility', de: 'Beweglichkeit'},
    {id: 2, en: 'Social Skills', de: 'Soziales'},
    {id: 3, en: 'Intuition', de: 'Intuition'},
    {id: 4, en: 'Knowledge', de: 'Wissen'},
    {id: 5, en: 'Combat', de: 'Kampf'},
    {id: 6, en: 'Magic', de: 'Magie'}
]

const BACKEND_get_attribute_group_translations = () => 
{
    return attribute_group_translations;
}

var magic_circle_type_translations = [
    {id: 0, en: '1', de: '1'},
    {id: 1, en: '2', de: '2'},
    {id: 2, en: '3', de: '3'},
    {id: 3, en: '4', de: '4'},
    {id: 4, en: '5', de: '5'},
    {id: 5, en: '6', de: '6'},
    {id: 6, en: '7', de: '7'}
]

const BACKEND_get_magic_circle_type_translations = () => 
{
    return magic_circle_type_translations;
}

var attribute_translations = [
    {id: 0, en: {display_name: 'Constitution', abbreviation: 'Co'}, de: {display_name: 'Konstitution', abbreviation: 'Ko'}},
    {id: 1, en: {display_name: 'Strength', abbreviation: 'St'}, de: {display_name: 'St\u00E4rke', abbreviation: 'St'}},
    {id: 2, en: {display_name: 'Dexterity', abbreviation: 'Dx'}, de: {display_name: 'Gewandtheit', abbreviation: 'Ge'}},
    {id: 3, en: {display_name: 'Coordination', abbreviation: 'Cd'}, de: {display_name: 'Koordination', abbreviation: 'Kd'}},
    {id: 4, en: {display_name: 'Stealth', abbreviation: 'Sh'}, de: {display_name: 'Heimlichkeit', abbreviation: 'Hm'}},
    {id: 5, en: {display_name: 'Thievery', abbreviation: 'Th'}, de: {display_name: 'Diebeskunst', abbreviation: 'Dk'}},
    {id: 6, en: {display_name: 'Society', abbreviation: 'Sc'}, de: {display_name: 'Gesellschaft', abbreviation: 'Gs'}},
    {id: 7, en: {display_name: 'Eloquence', abbreviation: 'Eq'}, de: {display_name: 'Eloquenz', abbreviation: 'Eq'}},
    {id: 8, en: {display_name: 'Deceive', abbreviation: 'Dc'}, de: {display_name: 'T\u00E4uschen', abbreviation: 'Ts'}},
    {id: 9, en: {display_name: 'Bardic art', abbreviation: 'Ba'}, de: {display_name: 'Bardenkunst', abbreviation: 'Bk'}},
    {id: 10, en: {display_name: 'Perception', abbreviation: 'Pr'}, de: {display_name: 'Wahrnehmung', abbreviation: 'Wn'}},
    {id: 11, en: {display_name: 'Nature', abbreviation: 'Nt'}, de: {display_name: 'Naturverbundenheit', abbreviation: 'Nt'}},
    {id: 12, en: {display_name: 'Healing', abbreviation: 'He'}, de: {display_name: 'Heilkunde', abbreviation: 'Hl'}},
    {id: 13, en: {display_name: 'Common', abbreviation: 'Cm'}, de: {display_name: 'Weltlich', abbreviation: 'Wt'}},
    {id: 14, en: {display_name: 'Mystic', abbreviation: 'My'}, de: {display_name: 'Mystik', abbreviation: 'My'}},
    {id: 15, en: {display_name: 'Military', abbreviation: 'Mi'}, de: {display_name: 'Milit\u00E4r', abbreviation: 'Mi'}},
    {id: 16, en: {display_name: 'Melee Combat', abbreviation: 'MC'}, de: {display_name: 'Nahkampf', abbreviation: 'NK'}},
    {id: 17, en: {display_name: 'Ranged Combat', abbreviation: 'RC'}, de: {display_name: 'Fernkampf', abbreviation: 'FK'}},
    {id: 18, en: {display_name: 'Arcanic Might', abbreviation: 'AM'}, de: {display_name: 'Arkane Macht', abbreviation: 'AM'}},
    {id: 19, en: {display_name: 'Magic Resistance', abbreviation: 'MR'}, de: {display_name: 'Magieresistenz', abbreviation: 'MR'}},
]

const BACKEND_get_attribute_translations = () => 
{
    return attribute_translations;
}

var skill_translations  = [
    {
        skill_group_id: 0,
        skill_translations:[
            {id: 0, en: 'Toughness', de: 'Z\u00E4higkeit'},
            {id: 1, en: 'Endurance', de: 'Ausdauer'},
            {id: 2, en: 'Poison resistance', de: 'Giftresistenz'},
            {id: 3, en: 'Disease resistance', de: 'Krankheitsresistenz'},
            {id: 4, en: 'Hold breath', de: 'Atem anhalten'},
            {id: 5, en: 'Booze', de: 'Zechen'}, 
        ]
    },
    {
        skill_group_id: 1,
        skill_translations:[
            {id: 0, en: 'Force', de: 'Gewalt'}, 
            //{id: 1, en: 'Heavy Lifting', de: 'Heben'}, 
            //{id: 2, en: 'Impact', de: 'Wucht'}, 
            {id: 1, en: 'Throw', de: 'Werfen'}, 
            {id: 2, en: 'Load Capacity', de: 'Tragkraft'}, 
            {id: 3, en: 'Climb', de: 'Klettern'} 
        ]
    },
    {
        skill_group_id: 2,
        skill_translations:[
            {id: 0, en: 'Swim', de: 'Schwimmen'}, 
            {id: 1, en: 'Run', de: 'Sprinten'}, 
            {id: 2, en: 'Jump', de: 'Springen'}, 
            //{id: 3, en: 'Landing', de: 'Abrollen'}, 
            //{id: 4, en: 'Pass obstacles', de: 'Hindernisse \u00FCberwinden'}, 
            //{id: 5, en: 'Dodge', de: 'Ausweichen'}, 
            {id: 3, en: 'Reflexes', de: 'Reflexe'} 
        ]
    },
    {
        skill_group_id: 3,
        skill_translations:[
            {id: 0, en: 'Ride', de: 'Reiten'},
            {id: 1, en: 'Balance', de: 'Gleichgewicht'},
            {id: 2, en: 'Tie up/Unleash', de: 'Fesseln/Entfesseln'},
            {id: 3, en: 'Steering vehicles', de: 'Fahrzeuge lenken'}
        ]
    },
    {
        skill_group_id: 4,
        skill_translations:[
            {id: 0, en: 'Sneak', de: 'Schleichen'}, 
            {id: 1, en: 'Hide', de: 'Sich verstecken'}, 
            {id: 2, en: 'Hide object', de: 'Objekte verstecken'}, 
            {id: 3, en: 'Appear unsuspicious', de: 'Unauff\u00E4llig wirken'} 
        ]
    },
    {
        skill_group_id: 5,
        skill_translations:[
            {id: 0, en: 'Pick pocket', de: 'Taschendiebstahl'},
            {id: 1, en: 'Pick lock', de: 'Schl\u00F6sser knacken'},
            {id: 2, en: 'Foul game', de: 'Falschspiel'},
            {id: 3, en: 'Poison knowledge', de: 'Wissen \u00FCber Gifte'},
            {id: 4, en: 'Set/Disarm Traps', de: 'Fallen stellen/entsch\u00E4rfen'}, 
            {id: 5, en: 'Assasination', de: 'Meucheln'} 
        ]
    },
    {
        skill_group_id: 6,
        skill_translations:[
            {id: 0, en: 'Estimate value', de: 'Wert sch\u00E4tzen'}, 
            {id: 1, en: 'Etiquette', de: 'Etikette'}, 
            {id: 2, en: 'Alley knowledge', de: 'Gassenwissen'}, 
            {id: 3, en: 'Dice-, Card-, Boardgames', de: 'W\u00FCrfel-, Karten-, Brettspiele'}, 
            {id: 4, en: 'Assess characters', de: 'Charaktere einsch\u00E4tzen'} 
        ]
    },
    {
        skill_group_id: 7,
        skill_translations:[
            {id: 0, en: 'Convince/Negotiate', de: '\u00DCberzeugen/Verhandeln'}, 
            {id: 1, en: 'Calm', de: 'Beruhigen'}, 
            {id: 2, en: 'Inspire', de: 'Begeistern'}, 
            {id: 3, en: 'Leadership', de: 'Anf\u00FChren'}, 
            {id: 4, en: 'Interrogate', de: 'Verh\u00F6ren'} 
        ]
    },
    {
        skill_group_id: 8,
        skill_translations:[
            {id: 0, en: 'Threaten', de: 'Einsch\u00FCchtern'}, 
            {id: 1, en: 'Lie/Deceive', de: 'L\u00FCgen/T\u00E4uschen'}, 
            {id: 2, en: 'Fraud', de: 'Trickbetrug'}, 
            {id: 3, en: 'Manipulation', de: 'Manipulation'}, 
            {id: 4, en: 'Feign', de: 'Verstellen'} 
        ]
    },
    {
        skill_group_id: 9,
        skill_translations:[
            {id: 0, en: 'Sing', de: 'Singen'}, 
            {id: 1, en: 'Play instruments', de: 'Musizieren'}, 
            {id: 2, en: 'Acting', de: 'Schauspielerei'}, 
            {id: 3, en: 'Dancing', de: 'Tanzen'}, 
            //{id: 4, en: 'Story telling', de: 'Geschichten erz\u00E4hlen'}, 
            {id: 4, en: 'Tales\u0026Legends', de: 'Sagen\u0026Legenden'}, 
            {id: 5, en: 'Draw/Paint', de: 'Malen/Zeichnen'} 
        ]
    },
    {
        skill_group_id: 10,
        skill_translations:[
            {id: 0, en: 'Senses', de: 'Sinnessch\u00E4rfe'}, 
            {id: 1, en: 'Orientation', de: 'Orientierung'}, 
            {id: 2, en: 'Sense for danger', de: 'Gefahrensinn'}, 
            //{id: 3, en: 'Attention', de: 'Aufmerksamkeit'}, 
            {id: 3, en: 'Estimate distance/speed', de: 'Entf./Geschw. sch\u00E4tzen'} ,
            {id: 4, en: 'Tracking', de: 'F\u00E4hrtensuche'}, 
        ]
    },
    {
        skill_group_id: 11,
        skill_translations:[
            {id: 0, en: 'Weather forecast', de: 'Wettervorhersage'}, 
            {id: 1, en: 'Bushcraft', de: 'Wildnisleben'}, 
            {id: 2, en: 'Prepare food', de: 'Nahrung zubereiten'}, 
            {id: 3, en: 'Flora\u0026Fauna', de: 'Flora\u0026Fauna'}, 
            {id: 4, en: 'Animal traps', de: 'Tierfallen'}, 
            {id: 5, en: 'Woodworking', de: 'Holzbearbeitung'} 
        ]
    },
    {
        skill_group_id: 12,
        skill_translations:[
            {id: 0, en: 'Heal wounds', de: 'Wunden heilen'}, 
            {id: 1, en: 'Heal disease', de: 'Krankheiten heilen'}, 
            {id: 2, en: 'Anatomy', de: 'Anatomie'}, 
            {id: 3, en: 'Heal soul', de: 'Seele heilen'}, 
            {id: 4, en: 'Heal poison', de: 'Gifte heilen'}, 
            {id: 5, en: 'Heal curse', de: 'Fl\u00FCche heilen'}
        ]
    },
    {
        skill_group_id: 13,
        skill_translations:[
            {id: 0, en: 'Read\u0026Write', de: 'Lesen\u0026Schreiben'}, 
            {id: 1, en: 'Geaology\u0026Geaography', de: 'Geaologie\u0026Geaographie'}, 
            {id: 2, en: 'Cultures and Nations', de: 'Kulturen\u0026V\u00F6lker'}, 
            {id: 3, en: 'Creatures\u0026Monsters', de: 'Kreaturen\u0026Monster'}, 
            {id: 4, en: 'Languages', de: 'Sprachen'}, 
            {id: 5, en: 'Mathematics', de: 'Mathematik'}
        ]
    },
    {
        skill_group_id: 14,
        skill_translations:[
            {id: 0, en: 'Divinity\u0026Cults', de: 'G\u00F6tter\u0026Kulte'}, 
            {id: 1, en: 'Magic knowledge', de: 'Magiekunde'}, 
            {id: 2, en: 'Astronomy', de: 'Astronomie'}, 
            {id: 3, en: 'Alchemy', de: 'Alchemie'}, 
            {id: 4, en: 'Ghosts\u0026Demons', de: 'Geister\u0026D\u00E4monen'}
        ]
    },
    {
        skill_group_id: 15,
        skill_translations:[
            {id: 0, en: 'Weapons knowledge', de: 'Waffenkunde'}, 
            {id: 1, en: 'Military knowledge', de: 'Milit\u00E4rkunde'}, 
            {id: 2, en: 'Tactics', de: 'Taktik'}, 
            {id: 3, en: 'Siege', de: 'Belagerungskunst'}, 
            {id: 4, en: 'Army logistics', de: 'Heereslogistik'}
        ]
    },
    {
        skill_group_id: 16,
        skill_translations:[
            {id: 0, en: 'Unarmed', de: 'Unbewaffnet'}, 
            //{id: 1, en: 'Martial arts', de: 'Kampfkunst'}, 
            //{id: 2, en: 'Light weapons', de: 'Flinke Waffen'}, 
            //{id: 3, en: 'Medium weapons', de: 'Ausgewogene Waffen'}, 
            //{id: 4, en: 'Heavy weapons', de: 'Schwere Waffen'}, 
            //{id: 5, en: 'Polearms', de: 'Stangenwaffen'}, 
            {id: 1, en: 'Onehand weapons', de: 'Einhandwaffen'}, 
            {id: 2, en: 'Offhand', de: 'Zweite Waffe'}, 
            {id: 3, en: 'Twohand weapons', de: 'Zweihandwaffen'}, 
            {id: 4, en: 'Shield fight', de: 'Schildkampf'}, 
            {id: 5, en: 'Armor habituation', de: 'R\u00FCstungsgew\u00F6hnung'}
        ]
    },
    {
        skill_group_id: 17,
        skill_translations:[
            {id: 0, en: 'Shooting weapons', de: 'Schu\u00DFwaffen'}, 
            {id: 1, en: 'Throwing weapns', de: 'Wurfwaffen'}
            //{id: 2, en: 'Heavy Throwing Weapons', de: 'Schwere Wurfwaffen'}, 
            //{id: 3, en: 'Slings', de: 'Schleudern'}, 
            //{id: 4, en: 'Blowpipes', de: 'Blasrohr'}
        ]
    },
    {
        skill_group_id: 18,
        skill_translations:[
            {id: 0, en: 'Light', de: 'Licht'}, 
            {id: 1, en: 'Heat', de: 'Hitze'}, 
            {id: 2, en: 'Fire', de: 'Feuer'}, 
            {id: 3, en: 'Earth/Ore/Rock', de: 'Erde/Erz/Gestein'}, 
            {id: 4, en: 'Nature', de: 'Natur'}, 
            {id: 5, en: 'Life', de: 'Leben'}, 
            {id: 6, en: 'Body', de: 'K\u00F6rper'}, 
            {id: 7, en: 'Darkness', de: 'Dunkelheit'}, 
            {id: 8, en: 'Cold', de: 'K\u00E4lte'}, 
            {id: 9, en: 'Water', de: 'Wasser'}, 
            {id: 10, en: 'Air', de: 'Luft'}, 
            {id: 11, en: 'Construct', de: 'Konstrukt'}, 
            {id: 12, en: 'Death', de: 'Tod'}, 
            {id: 13, en: 'Spirit', de: 'Geist'}, 
            {id: 14, en: 'Kho\u0027Tal', de: 'Kho\u0027Tal'}, 
            {id: 15, en: 'Arkana', de: 'Arkanuum'}, 
            {id: 16, en: 'Daemonica', de: 'Daemonica'}, 
            {id: 17, en: 'Holiness', de: 'Heiligkeit'}
        ]
    },
    {
        skill_group_id: 19,
        skill_translations:[
            {id: 0, en: 'Res. to magic damage', de: 'Res. gegen mag. Schaden'}, 
            {id: 1, en: 'Res. to magic manipulation', de: 'Res. gegen mag. Manipulation'}, 
            {id: 2, en: 'Res. to magic detection', de: 'Res. gegen mag. Erkennung'},
            {id: 3, en: 'Magic senses', de: 'Magische Sinne'}
        ]
    }
 ]

 const BACKEND_get_skill_translations = () => 
 {
     return skill_translations;
 }

const dice_log_translations = {
    header_label: {en: 'Dice Roller', de: 'W\u00FCrfel'},
    rolled_label: {en: 'Copied to clipboard', de: 'In die Zwischenablage kopiert'},
    result_label: {en: 'Result', de: 'Ergebnis'}
}

const BACKEND_get_dice_log_translations = () => 
{
    return dice_log_translations;
}


/*const switch_base_stat_language = (_language) => {
    for(var key in base_stat_labels){
        base_stat_labels[key] = base_stat_labels_translations[key][_language];
    }
}

const switch_std_labels_language = (_language) => {
    for(var key in std_labels){
        std_labels[key] = std_labels_translations[key][_language];
    }
}*/



export 
{
    BACKEND_get_base_stat_labels, 
    BACKEND_set_base_stat_labels, 
    BACKEND_get_base_stat_labels_translations,
    BACKEND_get_std_labels, 
    BACKEND_set_std_labels, 
    BACKEND_get_std_header_translations,
    BACKEND_get_std_labels_translations,
    BACKEND_get_std_header_labels,
    BACKEND_set_std_header_labels,
    BACKEND_set_stat_overview_labels,
    BACKEND_get_stat_overview_labels,
    BACKEND_get_stat_overview_translations,
    BACKEND_set_money_header_labels,
    BACKEND_get_money_header_labels,
    BACKEND_get_money_header_translations,
    BACKEND_get_money_list_translations,
    BACKEND_set_message_box_content_labels,
    BACKEND_get_message_box_content_labels,
    BACKEND_get_message_box_content_translations,
    BACKEND_get_tab_view_labels,  
    BACKEND_set_tab_view_labels,  
    BACKEND_get_tab_view_translations,
    BACKEND_set_character_stats_labels,
    BACKEND_get_character_stats_labels,
    BACKEND_get_character_stats_translations,
    BACKEND_get_attribute_header_labels, 
    BACKEND_set_attribute_header_labels,
    BACKEND_get_attribute_header_translations,
    BACKEND_get_melee_fighting_type_translations,
    BACKEND_get_ranged_fighting_type_translations,
    //BACKEND_get_hand_type_translations,
    BACKEND_set_equipment_header_labels,
    BACKEND_get_equipment_header_labels,
    BACKEND_get_equipment_header_translations,
    BACKEND_get_attribute_group_translations,
    BACKEND_get_attribute_translations,
    BACKEND_get_skill_translations,
    BACKEND_get_dice_log_translations,
    BACKEND_get_magic_circle_type_translations,
    BACKEND_get_user_labels,
    BACKEND_set_user_labels,
    BACKEND_get_user_label_translations
};



