import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import {CharacterMainPage} from './CharacterMainPage'
import {GameMasterMainPage} from './GameMasterMainPage'
import {LoginPage} from './LoginPage'
import {CheckIfKeyIsContained} from '../API/Functions/GeneralFunctions'


import MainPageStylish from '../SitesStylish/MainPageStylish'



const MainPage = () => {

    const {
        API_switch_language,
        API_get_language_list,
        API_init_character,
        API_init_campaign
    } = useContext(APIContext);

    const {
        API_get_tool_data
    } = useContext(ToolDataContext);

    const {
        API_get_user, 
        API_set_user,
        //API_get_legacy_login, 
        //API_set_legacy_login
    } = useContext(UserDataContext);

    useEffect(() => {
        console.log('First initialization of Character! Error when called while app is running.');
        API_init_character(API_get_tool_data().language);
    }, []);

    useEffect(() => {
        console.log('First initialization of Game Master! Error when called while app is running.');
        API_init_campaign(API_get_tool_data().language);
    }, []);

    const getScreen = () => {
        if(API_get_user() == null)
            return 'undefined'

        if(CheckIfKeyIsContained('role', API_get_user())){
            return API_get_user().role;
        }
        
        return 'undefined';
    }

    return ( 
        <div>
            { getScreen() == 'undefined' &&
                <div style={{display: "flex", height: "100vh", alignItems: "center", justifyContent: "center", background: 'lightgray'}}>
                    <LoginPage/>
                </div>
            }
            { getScreen() == 'player' && 
                <div>
                    <MainPageStylish />
                    {/* API_get_legacy_login() &&
                        <CharacterMainPage />
                */}
                </div>
            }
            { getScreen() == 'game_master' &&
                <div>
                    <MainPageStylish />
                    {/* API_get_legacy_login() &&
                        <GameMasterMainPage />
                    */}
                </div>
            }
        </div>
    );
}

export default MainPage;