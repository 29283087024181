import { useContext } from 'react'
import {APIContext} from '../../../API/API'
import {AttributeContext} from '../../../API/AttributeContext'
import {ScreenDataContext} from '../../../API/ScreenDataContext'
import TabBar from '../../ComponentsStylish/TabBar';
import CharacterSkillSheetTableHeader from './CharacterSkillSheetTableHeader';
import CharacterSkillSheetTable from './CharacterSkillSheetTable';
import {IsEven} from '../../../API/Functions/GeneralFunctions';

const CharacterSkillSheetBody = (props) => {

    const {
        API_get_attribute_group_ref
    } = useContext(APIContext);

    const {
        API_get_attribute_group_by_id,
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_skill_group_screen,
        API_set_skill_group_screen,
        API_get_skill_group_tab_name
    } = useContext(ScreenDataContext);

    const getAttributes = () => {
        let _group = API_get_attribute_group_by_id(props.group_id);
        console.log('getAttributes');
        console.log(_group);
        let _attributes = _group.attributes;
        return _attributes;

        /*abbreviation: "Ge"
        ancestry_bonus
        base
        cost
        dice: Object { dice: (1) […], offset: -1 }
        display_name
        group
        id
        increase
        other_bonus
        temporary_mod
        value*/
    }

    const idMapper = (_id) => {
        let _outID = _id;
    

        return _id;
    }

    const getRightMargin = (_index) => {
        if(IsEven(_index))
            return 16

        return 0;
    }

    return ( 
        <div>
            {Object.entries(getAttributes()).map(([key, value], index) => (
                <div style={{display: 'inline-block', width: 777, minWidth: 777, marginRight: getRightMargin(index), marginTop: 15, verticalAlign: 'top'}}>
                    <div>
                        <CharacterSkillSheetTableHeader attribute_id={idMapper(value.id)} />
                    </div>
                    <div style={{marginTop: 15}}>
                        <CharacterSkillSheetTable attribute_id={idMapper(value.id)} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CharacterSkillSheetBody;