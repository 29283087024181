import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {InputField} from '../../ComponentsStylish/InputField';
import RemoteUploadButton from '../../ComponentsStylish/Buttons/RemoteUploadButton';
import NewCharacterButton from '../../ComponentsStylish/Buttons/NewCharacterButton';
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const CharacterInfoHeader = () => {

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_init_character,
        API_set_base_stats_name,
        API_set_base_stats_heritage,
        API_set_base_stats_ancestry,
        API_set_base_stats_gender,
        API_set_base_stats_hair_color,
        API_set_base_stats_eye_color,
        API_set_base_stats_skin_color,
        API_set_base_stats_famous,
        API_set_base_stats_notorious,
        API_set_base_stats_height,
        API_set_base_stats_weight,
        API_set_base_stats_age,
        API_set_base_stats_experience,
        API_set_base_stats_level
    } = useContext(APIContext);

    const {
        API_get_user,
        API_get_character_read_only
    } = useContext(UserDataContext);
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels
    } = useContext(TextDataContext);

    const marginBottom = 4;
    const _user_role = API_get_user().role;
    return ( 
        <div >
            <div style={{ display: 'inline-block', marginLeft: 5, marginRight: 12, minWidth: 350, width: 350 }}>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='text' widthLabel={94} widthValue={210} label={API_get_base_stat_labels().heritage} content={API_get_base_stats().heritage} setter={API_set_base_stats_heritage} readOnly={API_get_character_read_only()}/>
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='text' widthLabel={94} widthValue={210} label={API_get_base_stat_labels().ancestry} content={API_get_base_stats().ancestry} setter={API_set_base_stats_ancestry} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='text' widthLabel={94} widthValue={210} label={API_get_base_stat_labels().gender} content={API_get_base_stats().gender} setter={API_set_base_stats_gender} readOnly={API_get_character_read_only()} />
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: 12, minWidth: 310, width: 310 }}>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='text' widthLabel={124} widthValue={140} label={API_get_base_stat_labels().hair_color} content={API_get_base_stats().hair_color} setter={API_set_base_stats_hair_color} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='text' widthLabel={124} widthValue={140} label={API_get_base_stat_labels().eye_color} content={API_get_base_stats().eye_color} setter={API_set_base_stats_eye_color} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='text' widthLabel={124} widthValue={140} label={API_get_base_stat_labels().skin_color} content={API_get_base_stats().skin_color} setter={API_set_base_stats_skin_color} readOnly={API_get_character_read_only()} />
                </div>
            </div>
            <div style={{ display: 'inline-block', marginRight: 12, minWidth: 260, width: 260 }}> 
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='integer' widthLabel={124} widthValue={88} label={API_get_base_stat_labels().famous} content={API_get_base_stats().famous} setter={API_set_base_stats_famous} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='float' widthLabel={124} widthValue={88} label={API_get_base_stat_labels().height + ' [Schritt]'} content={API_get_base_stats().height} setter={API_set_base_stats_height} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='float' widthLabel={124} widthValue={88} label={API_get_base_stat_labels().weight + ' [Stein]'} content={API_get_base_stats().weight} setter={API_set_base_stats_weight} readOnly={API_get_character_read_only()}  />
                </div>
            </div>
            <div style={{ display: 'inline-block' }}> 
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='integer' widthLabel={90} widthValue={96} label={API_get_base_stat_labels().age} content={API_get_base_stats().age} setter={API_set_base_stats_age} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='integer' widthLabel={90} widthValue={96} label={API_get_base_stat_labels().experience} content={API_get_base_stats().experience} setter={API_set_base_stats_experience} readOnly={API_get_character_read_only()} />
                </div>
                <div style={{marginBottom: marginBottom}}>
                    <InputField type='integer' widthLabel={90} widthValue={40} label={API_get_base_stat_labels().level} content={API_get_base_stats().level} setter={API_set_base_stats_level} buttonOnly={true} buttonField={true} buttonCallback={API_increment_level} readOnly={API_get_character_read_only()}/>
                </div>
            </div>
        </div>
    );
}

export default CharacterInfoHeader;