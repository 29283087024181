
import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {
    ParseToInteger,
    ParseToFloat
} from '../../API/Functions/GeneralFunctions'
import {
    API_get_check_difficulties,
    API_get_check_qualities,
    API_get_skill_meanings
}from '../../API/Parameters/RuleParameters'
import label_style from '../../StylesStylish/label.module.css'
import field_style from '../../StylesStylish/field.module.css'
import table_style from '../../StylesStylish/table.module.css'

const SkillCheckTable = (props) => {

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels
    } = useContext(TextDataContext);

    const getCheckHeader = () => {
        if(props.type == 'difficulty'){
            return API_get_std_header_labels().difficulty;
        }
        else if(props.type == 'quality'){
            return API_get_std_header_labels().quality;
        }
        else if(props.type == 'meaning'){
            return API_get_std_header_labels().meaning;
        }
        return null;
    }

    const getRangeList = () => {
        if(props.type == 'difficulty'){
            return API_get_check_difficulties();
        }
        else if(props.type == 'quality'){
            return API_get_check_qualities();
        }
        else if(props.type == 'meaning'){
            return API_get_skill_meanings();
        }
        return null;
    }

    return ( 
        <div>
            <table className={table_style.std_table} style={{background: 'transparent'}}>
                <tr>
                    <th colspan={2}>
        	            {getCheckHeader()}
                    </th>
                </tr>
                <tr style={{height: 10}}></tr>
                <tr style={{fontWeight: 'bold', borderBottom: '1px solid black'}}>
                    <td style={{width: 100, minWidth: 100}}>{API_get_std_header_labels().range}</td>
                    <td style={{width: 140, minWidth: 140, textAlign: 'left', paddingLeft:5}}>{getCheckHeader()}</td>
                </tr>
                {Object.entries(getRangeList()).map(([key, value], index) => (
                    <tr>
                        <td>
                            {index == 0 &&
                                '\u2264 ' + value.value_max
                            }
                            {(index > 0) && (index< Object.keys(getRangeList()).length - 1) && (value.value_min != value.value_max) &&
                                    value.value_min + '-' + value.value_max
                            }
                            {(index > 0) && (index< Object.keys(getRangeList()).length - 1) && (value.value_min == value.value_max) &&
                                    value.value_min
                            }
                            {index == Object.keys(getRangeList()).length - 1 &&
                                '\u2265 ' + value.value_min
                            }
                        </td>
                        <td style={{textAlign: 'left', paddingLeft:5}}>
                            {value.display_name}
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
}
 
export {
    SkillCheckTable
}
                