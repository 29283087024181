import { 
    ParseToFloat, 
    ParseToInteger ,
    GetListParameterByID,
    SetListParameterByID,
    AddElementToList,
    RemoveElementFromList,
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'
import {
    BACKEND_get_attribute_by_id,
    BACKEND_get_attribute_group_by_id,
    BACKEND_get_skill_by_id,
    BACKEND_get_skill_group_by_id,
    BACKEND_switch_weapon_type
} from '../BackendRouter'

import {
    //BACKEND_get_melee_type_skill_matching,
    //BACKEND_get_ranged_type_skill_matching,
    BACKEND_get_unarmed_weapons,
    BACKEND_set_unarmed_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_get_ranged_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_lead_attribute_tags,
    BACKEND_get_melee_weapon_template,
    BACKEND_get_ranged_weapon_template,
    BACKEND_get_melee_fighting_types,
    BACKEND_get_ranged_fighting_types,
    //BACKEND_get_hand_types,
    BACKEND_get_stat_overview,
    BACKEND_get_formula_skill_ids
} from '../Parameters/CharacterParameters'

import {
    RecalcWeapons,
    RecalcWeight
} from './RecalcFunctions'

function GetMatchingSkill(_typeID, _fighting_types){
    for(let key in _fighting_types){
        if(_fighting_types[key].id == _typeID)
        {
            let _matchingSkill = BACKEND_get_skill_by_id(_fighting_types[key].group_id, _fighting_types[key].skill_id)
            return _matchingSkill
        }
    }
    return null;
}

function GetDamageOffset(_value, _weapon_type, _hand_type){
    
    let _p0 = 0.0;
    let _p100 = 0.0;
    let _v0 = 0;
    let _v100 = 1;

    if(_weapon_type == 'unarmed')
    {

    }
    else if(_weapon_type == 'light')
    {

    }
    else if(_weapon_type == 'medium')
    {

    }
    else if(_weapon_type == 'heavy')
    {
        if(_hand_type == 'both_hands')
        {
            _v100 = 50;
            _v0 = 0;
            _p100 = 2.0;
            _p0 = 0.0;
        }
    }
    else if(_weapon_type == 'pole')
    {
        if(_hand_type == 'both_hands')
        {
            _v100 = 50;
            _v0 = 0;
            _p100 = 1.0;
            _p0 = 0.0;
        }
    }
    else if(_weapon_type == 'shield')
    {
    }

    let _out = (_p100-_p0)/(_v100-_v0)*(_value-_v0)+_p0;
    return _out;
}

function CalculateAttack (_meleeWeapon){
    let _matchingSkillWeaponType = GetMatchingSkill(_meleeWeapon.fighting_type_id , BACKEND_get_melee_fighting_types());
    //let _matchingSkillHandType = GetMatchingSkill(_meleeWeapon.hand_type_id , BACKEND_get_hand_types());
    let _lead_attribute = BACKEND_get_attribute_by_id(_meleeWeapon.lead_attr_id); 
    let _armor_restriction = BACKEND_get_stat_overview().armor_restriction;
    
    if(_matchingSkillWeaponType == null)
    {
        let _fighting_types = BACKEND_get_melee_fighting_types();
        let _keys = Object.keys(_fighting_types)
        let _length = _keys.length
        let _id = _fighting_types[_keys[_length-1]].id
        BACKEND_switch_weapon_type(_id, _meleeWeapon.id, 'melee');
        _matchingSkillWeaponType = GetMatchingSkill(_id , BACKEND_get_melee_fighting_types());
    }

    let _offset = 0;
    if(_meleeWeapon.fighting_type_id == 0)
    {
        //Unarmed
    }
    /*else if(_meleeWeapon.fighting_type_id == 1)
    {
        //One hand
    }*/
    else if(_meleeWeapon.fighting_type_id == 1)
    {
        //Main
    }
    else if(_meleeWeapon.fighting_type_id == 2)
    {
        _offset = -4;
        //Offhand
    }
    else if(_meleeWeapon.fighting_type_id == 3)
    {
        //Both
    }
    else if(_meleeWeapon.fighting_type_id == 4)
    {
        //Shield
    }

    //let _twoWeaponSkill = BACKEND_get_skill_by_id(BACKEND_get_formula_skill_ids().two_weapons.group_id, BACKEND_get_formula_skill_ids().two_weapons.skill_id);
    //let _bothHandsSkill = BACKEND_get_skill_by_id(BACKEND_get_formula_skill_ids().two_handed.group_id, BACKEND_get_formula_skill_ids().two_handed.skill_id);
    //let _shieldSkill = BACKEND_get_skill_by_id(BACKEND_get_formula_skill_ids().shield_fight.group_id, BACKEND_get_formula_skill_ids().shield_fight.skill_id);

    //let _factor = 1.0;
    //let _off_hand = false;
    //let _both_hands = false;


    /*if(_meleeWeapon.hand_type_id == 0)
    {
        _off_hand = false;
        _both_hands = false;
    }
    else if(_meleeWeapon.hand_type_id == 1)
    {
        _off_hand = true;
        _both_hands = false;
    }
    else if(_meleeWeapon.hand_type_id == 2)
    {
        _off_hand = false;
        _both_hands = true;
    }

    if(_meleeWeapon.fighting_type_id == 0)
    {
    }
    else if(_meleeWeapon.fighting_type_id == 1)
    {
        if(_off_hand)
            _factor = GetAttackFactor(_twoWeaponSkill.value, 'light', 'offhand');
        
    }
    else if(_meleeWeapon.fighting_type_id == 2)
    {
        if(_off_hand)
            _factor = GetAttackFactor(_twoWeaponSkill.value, 'medium', 'offhand');
    }
    else if(_meleeWeapon.fighting_type_id == 3)
    {
        if(_both_hands)
            _factor = GetAttackFactor(_bothHandsSkill.value, 'heavy', 'both_hands');

    }
    else if(_meleeWeapon.fighting_type_id == 4)
    {
        if(_both_hands)
            _factor = GetAttackFactor(_bothHandsSkill.value, 'pole', 'both_hands');
    }
    else if(_meleeWeapon.fighting_type_id == 5)
    {
        if(_off_hand)
            _factor = GetAttackFactor(_shieldSkill.value, 'shield', 'offhand');
    }*/

    //BACKEND_get_formula_skill_ids().two_weapons.group_id
    return Math.round(/*_factor*/(_meleeWeapon.attack_mod - Math.round(_armor_restriction) + Math.ceil((_lead_attribute.value + _matchingSkillWeaponType.value)/2.0)+_offset));
}

function CalculateDefense (_meleeWeapon){
    let _matchingSkillWeaponType = GetMatchingSkill(_meleeWeapon.fighting_type_id , BACKEND_get_melee_fighting_types());
    //let _matchingSkillHandType = GetMatchingSkill(_meleeWeapon.hand_type_id , BACKEND_get_hand_types());
    let _lead_attribute = BACKEND_get_attribute_by_id(_meleeWeapon.lead_attr_id); 
    let _armor_class = BACKEND_get_stat_overview().armor_class;
    
    if(_matchingSkillWeaponType == null)
    {
        let _fighting_types = BACKEND_get_melee_fighting_types();
        let _keys = Object.keys(_fighting_types)
        let _length = _keys.length
        let _id = _fighting_types[_keys[_length-1]].id
        BACKEND_switch_weapon_type(_id, _meleeWeapon.id, 'melee');
        _matchingSkillWeaponType = GetMatchingSkill(_id , BACKEND_get_melee_fighting_types());
    }

    //let _twoWeaponSkill = BACKEND_get_skill_by_id(BACKEND_get_formula_skill_ids().two_weapons.group_id, BACKEND_get_formula_skill_ids().two_weapons.skill_id);
    //let _bothHandsSkill = BACKEND_get_skill_by_id(BACKEND_get_formula_skill_ids().two_handed.group_id, BACKEND_get_formula_skill_ids().two_handed.skill_id);
    //let _shieldSkill = BACKEND_get_skill_by_id(BACKEND_get_formula_skill_ids().shield_fight.group_id, BACKEND_get_formula_skill_ids().shield_fight.skill_id);

    let _offset = 0;
    if(_meleeWeapon.fighting_type_id == 0)
    {
        //Unarmed
    }
    else if(_meleeWeapon.fighting_type_id == 1)
    {
        //One hand
    }
    else if(_meleeWeapon.fighting_type_id == 2)
    {
        //Main
    }
    else if(_meleeWeapon.fighting_type_id == 3)
    {
        _offset = -4;
        //Offhand
    }
    else if(_meleeWeapon.fighting_type_id == 4)
    {
        //Both
    }
    else if(_meleeWeapon.fighting_type_id == 5)
    {
        //Shield
    }

    /*let _factor = 1.0;
    let _off_hand = false;
    let _both_hands = false;
    if(_meleeWeapon.hand_type_id == 0)
    {
        _off_hand = false;
        _both_hands = false;
    }
    else if(_meleeWeapon.hand_type_id == 1)
    {
        _off_hand = true;
        _both_hands = false;
    }
    else if(_meleeWeapon.hand_type_id == 2)
    {
        _off_hand = false;
        _both_hands = true;
    }

    if(_meleeWeapon.fighting_type_id == 0)
    {
    }
    else if(_meleeWeapon.fighting_type_id == 1)
    {
        if(_off_hand)
            _factor = GetDefenseFactor(_twoWeaponSkill.value, 'light', 'offhand');
        
    }
    else if(_meleeWeapon.fighting_type_id == 2)
    {
        if(_off_hand)
            _factor = GetDefenseFactor(_twoWeaponSkill.value, 'medium', 'offhand');
    }
    else if(_meleeWeapon.fighting_type_id == 3)
    {
        if(_both_hands)
            _factor = GetDefenseFactor(_bothHandsSkill.value, 'heavy', 'both_hands');

    }
    else if(_meleeWeapon.fighting_type_id == 4)
    {
        if(_both_hands)
            _factor = GetDefenseFactor(_bothHandsSkill.value, 'pole', 'both_hands');
    }
    else if(_meleeWeapon.fighting_type_id == 5)
    {
        if(_off_hand)
            _factor = GetDefenseFactor(_shieldSkill.value, 'shield', 'offhand');
    }*/


    return Math.round(/*_factor*/(_meleeWeapon.defense_mod + Math.floor(_armor_class) + Math.floor((_lead_attribute.value+_matchingSkillWeaponType.value)/2.0)+_offset));
}

function CalculatePrecision (_rangedWeapon){
    let _matchingSkillWeaponType = GetMatchingSkill(_rangedWeapon.fighting_type_id , BACKEND_get_ranged_fighting_types());
    let _lead_attribute = BACKEND_get_attribute_by_id(_rangedWeapon.lead_attr_id); 
    let _armor_restriction = BACKEND_get_stat_overview().armor_restriction;

    if(_matchingSkillWeaponType == null)
    {
        let _fighting_types = BACKEND_get_ranged_fighting_types();
        let _keys = Object.keys(_fighting_types)
        let _length = _keys.length
        let _id = _fighting_types[_keys[_length-1]].id
        BACKEND_switch_weapon_type(_id, _rangedWeapon.id, 'ranged');
        _matchingSkillWeaponType = GetMatchingSkill(_id , BACKEND_get_ranged_fighting_types());
    }

    if(_rangedWeapon.fighting_type_id == 0)
    {
        //None
    }
    else if(_rangedWeapon.fighting_type_id == 1)
    {
        //Shooting weapon
    }
    else if(_rangedWeapon.fighting_type_id == 2)
    {
        //Throwing weapon
    }

    /*if(_matchingSkillWeaponType.id == 0)
    {
        //None
    }
    else if(_matchingSkillWeaponType.id == 1)
    {
        //(Cross-)bow
    }
    else if(_matchingSkillWeaponType.id == 2)
    {
        //Light throw
    }
    else if(_matchingSkillWeaponType.id == 3)
    {
        //Heavy throw
    }
    else if(_matchingSkillWeaponType.id == 4)
    {
        //Slings
    }
    else if(_matchingSkillWeaponType.id == 5)
    {
        //Blowpipes
    }*/
    
    return _rangedWeapon.precision_mod - Math.round(_armor_restriction) + Math.round((_lead_attribute.value+_matchingSkillWeaponType.value)/2.0);
}

function ModifyWeaponName(_weaponID, _newValue, _type){
    SetListParameterByID(_weaponID, getWeapons(_type), getWeaponSetter(_type), _newValue, 'text', 'id', 'display_name');
}

function ModifyWeaponWeight(_weaponID, _newValue, _type){
    SetListParameterByID(_weaponID, getWeapons(_type), getWeaponSetter(_type), _newValue, 'float', 'id', 'weight');
    RecalcWeapons();
    RecalcWeight();

    /*let _weapon_list = getWeapons(_type);


    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].weight = ParseToFloat(_newValue);
        }
    } 

    setWeapons(_weapon_list, _type);

    RecalcWeapons();
    RecalcWeight();*/
}

function ModifyMeleeWeaponAttackMod (_weaponID, _newValue, _type) {
    let _paramter_name = '';
    if(_type != 'ranged')
    {
        _paramter_name = 'attack_mod';
    }
    else if(_type == 'ranged')
    {
        _paramter_name = 'precision_mod';
    }
    SetListParameterByID(_weaponID, getWeapons(_type), getWeaponSetter(_type), _newValue, 'integer', 'id', _paramter_name);
    RecalcWeapons();

    /*let _weapon_list = getWeapons(_type);

    if(_weapon_list == null)
        return;

    
    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            if(_type != 'ranged')
            {
                _weapon_list[key].attack_mod = ParseToInteger(_newValue);
            }
            else if(_type == 'ranged')
            {
                _weapon_list[key].precision_mod = ParseToInteger(_newValue);
            }
        }
    } 
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();*/
}

function ModifyMeleeWeaponDefenseMod (_weaponID, _newValue, _type) {
    SetListParameterByID(_weaponID, getWeapons(_type), getWeaponSetter(_type), _newValue, 'integer', 'id', 'defense_mod');
    RecalcWeapons();

    /*let _weapon_list = getWeapons(_type);

    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].defense_mod = ParseToInteger(_newValue);
        }
    } 
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();*/
}

function ModifyRangedWeaponPrecisionMod (_weaponID, _newValue) {
    SetListParameterByID(_weaponID, BACKEND_get_ranged_weapons(), BACKEND_set_ranged_weapons, _newValue, 'integer', 'id', 'precision_mod');
    RecalcWeapons();

    /*let _weapon_list = BACKEND_get_ranged_weapons();

    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].precision_mod = ParseToInteger(_newValue);
        }
    } 
    
    BACKEND_set_ranged_weapons();
    RecalcWeapons();*/
}

const ModifyAmmoName = (_weaponID, _newValue, _type) => {
    SetListParameterByID(_weaponID, BACKEND_get_ranged_weapons(), BACKEND_set_ranged_weapons, _newValue, 'text', 'id', 'ammo_name');
    RecalcWeapons();

    /*let _weapon_list = getWeapons(_type);

    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].ammo_name = _newValue;
        }
    } 
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();*/
}

const ModifyAmmo = (_weaponID, _newValue, _type) => {
    SetListParameterByID(_weaponID, BACKEND_get_ranged_weapons(), BACKEND_set_ranged_weapons, _newValue, 'integer', 'id', 'ammo');
    RecalcWeapons();
    RecalcWeight();

    /*let _weapon_list = getWeapons(_type);

    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].ammo = ParseToInteger(_newValue);
        }
    } 
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();
    RecalcWeight();*/
}

const ModifyAmmoWeight = (_weaponID, _newValue, _type) => {
    SetListParameterByID(_weaponID, BACKEND_get_ranged_weapons(), BACKEND_set_ranged_weapons, _newValue, 'float', 'id', 'ammo_weight');
    RecalcWeapons();
    RecalcWeight();

    /*let _weapon_list = getWeapons(_type);

    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].ammo_weight = ParseToFloat(_newValue);
        }
    } 
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();
    RecalcWeight();*/
}

const ModifyDamage = (_weaponID, _newValue, _type) => {
    SetListParameterByID(_weaponID, getWeapons(_type), getWeaponSetter(_type), _newValue, 'float', 'id', 'damage');
    RecalcWeapons();
    
    /*let _weapon_list = getWeapons(_type);

    if(_weapon_list == null)
        return;

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weaponID){
            _weapon_list[key].damage = ParseToFloat(_newValue);
        }
    } 
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();*/
}

function SwitchWeaponType(_fighting_type_id, _weapon_id, _type){
    
    SetListParameterByID(_weapon_id, getWeapons(_type), getWeaponSetter(_type), _fighting_type_id, 'integer', 'id', 'fighting_type_id');
    RecalcWeapons();
}

/*const SwitchHandType = (_hand_type_id, _weapon_id, _type) => {
    SetListParameterByID(_weapon_id, getWeapons(_type), getWeaponSetter(_type), _hand_type_id, 'integer', 'id', 'hand_type_id');
    RecalcWeapons();
}*/

function SwitchLeadAttribute(_lead_attribute, _weapon_id, _type){
    SetListParameterByID(_weapon_id, getWeapons(_type), getWeaponSetter(_type), _lead_attribute.attribute_id, 'integer', 'id', 'lead_attr_id');
    RecalcWeapons();
}

/*function setWeapons(_weaponList, _type) {
    let _setter = getWeaponSetter(_type);
    _setter(_weaponList);
}*/

function getWeapons(_type) {
    let _weapon_list = null;
    if(_type == 'unarmed')
    {
        _weapon_list = BACKEND_get_unarmed_weapons();
    }
    else if(_type == 'melee')
    {
        _weapon_list = BACKEND_get_melee_weapons();
    }
    else if(_type == 'ranged')
    {
        _weapon_list = BACKEND_get_ranged_weapons();
    }
    return _weapon_list;
}


function getWeaponSetter(_type) {

    if(_type == 'unarmed')
    {
        return BACKEND_set_unarmed_weapons;
    }
    else if(_type == 'melee')
    {
        return BACKEND_set_melee_weapons;
    }
    else if(_type == 'ranged')
    {
        return BACKEND_set_ranged_weapons;
    }
    return null;
}

function getWeaponTemplate(_type) {
    let _weapon_template = null;
    if(_type != 'ranged')
    {
        _weapon_template = {...BACKEND_get_melee_weapon_template()};
    }
    else if(_type == 'ranged')
    {
        _weapon_template = {...BACKEND_get_ranged_weapon_template()};
    }
    
    if(_type == 'unarmed')
    {
        _weapon_template.fighting_type_id = 0;
    }
    return _weapon_template;
}

function AddNewWeapon (_type) {

    AddElementToList(getWeapons(_type), getWeaponTemplate(_type), getWeaponSetter(_type));
    RecalcWeapons();
    RecalcWeight();

    /*let _weapon_list = {...getWeapons(_type)};
    let _template = null;
    let _length = Object.keys(_weapon_list).length;

    let _new_id = 0;
    for(let key in _weapon_list){
        if(_weapon_list[key].id > _new_id)
        {
            _new_id = _weapon_list[key].id;
        }
    }
    _new_id += 1;

    if(_type != 'ranged')
    {
        _template = {...BACKEND_get_melee_weapon_template()};
    }
    else if(_type == 'ranged')
    {
        _template = {...BACKEND_get_ranged_weapon_template()};
    }
    _template.id = _new_id;
    if(_type == 'unarmed')
    {
        _template.fighting_type_id = 0;
    }
    _weapon_list[_length] = _template;
    
    setWeapons(_weapon_list, _type);
    RecalcWeapons();
    RecalcWeight();*/
}

function RemoveWeapon (_weapon, _type) {
    RemoveElementFromList(_weapon.id, getWeapons(_type), getWeaponSetter(_type));
    RecalcWeapons();
    RecalcWeight();
    /*let _weapon_list = {...getWeapons(_type)};

    for(let key in _weapon_list){
        if(_weapon_list[key].id == _weapon.id)
        {
            delete _weapon_list[key];
        }
    }

    setWeapons(_weapon_list, _type);
    RecalcWeapons();
    RecalcWeight();*/
}

function MoveWeapon (_weapon, _direction, _type) {
    MoveElementInList(_weapon.id, getWeapons(_type), getWeaponSetter(_type), _direction, 'id', true);
    /*let _weapon_list = getWeapons(_type);

    let _key_before = null;
    let _key_middle = null;
    let _key_after = null;

    let _tempValue_1 = null;
    let _tempValue_2 = null;

    let _run_before = true;
    let _run_after = false;

    let key_1 = null;
    let key_2 = null;

    for(let key in _weapon_list){
        if(_run_after)
        {
            _key_after = key;
            _run_after = false;
        }

        if(_weapon_list[key].id == _weapon.id)
        {
            _key_middle = key;
            _run_before = false;
            _run_after = true;
        }

        if(_run_before)
            _key_before = key;
    }

    if(_direction == 'up')
    {
        if(_key_before == null || _key_middle == null)
            return

        key_1 = _key_before;
        key_2 = _key_middle;

        if(_type == 'unarmed')
        {
            if(_weapon_list[key_1].id == 0)
                return;
        }
    }
    else if(_direction == 'down')
    {
        if(_key_after == null || _key_middle == null)
            return

            key_1 = _key_after;
            key_2 = _key_middle;
    }

    
    _tempValue_1 = _weapon_list[key_1];
    _tempValue_2 = _weapon_list[key_2]; 
    
    _weapon_list[key_2] = _tempValue_1;
    _weapon_list[key_1] = _tempValue_2;

    setWeapons(_weapon_list, _type);
    RecalcWeapons();*/
}

export {
    CalculateAttack,
    CalculateDefense,
    CalculatePrecision,
    SwitchWeaponType,
    //SwitchRangedWeaponType,
    //SwitchHandType,
    ModifyWeaponName,
    ModifyWeaponWeight,
    ModifyMeleeWeaponAttackMod,
    ModifyMeleeWeaponDefenseMod,
    ModifyRangedWeaponPrecisionMod,
    ModifyAmmoName,
    ModifyAmmo,
    ModifyAmmoWeight,
    ModifyDamage,
    SwitchLeadAttribute,
    AddNewWeapon,
    RemoveWeapon,
    MoveWeapon
}

