import {
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_free_skill_points,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_get_unarmed_weapons,
    BACKEND_set_unarmed_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_get_ranged_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_money_list,
    BACKEND_set_money_list,
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_melee_weapon_template,
    BACKEND_get_ranged_weapon_template
} from '../Parameters/CharacterParameters'
import {
    BACKEND_get_campaign_id,
    BACKEND_set_campaign_id,
    BACKEND_get_campaign_name,
    BACKEND_set_campaign_name,
    BACKEND_get_campaign_description,
    BACKEND_set_campaign_description,
    BACKEND_get_subscribed_user,
    BACKEND_set_subscribed_user,
    BACKEND_get_monster_list, 
    BACKEND_set_monster_list
} from '../Parameters/CampaignParameters.js'
import {
    INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction
} from '../ParametersInit/AttributesInit.js'
import { 
    INIT_get_skills,
    INIT_get_free_skill_points
 } from "../ParametersInit/SkillParametersInit.js";
import {
    CalculateAttribute, 
    GetHitpoints,
    GetLoadCapacity,
    GetEndurance,
    GetKhoTal,
    //GetInitiative,
    //GetDodgeValue,
    GetFreeAttributePoints, 
    GetFreeFocusPoints,
    GetFreeSkillPoints,
    GetSkillPointsAttribute,
    GetFocusCost,
    GetMaxAttributeIncrease,
    GetMaxSkillIncrease,
    CalculateSkill,
    ConvertAttributes, 
    ConvertAttributesBack
} from './CharacterFunctions'
import {
    BACKEND_get_character_file_name,
    BACKEND_set_upload_state,
    BACKEND_get_upload_state
} from '../Parameters/GameData'
/*import {
    LoadCharacter
} from './LoadCharacter'*/
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from '../Parameters/ToolData'
import {
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_get_character_id,
    BACKEND_set_character_id
} from '../Parameters/UserData'
import {
    Translate
} from '../Lang/Translator'
import {
    GetDiceList,
} from '../DiceRoller'
import {
    ParseToNumber,
    GetElementById,
    RollSingleDice,
    AdjustObjToRef,
    CloneObj
} from '../../API/Functions/GeneralFunctions'
import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeight,
    RecalcWeapons,
    RecalcArmor,
    RecalcMonsterList
} from './RecalcFunctions'
import {
    BACKEND_increment_level, 
    BACKEND_update_base_stat, 
    BACKEND_increment_attribute,
    BACKEND_increment_skill,
    //BACKEND_increment_ancestry_bonus,
    //BACKEND_increment_other_attribute_bonus,
    BACKEND_increment_focus,
    BACKEND_get_character_file,
    BACKEND_upload_character,
    BACKEND_init_character,
    BACKEND_switch_language,
    BACKEND_recalc_attribute_points,
    BACKEND_recalc_focus_points,
    BACKEND_recalc_skill_points,
    BACKEND_recalc_attributes,
    BACKEND_change_hitpoints,
    BACKEND_increment_kho_tal,
    BACKEND_modify_character_stat_bonus,
    BACKEND_get_attribute_by_id,
    BACKEND_get_attribute_group_by_id,
    BACKEND_increment_free_focus_points_bonus,
    BACKEND_increment_free_skill_points_bonus,
    BACKEND_increment_free_attribute_points_bonus,
    BACKEND_init_campaign
} from '../BackendRouter'

import{
    CheckCompatibility
} from './IORevisionHandler.js'

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder

const PrepareCharacterForDownload = () => 
{
    const _character = {
        id: BACKEND_get_character_id(),
        type: 'Character',
        tool_data: BACKEND_get_tool_data(),
        base_stats: BACKEND_get_base_stats(),
        money_list: BACKEND_get_money_list(),
        free_points_bonus:{
            focus: BACKEND_get_free_focus_points().bonus,
            attribute: BACKEND_get_free_attribute_points().bonus,
            skill: BACKEND_get_free_skill_points().bonus
        },
        comments: BACKEND_get_comments(),
        attribute_groups: BACKEND_get_attribute_groups(), 
        attributes: BACKEND_get_attributes(),
        skills: BACKEND_get_skills(),
        feats: {
            level_2: BACKEND_get_feat_lv_2(),
            level_3: BACKEND_get_feat_lv_3()
        },
        magic_spells: BACKEND_get_magic_spells(),
        character_stats: BACKEND_get_character_stats(),
        //secondary_attributes: BACKEND_get_secondary_attributes(),
        weapons: {
            unarmed: BACKEND_get_unarmed_weapons(),
            melee: BACKEND_get_melee_weapons(),
            ranged: BACKEND_get_ranged_weapons()
        },
        armor: BACKEND_get_armor(),
        gear: BACKEND_get_gear()
    }

    return _character;
}

const CleanOlderVersionRelics = (_list, _type) => {
    let _refList = null;
    if(_type == 'unarmed')
    {
        _refList = CloneObj(BACKEND_get_melee_weapon_template(), true);
        _refList.fighting_type_id = 0;
    }
    else if(_type == 'melee')
    {
        _refList = CloneObj(BACKEND_get_melee_weapon_template(), true);
    }
    else if(_type == 'ranged')
    {
        _refList = CloneObj(BACKEND_get_ranged_weapon_template(), true);
    }

    for(let key in _list){
        _list[key] = AdjustObjToRef(_list[key], _refList)
    }
    
    return _list;
}

const ValidateCharacter = (_character) => {
    if(!_character.hasOwnProperty("type"))
        _character.type = "Character"

    if(_character.type == 'Campaign')
        return false;    
    /*try{
        let _parsed_character = JSON.parse(_character);
    }
    catch{
        return false;
    }*/

    if(!_character.hasOwnProperty("tool_data"))
        return false;

    /*if(!_character.hasOwnProperty("base_stats"))
        return false;

    if(_character.base_stats.hasOwnProperty("gender"))
        _character.base_stats = '';

    if(_character.base_stats.hasOwnProperty("skin_color"))
        _character.skin_color = '';*/


    return true;
}

const LoadCharacter = (_character) => {

    if(!ValidateCharacter(_character))
        return;

    let _language = BACKEND_get_tool_data().language;
    BACKEND_init_character(_language)
    //BACKEND_init_character(_character.tool_data.language);

    //Init Character
    if(!_character.hasOwnProperty("id"))
        _character.id = -1;

    BACKEND_set_character_id(_character.id);
    BACKEND_set_base_stats({..._character.base_stats, level: 1});

    //Adjust character if imported from older version
    _character = CheckCompatibility(_character);

    //Import
    BACKEND_set_comments(_character.comments);
    BACKEND_set_free_attribute_points({...BACKEND_get_free_attribute_points()});
    BACKEND_set_free_focus_points({...BACKEND_get_free_focus_points()});
    BACKEND_set_free_skill_points({...BACKEND_get_free_skill_points()});
    
    if(_character.free_points_bonus.focus < 0)
    {
        for(var i = 0; i > _character.free_points_bonus.focus; i--)
        {
            BACKEND_increment_free_focus_points_bonus(-1)
        }
    }

    if(_character.free_points_bonus.focus > 0)
    {      
        for(var i = 0; i < _character.free_points_bonus.focus; i++)
        {
            BACKEND_increment_free_focus_points_bonus(1)
        }
    }

    if(_character.free_points_bonus.attribute < 0)
    {
        for(var i = 0; i > _character.free_points_bonus.attribute; i--)
        {
            BACKEND_increment_free_attribute_points_bonus(-1)
        }
    }

    if(_character.free_points_bonus.attribute > 0)
    {      
        for(var i = 0; i < _character.free_points_bonus.attribute; i++)
        {
            BACKEND_increment_free_attribute_points_bonus(1)
        }
    }

    if(_character.free_points_bonus.skill < 0)
    {
        for(var i = 0; i > _character.free_points_bonus.skill; i--)
        {
            BACKEND_increment_free_skill_points_bonus(-1)
        }
    }

    if(_character.free_points_bonus.skill > 0)
    {      
        for(var i = 0; i < _character.free_points_bonus.skill; i++)
        {
            BACKEND_increment_free_skill_points_bonus(1)
        }
    }

    RecalcFreeAttributePoints();
    RecalcFreeFocusPoints();
    RecalcFreeSkillPoints();

    let _cleanedUnarmedWeapons = CleanOlderVersionRelics(_character.weapons.unarmed, 'unarmed');
    let _cleanedMeleeWeapons = CleanOlderVersionRelics(_character.weapons.melee, 'melee');
    let _cleanedRangedWeapons = CleanOlderVersionRelics(_character.weapons.ranged, 'ranged');
    BACKEND_set_unarmed_weapons(_cleanedUnarmedWeapons);
    BACKEND_set_melee_weapons(_cleanedMeleeWeapons);
    BACKEND_set_ranged_weapons(_cleanedRangedWeapons);

    BACKEND_set_armor(_character.armor);
    BACKEND_set_gear(_character.gear);

    BACKEND_set_feat_lv_2(_character.feats.level_2);
    BACKEND_set_feat_lv_3(_character.feats.level_3);
    
    BACKEND_set_magic_spells(_character.magic_spells);

    BACKEND_set_money_list(_character.money_list);

    for(var i = 0; i < _character.base_stats.level-1; i++)
    {
        BACKEND_increment_level(1);
    }

    for(var key in _character.attribute_groups){
        let _init_attribute_group = BACKEND_get_attribute_group_by_id(_character.attribute_groups[key].id);

        if(_init_attribute_group.focus == 0)
        {
            if(_character.attribute_groups[key].focus < 0)
            {
                for(var i = 0; i > _character.attribute_groups[key].focus; i--)
                {
                    BACKEND_increment_focus(_character.attribute_groups[key].id, -1)
                }
            }
        }
    }
    for(var key in _character.attribute_groups){
        let _init_attribute_group = BACKEND_get_attribute_group_by_id(_character.attribute_groups[key].id);

        if(_init_attribute_group.focus == 0)
        {
            if(_character.attribute_groups[key].focus > 0)
            {
                for(var i = 0; i < _character.attribute_groups[key].focus; i++)
                {
                        BACKEND_increment_focus(_character.attribute_groups[key].id, 1)
                }
            }
        }
        else if(_init_attribute_group.focus == -1)
        {
            if(_character.attribute_groups[key].focus >= 0)
            {
                for(var i = 0; i <= _character.attribute_groups[key].focus; i++)
                {
                        BACKEND_increment_focus(_character.attribute_groups[key].id, 1)
                }
            }
        }
    }



    RecalcAttributes();
    //BACKEND_set_attributes(_character.attributes);

    for(var key in _character.attributes){
        if(_character.attributes[key].increase < 0)
        {
            for(var i = 0; i > _character.attributes[key].increase; i--)
            {
                BACKEND_increment_attribute(_character.attributes[key].id, -1, 'increase', false);
            }
        }
    }
    for(var key in _character.attributes){
        if(_character.attributes[key].increase > 0)
        {
            for(var i = 0; i < _character.attributes[key].increase; i++)
            {
                BACKEND_increment_attribute(_character.attributes[key].id, 1, 'increase', false);
            }
        }
    }

    for(var key in _character.attributes){
        if(_character.attributes[key].ancestry_bonus < 0)
        {
            for(var i = 0; i > _character.attributes[key].ancestry_bonus; i--)
            {
                BACKEND_increment_attribute(_character.attributes[key].id, -1, 'ancestry', false);
            }
        }
    }
    for(var key in _character.attributes){
        if(_character.attributes[key].ancestry_bonus > 0)
        {
            for(var i = 0; i < _character.attributes[key].ancestry_bonus; i++)
            {
                BACKEND_increment_attribute(_character.attributes[key].id, 1, 'ancestry', false);
            }
        }
    }

    for(var key in _character.attributes){
        if(_character.attributes[key].other_bonus < 0)
        {
            for(var i = 0; i > _character.attributes[key].other_bonus; i--)
            {
                BACKEND_increment_attribute(_character.attributes[key].id, -1, 'other', false);
            }
        }
    }
    for(var key in _character.attributes){
        if(_character.attributes[key].other_bonus > 0)
        {
            for(var i = 0; i < _character.attributes[key].other_bonus; i++)
            {
                BACKEND_increment_attribute(_character.attributes[key].id, 1, 'other', false);
            }
        }
    }

    RecalcAttributes();
    RecalcSkills();

    let _attributeID = 0;
    let _skillID = 0;
    for(var key1 in _character.skills){
        _attributeID = _character.skills[key1].skill_group_id;
        for(var key2 in _character.skills[key1].skills){
            _skillID = _character.skills[key1].skills[key2].id;
            if(_character.skills[key1].skills[key2].increase < 0)
            {
                for(var i = 0; i > _character.skills[key1].skills[key2].increase; i--)
                {
                    BACKEND_increment_skill(_attributeID, _skillID, -1, 'increase', false);
                }
            }
        }
    }
    for(var key1 in _character.skills){
        _attributeID = _character.skills[key1].skill_group_id;
        for(var key2 in _character.skills[key1].skills){
            _skillID = _character.skills[key1].skills[key2].id;
            if(_character.skills[key1].skills[key2].increase > 0)
            {
                for(var i = 0; i < _character.skills[key1].skills[key2].increase; i++)
                {
                    BACKEND_increment_skill(_attributeID, _skillID, 1, 'increase', false);
                }
            }
        }
    }

    for(var key1 in _character.skills){
        _attributeID = _character.skills[key1].skill_group_id;
        for(var key2 in _character.skills[key1].skills){
            _skillID = _character.skills[key1].skills[key2].id;
            if(_character.skills[key1].skills[key2].other_bonus < 0)
            {
                for(var i = 0; i > _character.skills[key1].skills[key2].other_bonus; i--)
                {
                    BACKEND_increment_skill(_attributeID, _skillID, -1, 'other', false);
                }
            }
        }
    }
    for(var key1 in _character.skills){
        _attributeID = _character.skills[key1].skill_group_id;
        for(var key2 in _character.skills[key1].skills){
            _skillID = _character.skills[key1].skills[key2].id;
            if(_character.skills[key1].skills[key2].other_bonus > 0)
            {
                for(var i = 0; i < _character.skills[key1].skills[key2].other_bonus; i++)
                {
                    BACKEND_increment_skill(_attributeID, _skillID, 1, 'other', false);
                }
            }
        }
    }

    for(var key1 in _character.skills){
        _attributeID = _character.skills[key1].skill_group_id;
        for(var key2 in _character.skills[key1].skills){
            _skillID = _character.skills[key1].skills[key2].id;
            if(_character.skills[key1].skills[key2].ancestry_mod < 0)
            {
                for(var i = 0; i > _character.skills[key1].skills[key2].ancestry_mod; i--)
                {
                    BACKEND_increment_skill(_attributeID, _skillID, -1, 'ancestry', false);
                }
            }
        }
    }
    for(var key1 in _character.skills){
        _attributeID = _character.skills[key1].skill_group_id;
        for(var key2 in _character.skills[key1].skills){
            _skillID = _character.skills[key1].skills[key2].id;
            if(_character.skills[key1].skills[key2].ancestry_mod > 0)
            {
                for(var i = 0; i < _character.skills[key1].skills[key2].ancestry_mod; i++)
                {
                    BACKEND_increment_skill(_attributeID, _skillID, 1, 'ancestry', false);
                }
            }
        }
    }

    BACKEND_modify_character_stat_bonus(0,_character.character_stats.hitpoints.bonus);
    BACKEND_modify_character_stat_bonus(1,_character.character_stats.load_capacity.bonus);
    BACKEND_modify_character_stat_bonus(2,_character.character_stats.endurance.bonus);
    BACKEND_modify_character_stat_bonus(3,_character.character_stats.kho_tal.bonus);

    for(var i = 0; i < _character.character_stats.kho_tal.lost; i++)
    {
        BACKEND_increment_kho_tal(1);
    }
    

    //BACKEND_modify_secondary_attribute_bonus(0,_character.secondary_attributes.initiative.bonus);
    //BACKEND_modify_secondary_attribute_bonus(1,_character.secondary_attributes.dodge.bonus);

    RecalcAttributes();
    RecalcSkills();
    RecalcArmor();
    RecalcWeapons();
    RecalcWeight();

    BACKEND_change_hitpoints(-_character.character_stats.hitpoints.lost);
}

const GetCharacterFile = () => 
{
    let _character = PrepareCharacterForDownload();
    return new Blob([JSON.stringify(_character, null, 2)], {type: 'text/plain'});
}

const UploadCharacter = (e, callback) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
      
    fileReader.onload = (e) => {
        var _character = null;
        try{
            _character = JSON.parse(e.target.result);
        }
        catch{
            return;
        }
        /*for(var key in _character.attributes)
        {
            if (typeof _character.attributes[key] !== 'undefined') {
                _character.attributes[key].value = CalculateAttribute(_character.attributes[key]);
            }
        }*/

        LoadCharacter(_character);
        callback();
    }
    fileReader.onError = () => {
        console.log('oops, something went wrong with the file reader.')
    }
}

const PrepareCampaignForDownload = () => {
    const _campaign = {
        id: BACKEND_get_campaign_id(),
        owner: BACKEND_get_user().name,
        type: 'Campaign',
        tool_data: BACKEND_get_tool_data(),
        name: BACKEND_get_campaign_name(),
        description: BACKEND_get_campaign_description(),
        //subscribed_user: BACKEND_get_subscribed_user(),
        combat_monster_list: BACKEND_get_monster_list()
    }

    return _campaign;
}
const ValidateCampaign = (_campaign) => {
    if(!_campaign.hasOwnProperty("type"))
        return false;    

    if(_campaign.type != 'Campaign')
        return false;    
    /*try{
        let _parsed_character = JSON.parse(_character);
    }
    catch{
        return false;
    }*/

    if(!_campaign.hasOwnProperty("tool_data"))
        return false;

    return true;
}

const LoadCampaign = (_campaign) => {
    
    if(!ValidateCampaign(_campaign))
        return;

    let _language = BACKEND_get_tool_data().language;
    BACKEND_init_campaign(_language);
    BACKEND_set_campaign_id(_campaign.id);
    BACKEND_set_campaign_name(_campaign.name);
    BACKEND_set_campaign_description(_campaign.description);
    BACKEND_set_subscribed_user(_campaign.subscribed_user);
    BACKEND_set_monster_list(_campaign.combat_monster_list);

    RecalcMonsterList();
}

const GetCampaignFile = () => 
{
    let _campaign = PrepareCampaignForDownload();
    return new Blob([JSON.stringify(_campaign, null, 2)], {type: 'text/plain'});
}

const UploadCampaign = (e, callback) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
      
    fileReader.onload = (e) => {
        var _campaign = null;
        try{
            _campaign = JSON.parse(e.target.result);
        }
        catch{
            return;
        }
        /*for(var key in _character.attributes)
        {
            if (typeof _character.attributes[key] !== 'undefined') {
                _character.attributes[key].value = CalculateAttribute(_character.attributes[key]);
            }
        }*/
        LoadCampaign(_campaign);
        callback();
    }
    fileReader.onError = () => {
        console.log('oops, something went wrong with the file reader.')
    }
}

export {
    PrepareCharacterForDownload,
    GetCharacterFile,
    UploadCharacter,
    LoadCharacter,
    PrepareCampaignForDownload,
    GetCampaignFile,
    UploadCampaign,
    LoadCampaign,
}