import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext,ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext,TextDataContextProvider} from '../API/TextDataContext'
import {ScreenDataContext,ScreenDataContextProvider} from '../API/ScreenDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../API/CampaignContext'
import {CheckIfKeyIsContained, IsWhitespace} from '../API/Functions/GeneralFunctions'
import label_style from '../StylesStylish/label.module.css'

const SideBarStylish = () => {
    const {
        API_switch_language,
        API_get_language_list,
        API_init_character,
        API_update_base_stat,
        API_update_campaign_name
    } = useContext(APIContext);

    const {
        API_get_campaign_name,
        API_get_campaign_id
    } = useContext(CampaignContext);
    
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_active_main_screen,
        API_set_active_main_screen,
        API_get_active_screen,
        API_set_active_screen
    } = useContext(ScreenDataContext);

    const {
        API_get_tool_data,
        API_set_tool_data,
        API_get_dice_log,
        API_get_dice_log_visibility,
        API_set_dice_log_visibility
    } = useContext(ToolDataContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels
    } = useContext(TextDataContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_character_id,
        API_set_character_id
        //API_get_user_mode,
        //API_set_user_mode
    } = useContext(UserDataContext);

    const linkLabelClass = (_screen) => {
        return API_get_active_main_screen() == _screen ? label_style.link_label_active : label_style.link_label
    }

    const [isHovered, setIsHovered] = useState(false);

    const fontSizeHeader = 24;
    const fontSizeLabel = 18;
    
    /*const switchLanguage = (_language) => {
        API_switch_language(_language);
    }*/

    const labelStyle = {
        color: isHovered ? 'blue' : 'grey', // Ändere die Farbe hier
        // Weitere Stile hier hinzufügen
    };

    const getUserName = () => {
        if(API_get_user() == null)
            return 'None'

        if(CheckIfKeyIsContained('name', API_get_user())){
            return API_get_user().name;
        }
        
        return 'None';
    }

    const switchScreen = (_screen) => {
        API_set_active_main_screen(_screen);
    }

    return ( 
        <div style={{margin: '25px 5px 5px 15px', padding: '0px 15px 0px 0px'}}>
            <label style={{color: 'white', fontSize: fontSizeHeader, fontWeight: 'bold'}}>{getUserName()}</label>
            <label className={linkLabelClass('campaign')} style={{display: 'block', fontSize: fontSizeLabel, marginTop: 45}} onClick={() => switchScreen('campaign')}>{API_get_tab_view_labels().campaign}</label>
            <label className={linkLabelClass('character')} style={{display: 'block', fontSize: fontSizeLabel, marginTop: 5}} onClick={() => switchScreen('character')}>{API_get_tab_view_labels().sheet}</label>
            <label className={linkLabelClass('remote_characters')} style={{display: 'block', fontSize: fontSizeLabel, marginTop: 5}} onClick={() => switchScreen('remote_characters')}>{API_get_tab_view_labels().characters}</label>
            <label className={linkLabelClass('remote_campaigns')} style={{display: 'block', fontSize: fontSizeLabel, marginTop: 5}} onClick={() => switchScreen('remote_campaigns')}>{API_get_tab_view_labels().campaigns}</label>
            <label className={linkLabelClass('rules')} style={{display: 'block', fontSize: fontSizeLabel, marginTop: 5}} onClick={() => switchScreen('rules')}>{API_get_tab_view_labels().rules}</label>
            <label className={linkLabelClass('settings')} style={{display: 'block', fontSize: fontSizeLabel, marginTop: 5}} onClick={() => switchScreen('settings')}>{API_get_tab_view_labels().settings}</label>
        </div>
    );
}

export default SideBarStylish;