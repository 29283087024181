import { useState, useContext, useEffect } from 'react'
import campaignDefault from '../Rss/Images/campaign_default.png';
import {CampaignContext} from '../API/CampaignContext'
import {UserDataContext} from '../API/UserDataContext'
import CampaignSheetHeader from './SubPagesCampaignSheet/CampaignSheetHeader'
import CharacterSelectionTable from './SubPagesCharacterSelectionSheet/CharacterSelectionTable'
import UploadImageButton from './ComponentsStylish/Buttons/UploadImageButton';
import ImageContainer from './ComponentsStylish/ImageContainer';


const CampaignSheetStylish = () => {
    const {
        API_get_user, 
        API_set_user
    } = useContext(UserDataContext);

    const {
        API_set_campaign_image,
        API_get_campaign_image,
        API_get_campaign_id
    } = useContext(CampaignContext)


    const getUserRole = () => {
        let role = API_get_user().role;
        return role;
    }

    return ( 
        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{marginRight: 10, marginTop: 5}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <ImageContainer image={API_get_campaign_image()} default_image={campaignDefault} width={400} max_height={400} />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{order: 1}}>
                                <label style={{marginTop: 2, fontSize: 11}}>ID: {API_get_campaign_id()}</label>
                            </div>
                            <div style={{order: 2}}>
                                {getUserRole() == 'game_master' &&
                                    <UploadImageButton image_setter={API_set_campaign_image}/>
                                }
                            </div>
                        </div>
                        {/*<img src={campaignDefault} alt="Super Check" style={{ float: 'left', width: '400px', border: '2px solid black' }} />*/}
                    </div>
                </div>
                <div>
                    <CampaignSheetHeader />
                </div>
            </div>
            <div style={{marginTop: 15}}>
                <CharacterSelectionTable display_subscribed_characters = {true}/>
            </div>
        </div>
    );
}

export default CampaignSheetStylish;