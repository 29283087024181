import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../../API/API'
import {ScreenDataContext} from '../../API/ScreenDataContext'
import TabElement from '../ComponentsStylish/TabElement'
import label_style from '../../StylesStylish/label.module.css'
import field_style from '../../StylesStylish/field.module.css'
import table_style from '../../StylesStylish/table.module.css'
import btn_style from '../../StylesStylish/btn.module.css'

const TabBar = (props) => {
    const { tab_elements = [{content: 'default', screen: 'default'}], screen_setter = null, screen_getter = null, width = 353} = props;

    const {
        API_get_active_sheet_screen,
        API_set_active_sheet_screen,
    } = useContext(ScreenDataContext);

    let _tab_elements_count = Object.keys(tab_elements).length;
    let _actual_width = width/_tab_elements_count

    return ( 
        <div style={{display: 'inline-block'}}>
            <table className={table_style.tab_table}>
                <tr>
                    {Object.entries(tab_elements).map(([key, value], index) => (
                        <th style={{minWidth: _actual_width, width: _actual_width}}>
                            <TabElement content={value.content} screen={value.screen} screen_getter={screen_getter} screen_setter={screen_setter} />
                        </th>
                    ))}
                </tr>
            </table>
        </div>
     );
}
 
export default TabBar