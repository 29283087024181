import { createRef, useContext } from 'react'
import {MessageBoxContext} from '../../API/MessageBoxContext'
import {TextDataContext} from '../../API/TextDataContext'
import btn_style from '../../StylesStylish/btn.module.css'
import container_style from '../../StylesStylish/container.module.css'


const YesNoMessageBox = (props) => {
    const {
        API_get_yes_no_message_box_visibility,
        API_get_message_box_content,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);

    const yesHandler = () => {
        let _function = API_get_confirm_function();
        _function();
        API_close_yes_no_message_box();
    }

    const noHandler = () => {
        API_close_yes_no_message_box();
    }

    return ( 
        <div className={container_style.message_box_container}>
            <div className={container_style.message_box}>
                <div style={{display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
                    <div dangerouslySetInnerHTML={{__html: API_get_message_box_content()}}></div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
                    <button className={btn_style.std_button} style={{display: 'inline-block', color: 'white', marginRight: 15, width: 100, height: 30}} onClick={yesHandler}>{API_get_std_labels().yes}</button>
                    <button className={btn_style.std_button} style={{color: 'white', width: 100, height: 30}} onClick={noHandler}>{API_get_std_labels().no}</button>
                </div>
            </div>
        </div>
     );
}
 
export default YesNoMessageBox;