import {
    INIT_get_guest_user,
    INIT_get_character_owner,
    INIT_get_character_id
} from '../ParametersInit/UserDataInit'

var user = null;
var character_owner = INIT_get_character_owner();
var character_id = INIT_get_character_id();
var guest_user = INIT_get_guest_user();

const BACKEND_get_user = () => {
    return user;
}

const BACKEND_set_user = (_user) => {
    user = _user;
}

const BACKEND_set_user_characters = (_characters) => {
    user.characters = _characters;
}

const BACKEND_set_user_campaigns = (_campaigns) => {
    user.campaigns = _campaigns;
}

const BACKEND_get_character_owner = () => {
    return character_owner;
}

const BACKEND_set_character_owner = (_character_owner) => {
    character_owner = _character_owner;
}

const BACKEND_get_character_id = () => {
    return character_id;
}

const BACKEND_set_character_id = (_character_id) => {
    character_id = _character_id;
}

const BACKEND_get_guest_user= () => {
    return guest_user;
}

const BACKEND_set_guest_user = (_user) => {
    guest_user = _user;
}

export{
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_get_character_owner,
    BACKEND_set_character_owner,
    BACKEND_get_character_id,
    BACKEND_set_character_id,
    BACKEND_get_guest_user,
    BACKEND_set_guest_user,
    BACKEND_set_user_characters,
    BACKEND_set_user_campaigns
}