import { createRef, useContext, useState } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {RestUserContext} from '../../../ServerConnection/RestUserContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import uploadIcon from '../../../Rss/Images/icons/upload_cloud.png';
import { Tooltip } from 'react-tooltip'
import OverlayMessageField from '../OverlayMessageField'
import { IsNullOrEmpty } from '../../../API/Functions/GeneralFunctions'

const RemoteUploadButton = (props) => {
    const { width = 48, height = 24 } = props;
    const [responseMessage, setResponseMessage] = useState('')
    const [responseMessageSuccess, setResponseMessageSuccess] = useState(false)
    

    const {
        API_get_character_file_name,
        API_get_character_file,
        API_get_campaign_file_name,
        API_get_campaign_file
    } = useContext(APIContext);

    const {
        API_get_user
    } = useContext(UserDataContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);

    
    const {
        REST_save_character,
        REST_save_campaign
    } = useContext(RestUserContext);


    const handleRemoteSave = () => {
        let _user_role = API_get_user().role;
        if(_user_role == 'player')
        {
            REST_save_character(setResponseMessage, setResponseMessageSuccess);
        }
        else if(_user_role == 'game_master')
        {
            REST_save_campaign(setResponseMessage, setResponseMessageSuccess);
        }
    }

    const getOverlayVisibility = () => {
        if(IsNullOrEmpty(responseMessage))
            return false;

        return true;
    }

    return ( 
        <div>
            <div style={{display:'inline-block', position: 'relative'}}>
                <button className={btn_style.std_button} data-tooltip-id="btn-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().save} onClick={handleRemoteSave} style={{width: width, minWidth: width, height: height}}>
                    <div style={{display: 'inline-block', verticalAlign: 'middle', alignItems: 'center'}}>
                        <img src={uploadIcon} alt="Bild" style={{ float: 'left', width: '22px' }} />
                    </div>
                </button>
                <Tooltip id="btn-tooltip" />
                { getOverlayVisibility() &&
                    <OverlayMessageField position={'left'}
                                         variableWidth={true}
                                         parentWidth={width}
                                         parentHeight={height}
                                         success={responseMessageSuccess}
                                         content={responseMessage}/>
                }
            </div>
        </div>
     );
}
 
export default RemoteUploadButton;