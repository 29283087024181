import { useContext } from 'react'
import { APIContext } from '../../../API/API'
import { AttributeContext } from '../../../API/AttributeContext'
import { TextDataContext } from '../../../API/TextDataContext'
import { BaseStatContext } from '../../../API/BaseStatContext'
import { SkillContext } from '../../../API/SkillContext'
import { MagicContext } from '../../../API/MagicContext'
import {
    ParseToNumber,
    GetElementById
} from '../../../API/Functions/GeneralFunctions'
import TabBar from '../../ComponentsStylish/TabBar';
import DiceButton from '../../ComponentsStylish/Buttons/DiceButton';
import {InputField} from '../../ComponentsStylish/InputField';
import xIcon from '../../../Rss/Images/icons/x.png';
import plusIcon from '../../../Rss/Images/icons/plus_icon.png';
import rightArrowIcon from '../../../Rss/Images/icons/right_arrow.png';
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'

const CharacterSkillSheetTable = (props) => {
    const { attribute_id = 0 } = props;

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_attribute_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_get_foundry_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const {
        API_get_base_stat_labels,
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_skill_group_visibility,
        API_set_skill_group_visibility,
        API_toggle_skill_group_visibility,
        API_get_skills,
        API_get_increase_selector,
        API_set_increase_selector,
        API_get_skill_selector,
        API_set_skill_selector,
        API_get_edit_skill_visibility,
        API_get_skill_by_id
    } = useContext(SkillContext);

    const speratorWidth = 13;

    const {
        API_get_max_magic_circle
    } = useContext(MagicContext);

    var _skill_group = GetElementById(attribute_id, API_get_skills(), 'skill_group_id');
    var _attibute = API_get_attribute_by_id(attribute_id);
    //var _attribute_group = API_get_attribute_group_by_id(_attibute.group);

    const incrementSkill = (_skillID, _increment) => {
        let _selector = 'increase'
        API_increment_skill(attribute_id, _skillID, _selector, _increment);
    }

    const incrementAncestry = (_skillID, _increment) => {
        let _selector = 'ancestry'
        API_increment_skill(attribute_id, _skillID, _selector, _increment);
    }

    const incrementOther = (_skillID, _increment) => {
        let _selector = 'other'
        API_increment_skill(attribute_id, _skillID, _selector, _increment);
    }


    /*const click_skill_dice = (_skillID) => {
        var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice(_skill.display_name, _skill.dice, true, 'attribute', _skill.display_name);
    }*/

    const isArcana = () => {
        let _attribute = API_get_attribute_by_id(_skill_group.skill_group_id);

        if (_skill_group.skill_group_id == API_get_attribute_ref().arcana)
            return true;

        return false;
    }

    /*const getVisibility = () => {

        if (!isArcana())
            return true;

        if (isArcana())
            return _attribute_group.focus >= 0;

        return false;
    }*/

    /*const getDiceLabel = (_dice) => {
        console.log(_dice);
        return API_get_dice_list_string(_dice)
    }*/

    const getValueButtonType = () => {
        if (isArcana())
            return true;

        return true;
    }

    const getValueFieldWidth = () => {
        if (isArcana())
            return 18;

        return 18;
    }

    const getIncreaseHeaderLabel = () => {
        if (isArcana())
            return API_get_std_header_labels().circle;

        return API_get_std_header_labels().specialization;
    }

    const getVerticalCellMergeLength = () => {
        //Object.keys(_skill_group.skills).length
        let _counter = 0;
        for(let key in _skill_group.skills){
            if(_skill_group.skills[key].display_name != 'empty')
                _counter++;
        }
        return _counter;
    }

    return (
        <div>
            <table className={table_style.std_table}>
                <tr className={table_style.std_table_sub_header}>
                    <td style={{ width: 200, minWidth: 200, textAlign: 'left', paddingLeft: 5 }}>{API_get_std_header_labels().skill}</td>
                    <td style={{textAlign: 'center'}} colspan={2}>{API_get_table_header_labels().training}</td>
                    <td style={{textAlign: 'center'}}>{getIncreaseHeaderLabel()}</td>
                    <td></td>
                    <td style={{textAlign: 'center'}}>{API_get_base_stat_labels().ancestry}</td>
                    <td></td>
                    <td style={{ textAlign: 'center'}} colspan={2}>{API_get_std_header_labels().influence}</td>
                    <td></td>
                    <td style={{textAlign: 'center'}}>{API_get_std_header_labels().value}</td>
                    <td></td>
                    <td style={{textAlign: 'center'}}>{API_get_std_header_labels().dice}</td>
                </tr>
                <tr style={{height: 4}}>

                </tr>
                {Object.entries(_skill_group.skills).map(([key, value], index) => (
                    <tr>
                        <td style={{ textAlign: 'left', paddingLeft: 5, borderRight: '0px solid black' }}>
                            {value.display_name != 'empty' &&
                                value.display_name
                            }
                        </td>
                        {/*<td style={{ width: 79, minWidth: 79, borderLeft: '0px solid black' }} >
                            {(API_get_edit_character_visibility() && value.display_name != 'empty') &&
                                <div>
                                    <p style={{ display: 'inline', fontSize: 11, marginRight: 4 }}>{API_get_std_header_labels().cost_first_letter}: {value.cost}</p>
                                    <button className={btn_style.button_icon} onClick={() => incrementSkill(_skill_group.skills[key].id, 1)} style={{ width: 20, height: 20, margin: '0px 2px 0px 0px' }}>+</button>
                                    <button className={btn_style.button_icon} onClick={() => incrementSkill(_skill_group.skills[key].id, -1)} style={{ width: 20, height: 20, margin: '0px 0px 0px 0px' }}>-</button>
                                </div>
                            }
                        </td>*/}
                        {index == 0 &&
                            <td style={{ width: 36, minWidth: 36, fontWeight: 'bold', textAlign: 'center' }} rowspan={getVerticalCellMergeLength()}>
                                {value.display_name != 'empty' &&
                                    _attibute.value
                                }
                            </td>
                        }
                        {index >= getVerticalCellMergeLength() &&
                            <td style={{ width: 36, minWidth: 36, fontWeight: 'bold', textAlign: 'center' }} >
                                {value.display_name != 'empty' &&
                                    _attibute.value
                                }
                            </td>
                        }
                        {index == 0 &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center' }} rowspan={getVerticalCellMergeLength()}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 4}} src={plusIcon} alt="Bild" />
                                }
                            </td>
                        }
                        {index >= getVerticalCellMergeLength() &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center' }}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 4}} src={plusIcon} alt="Bild" />
                                }
                            </td>
                        }
                        <td style={{ width: 54, minWidth: 54 }} >
                            {value.display_name != 'empty' &&
                                <InputField 
                                    type='integer' 
                                    labelVisibility={false} 
                                    widthValue={18} 
                                    content={value.increase} 
                                    setter={null} 
                                    buttonOnly={true} 
                                    fontSize={15} 
                                    paddingTop={1} 
                                    paddingBottom={1} 
                                    buttonField={true} 
                                    buttonWidth={20}
                                    overlayMessage={API_get_std_header_labels().cost + ': ' + _skill_group.skills[key].cost}
                                    plusButtonCallback={() => incrementSkill(_skill_group.skills[key].id, 1)} 
                                    minusButtonCallback={() => incrementSkill(_skill_group.skills[key].id, -1)}/>
                            }
                        </td>
                        {index == 0 &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center' }} rowspan={getVerticalCellMergeLength()}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 4}} src={plusIcon} alt="Bild" />
                                }
                            </td>
                        }
                        {index >= getVerticalCellMergeLength() &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center' }}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 4}} src={plusIcon} alt="Bild" />
                                }
                            </td>
                        }
                        <td style={{ width: 40, minWidth: 40 }}>
                            {value.display_name != 'empty' &&
                                <InputField 
                                    type='integer' 
                                    labelVisibility={false} 
                                    widthValue={18} 
                                    content={value.ancestry_mod} 
                                    setter={null} 
                                    buttonOnly={true} 
                                    buttonField={true}  
                                    buttonWidth={20}
                                    fontSize={15} 
                                    paddingTop={1} 
                                    paddingBottom={1}
                                    plusButtonCallback={() => incrementAncestry(_skill_group.skills[key].id, 1)} 
                                    minusButtonCallback={() => incrementAncestry(_skill_group.skills[key].id, -1)}/>
                            }
                        </td>
                        {index == 0 &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center'}} rowspan={getVerticalCellMergeLength()}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 4}} src={plusIcon} alt="Bild" />
                                }
                            </td>
                        }
                        {index >= getVerticalCellMergeLength() &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center'}}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 4}} src={plusIcon} alt="Bild" />
                                }
                            </td>
                        }
                        <td style={{ width: 29, minWidth: 29 }}>
                            {value.display_name != 'empty' &&
                                <InputField 
                                    type='text' 
                                    labelVisibility={false} 
                                    widthValue={50} 
                                    content={API_get_attribute_by_id(value.attr_mod_1_id).abbreviation + '|' + API_get_attribute_by_id(value.attr_mod_2_id).abbreviation} 
                                    fontSize={15} 
                                    textAlign={'center'}
                                    paddingTop={1} 
                                    paddingBottom={1}
                                    setter={null} 
                                    buttonOnly={true}/>
                            }
                        </td>
                        <td style={{ width: 24, minWidth: 24, fontSize: 15, textAlign: 'center' }}>
                            {value.display_name != 'empty' &&
                                API_get_skill_attribute_mod(_skill_group.skill_group_id, value)
                            }
                        </td>
                        {index == 0 &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center'}} rowspan={getVerticalCellMergeLength()}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '12px', verticalAlign: 'middle', marginBottom: 4}} src={rightArrowIcon} alt="Bild" />
                                }
                            </td>
                        }
                        {index >= getVerticalCellMergeLength() &&
                            <td style={{ width: speratorWidth, minWidth: speratorWidth, textAlign: 'center'}}>
                                {value.display_name != 'empty' &&
                                    <img style={{ width: '12px', verticalAlign: 'middle', marginBottom: 4}} src={rightArrowIcon} alt="Bild" />
                                }
                            </td>
                        }
                        {/*index == 0 && //erster Zeile in jeder Gruppe
                            <td style={{ borderTop: '2px solid black', textAlign: 'center' }}>
                                {value.display_name != 'empty' &&
                                    value.value
                                }
                                {value.other_bonus > 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'green' }}> (+{value.other_bonus})</label>}
                                {value.other_bonus < 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'red' }}> ({value.other_bonus})</label>}
                                {value.temporary_mod > 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'green' }}> (+{value.temporary_mod})</label>}
                                {value.temporary_mod < 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'red' }}> ({value.temporary_mod})</label>}
                            </td>
                            */}
                        {/*(index < Object.keys(_skill_group.skills).length - 1 && index > 0) &&*/}
                            <td style={{ textAlign: 'center' }}>
                                {value.display_name != 'empty' &&
                                    <InputField 
                                        type='integer' 
                                        labelVisibility={false} 
                                        widthValue={getValueFieldWidth()} 
                                        content={value.value} 
                                        setter={null} 
                                        buttonOnly={true} 
                                        fontSize={15} 
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        buttonField={getValueButtonType()} 
                                        buttonWidth={20}
                                        plusButtonCallback={() => incrementOther(_skill_group.skills[key].id, 1)} 
                                        minusButtonCallback={() => incrementOther(_skill_group.skills[key].id, -1)}/>
                                }
                            </td>
                            
                        <td style={{minWidth: 18, width: 18, position: 'relative'}}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                                <div style={{textAlign: 'left'}}>
                                    {value.temporary_mod > 0 && value.display_name != 'empty' && 
                                        <label style={{fontSize: 10, color: 'green'}}> (+{value.temporary_mod})</label>
                                    }
                                    {value.temporary_mod < 0 && value.display_name != 'empty' && 
                                        <label style={{fontSize: 10, color: 'red'}}> ({value.temporary_mod})</label>
                                    }
                                </div>
                                <div style={{textAlign: 'left', width: '100%', minWidth: '100%', alignSelf: 'flex-end', marginBottom: 4}}>
                                    {value.other_bonus > 0 && value.display_name != 'empty' && 
                                        <label style={{fontSize: 10, color: 'green'}}> (+{value.other_bonus})</label>
                                    }
                                    {value.other_bonus < 0 && value.display_name != 'empty' && 
                                        <label style={{fontSize: 10, color: 'red'}}> ({value.other_bonus})</label>
                                    }
                                </div>
                            </div>
                        </td>
                        {/**/}
                        {/*(index == Object.keys(_skill_group.skills).length - 1) &&
                            <td style={{ borderLeft: '2px solid black', borderRight: '2px solid black', borderBottom: '2px solid black', textAlign: 'center' }}>
                                {value.display_name != 'empty' &&
                                    value.value
                                }
                                {value.other_bonus > 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'green' }}> (+{value.other_bonus})</label>}
                                {value.other_bonus < 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'red' }}> ({value.other_bonus})</label>}
                                {value.temporary_mod > 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'green' }}> (+{value.temporary_mod})</label>}
                                {value.temporary_mod < 0 && value.display_name != 'empty' && <label style={{ fontSize: 9, color: 'red' }}> ({value.temporary_mod})</label>}
                            </td>
                        */}
                        <td>
                            {value.display_name != 'empty' &&
                                <DiceButton width={'100px'} height={'28px'} roll={value.dice}/>
                            }
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
}

export default CharacterSkillSheetTable;