import { useContext } from 'react'
import {APIContext} from '../../API/API'
import {AttributeContext} from '../../API/AttributeContext'
import {ScreenDataContext} from '../../API/ScreenDataContext'
import {TextDataContext} from '../../API/TextDataContext'
import TabBar from '../ComponentsStylish/TabBar';
import MagicSkillSheet from './SubPagesMagicSheet/MagicSkillSheet';
import MagicSpellSheet from './SubPagesMagicSheet/MagicSpellSheet';
import CharacterSkillSheetTableHeader from './SubPagesCharacterSkillSheet/CharacterSkillSheetTableHeader';
import CharacterSkillSheetTable from './SubPagesCharacterSkillSheet/CharacterSkillSheetTable';
import CharacterSkillSheetHeader from './SubPagesCharacterSkillSheet/CharacterSkillSheetHeader';
import circleImg from '../../Rss/Images/Zirkel.png';

const CharacterMagicSheet = () => {

    const {
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        API_get_attribute_group_by_id,
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_skill_group_screen,
        API_set_skill_group_screen,
        API_get_magic_screen,
        API_set_magic_screen,
        API_get_skill_group_tab_name
    } = useContext(ScreenDataContext);

    const {
        API_get_tab_view_labels
    } = useContext(TextDataContext);

    const getTalentTabBar = () => {
        let _groups = API_get_attributes();
        let _mainTabBar = [
            {content: API_get_tab_view_labels().magic_skills, screen: 'magic_skills'},
            {content: API_get_tab_view_labels().magic_spells, screen: 'magic_spells'}
        ];

        return _mainTabBar;
    }

    const getVisibility = () => {
        return API_get_attribute_group_by_id(API_get_attribute_group_ref().magic).focus >= 0 ;
    }

    return ( 
        <div>
            <div style={{marginTop: 20}}>
                <TabBar tab_elements = {getTalentTabBar()} width = {1568} screen_setter={API_set_magic_screen} screen_getter={API_get_magic_screen}  />
            </div>
            { API_get_magic_screen() == 'magic_skills' &&
                <div>
                    <MagicSkillSheet />
                </div>
            }
            { API_get_magic_screen() == 'magic_spells' &&
                <div style={{marginTop: 20}}>
                    <MagicSpellSheet />
                </div>
            }
        </div>
    );
}

export default CharacterMagicSheet;