import { useState, useContext } from 'react'
import {CheckIfKeyIsContained, IsWhitespace, ParseToInteger, ParseToFloat, TryParse} from '../../API/Functions/GeneralFunctions'
import field_style from '../../StylesStylish/field.module.css'
import label_style from '../../StylesStylish/label.module.css'
import checkmarkIcon from '../../Rss/Images/icons/checkmark.png';

const Checkbox = (props) => {
    const { onChange = null, checked = false, width = 15, height = 15} = props;

    const [hover, setHover] = useState(false);

    const handleOnChange = () => {
        if (onChange == null)
            return;

        onChange();
    }

    const handleMouseEnter = () => {
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
    }

    const getBackground = () => {
        if(!hover)
        {
            return 'rgba(160, 160, 160, 0.4)';
        }
        else
        {
            return 'rgba(120, 120, 120, 0.5)';
        }
    }

    const style_normal = () => {
        let _style = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: width,
            mindWidth: width,
            height: height,
            background: getBackground(),
            borderTop: '1px solid rgba(100, 100, 100, 0.8)',
            borderLeft: '1px solid rgba(100, 100, 100, 0.8)',
            borderBottom: '1px solid rgba(240, 240, 240, 0.8)',
            borderRight: '1px solid rgba(240, 240, 240, 0.8)',
            boxShadow: 'inset 0px 1px 0px rgba(240, 240, 240, 0.8), inset 0px -1px 0px rgba(100, 100, 100, 0.8), inset 1px 0px 0px rgba(240, 240, 240, 0.8), inset -1px 0px 0px rgba(100, 100, 100, 0.8)'
        };
        return _style;
    }


    return ( 
        <div>
            <div className={field_style.custom_checkbox} style={style_normal()} onClick={handleOnChange} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {checked &&
                    <img src={checkmarkIcon} alt="Bild" style={{ float: 'left', width: '10px' }} />
                }
            </div>
            
        </div>
    );
}

export default Checkbox;