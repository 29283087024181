import { createRef, useContext, useState } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {MessageBoxContext} from '../../../API/MessageBoxContext'
import {RemoteConnectContext} from '../../../ServerConnection/RemoteConnectContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import diceIcon from '../../../Rss/Images/icons/dice.png';
import { Tooltip } from 'react-tooltip'


const DiceButton = (props) => {
    const { width = '72px', height = '32px', roll = {dice: [4], offset: 0}} = props;

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_attribute_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_get_foundry_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        BACKEND_send_dice_roll,
        BACKEND_open_foundry_connector_window,
        BACKEND_is_foundry_connector_window_active
    } = useContext(RemoteConnectContext);

    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    const {
        API_get_std_labels,
        API_get_message_box_content_labels,
    } = useContext(TextDataContext);
    
    const handleRoll = () => {
        let _content = API_get_message_box_content_labels().open_foundry_connector;
        if(!BACKEND_is_foundry_connector_window_active())
        {
            API_open_yes_no_message_box(_content, BACKEND_open_foundry_connector_window);
        }
        else
        {
            BACKEND_send_dice_roll(getFoundryRollString());
        }
    }

    const getFoundryRollString = () => {
        return API_get_foundry_dice_list_string(roll, true);
    }

    const getRollLabel = () => {
        return API_get_dice_list_string(roll);
    }

    const getFontSize = () => {
        let _stringLabel = getRollLabel();
        let _length = _stringLabel.length;

        if(_length < 9){
            return 12;
        }
        else if(_length < 10){
            return 11;
        }
        else if(_length < 11){
            return 10;
        }
        else if(_length < 12){
            return 9;
        }
        else{
            return 8;
        }
    }

    const getMargin = () => {
        let _stringLabel = getRollLabel();
        let _length = _stringLabel.length;

        if(_length < 9){
            return 4;
        }
        else if(_length < 10){
            return 3;
        }
        else if(_length < 11){
            return 2;
        }
        else if(_length < 12){
            return 1;
        }
        else{
            return 0;
        }
    }

    const getImageVisibility = () =>{
        let _stringLabel = getRollLabel();
        let _length = _stringLabel.length;

        if(_length < 15){
            return true;
        }
        else{
            return false;
        }
    }

    return ( 
        <div>
            <button className={btn_style.std_button} onClick={handleRoll} style={{width: width, minWidth: width, height: height}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{display: 'inline-block', float: 'left', verticalAlign: 'middle', alignItems: 'center', marginRight: getMargin()}}>
                        <img src={diceIcon} alt="Bild" style={{ float: 'left', width: '16px' }} /> 
                    </div>
                    <div style={{display: 'inline-block', float: 'left', verticalAlign: 'middle', alignItems: 'center', display: 'flex', alignItems: 'center'}}>
                        <p style={{fontSize: getFontSize(), margin: 0}}>{getRollLabel()}</p>
                    </div>
                </div>
            </button>

        </div>
     );
}
 
export default DiceButton;