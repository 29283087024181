import { useContext } from 'react'
import {APIContext} from '../../../API/API'
import {AttributeContext} from '../../../API/AttributeContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {SkillContext} from '../../../API/SkillContext'
import {MagicContext} from '../../../API/MagicContext'
import {
    ParseToNumber,
    GetElementById
} from '../../../API/Functions/GeneralFunctions'
import TabBar from '../../ComponentsStylish/TabBar';
import {InputField} from '../../ComponentsStylish/InputField';
import rightArrowIcon from '../../../Rss/Images/icons/right_arrow.png';
import xIcon from '../../../Rss/Images/icons/x.png';
import heartIcon from '../../../Rss/Images/icons/attributes/heart.png';
import chestIcon from '../../../Rss/Images/icons/attributes/chest.png';
import musclesIcon from '../../../Rss/Images/icons/attributes/muscles.png';
import jumpIcon from '../../../Rss/Images/icons/attributes/jump.png';
import coodinationIcon from '../../../Rss/Images/icons/attributes/coordination.png';
import hideIcon from '../../../Rss/Images/icons/attributes/hide.png';
import maskIcon from '../../../Rss/Images/icons/attributes/mask.png';
import chessIcon from '../../../Rss/Images/icons/attributes/chess.png';
import speakIcon from '../../../Rss/Images/icons/attributes/speak.png';
import questionMarkIcon from '../../../Rss/Images/icons/attributes/question_mark.png';
import luteIcon from '../../../Rss/Images/icons/attributes/lute.png';
import leafIcon from '../../../Rss/Images/icons/attributes/leaf.png';
import eyeIcon from '../../../Rss/Images/icons/attributes/eye.png';
import bookIcon from '../../../Rss/Images/icons/attributes/book.png';
import swordIcon from '../../../Rss/Images/icons/attributes/sword.png';
import bowIcon from '../../../Rss/Images/icons/attributes/bow.png';
import magicResistanceIcon from '../../../Rss/Images/icons/attributes/magic_resistance.png';
import starsIcon from '../../../Rss/Images/icons/stars.png';
import councilIcon from '../../../Rss/Images/icons/councilLogo.png';
import fistIcon from '../../../Rss/Images/icons/fist.png';
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'

const CharacterSkillSheetTableHeader = (props) => {
    const { attribute_id = 0 } = props;

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_attribute_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_skill_group_visibility,
        API_set_skill_group_visibility,
        API_toggle_skill_group_visibility,
        API_get_skills,
        API_get_increase_selector,
        API_set_increase_selector,
        API_get_skill_selector,
        API_set_skill_selector,
        API_get_edit_skill_visibility,
        API_get_skill_by_id
    } = useContext(SkillContext);

    const {
        API_get_max_magic_circle
    } = useContext(MagicContext);

    const getIcon = () => {
        if(attribute_id == 0){
            //const _width = 512;
            //const _height = 448;
            const _width = 512;
            const _height = 502;
            return {icon: chestIcon };
        }
        else if(attribute_id == 1){
            const _width = 512;
            const _height = 512;
            return {icon: musclesIcon };
        }
        else if(attribute_id == 2){
            const _width = 442;
            const _height = 512;
            return {icon: jumpIcon };
        }
        else if(attribute_id == 3){
            const _width = 512;
            const _height = 512;
            return {icon: coodinationIcon };
        }
        else if(attribute_id == 4){
            const _width = 512;
            const _height = 334;
            return {icon: hideIcon };
        }
        else if(attribute_id == 5){
            const _width = 512;
            const _height = 278;
            return {icon: maskIcon };
        }
        else if(attribute_id == 6){
            const _width = 326;
            const _height = 512;
            return {icon: chessIcon };
        }
        else if(attribute_id == 7){
            const _width = 512;
            const _height = 428;
            return {icon: speakIcon };
        }
        else if(attribute_id == 8){
            const _width = 380;
            const _height = 512;
            return {icon: questionMarkIcon };
        }
        else if(attribute_id == 9){
            const _width = 512;
            const _height = 512;
            return {icon: luteIcon };
        }
        else if(attribute_id == 10){
            const _width = 512;
            const _height = 284;
            return {icon: eyeIcon };
        }
        else if(attribute_id == 11){
            const _width = 512;
            const _height = 480;
            return {icon: leafIcon };
        }
        else if(attribute_id == 12){
            const _width = 430;
            const _height = 512;
            return {icon: bookIcon };
        }
        else if(attribute_id == 13){
            const _width = 430;
            const _height = 512;
            return {icon: bookIcon };
        }
        else if(attribute_id == 14){
            const _width = 430;
            const _height = 512;
            return {icon: bookIcon };
        }
        else if(attribute_id == 15){
            const _width = 430;
            const _height = 512;
            return {icon: bookIcon };
        }
        else if(attribute_id == 16){
            const _width = 512;
            const _height = 508;
            return {icon: swordIcon };
        }
        else if(attribute_id == 17){
            const _width = 512;
            const _height = 512;
            return {icon: bowIcon };
        }
        else if(attribute_id == 18){
            const _width = 512;
            const _height = 512;
            return {icon: councilIcon };
        }
        else if(attribute_id == 19){
            const _width = 512;
            const _height = 512;
            return {icon: magicResistanceIcon};
        }
        else
        {
            const _width = 512;
            const _height = 512;
            return {icon: xIcon };
        }
    }

    var _attribute = API_get_attribute_by_id(attribute_id);
    var _attribute_group = API_get_attribute_group_by_id(_attribute.group);

    const incrementAttribute = (_increment) => {
        API_increment_attribute (attribute_id, 'increase', _increment);
    }

    const incrementAncestry = (_increment) => {
        API_increment_attribute (attribute_id, 'ancestry', _increment);
    }

    const incrementOther = (_increment) => {
        API_increment_attribute (attribute_id, 'other', _increment);
    }

    /*const incrementSkill = (_skillID, _increment) => {
        API_increment_skill (attribute_id, _skillID, API_get_increase_selector(), _increment);
    }

    const click_skill_dice = (_skillID) => {
        var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice(_skill.display_name, _skill.dice, true, 'attribute', _skill.display_name);
    }*/

    const isArcana = () => {
        let _skill_group = GetElementById(attribute_id, API_get_skills(), 'skill_group_id');
        //let _attribute = API_get_attribute_by_id(_skill_group.skill_group_id);

        if(_skill_group.skill_group_id == API_get_attribute_ref().arcana)
            return true;

        return false;
    }
    
    const getMaxSpecialization = () => {
        let max_specialization = _attribute_group.max_specialization
        return max_specialization;
    }

    const getMaxSpecializationIcon = () => {
        if(isArcana())
            return councilIcon;
        
        return starsIcon;
    }

    const getMaxSpecializationLabel = () => {
        if(isArcana())
            return API_get_std_header_labels().max + '. ' + API_get_std_header_labels().circle + ':';
        
        return API_get_std_header_labels().max + '. ' + API_get_std_header_labels().specialization + ':';
    }

    const getMaxSpecializationValue = () => {
        if(isArcana())
            return API_get_max_magic_circle();
        
        return getMaxSpecialization();
    }

    return ( 
        <div>
            <table className={table_style.std_table} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                <tr>
                    <th colSpan={10}>
                        <div style={{display: 'inline-block', marginRight: 10}}>
                            <img style={{ width: 20, verticalAlign: 'middle', marginBottom: 4}} src={getIcon().icon} alt="Bild" />
                        </div>
                        <div style={{display: 'inline-block'}}>
                            {_attribute.display_name} ({_attribute.abbreviation})
                        </div>
                    </th>
                </tr>
                <tr style={{height: 12}}></tr>
                <tr>
                    <td style={{textAlign: 'center', width: 22, minWidth: 22}}>
                        <label style={{fontWeight: 'bold'}}>{_attribute.base}</label>
                    </td>
                    <td style={{textAlign: 'center', width: 16, minWidth: 16}}>
                        +
                    </td> 
                    <td style={{minWidth: 140, width: 140}}>
                        <InputField 
                            type='integer' 
                            widthLabel={62}  
                            widthValue={20} 
                            label={API_get_table_header_labels().training} 
                            content={_attribute.increase} 
                            setter={null} 
                            buttonOnly={true} 
                            buttonField={true} 
                            buttonCallback={incrementAttribute}
                            overlayMessage={API_get_std_header_labels().cost + ': ' + _attribute.cost}/>
                    </td>
                    {/*<td style={{minWidth: 28, width: 28, position: 'relative'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                            <div style={{textAlign: 'left'}}></div>
                            <div style={{textAlign: 'left', width: '100%', minWidth: '100%', alignSelf: 'flex-end', marginBottom: 4}}>
                                <p style={{display: 'inline', fontSize: 11, marginRight: 4}}>({API_get_std_header_labels().cost_first_letter}: {_attribute.cost})</p>
                            </div>
                        </div>
                    </td>*/}
                    <td style={{textAlign: 'center', width: 16, minWidth: 16}}>+</td> 
                    <td style={{minWidth: 143, width: 143}}>
                        <InputField type='integer' widthLabel={66} widthValue={20} label={API_get_table_header_labels().ancestry_mod} content={_attribute.ancestry_bonus} setter={null} buttonOnly={true} buttonField={true} buttonCallback={incrementAncestry}/>
                    </td>
                    <td style={{width: 64, minWidth: 64, textAlign: 'center', verticalAlign: 'middle'}}>
                        <img style={{ width: '14px', verticalAlign: 'middle', marginBottom: 2, marginRight: 0}} src={rightArrowIcon} alt="Bild" />
                    </td>
                    <td style={{minWidth: 142, width: 142}}>
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 2, marginRight: 5}}>
                                <img style={{ width: '11px', verticalAlign: 'middle', marginBottom: 6}} src={fistIcon} alt="Bild" />
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <InputField type='integer' widthLabel={45} widthValue={20} label={API_get_std_header_labels().value} content={_attribute.value} setter={null} buttonOnly={true} buttonField={true} buttonCallback={incrementOther}/>
                            </div>
                        </div>
                    </td>
                    <td style={{minWidth: 18, width: 18, position: 'relative'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                            <div style={{textAlign: 'left'}}>
                                {_attribute.temporary_mod > 0 && 
                                    <label style={{fontSize: 10, color: 'green'}}> (+{_attribute.temporary_mod})</label>
                                }
                                {_attribute.temporary_mod < 0 && 
                                    <label style={{fontSize: 10, color: 'red'}}> ({_attribute.temporary_mod})</label>
                                }
                            </div>
                            <div style={{textAlign: 'left', width: '100%', minWidth: '100%', alignSelf: 'flex-end', marginBottom: 4}}>
                                {_attribute.other_bonus > 0 && 
                                    <label style={{fontSize: 10, color: 'green'}}> (+{_attribute.other_bonus})</label>
                                }
                                {_attribute.other_bonus < 0 && 
                                    <label style={{fontSize: 10, color: 'red'}}> ({_attribute.other_bonus})</label>
                                }
                            </div>
                        </div>
                    </td>
                    <td style={{width: 120, minWidth: 120, textAlign: 'right'}}>
                        <div style={{display: 'inline-block', marginRight: 4}}>
                            <img style={{ width: '18px', verticalAlign: 'middle'}} src={getMaxSpecializationIcon()} alt="Bild" />
                        </div>
                        <div style={{display: 'inline-block'}}>
                            {getMaxSpecializationLabel()}
                        </div>
                    </td>
                    <td style={{width: 26, minWidth: 26, textAlign: 'center'}}>
                        {getMaxSpecializationValue()}
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default CharacterSkillSheetTableHeader;