import { useContext } from 'react'
import {APIContext} from '../../../API/API'
import {AttributeContext} from '../../../API/AttributeContext'
import {ScreenDataContext} from '../../../API/ScreenDataContext'
import {BaseStatContext} from '../../../API/BaseStatContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {SkillContext} from '../../../API/SkillContext'
import {MagicContext} from '../../../API/MagicContext'
import {
    ParseToNumber,
    GetElementById
} from '../../../API/Functions/GeneralFunctions'
import TabBar from '../../ComponentsStylish/TabBar';
import handIcon from '../../../Rss/Images/icons/hand.png';
import bookIcon from '../../../Rss/Images/icons/book.png';
import rightArrowIcon from '../../../Rss/Images/icons/right_arrow.png';
import fistIcon from '../../../Rss/Images/icons/fist.png';
import starsIcon from '../../../Rss/Images/icons/stars.png';
import councilIcon from '../../../Rss/Images/icons/councilLogo.png';
import container_style from '../../../StylesStylish/container.module.css'

const CharacterSkillSheetHeader = (props) => {
    const { group_id = 0 } = props;

    const {
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        API_get_base_stats
    } = useContext(BaseStatContext);

    const {
        API_get_attribute_group_by_id,
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_skill_group_visibility,
        API_set_skill_group_visibility,
        API_toggle_skill_group_visibility,
        API_get_skills,
        API_get_increase_selector,
        API_set_increase_selector,
        API_get_skill_selector,
        API_set_skill_selector,
        API_get_edit_skill_visibility,
        API_get_skill_by_id
    } = useContext(SkillContext);

    const {
        API_get_skill_group_screen,
        API_set_skill_group_screen,
        API_get_skill_group_tab_name
    } = useContext(ScreenDataContext);

    const {
        API_get_table_header_labels,
        API_get_base_stat_labels,
        API_get_std_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_max_magic_circle
    } = useContext(MagicContext);

    const cellWidth = 306;

    const getFocus = () => {
        let _group = API_get_attribute_group_by_id(group_id);
        let _focus = _group.focus
        return _focus;
    }

    const getLevel = () => {
        let _level = API_get_base_stats().level
        return _level;
    }

    const getMaxTraining = () => {
        let _group = API_get_attribute_group_by_id(group_id);
        let max_training = _group.max_training
        return max_training;
    }

    const getMaxSpecialization = () => {
        let _group = API_get_attribute_group_by_id(group_id);
        let max_specialization = _group.max_specialization
        return max_specialization;
    }

    const isMagic = () => {
        if(group_id == API_get_attribute_group_ref().magic)
            return true;

        return false;
    }

    return ( 
        <div>
            <div className={container_style.header_border_container} style={{height: 25, minWidth: 1568, width: 1568}}>
                
            </div>
            <div className={container_style.header_container} style={{height: 60, minWidth: 1568, width: 1568}}>
                <table>
                    <tr style={{height: 60, fontSize: 18, fontWeight: 'bold'}}>
                        <td style={{minWidth: cellWidth, width: cellWidth, verticalAlign: 'middle', paddingLeft: 15}}>
                            <div style={{display: 'inline-block', marginRight: 10}}>
                                <img style={{ width: '14px', verticalAlign: 'middle', marginBottom: 4}} src={handIcon} alt="Bild" />
                            </div>
                            <div style={{display: 'inline-block'}}>
                                {API_get_table_header_labels().focus}: {getFocus()}
                            </div>
                        </td>
                        <td style={{minWidth: cellWidth, width: cellWidth, verticalAlign: 'middle', paddingLeft: 5}}>
                            <div style={{display: 'inline-block', marginRight: 10}}>
                                <img style={{ width: '16px', verticalAlign: 'middle', marginBottom: 4}} src={bookIcon} alt="Bild" />
                            </div>
                            <div style={{display: 'inline-block'}}>
                                {API_get_base_stat_labels().level}: {getLevel()}
                            </div>
                        </td>
                        <td style={{minWidth: cellWidth, width: cellWidth, textAlign: 'center', verticalAlign: 'middle', paddingLeft: 5}}>
                            <img style={{ width: '20px', verticalAlign: 'middle', marginBottom: 4, marginRight: 75}} src={rightArrowIcon} alt="Bild" />
                        </td>
                        <td style={{minWidth: cellWidth, width: cellWidth, verticalAlign: 'middle', paddingLeft: 5}}>
                            <div style={{display: 'inline-block', marginRight: 10}}>
                                <img style={{ width: '14px', verticalAlign: 'middle', marginBottom: 4}} src={fistIcon} alt="Bild" />
                            </div>
                            <div style={{display: 'inline-block'}}>
                                {API_get_std_header_labels().max_training}: {getMaxTraining()}
                            </div>
                        </td>
                        <td style={{ minWidth: cellWidth, width: cellWidth, verticalAlign: 'middle', paddingLeft: 5 }}>
                            <table>
                                <tr style={{height: 26}}>
                                    <td style={{width: 30, textAlign: 'center', verticalAlign: 'middle' }}>
                                        <img style={{ width: '22px' }} src={starsIcon} alt="Bild" />
                                    </td>
                                    <td style={{width: 216, textAlign: 'left', verticalAlign: 'middle'}}>
                                        {API_get_std_header_labels().max_specialization}:
                                    </td>
                                    <td style={{width: 30, textAlign: 'center', verticalAlign: 'middle'}}>
                                        {getMaxSpecialization()}
                                    </td>
                                </tr>
                                {isMagic() && 
                                    <tr style={{height: 26}}>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle'}}>
                                            <div>
                                                <img style={{ width: '22px' }} src={councilIcon} alt="Bild" />
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'left', verticalAlign: 'middle'}}>
                                            {API_get_std_header_labels().max_circle}:
                                        </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle'}}>
                                            {API_get_max_magic_circle()}
                                        </td>
                                    </tr>
                                }
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default CharacterSkillSheetHeader;