import { useState, useContext } from 'react'
import {CheckIfKeyIsContained, IsWhitespace, ParseToInteger, ParseToFloat, TryParse} from '../../API/Functions/GeneralFunctions'
import field_style from '../../StylesStylish/field.module.css'
import label_style from '../../StylesStylish/label.module.css'

const MultiLineInput = (props) => {
    const { rows = 14, cols = 40, readOnly = false } = props;
    const [currentInput, setCurrentInput] = useState(props.content)

    const updateInput = (_input, _use_setter = true) => {
        if(_use_setter)
            props.setter(_input);
        setCurrentInput(_input);
    }

    return ( 
        <div>
            <textarea
                className={field_style.multiline_input}
                rows={rows} 
                cols={cols}
                readOnly={readOnly}
                placeholder={props.default_content}
                onChange={(e) => updateInput(e.target.value)}
                value={props.content}
            />
        </div>
    );
}

export {MultiLineInput}