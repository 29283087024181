import React, { useState } from 'react';
import invalidImage from '../../Rss/Images/invalidImage.png';


const ImageContainer = (props) => {
    const { 
        image = null, 
        default_image = null,
        //image_setter = null, 
        width = 60, 
        max_height = -1, 
        borderWidth = 2, 
        broderShaddow = true,
        borderColor = 'default' } = props;

    const [uiImage, setUiImage] = useState(default_image);


    const getImage = () => {
        if(image == null)
            return default_image

        return image
    };

    const getMaxHeight = () => {
        if(max_height <= 0)
        {
            return 'auto';
        }
        else
        {
            return max_height;
        }
    }

    const handleOnError = (e) => {
        e.onerror = null;
        e.target.src = invalidImage;
    }

    const getStyle = () => {
        let _style = {
            width: width, 
            minWidth: width, 
            maxHeight: getMaxHeight(),
            borderTop: borderWidth + 'px solid rgba(100, 100, 100, 0.8)',
            borderLeft: borderWidth + 'px solid rgba(100, 100, 100, 0.8)',
            borderBottom: borderWidth + 'px solid rgba(240, 240, 240, 0.8)',
            borderRight: borderWidth + 'px solid rgba(240, 240, 240, 0.8)',
            boxShadow: 'inset 0px 1px 0px rgba(240, 240, 240, 0.8), inset 0px -1px 0px rgba(100, 100, 100, 0.8), inset 1px 0px 0px rgba(240, 240, 240, 0.8), inset -1px 0px 0px rgba(100, 100, 100, 0.8)'
        }
        return _style;
    }

    return (
        <div>
            <img src={getImage()} style={getStyle()} alt="Image not found" onError={handleOnError} />
        </div>
    );
}

export default ImageContainer;