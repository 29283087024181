import { useState, createContext} from 'react'
import {
    BACKEND_get_base_stats, 
    BACKEND_get_free_attribute_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_stat_overview,
    BACKEND_get_stat_overview,
    BACKEND_set_money_list,
    BACKEND_get_money_list,
    BACKEND_get_ancestry_saldo,
    BACKEND_set_ancestry_saldo
} from '../Backend/Parameters/CharacterParameters'

const BaseStatContext = createContext();

const BaseStatContextProvider = ({children}) => {
    
    const [characterImage, setCharacterImage] = useState(null);
    const [baseStats, setBaseStats] = useState({...BACKEND_get_base_stats()});
    const [attributePoints, setAttributePoints] = useState({...BACKEND_get_free_attribute_points()});
    const [focusPoints, setFocusPoints] = useState({...BACKEND_get_free_focus_points()});
    const [ancestrySaldo, setAncestrySaldo] = useState({...BACKEND_get_ancestry_saldo()});
    const [editCharacterVisibility, setEditCharacterVisibility] = useState(false);
    const [statOverview, setStatOverview] = useState({...BACKEND_get_stat_overview()});
    const [moneyList, setMoneyList] = useState({...BACKEND_get_money_list()});

    const API_get_character_image = () => {return characterImage;}
    const API_set_character_image = (_image) => {setCharacterImage(_image);}

    const API_get_base_stats = () => {return baseStats;}
    const API_set_base_stats = () => {setBaseStats({...BACKEND_get_base_stats()});}
    const API_get_free_attribute_points = () => {return attributePoints;}
    const API_set_free_attribute_points = () => {setAttributePoints({...BACKEND_get_free_attribute_points()});}
    const API_get_free_focus_points = () => {return focusPoints}
    const API_set_free_focus_points = () => {setFocusPoints({...BACKEND_get_free_focus_points()});}
    const API_get_ancestry_saldo = () => {return ancestrySaldo;}
    const API_set_ancestry_saldo = () => {setAncestrySaldo({...BACKEND_get_ancestry_saldo()});}
    const API_get_edit_character_visibility = () => {return editCharacterVisibility}
    const API_set_edit_character_visibility = (_visiblilty) => {setEditCharacterVisibility(_visiblilty);}
    const API_get_stat_overview = () => {return statOverview}
    const API_set_stat_overview = () => {setStatOverview({...BACKEND_get_stat_overview()});}
    const API_get_money_list = () => {return moneyList}
    const API_set_money_list = () => {setMoneyList({...BACKEND_get_money_list()});}

    return(
        <BaseStatContext.Provider value={{
            API_get_character_image,
            API_set_character_image,
            API_get_base_stats,
            API_set_base_stats,
            API_get_free_attribute_points,
            API_set_free_attribute_points,
            API_get_free_focus_points,
            API_set_free_focus_points,
            API_get_ancestry_saldo,
            API_set_ancestry_saldo,
            API_get_edit_character_visibility,
            API_set_edit_character_visibility,
            API_get_stat_overview,
            API_set_stat_overview,
            API_get_money_list,
            API_set_money_list
        }}>
            {children}
        </BaseStatContext.Provider>
    )
}

export {BaseStatContext, BaseStatContextProvider}