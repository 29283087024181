import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../../API/TextDataContext'
import {InputField} from '../../ComponentsStylish/InputField';
import {
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID,
    TryParse
} from '../../../API/Functions/GeneralFunctions'
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'
import field_style from '../../../StylesStylish/field.module.css'

const CharacterStatsTable = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);


    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_attributes,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_get_secondary_attributes,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const [dmgVal, setDmgVal] = useState(0);
    const [healVal, setHealVal] = useState(0);

    //API_set_character_stats();

    //const [editHitpoints, setEditHitpoints] = useState(0);
    //const [editLoadCapacity, setEditLoadCapacity] = useState(0);
    //const [editEndurance, setEditEndurance] = useState(0);
    //const [editKhoTal, setEditKhoTal] = useState(0);


    const modifyCharacterStat = (_id, _value) => {
        //setEditHitpoints(ParseToInteger(editHitpoints));
        //setEditLoadCapacity(ParseToInteger(editLoadCapacity));
        //setEditEndurance(ParseToInteger(editEndurance));
        //setEditKhoTal(ParseToInteger(editKhoTal));
        //API_modify_character_stat_bonus(_id, _value);
    }

    const tryModifyHitpoints = (_newValue) => {
        //if(TryParse(_newValue, 'integer'))
            //modifyCharacterStat(API_get_character_stats().hitpoints.id, _newValue)

        //setEditHitpoints(_newValue);
    }

    const tryModifyLoadCapacity = (_newValue) => {
        //if(TryParse(_newValue, 'integer'))
            //modifyCharacterStat(API_get_character_stats().load_capacity.id, _newValue)

        //setEditLoadCapacity(_newValue);
    }

    const tryModifyEditEndurance = (_newValue) => {
        //if(TryParse(_newValue, 'integer'))
            //modifyCharacterStat(API_get_character_stats().endurance.id, _newValue)

        //setEditEndurance(_newValue);
    }


    /*const tryModifyHitpoints = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().hitpoints.id, _newValue);

        setEditHitpoints(_newValue);
    }

    const tryModifyLoadCapacity = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().load_capacity.id, _newValue);

        setEditLoadCapacity(_newValue);
    }

    const tryModifyEditEndurance = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().endurance.id, _newValue);

        setEditEndurance(_newValue);
    }*/

    /*const tryModifyKhoTal = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().kho_tal.id, _newValue);

        setEditKhoTal(_newValue);
    }*/

    const DealDamage = () => {
        var _val = parseInt(dmgVal);
        var _val = -_val;
        API_change_hitpoints(_val)
        setDmgVal(0);
    }

    const IncrementDamage = (_increment) => {
        setDmgVal(dmgVal+_increment);
    }

    const Heal = () => {
        API_change_hitpoints(healVal)
        setHealVal(0);
    }

    const IncrementHeal = (_increment) => {
        setHealVal(healVal+_increment);
    }

    /*const incrementKhoTal = (_increment) => {
        API_increment_kho_tal(_increment);
    }*/

    const incrementHitpointBonus = (_increment) => {
        let _bonus = API_get_character_stats().hitpoints.bonus;
        API_modify_character_stat_bonus(API_get_character_stats().hitpoints.id, _bonus+_increment);
    }

    const incrementLoadCapacity = (_increment) => {
        let _bonus = API_get_character_stats().load_capacity.bonus;
        API_modify_character_stat_bonus(API_get_character_stats().load_capacity.id, _bonus+_increment);
    }

    const incrementEndurance = (_increment) => {
        let _bonus = API_get_character_stats().endurance.bonus;
        API_modify_character_stat_bonus(API_get_character_stats().endurance.id, _bonus+_increment);
    }
    
    const getBonusColor = (_value) => {
        if(_value >= 0)
        {
            return 'green'
        }
        else
        {
            return 'red'
        }
    }

    const getBonusString = (_value) => {
        if(_value > 0)
        {
            return '(+' + _value + ')';
        }
        else{
            return '(' + _value + ')';
        }
    }


    return ( 
        <div>
            <table className={table_style.std_table} style={{ marginLeft: 0 }}>
                <tr>
                    <th colspan={4}>
                        {API_get_character_stats_labels().character_stats}
                    </th>
                </tr>
                <tr style={{ bordeBottom: '1px solid black' }}>
                    <td style={{ fontWeight: 'bold', width: 130, minWidth: 130, textAlign: 'left', paddingLeft: '5px' }}>{API_get_std_header_labels().characteristics}</td>
                    <td style={{ fontWeight: 'bold', width: 120, minWidth: 120, textAlign: 'center' }}>{API_get_std_header_labels().formula}</td>
                    <td style={{ fontWeight: 'bold', width: 80, minWidth: 80, textAlign: 'center' }}>{API_get_std_header_labels().remain}</td>
                    <td style={{ fontWeight: 'bold', width: 80, minWidth: 80, textAlign: 'center' }}>{API_get_std_header_labels().value}</td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{ width: 10, minWidth: 10, textAlign: 'left', paddingLeft: '5px' }}>{API_get_character_stats_labels().hitpoints}</td>
                    <td style={{ textAlign: 'center' }}>3x{API_get_character_stats_labels().formula_toughness}+20</td>
                    <td style={{ textAlign: 'center' }}>
                        <InputField type='integer'
                            widthValue={86}
                            labelVisibility={false}
                            content={API_get_character_stats().hitpoints.value - API_get_character_stats().hitpoints.lost}
                            setter={null}
                            buttonOnly={true}
                            buttonField={false}
                        />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <InputField type='integer'
                            widthValue={28}
                            labelVisibility={false}
                            content={API_get_character_stats().hitpoints.value}
                            setter={null}
                            buttonOnly={true}
                            buttonField={true}
                            buttonCallback={incrementHitpointBonus}
                        />
                    </td>
                    <td style={{ minWidth: 18, width: 18, position: 'relative' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div style={{ textAlign: 'left' }}>
                                {API_get_character_stats().hitpoints.temporary_mod != 0 &&
                                    <label style={{ fontSize: 10, color: getBonusColor(API_get_character_stats().hitpoints.temporary_mod) }}> {getBonusString(API_get_character_stats().hitpoints.temporary_mod)}</label>
                                }
                            </div>
                            <div style={{ textAlign: 'left', width: '100%', minWidth: '100%', alignSelf: 'flex-end', marginBottom: 4 }}>
                                {API_get_character_stats().hitpoints.bonus != 0 &&
                                    <label style={{ fontSize: 10, color: getBonusColor(API_get_character_stats().hitpoints.bonus) }}> {getBonusString(API_get_character_stats().hitpoints.bonus)}</label>
                                }
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: 110, textAlign: 'left', paddingLeft: '5px' }}>{API_get_character_stats_labels().load_capacity}</td>
                    <td style={{ textAlign: 'center' }}>3x{API_get_character_stats_labels().formula_load_capacity}+28</td>
                    <td style={{ textAlign: 'center' }}>

                        <InputField type='integer'
                            widthValue={86}
                            labelVisibility={false}
                            content={API_get_character_stats().load_capacity.value - API_get_character_stats().load_capacity.lost}
                            setter={null}
                            buttonOnly={true}
                            buttonField={false}
                        />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <InputField type='integer'
                            widthValue={28}
                            labelVisibility={false}
                            content={API_get_character_stats().load_capacity.value}
                            setter={null}
                            buttonOnly={true}
                            buttonField={true}
                            buttonCallback={incrementLoadCapacity}
                        />
                    </td>
                    <td style={{ minWidth: 18, width: 18, position: 'relative' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div style={{ textAlign: 'left' }}>
                                {API_get_character_stats().load_capacity.temporary_mod != 0 &&
                                    <label style={{ fontSize: 10, color: getBonusColor(API_get_character_stats().load_capacity.temporary_mod) }}> {getBonusString(API_get_character_stats().load_capacity.temporary_mod)}</label>
                                }
                            </div>
                            <div style={{ textAlign: 'left', width: '100%', minWidth: '100%', alignSelf: 'flex-end', marginBottom: 4 }}>
                                {API_get_character_stats().load_capacity.bonus != 0 &&
                                    <label style={{ fontSize: 10, color: getBonusColor(API_get_character_stats().load_capacity.bonus) }}> {getBonusString(API_get_character_stats().load_capacity.bonus)}</label>
                                }
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: 110, textAlign: 'left', paddingLeft: '5px' }}>{API_get_character_stats_labels().endurance}</td>
                    <td style={{ textAlign: 'center' }}>4x{API_get_character_stats_labels().formula_endurance}+16</td>
                    <td style={{ textAlign: 'center' }}>
                        <InputField type='integer'
                            widthValue={86}
                            labelVisibility={false}
                            content={API_get_character_stats().endurance.value - API_get_character_stats().endurance.lost}
                            setter={null}
                            buttonOnly={true}
                            buttonField={false}
                        />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <InputField type='integer'
                            widthValue={28}
                            labelVisibility={false}
                            content={API_get_character_stats().endurance.value}
                            setter={null}
                            buttonOnly={true}
                            buttonField={true}
                            buttonCallback={incrementEndurance}
                        />
                    </td>
                    <td style={{ minWidth: 18, width: 18, position: 'relative' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div style={{ textAlign: 'left' }}>
                                {API_get_character_stats().endurance.temporary_mod != 0 &&
                                    <label style={{ fontSize: 10, color: getBonusColor(API_get_character_stats().endurance.temporary_mod) }}> {getBonusString(API_get_character_stats().endurance.temporary_mod)}</label>
                                }
                            </div>
                            <div style={{ textAlign: 'left', width: '100%', minWidth: '100%', alignSelf: 'flex-end', marginBottom: 4 }}>
                                {API_get_character_stats().endurance.bonus != 0 &&
                                    <label style={{ fontSize: 10, color: getBonusColor(API_get_character_stats().endurance.bonus) }}> {getBonusString(API_get_character_stats().endurance.bonus)}</label>
                                }
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left' }} colspan={2}>
                        <div style={{ display: 'inline-block', marginRight: 4 }}>
                            <InputField type='integer'
                                widthValue={28}
                                labelVisibility={false}
                                content={dmgVal}
                                setter={setDmgVal}
                                buttonOnly={false}
                                buttonField={true}
                                buttonCallback={IncrementDamage}
                            />
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <button className={btn_style.std_button} onClick={DealDamage} style={{ width: 80, height: 28 }}>{API_get_character_stats_labels().deal_dmg}</button>
                        </div>
                        {/*<input type='number' className={field.input_field_number} value={dmgVal} style={{width: 70, minWidth: 70}} onChange={(e) => setDmgVal(e.target.value)}></input>*/}
                    </td>
                    <td style={{ textAlign: 'right' }} colspan={2}>
                        <div style={{ display: 'inline-block', marginRight: 4 }}>
                            <button className={btn_style.std_button} onClick={Heal} style={{ width: 80, height: 28 }}>{API_get_character_stats_labels().heal}</button>
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <InputField type='integer'
                                widthValue={28}
                                labelVisibility={false}
                                content={healVal}
                                setter={setHealVal}
                                buttonOnly={false}
                                buttonField={true}
                                buttonCallback={IncrementHeal}
                            />
                        </div>
                        {/*<input type='number' className={field.input_field_number} value={healVal} style={{width: 70, minWidth: 70}} onChange={(e) => setHealVal(e.target.value)}></input>*/}
                    </td>
                </tr>
            </table>
        </div>
    );
}
 
export default CharacterStatsTable;