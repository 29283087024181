import { useContext } from 'react'
import {AttributeContext} from '../../../API/AttributeContext'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {APIContext} from '../../../API/API'
import table_style from '../../../StylesStylish/table.module.css'
import {InputField} from '../../ComponentsStylish/InputField';
import handIcon from '../../../Rss/Images/icons/hand.png';

const TalentTable = () => {
    const {
        API_increment_focus
    } = useContext(APIContext);

    const {
        API_get_attribute_group_by_id,
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_user,
        API_get_character_read_only
    } = useContext(UserDataContext);

    const getAttributeFocus = (_id) => {
        return API_get_attribute_group_by_id(_id).focus;
    }

    const incrementFocus = (_id, _increment) => {
        API_increment_focus (_id, _increment);
    }

    const getAttributes = () => {
        const _groups = API_get_attributes();
        return _groups;
    }

    const getInputWidth = () => {
        const inputWidth = 7*102 / Object.keys(getAttributes()).length;
        return inputWidth;

    }

    return ( 
        <div>
            <table className={table_style.std_table} style={{justifyContent: 'center'}} >
                <tr>
                    <th style={{justifyContent: 'center', alignItems: 'center'}} colspan={7}>
                        <div style={{display: 'inline-block', marginRight: 10}}>
                            <img style={{ width: '16px', verticalAlign: 'middle', marginBottom: 2}} src={handIcon} alt="Bild" />
                        </div>
                        <div style={{display: 'inline-block'}}>
                            {API_get_table_header_labels().focus}
                        </div>
                    </th>
                </tr>
                <tr style={{height: 24}}>

                </tr>
                <tr style={{fontWeight: 'bold', textAlign: 'center'}}>
                    {Object.entries(getAttributes()).map(([key, value], index) => (
                        <td>{value.group}</td>
                    ))}
                </tr>
                <tr style={{textAlign: 'center', borderBottom: '1px solid black'}}>
                    {Object.entries(getAttributes()).map(([key, value], index) => (
                        <td>
                            <InputField type='integer' widthValue={getInputWidth()} labelVisibility={false} content={getAttributeFocus(value.group_id)} setter={null} buttonOnly={true} buttonField={true} plusButtonCallback={() => incrementFocus(value.group_id, 1)} minusButtonCallback={() => incrementFocus(value.group_id, -1)} readOnly={API_get_character_read_only()}/>
                        </td>
                    ))}
                </tr>
            </table>
        </div>
     );
}
 
export default TalentTable