import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {EquipmentContext, EquipmentContextProvider} from '../../../API/EquipmentContext'
import {TextDataContext, TextDataContextProvider} from '../../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../../API/BaseStatContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {MessageBoxContext} from '../../../API/MessageBoxContext'
import { 
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID,
    TryParse
} from '../../../API/Functions/GeneralFunctions'
import DiceButton from '../../ComponentsStylish/Buttons/DiceButton';
import upIcon from '../../../Rss/Images/icons/up.png';
import downIcon from '../../../Rss/Images/icons/down.png';
import deleteIcon from '../../../Rss/Images/icons/remove.png';
import plusIcon from '../../../Rss/Images/icons/plus_icon.png';
import {InputField} from '../../ComponentsStylish/InputField';
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'
import field_style from '../../../StylesStylish/field.module.css'
import { Tooltip } from 'react-tooltip'

const InventoryTable = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_modify_gear_name,
        API_modify_gear_amount,
        API_modify_gear_weight,
        API_modify_gear_description,
        API_add_new_gear_element,
        API_remove_gear_element,
        API_move_gear_element
    } = useContext(APIContext);

    const {
        API_get_armor,
        API_set_armor,
        API_get_gear,
        API_set_gear,
        API_get_melee_weapon_type_by_id,
        API_get_ranged_weapon_type_by_id,
        API_get_hand_type_by_id,
        API_get_armor_by_id,
        API_get_gear_by_id
    } = useContext(EquipmentContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels,
        API_get_message_box_content_labels
    } = useContext(TextDataContext);

    const {
        API_get_stat_overview,
        API_set_stat_overview
    } = useContext(BaseStatContext);

    const {
        API_open_yes_no_message_box
    } = useContext(MessageBoxContext);

    const {
        API_get_user,
        API_get_character_read_only
    } = useContext(UserDataContext);

    const [editList, setEditList] = useState(null);
    const [editName, setEditName] = useState(null);
    const [editAmount, setEditAmount] = useState(null);
    const [editWeight, setEditWeight] = useState(null);
    const [editDescription, setEditDescription] = useState(null);

    const clickEditElementHandler = (_value) => {
        let _list = API_get_gear();

        let _local_edit_list = [];
        let _local_edit_name = [];
        let _local_edit_amount = [];
        let _local_edit_weight = [];
        let _local_edit_description = [];

        for(let key in _list){
            _local_edit_list.push({id: _list[key].id, value: false});
            _local_edit_name.push({id: _list[key].id, value: _list[key].display_name});
            _local_edit_amount.push({id: _list[key].id, value: _list[key].amount});
            _local_edit_weight.push({id: _list[key].id, value: _list[key].weight});
            _local_edit_description.push({id: _list[key].id, value: _list[key].description});
        }

        if(editList == null)
        {
            for(let key in _local_edit_list){
                if(_local_edit_list[key].id == _value.id)
                    _local_edit_list[key].value = true;
            }

            setEditName(_local_edit_name);
            setEditAmount(_local_edit_amount);
            setEditWeight(_local_edit_weight);
            setEditDescription(_local_edit_description);
            setEditList(_local_edit_list);

            return;
        }


        for(let key1 in _local_edit_list){
            for(let key2 in editList){
                if(_local_edit_list[key1].id == editList[key2].id){
                    _local_edit_list[key1].value = editList[key2].value;
                }
            }
        }

        for(let key in _local_edit_list){
            if(_local_edit_list[key].id == _value.id)
            _local_edit_list[key].value = !_local_edit_list[key].value;
        }
            
        setEditName(_local_edit_name);
        setEditAmount(_local_edit_amount);
        setEditWeight(_local_edit_weight);
        setEditDescription(_local_edit_description);
        setEditList(_local_edit_list);
    }

    const getValueByID = (_id, _list, _type) => {
        return GetListParameterByID(_id, _list, _type);
    }

    const setValueByID = (_id, _list, _setter, _newValue, _type) => {
        SetListParameterByID(_id, _list, _setter, _newValue, _type);
    }

    const modifyName = (_id, _newValue) => {
        API_modify_gear_name(_id, _newValue);
    }

    /*const tryModifyAmount = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyAmount(_id, _newValue);

        SetListParameterByID(_id, editAmount, setEditAmount, _newValue, 'integer');
    }*/

    const modifyAmount = (_id, _newValue) => {
        API_modify_gear_amount(_id, _newValue);
    }

    const incrementAmount = (_id, _increment) => {
        let _gear = API_get_gear_by_id(_id);
        let _amount = _gear.amount;
        API_modify_gear_amount(_id, _amount + _increment);
    }

    /*const tryModifyWeight = (_id, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyWeight(_id, _newValue);

        SetListParameterByID(_id, editWeight, setEditWeight, _newValue, 'float');
    }*/

    const modifyWeight = (_id, _newValue) => {
        API_modify_gear_weight(_id, _newValue);
    }

    const modifyDescription = (_id, _newValue) => {
        API_modify_gear_description(_id, _newValue);
    }
    
    const addNewElement = () => {
        API_add_new_gear_element();
    }

    const removeElement = (_element) => {
        //API_remove_gear_element(_element);
        let _content = API_get_message_box_content_labels().delete_gear;
        API_open_yes_no_message_box(_content, () => API_remove_gear_element(_element));
    }

    const moveElement = (_element, _direction) => {
        API_move_gear_element(_element, _direction);
    }

    return ( 
        <div>
            <table className={table_style.std_table}>
                <tr>
                    <th colspan={3}>
                        {API_get_equipment_header_labels().gear}
                    </th>
                </tr>
                <tr style={{height: 12}}></tr>
                {/*<tr >
                    <td style={{width: 416, minWidth: 416}}></td>
                    <td style={{width: 80, minWidth: 80}}>{API_get_std_header_labels().total}</td>
                    <td style={{width: 80, minWidth: 80, border: '2px solid black'}}>{ParseToFloat(API_get_stat_overview().carried_weight)}kg</td>
                    <td style={{width: 106, minWidth: 106}} colspan={5}></td>
                </tr>*/}
                <tr>
                    <td style={{fontWeight: 'bold', textAlign: 'left', paddingLeft: 5, width: 436, minWidth: 436, borderBottom: '1px solid black'}}>{API_get_equipment_header_labels().gear}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 86, minWidth: 86, borderBottom: '1px solid black'}}>{API_get_std_header_labels().amount}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center', width: 84, minWidth: 84, borderBottom: '1px solid black'}}>{API_get_std_header_labels().weight}</td>
                    {/*<td>{API_get_std_header_labels().description}</td>*/}
                    <td style={{width: 106, minWidth: 106}} colspan={4}></td>
                </tr>
                <tr style={{height: 8}}></tr>
                {Object.entries(API_get_gear()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left'}}>
                            <InputField type='text' 
                                        labelVisibility={false}
                                        widthValue={428} 
                                        content={value.display_name} 
                                        placeholder = {API_get_std_labels().default_name}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        setter={modifyName}
                                        setter_parameter={[value.id]} />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.display_name}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input style={{width: 396}} value={getValueByID(value.id, editName, 'text')} onChange={(e) => setValueByID(value.id, editName, setEditName, e.target.value, 'text')} onBlur={(e) => modifyName(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <InputField type='integer' 
                                        widthValue={28} 
                                        labelVisibility={false}
                                        content={value.amount} 
                                        setter={modifyAmount} 
                                        setter_parameter={[value.id]}
                                        readOnly={API_get_character_read_only()}
                                        paddingTop={1} 
                                        paddingBottom={1}
                                        buttonField={true} 
                                        minusButtonCallback={() => incrementAmount(value.id, -1)}
                                        plusButtonCallback={() => incrementAmount(value.id, 1)}/>
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                value.amount
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' style={{width: 65}} value={getValueByID(value.id, editAmount, 'integer')} onChange={(e) => tryModifyAmount(value.id, e.target.value)} onBlur={(e) => modifyAmount(value.id, e.target.value)} />
                            */}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <InputField 
                                type='float' 
                                labelVisibility={false} 
                                widthValue={80} 
                                content={value.weight} 
                                setter={modifyWeight} 
                                setter_parameter={[value.id]}
                                paddingTop={1} 
                                paddingBottom={1}
                                readOnly={API_get_character_read_only()}  />
                            {/*(!getValueByID(value.id, editList, 'bool')) && 
                                value.weight + ' kg'
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' style={{width: 65}} value={getValueByID(value.id, editWeight, 'float')} onChange={(e) => tryModifyWeight(value.id, e.target.value)} onBlur={(e) => modifyWeight(value.id, e.target.value)} />
                            */}
                        </td>
                        {/*<td style={{textAlign: 'left'}}>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.description}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 185}} value={getValueByID(value.id, editDescription, 'text')} onChange={(e) => setValueByID(value.id, editDescription, setEditDescription, e.target.value, 'text')} onBlur={(e) => modifyDescription(value.id, e.target.value)} />
                            }
                        </td>*/}
                        {/*<td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => clickEditElementHandler(value)}>E</button>
                        </td>*/}
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value, 'up')}>
                                <img src={upIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => moveElement(value, 'down')}>
                                <img src={downIcon} alt="Bild" style={{ float: 'left', width: '14px', marginTop: 2 }} />
                            </button>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                        </td>
                        <td style={{width: 12, minWidth: 12}}>
                            <button className={btn_style.icon_button} onClick={() => removeElement(value)}>
                                <img src={deleteIcon} alt="Bild" style={{ float: 'left', width: '14px' }} />
                            </button>
                        </td>
                    </tr>
                ))}
                <tr style={{height: 6}}></tr>
                <tr>
                    <td style={{textAlign: 'right', paddingLeft: 5}}>
                        <button className={btn_style.icon_button} onClick={() => addNewElement()} data-tooltip-id="btn-add-tooltip"  data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().add_gear}>
                            <img style={{ width: '10px', verticalAlign: 'middle', marginBottom: 0}} src={plusIcon} alt="Bild" />
                        </button>
                        <Tooltip id="btn-add-tooltip" />
                    </td>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{API_get_std_header_labels().total}</td>
                    <td style={{fontWeight: 'bold', textAlign: 'center'}}>{ParseToFloat(API_get_stat_overview().carried_weight)}St.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>
    );
}


export default InventoryTable;