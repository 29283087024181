import {
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_free_skill_points,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_get_unarmed_weapons,
    BACKEND_set_unarmed_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_get_ranged_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_money_list,
    BACKEND_set_money_list,
    BACKEND_get_stat_overview,
    BACKEND_set_stat_overview,
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
} from '../Parameters/CharacterParameters'
import{
    BACKEND_get_attribute_group_by_id
} from '../BackendRouter'
import {
    INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction
} from '../ParametersInit/AttributesInit'
import { 
    INIT_get_skills,
    INIT_get_free_skill_points
 } from "../ParametersInit/SkillParametersInit";
import {
    CalculateAttribute, 
    GetHitpoints,
    GetLoadCapacity,
    GetEndurance,
    GetKhoTal,
    //GetInitiative,
    //GetDodgeValue,
    GetFreeAttributePoints, 
    GetFreeFocusPoints,
    GetFreeSkillPoints,
    GetSkillPointsAttribute,
    GetFocusCost,
    GetMaxAttributeIncrease,
    GetMaxSkillIncrease,
    CalculateSkill,
    ConvertAttributes, 
    ConvertAttributesBack
} from './CharacterFunctions'
import {
    BACKEND_get_character_file_name,
    BACKEND_set_upload_state,
    BACKEND_get_upload_state
} from '../Parameters/GameData'
/*import {
    LoadCharacter
} from './LoadCharacter'*/
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from '../Parameters/ToolData'
import {
    Translate
} from '../Lang/Translator'
import {
    GetDiceList
} from '../DiceRoller'
import {
    ParseToNumber,
    GetElementById,
    RollSingleDice,
    FixDecimalPlaces
} from '../../API/Functions/GeneralFunctions'
import {
    CalculateAttack,
    CalculateDefense,
    CalculatePrecision
} from './WeaponFunctions'
import { BACKEND_get_monster_list } from '../Parameters/CampaignParameters';

const RecalcCharacterStats = () => {
    var _character_stats = BACKEND_get_character_stats();
    _character_stats.hitpoints.value = GetHitpoints();
    _character_stats.load_capacity.value = GetLoadCapacity();
    _character_stats.endurance.value = GetEndurance();
    _character_stats.kho_tal.value = GetKhoTal();
    BACKEND_set_character_stats(_character_stats);

}

/*const RecalcSecondaryAttributes = () => {
    var _secondary_attributes = BACKEND_get_secondary_attributes();
    _secondary_attributes.initiative.value = GetInitiative();
    _secondary_attributes.dodge.value = GetDodgeValue();
    _secondary_attributes.initiative.dice = GetDiceList(_secondary_attributes.initiative.value);
    _secondary_attributes.dodge.dice = GetDiceList(_secondary_attributes.dodge.value);
    BACKEND_set_secondary_attributes(_secondary_attributes);

}*/

const RecalcFreeAttributePoints = () => {
    var _free_attribute_points = BACKEND_get_free_attribute_points();
    var _base_stats = BACKEND_get_base_stats();
    
    _free_attribute_points.total = GetFreeAttributePoints(_base_stats.level);
    BACKEND_set_free_attribute_points(_free_attribute_points);
}

const RecalcFreeFocusPoints = () => {
    var _free_focus_points = BACKEND_get_free_focus_points();
    var _base_stats = BACKEND_get_base_stats();

    _free_focus_points.total = GetFreeFocusPoints(_base_stats.level);
    BACKEND_set_free_focus_points(_free_focus_points);
}

const RecalcFreeSkillPoints = () => {
    var _free_skill_points = BACKEND_get_free_skill_points();
    var _base_stats = BACKEND_get_base_stats();

    _free_skill_points.total = GetFreeSkillPoints(_base_stats.level);
    BACKEND_set_free_skill_points(_free_skill_points);
}

const RecalcAttributes = () => {
    let _attributes = BACKEND_get_attributes();
    let _base_stats = BACKEND_get_base_stats();

    //console.log('RecalcAttributes');
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            //console.log(_attributes[key].display_name);
            let _attribute_group = BACKEND_get_attribute_group_by_id(_attributes[key].group);
            //console.log(_attribute_group.display_name);
            //console.log(_attribute_group.id);
            _attribute_group.max_training = GetMaxAttributeIncrease(_attribute_group.focus, _base_stats.level);
            _attribute_group.max_specialization = GetMaxSkillIncrease(_attributes[key].id);
            //_attributes[key].max = GetMaxAttributeIncrease(_attribute_group.focus, _base_stats.level);
            _attributes[key].value = CalculateAttribute(_attributes[key]);
            _attributes[key].dice = GetDiceList(_attributes[key].value);
        }
    }

    RecalcCharacterStats();
    //RecalcSecondaryAttributes();
    RecalcSkills();
    RecalcWeapons();
}

const RecalcSkills = () => {
    var _skills = BACKEND_get_skills();
    for(var key1 in _skills)
    {
        //let _attribute = BACKEND_get_attribute_by_id(_skills[key1].skill_group_id);
        //_skills[key1].skill_points.total = GetSkillPointsAttribute(_attribute.value);
        for(var key2 in _skills[key1].skills)
        {
            if (typeof _skills[key1].skills[key2] !== 'undefined') {
                //_skills[key1].skills[key2].max = GetMaxSkillIncrease(_skills[key1].skill_group_id/*, _skills[key1].skills[key2]*/);
                _skills[key1].skills[key2].value = CalculateSkill(_skills[key1].skill_group_id, _skills[key1].skills[key2]);
                _skills[key1].skills[key2].dice = GetDiceList(_skills[key1].skills[key2].value);
            }
        }
    }
    RecalcWeapons();
    RecalcCharacterStats();
    BACKEND_set_skills(_skills);

}

const RecalcWeapons = () => {
    let _unarmed_weapons = BACKEND_get_unarmed_weapons();
    let _meleee_weapons = BACKEND_get_melee_weapons();
    let _ranged_weapons = BACKEND_get_ranged_weapons();

    let _recalc_unarmed = RecalcMeleeWeapons(_unarmed_weapons);
    let _recalc_melee = RecalcMeleeWeapons(_meleee_weapons);
    let _recalc_ranged = RecalcRangedWeapons(_ranged_weapons);

    BACKEND_set_unarmed_weapons(_recalc_unarmed);
    BACKEND_set_melee_weapons(_recalc_melee);
    BACKEND_set_ranged_weapons(_recalc_ranged);
}

const RecalcMeleeWeapons = (_weaponList) => {
    for(var key in _weaponList)
    {
        _weaponList[key].attack = CalculateAttack(_weaponList[key]);
        _weaponList[key].defense = CalculateDefense(_weaponList[key]);
        _weaponList[key].attack_dice = GetDiceList(_weaponList[key].attack);
        _weaponList[key].defense_dice = GetDiceList(_weaponList[key].defense);
    }
    return _weaponList;
}

const RecalcRangedWeapons = (_weaponList) => {
    for(var key in _weaponList)
    {
        _weaponList[key].precision = CalculatePrecision(_weaponList[key]);
        _weaponList[key].precision_dice = GetDiceList(_weaponList[key].precision);
    }
    return _weaponList;
} 

const RecalcWeight = () => {
    let _stat_overview = BACKEND_get_stat_overview();
    let _money_list = BACKEND_get_money_list();
    let _melee_weapons = BACKEND_get_melee_weapons();
    let _ranged_weapons = BACKEND_get_ranged_weapons();
    
    let _armor = BACKEND_get_armor();
    let _gear = BACKEND_get_gear();

    let _weight = 0;

    for(var key in _money_list){
        let _delta_weight = _money_list[key].amount*_money_list[key].weight;
        _weight+=_delta_weight;
    }

    for(var key in _melee_weapons){
        let _delta_weight = _melee_weapons[key].weight;
        _weight+=_delta_weight;
    }

    for(var key in _ranged_weapons){
        let _delta_weight1 = _ranged_weapons[key].weight;
        let _delta_weight2 = _ranged_weapons[key].ammo*_ranged_weapons[key].ammo_weight;
        _weight+=_delta_weight1;
        _weight+=_delta_weight2;
    }

    for(var key in _armor){
        let _delta_weight = _armor[key].weight;
        _weight+=_delta_weight;
    }

    for(var key in _gear){
        let _delta_weight = _gear[key].amount*_gear[key].weight;
        _weight+=_delta_weight;
    }

    _stat_overview.carried_weight = _weight;
    
    BACKEND_set_stat_overview(_stat_overview);
}

const RecalcArmor = () => {
    let _stat_overview = BACKEND_get_stat_overview();
    let _armor = BACKEND_get_armor();

    let _armor_class = 0;
    let _armor_restriction = 0;

    for(var key in _armor){
        if(_armor[key].is_put_on)
        {
            _armor_class += _armor[key].armor_class
            _armor_restriction += _armor[key].armor_restriction
        }
    }

    _stat_overview.armor_class = FixDecimalPlaces(_armor_class);
    _stat_overview.armor_restriction = FixDecimalPlaces(_armor_restriction);

    BACKEND_set_stat_overview(_stat_overview);
}

const RecalcMonsterList = () => {
    let _monster_list = BACKEND_get_monster_list();
    
    for(var key in _monster_list){
        _monster_list[key].initiative_dice = GetDiceList(_monster_list[key].initiative);
        _monster_list[key].attack_dice = GetDiceList(_monster_list[key].attack);
        _monster_list[key].defense_dice = GetDiceList(_monster_list[key].defense);
    }
}

export {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeapons,
    RecalcWeight,
    RecalcArmor,
    RecalcMonsterList
};