import { useState, createContext} from 'react'
import {
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_get_character_owner,
    BACKEND_set_character_owner,
    BACKEND_set_character_id,
    BACKEND_get_character_id
} from '../Backend/Parameters/UserData'
import{
    SetTimedMessage
} from './Functions/GeneralFunctions'

const UserDataContext = createContext();

const UserDataContextProvider = ({children}) => {

    //var timer = null;

    //const [legacyLogin, setLegacyLogin] = useState(false); //Später entfernen wenn es nur noch einen Style gibt
    const [user, setUser] = useState({...BACKEND_get_user()});
    const [characterOwner, setCharacterOwner] = useState(BACKEND_get_character_owner());
    const [characterID, setCharacterID] = useState(BACKEND_get_character_id());
    const [remoteMessage, setRemoteMessage] = useState('');
    const [characterReadOnly, setCharacterReadOnly] = useState(false);
    const [campaignReadOnly, setCampaignReadOnly] = useState(false);
    //const [userMode, setUserMode] = useState('undefined');

    //const API_get_legacy_login = () => {return legacyLogin;}
    //const API_set_legacy_login = (_legacy_login) => {setLegacyLogin(_legacy_login);}

    const API_get_user = () => {return user;}
    const API_set_user = () => {setUser({...BACKEND_get_user()});}

    const API_get_character_owner = () => {return characterOwner;}
    const API_set_character_owner = () => {setCharacterOwner(BACKEND_get_character_owner());}

    const API_get_character_id = () => {return characterID;}
    const API_set_character_id = () => {setCharacterID(BACKEND_get_character_id());}

    const API_get_character_read_only = () => {return characterReadOnly;}
    const API_set_character_read_only = (_value) => {setCharacterReadOnly(_value);}

    const API_get_campaign_read_only = () => {return campaignReadOnly;}
    const API_set_campaign_read_only = (_value) => {setCampaignReadOnly(_value);}

    const API_get_remote_connect_message = () => {return remoteMessage;}
    const API_set_remote_connect_message = (_message, _timer = 3000) => {
        SetTimedMessage(_message, setRemoteMessage, _timer)
    }

    return(
        <UserDataContext.Provider value={{
            //API_get_legacy_login, 
            //API_set_legacy_login,
            API_get_user, 
            API_set_user,
            API_get_character_owner,
            API_set_character_owner,
            API_get_character_id,
            API_set_character_id,
            API_get_character_read_only,
            API_set_character_read_only,
            API_get_campaign_read_only,
            API_set_campaign_read_only,
            API_get_remote_connect_message,
            API_set_remote_connect_message
            //API_get_user_mode,
            //API_set_user_mode
        }}>
            {children}
        </UserDataContext.Provider>
    )
}

export {UserDataContext, UserDataContextProvider}