import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../../API/TextDataContext'
import {UserDataContext, UserDataContextProvider} from '../../../API/UserDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../../../API/API'
import {InputField} from '../../ComponentsStylish/InputField';
import plusIcon from '../../../Rss/Images/icons/plus_icon.png';
import rightArrowIcon from '../../../Rss/Images/icons/right_arrow.png';
import table_style from '../../../StylesStylish/table.module.css'
import btn_style from '../../../StylesStylish/btn.module.css'

const FreePointsTable = (props) => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_increment_free_skill_points_bonus
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_skills,
        API_get_free_skill_points,
        API_get_edit_skill_visibility,
        API_set_edit_skill_visibility,
        API_get_skill_selector,
        API_set_skill_selector
    } = useContext(SkillContext);

    const {
        API_get_character_read_only
    } = useContext(UserDataContext);
    

    const incrementPointBonus = (_increment) => {
        if(props.id == 'attribute')
        {
            API_increment_free_attribute_points_bonus(_increment);
        }
        else if(props.id == 'focus')
        {
            API_increment_free_focus_points_bonus(_increment);
        }
        else if(props.id == 'skill')
        {
            API_increment_free_skill_points_bonus(_increment);
        }
    }

    const getHeaderName = () => {
        if(props.id == 'attribute')
        {
            return API_get_table_header_labels().free_attribute_points
        }
        else if(props.id == 'focus')
        {
            return API_get_table_header_labels().free_focus_points
        }
        else if(props.id == 'skill')
        {
            return API_get_table_header_labels().free_skill_points
        }
        return null;
    }

    const getPoints = () => {
        if(props.id == 'attribute')
        {
            return API_get_free_attribute_points();
        }
        else if(props.id == 'focus')
        {
            return API_get_free_focus_points();
        }
        else if(props.id == 'skill')
        {
            return API_get_free_skill_points();
        }
        return 'unknown'
    }

    return ( 
        <div style={{display: 'inline-block'}}>
            <table className = {table_style.std_table}>
                <tr>
                    <td style={{textAlign: 'left', width: 160, minWidth: 160, textAlign: 'center', fontWeight: 'bold'}} colspan={3}>
                        {getHeaderName(props.id)}
                    </td>
                </tr>
                <tr>
                    <td style={{verticalAlign: 'top', width: 25, minWidth: 25, textAlign: 'right'}}></td>
                    <td style={{textAlign: 'center', width: 105, minWidth: 105}} >
                        <InputField type='integer' 
                                    labelVisibility={false} 
                                    widthValue={44} 
                                    content={getPoints().total - getPoints().used} 
                                    setter={null} 
                                    readOnly={API_get_character_read_only()}
                                    buttonOnly={true} 
                                    buttonField={true} 
                                    buttonCallback={incrementPointBonus}
                                    />
                    </td>
                    <td style={{verticalAlign: 'bottom', width: 25, minWidth: 25, textAlign: 'left'}}>
                        {getPoints().bonus > 0 && 
                            <label style={{fontSize: 10, color: 'green'}}> (+{getPoints().bonus})</label>
                        }
                        {getPoints().bonus < 0 && 
                            <label style={{fontSize: 10, color: 'red'}}> ({getPoints().bonus})</label>
                        }
                    </td>
                </tr>
            </table>
        </div>
     );
}
 
export default FreePointsTable